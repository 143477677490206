import { KycStatus, KycType } from 'types/entities'

import { Button, Input, Select, SelectOptions } from 'components/ui'
import { FilterContainer, FilterWrap } from './Style'

type Props = {
  name: string
  kycType: KycType | null
  kycStatus: KycStatus | null
  onChangeName: (name: string) => void
  onChangeKycType: (kycType: KycType | null) => void
  onChangeKycStatus: (kycStatus: KycStatus | null) => void
}

const typesOptions = Object.values(KycType).map<SelectOptions<KycType>>((value) => ({
  value,
  label: value,
}))

const statusesOptions = Object.values(KycStatus).map<SelectOptions<KycStatus>>((value) => ({
  value,
  label: value,
}))

export function Filters({ name, kycType, kycStatus, onChangeName, onChangeKycType, onChangeKycStatus }: Props) {
  const handleChangeKycType = (option: SelectOptions<KycType | null> | null) => {
    if (option?.value) {
      onChangeKycType(option?.value)
    }
  }

  const handleChangeKycStatus = (option: SelectOptions<KycStatus | null> | null) => {
    if (option?.value) {
      onChangeKycStatus(option?.value)
    }
  }

  const handleChangeName = (value: string) => {
    onChangeName(value)
  }

  const handleClearFilters = () => {
    onChangeName('')
    onChangeKycType(null)
    onChangeKycStatus(null)
  }

  return (
    <FilterContainer>
      <FilterWrap>
        <Select<KycType | null> value={kycType} label='KYC type' options={typesOptions} select={handleChangeKycType} />
        <Select<KycStatus | null>
          value={kycStatus}
          label='KYC status'
          options={statusesOptions}
          select={handleChangeKycStatus}
        />
        <Input value={name} inputSize='medium' label='Name' placeholder='Mick' onChange={handleChangeName} />
      </FilterWrap>

      <Button onClick={handleClearFilters}>Clear filters</Button>
    </FilterContainer>
  )
}
