const successful = {
  verified: 'Code successfully verified',
  signIn: 'You are logged in to your account',
  signUp: 'You have registered the account',
  sendCode: 'Code successfully sent',
  logout: 'You are logged out of your account',
}

const errors = {
  signIn: (message: string) => `Sign-in error: ${message}`,
  signUp: (message: string) => `Sign-un error: ${message}`,
  verified: (message: string) => `Code verification error: ${message}`,
  sendCode: (message: string) => `Send code error: ${message}`,
  initKyc: (message: string) => `Init KYC error: ${message}`,
  kyc: (message: string) => `KYC error: ${message}`,
  donation: (message: string) => `Creating new donation error: ${message}`,
  donationTx: (message: string) => `Donation transaction error: ${message}`,
  approveTx: (message: string) => `Approve transaction error: ${message}`,
  revokeTx: (message: string) => `Revoke transaction error: ${message}`,
  logout: (message: string) => `Logout error: ${message}`,
  changeKycStatus: (message: string) => `Change KYC status error: ${message}`,
  changeDonationStatus: (message: string) => `Change Donation status error: ${message}`,
}

export const notifications = {
  errors,
  successful,
}
