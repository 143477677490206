import { useMemo } from 'react'

import { Icon } from 'components/ui'
import { ConfirmationStatuses, ConfirmationStep, Steps } from './types'

import {
  Line,
  Ring,
  SubTitle,
  StepInfo,
  StepLabel,
  StepStatus,
  HeaderModalStepper,
  Steps as StyledSteps,
} from '../Style'

const stepStatuses = {
  [ConfirmationStatuses.IN_LINE]: 'in_line',
  [ConfirmationStatuses.PREPARE]: 'prepare',
  [ConfirmationStatuses.PENDING]: 'pending',
  [ConfirmationStatuses.COMPLETE]: 'complete',
  [ConfirmationStatuses.FAILED]: 'failed',
}

type Props = {
  steps: Steps | null
  stepLength: number
  current: ConfirmationStep | null
}

export function Stepper({ current, stepLength, steps }: Props) {
  const _steps = useMemo(() => (steps ? Object.values(steps) : []), [steps])
  const shouldStepShow = useMemo(() => Boolean(_steps.length > 1), [_steps])

  const subTitle = useMemo(() => {
    switch (stepLength) {
      case 2:
        return <>Crypto donate includes 2&nbsp;steps.</>
      case 3:
        return (
          <>
            To&nbsp;make a&nbsp;new donation, you will need to&nbsp;complete 3&nbsp;quick steps due to&nbsp;the
            technical features of&nbsp;USDT.
          </>
        )
      default:
        return null
    }
  }, [stepLength])

  if (!shouldStepShow) {
    return null
  }

  return (
    <>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}

      <HeaderModalStepper>
        <StyledSteps>
          <Line>
            <Icon name='dottedLine' size='auto' />
          </Line>

          {_steps?.map((step) => (
            <StepInfo key={step.key}>
              <StepLabel isActive={current === step.key} status={stepStatuses[step.status]}>
                <StepStatus>
                  {stepStatuses[step.status] === 'in_line' && <Ring />}
                  {stepStatuses[step.status] === 'prepare' && <Ring />}
                  {stepStatuses[step.status] === 'pending' && <Icon name='loader' size='mediumPlus' />}
                  {stepStatuses[step.status] === 'complete' && <Icon name='tick' size='mediumPlus' />}
                  {stepStatuses[step.status] === 'failed' && <Icon name='cross' size='mediumPlus' />}
                </StepStatus>
                {step.label}
              </StepLabel>
            </StepInfo>
          ))}
        </StyledSteps>
      </HeaderModalStepper>
    </>
  )
}
