const Clear = () => (
  <svg viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M16 8A8 8 0 110 8a8 8 0 0116 0zm-4.893-3.107a1 1 0 010 1.414L9.414 8l1.693 1.693a1 1 0 01-1.414 1.414L8 9.414l-1.693 1.693a1 1 0 01-1.415-1.414L6.585 8 4.893 6.307a1 1 0 011.414-1.414L8 6.586l1.693-1.693a1 1 0 011.414 0z'
      clipRule='evenodd'
    />
  </svg>
)

export { Clear }
