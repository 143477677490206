import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

import { SwitchButtonType, SwitchButtonSize, DirectionType } from './@types'
import { getSize } from './helpers'

export const Wrap = styled.div<{
  typeSwitch: SwitchButtonType
  sizeSwitch: SwitchButtonSize
  direction: DirectionType
}>`
  position: relative;
  padding: 0;
  width: 100%;
  max-width: ${({ sizeSwitch }) => getSize(sizeSwitch).maxWidth};
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'horizontal' ? 'row' : 'column')};
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  border: none;
  border-radius: 100rem;
  overflow: hidden;
  &:first-child {
    &::before {
      display: none;
    }
  }
  ${(props) =>
    props.typeSwitch === 'primary' &&
    css`
      border-top: 0.1rem solid ${(props) => transparentize(0.8, props.theme.color.black)};
      border-bottom: 0.1rem solid ${(props) => transparentize(0.8, props.theme.color.black)};
      border-radius: 0;
    `}
`

export const Slider = styled.div<{ typeSwitch: SwitchButtonType; quantity: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  display: none;
  width: calc(100% / ${({ quantity }) => (quantity ? quantity : 1)});
  border-radius: 10rem;
  transform: translateX(-110%);
  transition: transform 0.25s linear;
  z-index: ${(props) => props.theme.zIndex.default};
  ${(props) =>
    props.typeSwitch === 'default' &&
    css`
      background-color: ${(props) => props.theme.color.default};
    `}
  ${(props) =>
    props.typeSwitch === 'primary' &&
    css`
      background-color: ${(props) => props.theme.color.default};
      border-radius: 0;
    `}
`

export const Label = styled.label<{
  quantity?: number
  disabled?: boolean
  typeSwitch: SwitchButtonType
  sizeSwitch: SwitchButtonSize
}>`
  position: relative;
  padding: 0;
  width: calc(100% / ${({ quantity }) => (quantity ? quantity : 1)});
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.text};
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: ${({ sizeSwitch }) => getSize(sizeSwitch).fontSize};
  line-height: ${({ sizeSwitch }) => getSize(sizeSwitch).lineHeight};
  letter-spacing: ${({ sizeSwitch }) => getSize(sizeSwitch).letterSpacing};
  text-transform: capitalize;
  transition: color 0.25s ease-out;
  z-index: ${(props) => props.theme.zIndex.minimal};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${(props) => props.theme.opacity05};
      cursor: not-allowed;
    `}
  ${(props) =>
    props.sizeSwitch === 'default' &&
    css`
      padding: 0.8rem 1.6rem;
      ${(props) => props.theme.breakpoints.greaterThan('md')`
        padding: 1.4rem;
      `};
    `}
  ${(props) =>
    props.sizeSwitch === 'medium' &&
    css`
      padding: 0.8rem 1.6rem;
      ${(props) => props.theme.breakpoints.greaterThan('md')`
        padding: 1.2rem;
      `};
    `}
  ${(props) =>
    props.sizeSwitch === 'small' &&
    css`
      padding: 1rem 1.25rem;
    `}
`

export const Radio = styled.input<{
  typeSwitch: SwitchButtonType
}>`
  position: absolute;
  width: 0;
  height: 0;
  opacity: ${(props) => props.theme.opacity.invisible};
  ${(props) =>
    props.typeSwitch === 'default' &&
    css`
      &:nth-of-type(1):checked
        + ${Label},
        &:nth-of-type(2):checked
        + ${Label},
        &:nth-of-type(3):checked
        + ${Label},
        &:nth-of-type(4):checked
        + ${Label} {
        color: ${(props) => props.theme.color.textPrimary};
      }
    `}
  ${(props) =>
    props.typeSwitch === 'primary' &&
    css`
      &:nth-of-type(1):checked
        + ${Label},
        &:nth-of-type(2):checked
        + ${Label},
        &:nth-of-type(3):checked
        + ${Label},
        &:nth-of-type(4):checked
        + ${Label} {
        color: ${(props) => props.theme.color.textPrimary};
      }
    `}
  &:nth-of-type(1):checked ~ ${Slider} {
    display: block;
    transform: translateX(0);
  }
  &:nth-of-type(2):checked ~ ${Slider} {
    display: block;
    transform: translateX(100%);
  }
  &:nth-of-type(3):checked ~ ${Slider} {
    display: block;
    transform: translateX(200%);
  }
  &:nth-of-type(4):checked ~ ${Slider} {
    display: block;
    transform: translateX(300%);
  }
`

export const LoadingWrap = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.8rem;
`
