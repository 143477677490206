import styled, { css } from 'styled-components/macro'

import { IconProps } from './@types'
import { getSize } from './helpers'

export const IconWrapper = styled.span<IconProps>`
  box-sizing: border-box;
  padding: ${({ size }) => getSize(size).padding};
  width: ${({ size }) => getSize(size).width};
  height: ${({ size }) => getSize(size).height};
  display: block;
  justify-content: center;
  align-items: center;
  color: inherit;
  transition: transform 0.3s ease-out;
  transform: rotate(${({ rotate }) => rotate});
  ${(props) =>
    props.noTransition &&
    css`
      transition: none;
    `}
  svg {
    display: block;
    fill: ${({ fill }) => fill};
  }
`
