import { BigNumber } from 'ethers'
import { Token, Balances } from 'types/entities'

import { fromWei, isEther } from 'utils/crypto'

import { Multicall } from '_contracts'
import { getERC20Token } from 'contracts'

import { MulticallArgs, MulticallParams } from './types'

const GAS_LIMIT = 100000
export const getMulticallParams = (
  { customer, tokens, chainId, rpcUrl }: MulticallArgs,
  multicallContract: Multicall,
) => {
  const randomToken = tokens.find((token) => !isEther(token.address))
  const erc20Token = randomToken ? getERC20Token(randomToken.address, chainId, rpcUrl) : undefined

  const getMulticallParam = (acc: MulticallParams, token: Token) => {
    if (isEther(token.address)) {
      const callData = multicallContract.interface.encodeFunctionData('getEthBalance', [customer])

      acc.push({ callData, gasLimit: GAS_LIMIT, target: multicallContract.address })
    } else if (erc20Token) {
      const callData = erc20Token.interface.encodeFunctionData('balanceOf', [customer])

      acc.push({ callData, gasLimit: GAS_LIMIT, target: token.address })
    }
    return acc
  }

  const multicallParams = tokens.reduce<MulticallParams>(getMulticallParam, [])
  return multicallParams
}

export const formatBalances = (acc: Balances, curr: { returnData: string }, token: Token) => {
  const { address, decimals } = token
  const value = curr.returnData === '0x' ? '0x0' : curr.returnData

  acc[address] = {
    balance: fromWei(value, decimals),
    bgBalance: BigNumber.from(value)._hex,
  }
  return acc
}
