import { useEffect, useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useClearState as useClearKycState } from 'state/kyc/hooks'
import { useClearState as useClearAuthState } from 'state/auth/hooks'
import { useClearState as useClearUserState, useShouldEmailVerify, useUpdateUser, useUser } from 'state/user/hooks'
import { useLogoutRequest, useSignUpGetCodeRequest, useSignUpVerifyEmailRequest } from 'state/auth/hooks'

import { Links } from 'constants/link'
import { notifications } from 'constants/notifications'

import { useCallNotification } from 'notifications/hooks'
import { NotificationTypes } from 'notifications/service'

import { Button, Icon } from 'components/ui'
import { TransactionsList, VerifyEmail } from 'components/features'

import { Section, Container, Wrap, Title, Info, InfoContent, InfoContentValue, VerifyWrap } from './Style'

export function Profile() {
  const user = useUser()
  const callNotification = useCallNotification()
  const shouldEmailVerify = useShouldEmailVerify()

  const { onLogout, isLoading } = useLogoutRequest()
  const { refetch, ...updateUserRequest } = useUpdateUser()
  const { onSendCode, ...sendCodeRequest } = useSignUpGetCodeRequest()
  const { onVerifyEmail, ...verifyEmailRequest } = useSignUpVerifyEmailRequest()

  const [emailVerifyShow, setEmailVerifyShow] = useState(false)

  const clearKycState = useClearKycState()
  const clearUserState = useClearUserState()
  const clearAuthState = useClearAuthState()

  const navigate = useNavigate()

  useEffect(() => {
    refetch()
  }, [])

  const handleLogout = async () => {
    try {
      await onLogout()
      callNotification({ type: NotificationTypes.ERROR, message: notifications.successful.logout })
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.logout(err.message) })
    } finally {
      clearKycState()
      clearUserState()
      clearAuthState()
      navigate(Links.SIGN_IN)
    }
  }

  const handleChangeEmailVerifyView = () => {
    setEmailVerifyShow(!emailVerifyShow)
  }

  const handleVerifyClick = async () => {
    try {
      handleChangeEmailVerifyView()
      await onSendCode()
      callNotification({ type: NotificationTypes.SUCCESS, message: notifications.successful.sendCode })
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.sendCode(err.message) })
    }
  }

  const handleVerifyCode = async (code: string) => {
    await onVerifyEmail(code)
    refetch()
    handleChangeEmailVerifyView()
  }

  const verifyState = useMemo(() => {
    return { ...verifyEmailRequest, isLoading: verifyEmailRequest.isLoading || updateUserRequest.isLoading }
  }, [verifyEmailRequest, updateUserRequest.isLoading])

  return (
    <Section>
      <Container>
        <Wrap>
          <Title>Profile</Title>
          <Button type='primary' size='medium' onClick={handleLogout} isLoading={isLoading}>
            Logout <Icon name='exit' size='mediumPlus' />
          </Button>
        </Wrap>

        {user?.email && emailVerifyShow ? (
          <VerifyEmail
            email={user?.email}
            callGetCode={onSendCode}
            verify={handleVerifyCode}
            verifyState={verifyState}
            title='Verify your e-mail'
            sendCodeState={sendCodeRequest}
          />
        ) : (
          <>
            <Info>
              <InfoContent>
                <InfoContentValue>
                  E-mail: <b>{user?.email}</b>
                </InfoContentValue>
                {shouldEmailVerify && (
                  <VerifyWrap>
                    <Button
                      type='outline'
                      size='medium'
                      onClick={handleVerifyClick}
                      isLoading={sendCodeRequest.isLoading}
                    >
                      Verify
                    </Button>
                  </VerifyWrap>
                )}
              </InfoContent>
            </Info>

            <TransactionsList />
          </>
        )}
      </Container>
    </Section>
  )
}
