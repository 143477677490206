import { DocStatus } from 'types/entities'

import { sourceOfFunds } from 'constants/app'
import { Button, Input, Select, SelectOptions } from 'components/ui'
import { FilterContainer, FilterWrap } from './Style'

type Props = {
  email: string
  sof: string | null
  docStatus: DocStatus | null
  onChangeEmail: (name: string) => void
  onChangeSof: (sof: string | null) => void
  onChangeDocStatus: (kycStatus: DocStatus | null) => void
}

const sofOptions = Object.values(sourceOfFunds).map<SelectOptions<string>>((value) => ({
  value,
  label: value,
}))

const statusesOptions = Object.values(DocStatus).map<SelectOptions<DocStatus>>((value) => ({
  value,
  label: value,
}))

export function Filters({ email, docStatus, sof, onChangeEmail, onChangeSof, onChangeDocStatus }: Props) {
  const handleChangeSof = (option: SelectOptions<string | null> | null) => {
    if (option?.value) {
      onChangeSof(option?.value)
    }
  }

  const handleChangeDocStatus = (option: SelectOptions<DocStatus | null> | null) => {
    if (option?.value) {
      onChangeDocStatus(option?.value)
    }
  }

  const handleChangeName = (value: string) => {
    onChangeEmail(value)
  }

  const handleClearFilters = () => {
    onChangeEmail('')
    onChangeSof(null)
    onChangeDocStatus(null)
  }

  return (
    <FilterContainer>
      <FilterWrap>
        <Select<string | null> value={sof} label='Source of funds' options={sofOptions} select={handleChangeSof} />
        <Select<DocStatus | null>
          value={docStatus}
          label='Document status'
          options={statusesOptions}
          select={handleChangeDocStatus}
        />
        <Input
          value={email}
          label='E-mail'
          inputSize='medium'
          placeholder='example@gmail.com'
          onChange={handleChangeName}
        />
      </FilterWrap>

      <Button onClick={handleClearFilters}>Clear filters</Button>
    </FilterContainer>
  )
}
