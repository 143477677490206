import { useCallback, useEffect, useRef, useState } from 'react'

import { numbers } from 'constants/variables'

export function useSimpleTimer(seconds = 20) {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [time, setTime] = useState(seconds)

  const run = useCallback(
    (restarted?: boolean) => {
      if (restarted) {
        setTime(seconds)
      }
      timer.current = setTimeout(() => {
        setTime((_time) => {
          if (_time === 1 && timer.current) {
            clearTimeout(timer.current)
          } else {
            run()
          }
          return _time - 1
        })
      }, numbers.SECOND)
    },
    [time],
  )

  const stop = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
  }, [time])

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current)
    }
  }, [])

  return { time, run, stop }
}
