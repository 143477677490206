import { BigNumber } from 'ethers'

const numbers = {
  ZERO: 0,
  ONE: 1,
  PAGE_STEP: 1,
  SECOND: 1000,
  MINUTE: 60000,
  MD_WIDTH: 1024,
  COPY_DELAY: 800,
  MAX_PERCENT: 100,
  ONE_HUNDRED: 100,
  DAYS_PER_YEAR: 365,
  ONE_THOUSAND: 1000,
  MS_IN_HOUR: 3600000,
  HOURS_IN_YEAR: 8760,
  SECONDS_IN_MINUTE: 60,
  NOTIFY_HIDE_DELAY: 4000,
  NOTIFY_REMOVE_DELAY: 400,
  SECONDS_IN_YEAR: 31536000,
}

const crypto = {
  USD: 1e6,
  WEI: 1e18,
  GWEI: 1e9,
  GWEI_DECIMALS: 9,
  USD_DECIMALS: 6,
  WEI_DECIMALS: 18,
  ZERO_X_LENGTH: 2,
  BG_ZERO: BigNumber.from(numbers.ZERO),
  ONE_ETHER: BigNumber.from(String(1e18)),
  MAX_UINT: '115792089237316195423570985008687907853269984665640564039457584007913129639935',
}

export { numbers, crypto }
