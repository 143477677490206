import styled from 'styled-components/macro'

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const CheckboxLabel = styled.label`
  display: block;
  cursor: pointer;
`

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.white};
  border: 0.1rem solid ${(props) => props.theme.color.elementBorderSecond};
  border-radius: 0.6rem;
  transition: background-color 0.2s, border-color 0.2s;
`

export const CheckboxIcon = styled.div`
  display: block;
  opacity: ${(props) => props.theme.opacity.invisible};
  transition: opacity 0.2s;
`

export const CheckboxInput = styled.input`
  position: absolute;
  margin: -0.1rem;
  width: 0.1rem;
  height: 0.1rem;
  clip: rect(0.1rem 0.1rem 0.1rem 0.1rem);
  &:hover ~ ${CheckboxContainer} {
    border-color: ${(props) => props.theme.color.checkboxHover};
  }
  &:focus ~ ${CheckboxContainer} {
    border-color: ${(props) => props.theme.color.default};
  }
  &:checked ~ ${CheckboxContainer} {
    background-color: ${(props) => props.theme.color.default};
    border-color: ${(props) => props.theme.color.default};
  }
  &:checked ~ ${CheckboxContainer} > ${CheckboxIcon} {
    opacity: ${(props) => props.theme.opacity.default};
  }
`
