import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

export const Container = styled.div<{ maxWidth: string }>`
  position: relative;
  margin: 3.2rem auto;
  padding: 1.6rem;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  margin: 0 0 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2.4rem;
  line-height: 1.44;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    font-size: 3.2rem;
  `};
`

export const PreTitle = styled.h4`
  margin: 0 0 3.2rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.33;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  ${(props) => props.theme.breakpoints.greaterThan('xsm')`
     margin: 0 0 5.8rem;
  `};
`

export const Wrap = styled.div<{ type?: string }>`
  margin: 0 0 3.2rem;
  display: flex;
  ${(props) =>
    props.type === 'selectKYC' &&
    css`
      flex-direction: column;
      justify-content: center;
    `}
`

export const IconWrapChevron = styled.div`
  margin: 0;
  display: flex;
  flex: 0 0 auto;
  color: ${(props) => props.theme.color.elementBgSecond};
  transition: all 0.26s ease-out;
`

export const SelectButton = styled.button<{ type?: string }>`
  padding: 2.4rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  background-color: ${(props) => props.theme.color.white};
  border: none;
  border-radius: 1.6rem;
  transition: all 0.26s ease-out;
  box-shadow: 0 0 0.6rem ${(props) => transparentize(0.7, props.theme.color.black)};
  cursor: pointer;
  &:disabled {
    opacity: ${(props) => props.theme.opacity.opacity05};
  }
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    box-shadow: 0 0 2rem ${(props) => transparentize(0.7, props.theme.color.black)};
    ${IconWrapChevron} {
      transform: translateX(0.4rem);
    }
  }
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
     gap: 2rem;
  `};
`

export const SelectButtonContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.6rem;
  color: ${(props) => props.theme.color.elementBgSecond};
`

export const SelectButtonTitle = styled.h5`
  margin: 0;
  font-family: ${(props) => props.theme.font.family.primary};
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2rem;
  line-height: 1.43;
  text-align: left;
  color: ${(props) => props.theme.color.text};
`

export const SelectButtonText = styled.p`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.33;
  color: ${(props) => props.theme.color.text};
`

export const ErrorWrap = styled.div`
  margin: 1.6rem 0 0;
  min-height: 3.6rem;
`

export const ErrorContent = styled.span`
  margin: 0;
  padding: 0.6rem 1rem;
  width: fit-content;
  display: flex;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.67;
  letter-spacing: 0.06rem;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.danger};
  border-radius: 0.6rem;
  z-index: ${(props) => props.theme.zIndex.minimal};
  &::first-letter {
    text-transform: uppercase;
  }
`

export const IconWrap = styled.div`
  margin: 0;
  display: flex;
  flex: 0 0 auto;
  color: ${(props) => props.theme.color.darkBg};
`

export const Divider = styled.div`
  position: relative;
  margin: 2.6rem auto 1.6rem;
  max-width: 42rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &::before {
    content: '';
    position: absolute;
    top: 1.1rem;
    left: 0;
    width: 100%;
    border-bottom: 0.2rem dashed ${(props) => props.theme.color.default};
  }
`

export const DividerText = styled.span`
  position: relative;
  padding: 0 1.2rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.33;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  background-color: ${(props) => props.theme.color.pageBg};
`
