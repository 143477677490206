import { useEffect, useMemo } from 'react'

import { useStepper } from 'hooks/app'

import { useShouldEmailVerify, useUpdateUser, useUser } from 'state/user/hooks'

import { Links, SUPPORT_EMAIL } from 'constants/link'

import { FormLoader } from 'components/ui'
import { DonationFlow, VerificationFlow } from 'components/features'

import { Container, Title, PreTitle, StyledNavLink, SupportLink } from './Style'

export function Main() {
  const user = useUser()

  const { isPayment } = useStepper()
  const { refetch, isLoading } = useUpdateUser()
  const shouldEmailVerify = useShouldEmailVerify()

  const _isLoading = useMemo(() => !user && isLoading, [user, isLoading])
  const _isFlow = useMemo(() => user && !_isLoading, [user, _isLoading])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <>
      {shouldEmailVerify ? (
        <Container>
          <Title>Please verify your e-mail in&nbsp;Profile tab</Title>
          <PreTitle>You need to&nbsp;verify your email in&nbsp;order to&nbsp;continue</PreTitle>
          <StyledNavLink to={Links.PROFILE}>Go&nbsp;to&nbsp;Profile</StyledNavLink>
        </Container>
      ) : (
        <>
          {_isLoading && <FormLoader />}

          {!user && !isLoading && (
            <Container>
              <Title>Something went wrong!</Title>
              <PreTitle>
                Can&rsquo;t to&nbsp;fetch your account, please{' '}
                <SupportLink href={SUPPORT_EMAIL}>contact support</SupportLink>.
              </PreTitle>
            </Container>
          )}

          {_isFlow && <>{isPayment ? <DonationFlow /> : <VerificationFlow />}</>}
        </>
      )}
    </>
  )
}
