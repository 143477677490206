import { useEffect, useMemo, useRef, useState } from 'react'
import { useFeeData } from 'wagmi'

import { CHAIN_INFO } from 'constants/chains'

import { useChainData } from './useChainData'

export function useGasFetcher() {
  const [isMounted, setMounted] = useState(false)
  const { chainId } = useChainData()

  const timeout = useRef<NodeJS.Timeout | null>(null)
  const feeData = useFeeData()

  const recursiveCall = async () => {
    try {
      if (isMounted) {
        await feeData.refetch()
      }
    } finally {
      timeout.current = setTimeout(() => recursiveCall(), CHAIN_INFO[chainId].blockDuration)
    }
  }
  useEffect(() => {
    setMounted(true)
    recursiveCall()
    return () => {
      setMounted(false)

      if (timeout.current) {
        clearTimeout(timeout.current)
        timeout.current = null
      }
    }
  }, [])

  return useMemo(
    () => ({
      data: feeData.data,
      isLoading: feeData.isLoading,
      cost: feeData.data?.maxFeePerGas?.toString() || feeData.data?.gasPrice?.toString() || '0',
    }),
    [feeData.data],
  )
}
