import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { ThemeProvider } from 'styled-components/macro'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from 'state'

import App from 'pages/App'

import { ModalConductor } from 'modal'
import { Notifications } from 'notifications'
import { defaultTheme, GlobalStyle } from 'theme'

import { Updater } from 'containers/Updater'
import RainbowProvider from 'containers/RainbowProvider'

const RELEASE = process.env.REACT_APP_VERSION || '0.0.1'
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_DSN) {
  Sentry.init({
    release: RELEASE,
    dsn: process.env.REACT_APP_API_DSN,
    tracePropagationTargets: [/http:\/\/localhost:.*$/, /https:\/\/.*\.wewantjusticedao\.org/],
  })
}

const render = () => {
  // will render twice, don't worry it is only for dev mode
  // https://github.com/facebook/react/issues/24467#issuecomment-1113273743
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  return root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <ThemeProvider theme={defaultTheme}>
            <RainbowProvider>
              <Updater />
              <GlobalStyle />
              <ModalConductor />
              <Notifications />
              <App />
            </RainbowProvider>
          </ThemeProvider>
        </HashRouter>
      </PersistGate>
    </Provider>,
  )
}

render()
