import { useMemo } from 'react'

import { Name, Location } from 'types/entities'

import { Input, Wrap } from 'components/ui'
import { AddressField } from 'components/features'
import { ValidationResults } from 'utils/validation'

import { FormWrap, ContainerKYC } from './Style'

type Props = {
  userName: Name
  location?: Location
  withLocation?: boolean
  validationResults: ValidationResults
  onChangeUserName: (userName: Name) => void
  onChangeLocation?: (location: Location) => void
}

export function UserData({
  userName,
  location,
  onChangeUserName,
  onChangeLocation,
  validationResults,
  withLocation = true,
}: Props) {
  const handleChangLocation = (location: Location) => {
    if (typeof onChangeLocation === 'function') {
      onChangeLocation(location)
    }
  }

  const handleChangeName = (value: string, name?: string) => {
    if (name) {
      onChangeUserName({ ...userName, [name]: value })
    }
  }

  const locationErrors = useMemo(
    () => ({
      city: validationResults.city,
      region: validationResults.region,
      country: validationResults.country,
      postcode: validationResults.postcode,
      addressLine1: validationResults.addressLine1,
      addressLine2: validationResults.addressLine2,
    }),
    [validationResults],
  )

  return (
    <ContainerKYC>
      <FormWrap withLocation={withLocation}>
        <Wrap gridArea='FirstName'>
          <Input
            label='First Name'
            name='firstName'
            inputSize='medium'
            placeholder='Nikolas'
            value={userName.firstName}
            onChange={handleChangeName}
            error={validationResults.firstName?.isError}
            errorMessage={validationResults.firstName?.errorMessage}
          />
        </Wrap>
        <Wrap gridArea='LastName'>
          <Input
            label='Last Name'
            name='lastName'
            inputSize='medium'
            placeholder='Klatcher'
            value={userName.lastName}
            onChange={handleChangeName}
            error={validationResults.lastName?.isError}
            errorMessage={validationResults.lastName?.errorMessage}
          />
        </Wrap>
        {withLocation && (
          <AddressField location={location} onChange={handleChangLocation} locationErrors={locationErrors} />
        )}
      </FormWrap>
    </ContainerKYC>
  )
}
