import styled, { css } from 'styled-components/macro'
import { appConstants } from 'constants/app'

export const Container = styled.div<{ value: string }>`
  padding: 0.4rem 1.2rem;
  min-width: 5.6rem;
  height: 2.4rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  color: ${(props) => props.theme.color.textPrimary};
  background-color: ${(props) => props.theme.color.black};
  border-radius: 1.6rem;
  ${(props) =>
    appConstants.STATUSES.SUCCESS.includes(props.value) &&
    css`
      color: ${(props) => props.theme.color.textPrimary};
      background-color: ${(props) => props.theme.color.success};
    `}
  ${(props) =>
    appConstants.STATUSES.WARNING.includes(props.value) &&
    css`
      color: ${(props) => props.theme.color.text};
      background-color: ${(props) => props.theme.color.warning};
    `}
  ${(props) =>
    appConstants.STATUSES.DANGER.includes(props.value) &&
    css`
      color: ${(props) => props.theme.color.textPrimary};
      background-color: ${(props) => props.theme.color.danger};
    `}
  ${(props) =>
    appConstants.STATUSES.NEW.includes(props.value) &&
    css`
      color: ${(props) => props.theme.color.textPrimary};
      background-color: ${(props) => props.theme.color.default};
    `}
`

export const Text = styled.span`
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  color: inherit;
  text-transform: capitalize;
`
