import styled from 'styled-components/macro'
import { transparentize } from 'polished'

export const Banner = styled.section`
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  gap: 7rem;
  background-color: ${(props) => props.theme.color.default};
  border-top: 0.3rem solid ${(props) => props.theme.color.textTertiary};
  overflow: hidden;
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    display: flex;
  `};
`

export const IconWrap = styled.div`
  position: absolute;
  top: 1%;
  left: -1%;
  width: 30.6rem;
  height: 37.4rem;
  color: ${(props) => transparentize(0.94, props.theme.color.white)};
`

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const BannerTitle = styled.h3`
  margin: 0;
  padding: 4rem 4rem 0 4rem;
  font-family: ${(props) => props.theme.font.family.primary};
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: ${(props) => props.theme.font.size.maxPlus};
  line-height: ${(props) => props.theme.font.lineHeight.small};
  color: ${(props) => props.theme.color.textPrimary};
  ${(props) => props.theme.breakpoints.greaterThan('lg')`
     padding: 8rem 4rem 0 4rem;
  `};
`

export const BannerImageWrap = styled.div`
  width: 100%;
  max-height: 56%;
  border-top: 0.3rem solid ${(props) => props.theme.color.textTertiary};
`

export const BannerImage = styled.img`
  width: 50%;
  height: 100%;
  max-height: 46rem;
  object-fit: cover;
  object-position: top;
  user-select: none;
  pointer-events: none;
  &:first-child {
    filter: contrast(120%);
    border-right: 0.3rem solid ${(props) => props.theme.color.textTertiary};
  }
`
