import { useState } from 'react'

import { numbers } from 'constants/variables'
import { copyToClipboard } from 'utils/copyToClipboard'

type ReturnValue = [boolean, VoidFunction]

export function useCopy(value: string): ReturnValue {
  const [isCopied, setCopy] = useState(false)

  const handleClick = () => {
    setCopy(true)
    copyToClipboard(value)

    setTimeout(() => {
      setCopy(false)
    }, numbers.COPY_DELAY)
  }

  return [isCopied, handleClick]
}
