import styled from 'styled-components/macro'

export const Title = styled.h1`
  margin: 3.2rem 0 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2.4rem;
  line-height: 1.44;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
     font-size: 3.2rem;
  `};
`

export const Wrap = styled.div`
  padding: 1.6rem;
  width: 100%;
  max-width: 144rem;
  display: grid;
  grid-template-column: 1fr;
  gap: 3.2rem 1.6rem;
  grid-template-areas:
    'Donations'
    'Users'
    'DocsTable';
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    grid-template-column: repeat(2, 1fr);
    grid-template-areas:
      'Donations Users'
      'DocsTable DocsTable';
  `};
`
