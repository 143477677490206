import { useCallback, useMemo } from 'react'

import { AppState } from 'state'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { errors } from 'constants/errors'
import { getApiError } from 'utils/helpers'

import { CheckExternalInput, InitExternalKycInput, UpdateKycInput, UpdateKycStatusInput } from './types'
import { kycClearState, setToken } from './slice'
import {
  useGetAllKycQuery,
  useInitKycMutation,
  useUpdateKycMutation,
  useCheckExternalMutation,
  useUpdateKycStatusMutation,
  useInitExternalKycMutation,
} from './api'

const POOLING_INTERVAL = 30_000
export function useGetAllKycRequest(shouldPooling: boolean, pollingInterval = POOLING_INTERVAL) {
  const request = useGetAllKycQuery(shouldPooling, {
    skip: !shouldPooling,
    pollingInterval,
  })

  return { ...request, error: getApiError(request.error) }
}

export function useOnInitKyc() {
  const [initKyc, requestState] = useInitKycMutation()
  const onInitKyc = useCallback(async () => {
    try {
      const response = await initKyc().unwrap()
      return response
    } catch (err) {
      throw new Error(getApiError(err))
    }
  }, [initKyc])

  return { ...requestState, error: getApiError(requestState.error), onInitKyc }
}

export function useOnInitExternalKyc() {
  const [initExternalKyc, requestState] = useInitExternalKycMutation()

  const onExternalKyc = useCallback(
    async (params: InitExternalKycInput) => {
      try {
        const response = await initExternalKyc(params).unwrap()
        return response
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [initExternalKyc],
  )

  return { ...requestState, error: getApiError(requestState.error), onExternalKyc }
}

export function useOnExternalKycStart() {
  const setToken = useSetToken()

  const { onExternalKyc, ...restExternal } = useOnInitExternalKyc()

  const onStart = useCallback(
    async (firstName: string, lastName: string, location: string) => {
      if (!firstName || !lastName) {
        throw new Error(errors.validation.NAMES_REQUIRED)
      }
      const token = await onExternalKyc({ firstName, lastName, location })
      setToken(token)
      return token
    },
    [onExternalKyc, setToken],
  )

  return useMemo(() => ({ onStart, ...restExternal }), [restExternal])
}

export function useOnSelfHostedKycStart() {
  const { onInitKyc, ...requestState } = useOnInitKyc()

  const onStart = useCallback(async () => {
    return await onInitKyc()
  }, [onInitKyc])

  return useMemo(() => ({ onStart, ...requestState }), [requestState])
}

export function useOnUpdateKyc() {
  const [updateKyc, requestState] = useUpdateKycMutation()

  const onUpdateKyc = useCallback(
    async (params: UpdateKycInput) => {
      try {
        const response = await updateKyc(params).unwrap()
        return response
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [updateKyc],
  )

  return { ...requestState, error: getApiError(requestState.error), onUpdateKyc }
}

export function useOnCheckExternalKyc() {
  const [checkExternalKyc, requestState] = useCheckExternalMutation()

  const onCheckExternalKyc = useCallback(
    async (params: CheckExternalInput) => {
      try {
        const response = await checkExternalKyc(params).unwrap()
        return response
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [checkExternalKyc],
  )

  return { ...requestState, error: getApiError(requestState.error), onCheckExternalKyc }
}

export function useOnUpdateKycStatus() {
  const [updateKycStatus, requestState] = useUpdateKycStatusMutation()
  const onUpdateKycStatus = useCallback(
    async (params: UpdateKycStatusInput) => {
      try {
        const response = await updateKycStatus(params).unwrap()
        return response
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [updateKycStatus],
  )

  return { ...requestState, error: getApiError(requestState.error), onUpdateKycStatus }
}

export function useSetToken() {
  const dispatch = useAppDispatch()
  return useCallback(
    (token: string) => {
      dispatch(setToken(token))
    },
    [dispatch],
  )
}

export function useClearState() {
  const dispatch = useAppDispatch()
  return useCallback(() => {
    dispatch(kycClearState())
  }, [dispatch])
}

// selectors
export function useToken() {
  return useAppSelector((state: AppState) => state.kyc.token)
}
