import { useCallback, useMemo } from 'react'

import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'

// for get query param from url
export function usePathParams() {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const query = useMemo(() => new URLSearchParams(location.search), [location.search])

  const addToQuery = useCallback(
    (key: string, value: string | null) => {
      if (value === null) {
        searchParams.delete(key)
      } else {
        searchParams.set(key, value)
      }
      setSearchParams(searchParams)
    },
    [query, setSearchParams, location, searchParams],
  )

  return { query, addToQuery, searchParams, setSearchParams, ...location }
}
