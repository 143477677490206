import { Kyc } from 'types/entities'

import { CellProps } from 'components/ui'
import { Wrapper, Title, Value } from '../Style'

export function ControlType({ row }: CellProps<Kyc>) {
  const { controlType } = row.original

  return (
    <Wrapper area='ControlType'>
      <Title>Control Type</Title>
      <Value>{controlType}</Value>
    </Wrapper>
  )
}
