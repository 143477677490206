import { useEffect, useMemo, useState } from 'react'

import { KycType } from 'types/entities'

import { useKycType } from 'state/user/hooks'
import { useOnInitExternalKyc, useOnInitKyc } from 'state/kyc/hooks'

import { FormLoader } from 'components/ui'

import { SelfHosted } from './SelfHosted'
import { ExternalKyc } from './ExternalKyc'
import { KycSelector } from './KycSelector'

export function Kyc() {
  const uerKycType = useKycType()

  const [kycType, setKycType] = useState(uerKycType)

  const initKycRequest = useOnInitKyc()
  const initExternalKycRequest = useOnInitExternalKyc()

  useEffect(() => {
    updateKycType(uerKycType)
  }, [uerKycType])

  const updateKycType = (_kycType: KycType) => {
    setKycType(_kycType)
  }

  const isSelfKyc = useMemo(() => kycType === KycType.SELF, [kycType])
  const isExternal = useMemo(() => kycType === KycType.KYC, [kycType])
  const isNotSelected = useMemo(() => kycType === KycType.NOT_SELECT, [kycType])

  const isLoading = useMemo(
    () => initKycRequest.isLoading || initExternalKycRequest.isLoading,
    [initExternalKycRequest.isLoading, initKycRequest.isLoading],
  )
  return (
    <>
      {isLoading ? (
        <FormLoader />
      ) : (
        <>
          {isNotSelected && <KycSelector onChangeKycType={updateKycType} />}
          {isExternal && <ExternalKyc />}
          {isSelfKyc && <SelfHosted />}
        </>
      )}
    </>
  )
}
