import { useMemo } from 'react'

import { Route, Routes } from 'react-router-dom'

import { useScrollToTop } from 'hooks/router'

import { useIsAdmin, useIsManager } from 'state/user/hooks'

import { Links } from 'constants/link'

import { PrivateOutlet } from './PrivateOutlet'

import Main from './Main'
import SignIn from './SignIn'
import Profile from './Profile'
import SignUp from './SignUp'
import Dashboard from './Dashboard'
// import RestorePassword from './RestorePassword'
import { ManagerSignUp } from './ManagerSignUp'

import NotFound from './NotFound'

function Router() {
  const isAdmin = useIsAdmin()
  const isManager = useIsManager()

  useScrollToTop()

  const havePermissionForApprove = useMemo(() => isManager || isAdmin, [isManager, isAdmin])
  const routes = useMemo(() => {
    const _routes = [
      {
        path: Links.MAIN,
        element: havePermissionForApprove ? <Dashboard /> : <Main />,
      },
      {
        path: Links.PROFILE,
        element: <Profile />,
      },
    ]

    if (isAdmin) {
      _routes.push({
        path: Links.MANGER_SIGN_UP,
        element: <ManagerSignUp />,
      })
    }
    return _routes
  }, [isAdmin, havePermissionForApprove])

  return (
    <Routes>
      <Route path={Links.SIGN_IN} element={<SignIn />} />
      <Route path={Links.SIGN_UP} element={<SignUp />} />
      {/*<Route path={Links.RESTORE_PASSWORD} element={<RestorePassword />} />*/}

      <Route element={<PrivateOutlet />}>
        {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </Route>

      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default Router
