import { Eye } from './Eye'
import { Eth } from './Eth'
import { Dai } from './Dai'
import { Bnb } from './Bnb'
import { Pen } from './Pen'
import { Copy } from './Copy'
import { Exit } from './Exit'
import { Info } from './Info'
import { Logo } from './Logo'
import { Lock } from './Lock'
import { Tick } from './Tick'
import { Wbnb } from './Wbnb'
import { Busd } from './Busd'
import { Usdc } from './Usdc'
import { Usdt } from './Usdt'
import { Weth } from './Weth'
import { Clear } from './Clear'
import { GöETH } from './GöETH'
import { Error } from './Error'
import { Ghost } from './Ghost'
import { Clock } from './Clock'
import { Cross } from './Cross'
import { Arrow } from './Arrow'
import { Matic } from './Matic'
import { Skull } from './Skull'
import { Eraser } from './Eraser'
import { Loader } from './Loader'
import { SentTx } from './SentTx'
import { EyeOff } from './EyeOff'
import { Search } from './Search'
import { Danger } from './Danger'
import { Warning } from './Warning'
import { Success } from './Success'
import { Chevron } from './Chevron'
import { Rounded } from './Rounded'
import { DownLoad } from './DownLoad'
import { NoContent } from './NoContent'
import { DoubleTick } from './DoubleTick'
import { DottedLine } from './DottedLine'
import { Fingerprint } from './Fingerprint'
import { ExternalLink } from './ExternalLink'

export const icons = {
  eye: Eye,
  eth: Eth,
  dai: Dai,
  bnb: Bnb,
  pen: Pen,
  exit: Exit,
  info: Info,
  logo: Logo,
  lock: Lock,
  tick: Tick,
  copy: Copy,
  busd: Busd,
  usdc: Usdc,
  usdt: Usdt,
  wbnb: Wbnb,
  weth: Weth,
  arrow: Arrow,
  matic: Matic,
  clock: Clock,
  cross: Cross,
  error: Error,
  ghost: Ghost,
  skull: Skull,
  clear: Clear,
  göeth: GöETH,
  danger: Danger,
  eraser: Eraser,
  eyeOff: EyeOff,
  loader: Loader,
  search: Search,
  sentTx: SentTx,
  warning: Warning,
  success: Success,
  chevron: Chevron,
  rounded: Rounded,
  download: DownLoad,
  noContent: NoContent,
  doubleTick: DoubleTick,
  dottedLine: DottedLine,
  fingerprint: Fingerprint,
  externalLink: ExternalLink,
}
