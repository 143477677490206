import { useMemo } from 'react'

import { usePendingTransactions, useTransactions } from 'state/user/hooks'

import { Transaction } from './Transaction'
import { Wrap, Title, Container, ContainerContent, Header, HeaderCell, WrapRow } from './Style'

export function TransactionsList() {
  const transactions = useTransactions()
  const pendingTransactions = usePendingTransactions()

  const allTransactions = useMemo(() => {
    const _allTxs = Object.assign({}, transactions, pendingTransactions)
    const result = [...Object.values(_allTxs)].sort((a, b) => a.timestamp - b.timestamp)
    return result
  }, [pendingTransactions, transactions])

  return (
    <>
      {allTransactions.length !== 0 && (
        <Wrap>
          <Title>Your donation transactions</Title>

          <Container>
            <ContainerContent>
              <Header>
                <HeaderCell>Time</HeaderCell>
                <HeaderCell>Tx Hash</HeaderCell>
                <HeaderCell>Wallet</HeaderCell>
                <HeaderCell>Token</HeaderCell>
                <HeaderCell>Value</HeaderCell>
                <HeaderCell>Operation</HeaderCell>
                <HeaderCell>Status</HeaderCell>
              </Header>

              <WrapRow>
                {allTransactions.map((tx) => (
                  <Transaction key={tx.id} tx={tx} />
                ))}
              </WrapRow>
            </ContainerContent>
          </Container>
        </Wrap>
      )}
    </>
  )
}
