import styled from 'styled-components/macro'
import { transparentize } from 'polished'
import { Href } from 'components/ui'

export const WrapList = styled.ul`
  margin: 3.2rem 0 1.6rem;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 1.2rem;
  box-shadow: 0 0.4rem 1.6rem 0 ${(props) => props.theme.color.elementBorder};
  overflow: hidden;
`

export const Title = styled.h5`
  margin: 0;
  padding: 1.6rem;
  width: 100%;
  font-family: ${(props) => props.theme.font.family.primary};
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2.4rem;
  line-height: 1.33;
  letter-spacing: 0.04rem;
  text-align: center;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.default};
`

export const Info = styled.div`
  margin: 0;
  padding: 3.2rem 1.6rem;
  width: 100%;
  display: grid;
  grid-template-columns: 3rem 1fr;
  gap: 1.2rem;
  color: ${(props) => props.theme.color.text};
  background-color: ${(props) => transparentize(0.9, props.theme.color.default)};
`

export const PreTitle = styled.p`
  margin: 0;
  font-family: ${(props) => props.theme.font.family.default};
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.8rem;
  line-height: 1.43;
  text-align: left;
`

export const ItemWrap = styled.li`
  list-style: none;
  padding: 1.6rem;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  gap: 2rem;
  border-bottom: 0.1rem solid ${(props) => transparentize(0.8, props.theme.color.black)};
  &:last-child {
    border-bottom: none;
  }
`

export const ItemTitle = styled.span`
  margin: 0;
  flex: 0 0 auto;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.33;
  color: ${(props) => transparentize(0.4, props.theme.color.text)};
`

export const ItemDescription = styled.span`
  display: inline-flex;
  justify-self: flex-end;
  align-items: flex-start;
  gap: 0.4rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.6rem;
  line-height: 1.33;
  text-align: right;
  color: ${(props) => props.theme.color.text};
  white-space: pre-line;
`

export const CopyButtonWrap = styled.div`
  max-width: 37.6rem;
  width: 100%;
`

export const QrWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
`

export const SupportLink = styled(Href)`
  color: ${(props) => props.theme.color.textHint};
  text-decoration: underline;
`
