import styled, { css } from 'styled-components/macro'

import { animated } from 'react-spring'
import SimpleBar from 'simplebar-react'
import { transparentize } from 'polished'

import { Href } from 'components/ui'

export const Overlay = styled.div<{ disableOverlayClose?: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: ${(props) => props.theme.zIndex.overlay};
  cursor: ${(props) => (props.disableOverlayClose ? 'default' : 'pointer')};
`

const AnimatedDialogOverlay = animated(Overlay)
export const StyledDialogOverlay = styled(AnimatedDialogOverlay)<{ type: string }>`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => transparentize(0.4, props.theme.color.black)};
  backdrop-filter: blur(0.8rem);

  ${(props) =>
    props.type === 'content' &&
    css`
      background-color: ${(props) => props.theme.color.modalBgContent};
    `}
`

export const Content = styled(SimpleBar)``

const AnimatedDialogContent = animated(Content)
export const StyledDialogContent = styled(AnimatedDialogContent)<{ type: string; size: string }>`
  margin: 0;
  padding: 0 1.6rem;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-self: center;

  background-color: ${(props) => props.theme.color.white};
  border: 0.1rem solid ${(props) => transparentize(0.8, props.theme.color.default)};
  box-shadow: 0 0.8rem 2.4rem ${(props) => props.theme.color.elementBgThird},
    0 1.6rem 3.2rem ${(props) => props.theme.color.elementBgThird};
  transition: all 0.3s ease-out;

  overflow-y: auto;
  overflow-x: hidden;

  z-index: ${(props) => props.theme.zIndex.modalContainer};
  cursor: auto;

  ${(props) =>
    props.type === 'content' &&
    css`
      position: relative;
      background-color: ${(props) => props.theme.color.transparent};
    `}

  ${(props) =>
    props.type === 'position' &&
    css`
      position: absolute;
      bottom: 0;
      max-height: 92vh;
      min-height: 92vh;
      padding: 0;
      background-color: ${(props) => props.theme.color.pageBg};
      border-radius: 2.4rem 2.4rem 0 0;
    `}

  ${(props) =>
    props.theme.breakpoints.greaterThan('sm')`
      padding: 2rem 2.4rem 2.4rem;
      max-width: 54.4rem;
      max-height: calc(100vh - 3.2rem);
      height: auto;
      border-radius: 2.4rem;
      ${
        props.size === 'small' &&
        css`
          max-width: 46.4rem;
        `
      }
      ${
        props.type === 'content' &&
        css`
          padding: 0 3.2rem;
          max-width: 134rem;
          border-radius: 0;
        `
      }
      ${
        props.type === 'position' &&
        css`
          position: absolute;
          bottom: 0;
          max-height: 92vh;
          min-height: 92vh;
          padding: 0;
          background-color: ${(props) => props.theme.color.elementBgThird};
          border-radius: 2.4rem 2.4rem 0 0;
        `
      }
    `};
`

export const ErrorWrapper = styled.div`
  padding: 1.6rem 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const ErrorTitle = styled.h3`
  margin: 0 0 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 1.8rem;
  line-height: 1.43;
  letter-spacing: -0.03rem;
  text-align: center;
  color: ${(props) => props.theme.color.text};
`

export const Subtitle = styled.p`
  margin: 0 0 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  letter-spacing: -0.03rem;
  text-align: center;
  color: ${(props) => props.theme.color.text};
`

export const SupportLink = styled(Href)`
  color: ${(props) => props.theme.color.textHint};
  text-decoration: underline;
`

export const ErrorIcon = styled.span`
  margin: 0 0 1.6rem;
`

export const ErrorContent = styled.span`
  margin: 1.2rem 0 0 0;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: -0.03rem;
  color: ${(props) => props.theme.color.textHint};
`
