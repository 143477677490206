import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

const newDonations = css`
  grid-template-columns: 8.8rem 18rem repeat(2, minmax(14rem, auto)) minmax(10rem, auto);
`

const users = css`
  grid-template-columns: 18rem minmax(12rem, auto) 8rem repeat(2, minmax(12rem, auto));
`

const verifications = css`
  grid-template-columns: repeat(2, 8.8rem) minmax(12rem, 1fr) minmax(16rem, 2fr) 10rem repeat(2, minmax(14rem, auto)) repeat(
      2,
      minmax(8rem, auto)
    );
`

const newDocuments = css`
  grid-template-columns: repeat(2, 8.8rem) minmax(16rem, 2fr) minmax(16rem, 2fr) repeat(2, minmax(14rem, auto)) repeat(
      2,
      minmax(8rem, auto)
    );
`

const txHistoryGrid = css`
  grid-template-columns: repeat(4, minmax(auto, 25%));
`

export const Header = styled.div<{ type?: string }>`
  padding: 0;
  position: relative;
  display: grid;
  align-items: center;
  ${(props) =>
    props.type === 'newDonations' &&
    css`
      ${newDonations};
    `}
  ${(props) =>
    props.type === 'users' &&
    css`
      ${users};
    `}
  ${(props) =>
    props.type === 'verifications' &&
    css`
      ${verifications};
    `}
  ${(props) =>
    props.type === 'newDocuments' &&
    css`
      ${newDocuments};
    `}
  ${(props) =>
    props.type === 'transactions' &&
    css`
      ${txHistoryGrid};
      border-bottom: none;
    `}
`

export const HeaderCell = styled.div<{ type?: string }>`
  padding: 1.2rem 0.2rem;
  height: 4.1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: -0.03rem;
  text-align: left;
  text-transform: capitalize;
  white-space: nowrap;
  color: ${(props) => transparentize(0.5, props.theme.color.text)};
  border-bottom: 0.1rem solid ${(props) => props.theme.color.transparent};
  &:first-child {
    padding: 0.9rem 0.2rem 0.9rem 1.2rem;
  }
  &:last-child {
    padding: 1.2rem 1.2rem 1.2rem 0.2rem;
    justify-content: flex-end;
  }
  ${(props) =>
    props.type === 'transactions' &&
    css`
      padding: 1.4rem 1.2rem;
      border-bottom: none;
    `}
`

export const HeaderCellSpan = styled.span<{ withTooltip: boolean }>`
  display: inline-block;
  cursor: default;
  ${(props) =>
    props.withTooltip &&
    css`
      text-decoration-style: dotted;
      text-decoration-line: underline;
      cursor: pointer;
    `}
`

export const RowCell = styled.div<{ type?: string }>`
  padding: 0.9rem 0.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: ${(props) => props.theme.font.weight.regular};
  text-align: left;
  white-space: nowrap;
  &:first-child {
    padding: 0.9rem 0.2rem 0.9rem 1.2rem;
  }
  &:last-child {
    padding: 0.9rem 1.2rem 0.9rem 0.2rem;
    justify-content: flex-end;
  }
  ${(props) =>
    props.type === 'newDonations' &&
    css`
      gap: 1.6rem;
      background-color: ${(props) => transparentize(0.9, props.theme.color.black)};
      &:first-child {
        border-radius: 1.2rem 0 0 1.2rem;
      }
      &:last-child {
        border-radius: 0 1.2rem 1.2rem 0;
      }
    `}
  ${(props) =>
    props.type === 'users' &&
    css`
      gap: 1.6rem;
      background-color: ${(props) => transparentize(0.9, props.theme.color.black)};
      &:first-child {
        border-radius: 1.2rem 0 0 1.2rem;
      }
      &:last-child {
        border-radius: 0 1.2rem 1.2rem 0;
      }
    `}
  ${(props) =>
    props.type === 'verifications' &&
    css`
      gap: 1.6rem;
      background-color: ${(props) => transparentize(0.9, props.theme.color.black)};
      &:first-child {
        border-radius: 1.2rem 0 0 1.2rem;
      }
      &:last-child {
        border-radius: 0 1.2rem 1.2rem 0;
      }
    `}
  ${(props) =>
    props.type === 'newDocuments' &&
    css`
      gap: 1.6rem;
      background-color: ${(props) => transparentize(0.9, props.theme.color.black)};
      &:first-child {
        border-radius: 1.2rem 0 0 1.2rem;
      }
      &:last-child {
        border-radius: 0 1.2rem 1.2rem 0;
      }
    `}
  ${(props) =>
    props.type === 'transactions' &&
    css`
      padding: 0.9rem 1.2rem;
      background-color: ${(props) => transparentize(0.9, props.theme.color.black)};
      border-bottom: 0.2rem solid ${(props) => props.theme.color.elementBg};
      &:first-child {
        border-radius: 1.2rem 0 0 1.2rem;
      }
      &:last-child {
        border-radius: 0 1.2rem 1.2rem 0;
      }
    `}
`

export const Row = styled.div<{ type?: string }>`
  position: relative;
  display: grid;
  ${(props) =>
    props.type === 'newDonations' &&
    css`
      ${newDonations};
    `}
  ${(props) =>
    props.type === 'users' &&
    css`
      ${users};
    `}
  ${(props) =>
    props.type === 'verifications' &&
    css`
      ${verifications};
    `}
  ${(props) =>
    props.type === 'newDocuments' &&
    css`
      ${newDocuments};
    `}
  ${(props) =>
    props.type === 'transactions' &&
    css`
      ${txHistoryGrid};
      border-bottom: none;
    `}
`

export const LoadingCell = styled.div`
  padding: 0.9rem 0.2rem;
  height: 5.6rem;
  &:first-child {
    padding: 0.9rem 0.2rem 0.9rem 1.2rem;
  }
  &:last-child {
    padding: 0.9rem 1.2rem 0.9rem 0.2rem;
  }
`

export const LoadingItemBulk = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`
