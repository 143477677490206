import { Notification, useSelectNotifications } from './service'
import { NotificationFactory } from './NotificationsFactory'
import { Wrapper } from './Style'

export function Notifications() {
  const notifications = useSelectNotifications()

  return (
    <Wrapper>
      {notifications?.map((notification: Notification) => (
        <NotificationFactory key={notification.id} {...notification} />
      ))}
    </Wrapper>
  )
}
