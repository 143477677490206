import { useEffect, useMemo } from 'react'

import { Hash } from 'types/entities'

import { useTransactions } from 'state/user/hooks'
import { useGetAllowancesQuery } from 'state/multicalls/allowances'

import { tokens } from 'constants/tokens'

import { useGetChainData } from './useChainData'

export function useGetAllowancesRequest(address: Hash | undefined, chainId: SupportedChainId, skip = false) {
  const { rpcUrl, blockDuration, donatesContractAddress } = useGetChainData(chainId)

  const transactions = useTransactions()
  const _tokens = useMemo(() => Object.values(tokens[chainId]), [tokens, chainId])

  const request = useGetAllowancesQuery(
    {
      rpcUrl,
      chainId,
      tokens: _tokens,
      customer: String(address),
      targetAddress: donatesContractAddress,
    },
    {
      skip,
      pollingInterval: blockDuration * 3,
    },
  )

  useEffect(() => {
    if (!request.isLoading && !skip) {
      request.refetch()
    }
  }, [transactions])

  return { ...request, tokens: _tokens }
}
