import { errors } from 'constants/errors'

type WalletErrorKey = keyof typeof errors.wallet

function onSearchErrorKey<K extends string>(errorKey: K, errorMessage: string) {
  const parsedKey = errorKey.toLowerCase().replaceAll('_', ' ')
  return errorMessage.toLowerCase().includes(parsedKey)
}

export function validationErrorsParser(errorMessage: string) {
  const expectedValidationError = Object.values(errors.validation).find((errorText) => errorMessage.includes(errorText))
  return expectedValidationError
}

export function walletErrorsParser(errorMessage: string) {
  const walletErrorKeys = Object.keys(errors.wallet) as WalletErrorKey[]
  const walletErrorKey = walletErrorKeys.find((errorKey) => onSearchErrorKey<WalletErrorKey>(errorKey, errorMessage))
  return walletErrorKey ? errors.wallet[walletErrorKey] : undefined
}

export function errorParser(errorMessage: string): string {
  const expectedValidationError = validationErrorsParser(errorMessage)
  if (expectedValidationError) {
    return expectedValidationError
  }

  const walletError = walletErrorsParser(errorMessage)
  if (walletError) {
    return walletError
  }

  const nodeMatchedError = errorMessage.match(/(?:\\?"message\\?"):\\?"([^"]+)\\+"/)
  if (nodeMatchedError) {
    return nodeMatchedError[1]
  }

  const contractMatchedError = errorMessage.match(/(?:\\?"message\\?"):\\?"([^"]+)\\?"/)
  if (contractMatchedError) {
    return contractMatchedError[1]
  }

  const matchedError = errorMessage.match(/message":"(.+)"/)
  if (matchedError) {
    return matchedError[1]
  }

  // ToDo add error parser for every wallet provider
  const [, walletConnectError] = errorMessage.split('WalletConnect')
  if (walletConnectError) {
    return errorParser(walletConnectError)
  }
  return errorMessage
}

/**
 * This is hacking out the revert reason from the ethers provider thrown error however it can.
 * This object seems to be undocumented by ethers.
 * @param error an error from the ethers provider
 */

export function parseErrorToUserReadableMessage(error: any): string {
  let reason: string | undefined
  const { providerErrorsCodes } = errors

  if (error?.code) {
    switch (error.code) {
      case providerErrorsCodes.userRejectedRequest:
        return `You've rejected the request. Please try again and sign transaction.`
      case providerErrorsCodes.unauthorized:
        return 'The requested account and/or method has not been authorized by the user.'
      case providerErrorsCodes.unsupportedMethod:
        return 'The requested method is not supported by this Ethereum provider.'
      case providerErrorsCodes.disconnected:
        return 'The provider is disconnected from all chains.'
      case providerErrorsCodes.chainDisconnected:
        return 'The provider is disconnected from the specified chain.'
    }
  }

  while (Boolean(error)) {
    reason = error.reason ?? error.message ?? reason
    error = error.error ?? error.data?.originalError
  }

  if (reason?.indexOf('execution reverted: ') === 0) {
    reason = reason.substr('execution reverted: '.length)
  }

  if (reason?.indexOf('undefined is not an object') !== -1) {
    return 'An error occurred. Please try again.'
  }
  return errorParser(reason)
}
