import { useMemo } from 'react'

import { Token, TokenSelectItem } from 'types/entities'

import { Icon, IconMap } from 'components/ui'
import { formatPrice, thousandSeparated } from 'utils/format'

import { ItemBtn, TokenItemWrap, TokenName, Balance, BalanceValue, TokenSymbol } from './Style'

type Props = {
  onClose: VoidFunction
  token: TokenSelectItem
  onSelect: (token: Token) => void
}
export function TokenRow({ token, onSelect, onClose }: Props) {
  const handleClick = () => {
    onSelect(token)
    onClose()
  }

  const formattedBalance = useMemo(() => {
    if (!token.balances?.balance) {
      return '0'
    }
    return thousandSeparated(formatPrice(token.balances?.balance, 6))
  }, [token.balances?.balance])

  return (
    <ItemBtn onClick={handleClick}>
      <TokenItemWrap>
        <Icon name={token.symbol.toLowerCase() as IconMap} size='large' />
        <TokenName>{token.name}</TokenName>
      </TokenItemWrap>

      <Balance>
        <BalanceValue>{formattedBalance}</BalanceValue>
        <TokenSymbol>{token.symbol}</TokenSymbol>
      </Balance>
    </ItemBtn>
  )
}
