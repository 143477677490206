import { getProvider } from 'services/ether'

import {
  Donate__factory as DonateFactory,
  ERC20__factory as getERC20Factory,
  Multicall__factory as MulticallFactory,
} from '_contracts'

import { SupportedChainId } from 'constants/chains'
import { DONATIONS, MULTICALL } from 'constants/addresses'

export function getERC20Token(address: string, supportedChainId: SupportedChainId, rpcUrl: string) {
  const { provider } = getProvider(supportedChainId, rpcUrl)

  return getERC20Factory.connect(address, provider)
}

export function getMulticall(supportedChainId: SupportedChainId, rpcUrl: string) {
  const { provider } = getProvider(supportedChainId, rpcUrl)
  return MulticallFactory.connect(MULTICALL[supportedChainId], provider)
}

export function getDonation(supportedChainId: SupportedChainId, rpcUrl: string) {
  const { provider } = getProvider(supportedChainId, rpcUrl)
  return DonateFactory.connect(DONATIONS[supportedChainId], provider)
}
