import { useStepper } from 'hooks/app'

import { DocVerification, Kyc, KycStatuses } from 'components/features'

export function VerificationFlow() {
  const { isKyc, isStatus, isDocVerification } = useStepper()

  return (
    <>
      {isKyc && <Kyc />}
      {isDocVerification && <DocVerification />}
      {isStatus && <KycStatuses />}
    </>
  )
}
