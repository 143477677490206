const Cross = () => (
  <svg viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M18.7 5.3a1 1 0 0 1 0 1.4l-12 12a1 1 0 0 1-1.4-1.4l12-12a1 1 0 0 1 1.4 0Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M5.3 5.3a1 1 0 0 1 1.4 0l12 12a1 1 0 0 1-1.4 1.4l-12-12a1 1 0 0 1 0-1.4Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Cross }
