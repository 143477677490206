import { HeaderGroup } from 'react-table'

import { Tooltip } from 'components/ui'

import { DefaultTableOptions } from '../types'

import { Header as StyledHeader, HeaderCell, HeaderCellSpan } from './Style'

interface HeaderProps<R extends DefaultTableOptions> {
  type?: string
  headerGroups: HeaderGroup<R>[]
}

export function Header<T extends DefaultTableOptions>(props: HeaderProps<T>) {
  const { type, headerGroups } = props

  return (
    <>
      {headerGroups.map((group) => {
        const { key, style, ...rest } = group.getHeaderGroupProps()

        return (
          <StyledHeader key={key} type={type} style={style} {...rest}>
            {group.headers.map((columns) => {
              // there is no way to nicely extend the column type
              // if the tooltip is passed differently,
              // the code is increased several times
              // @ts-ignore
              const { getHeaderProps, render, tooltip, id } = columns
              const { key: columnKey, style: columnStyle, ...restColumnProps } = getHeaderProps()

              return (
                <HeaderCell key={columnKey} className={id} type={type} style={columnStyle} {...restColumnProps}>
                  <HeaderCellSpan id={id} withTooltip={Boolean(tooltip)}>
                    {render('Header')}
                  </HeaderCellSpan>
                  {tooltip && (
                    <Tooltip id={id} place='top' position='fixed'>
                      {render('tooltip')}
                    </Tooltip>
                  )}
                </HeaderCell>
              )
            })}
          </StyledHeader>
        )
      })}
    </>
  )
}
