import { Row as RowType } from 'react-table'

import { Row as StyledRow, RowCell } from './Style'

interface RowProps<R extends Record<string, unknown>> {
  row: RowType<R>
  type?: string
  prepareRow: (row: RowType<R>) => void
}

export function Row<T extends Record<string, unknown>>(props: RowProps<T>) {
  const { row, type, prepareRow } = props

  prepareRow(row)
  const { key, ...rest } = row.getRowProps()

  return (
    <StyledRow key={key} {...rest} type={type}>
      {row.cells.map((cell) => {
        const { key: cellKey, ...restCell } = cell.getCellProps()
        return (
          <RowCell className={cell.column.id} key={cellKey} type={type} {...restCell}>
            {cell.render('Cell')}
          </RowCell>
        )
      })}
    </StyledRow>
  )
}
