export enum SupportedChainId {
  MAINNET = 1,
  GOERLI = 5,
}

interface L1ChainInfo {
  readonly name: string
  readonly icon: string
  readonly label: string
  readonly symbol: string
  readonly network: string
  readonly explorer: string
  readonly shortName: string
  readonly blockDuration: number
}

type ChainInfo = { readonly [SupportedChainId: number]: L1ChainInfo }

export const CHAIN_INFO: ChainInfo = {
  [SupportedChainId.MAINNET]: {
    explorer: 'https://etherscan.io/',
    label: 'Mainnet',
    symbol: 'ETH',
    name: 'Ethereum',
    shortName: 'eth',
    network: 'Mainnet',
    icon: 'eth',
    blockDuration: 12000,
  },
  [SupportedChainId.GOERLI]: {
    explorer: 'https://goerli.etherscan.io/',
    label: 'Göerli',
    symbol: 'GöETH',
    name: 'goerli',
    shortName: 'bsc',
    network: 'Göerli testnet',
    icon: 'göeth',
    blockDuration: 12_000,
  },
}

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [SupportedChainId.MAINNET, SupportedChainId.GOERLI]

export const RPC_LIST: Record<SupportedChainId, string> = {
  [SupportedChainId.MAINNET]: 'https://eth-mainnet.g.alchemy.com/v2/FBdLxiHF_-Sxxf9HCWvM_kAIjwVcfhB_',
  [SupportedChainId.GOERLI]: 'https://eth-goerli.g.alchemy.com/v2/FBdLxiHF_-Sxxf9HCWvM_kAIjwVcfhB_',
}
