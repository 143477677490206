import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

import { Button, Href } from 'components/ui'

export const Container = styled.div`
  margin: 1rem 0 0;
  width: 100%;
  max-width: 54.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SignatureWrap = styled.div`
  margin: 0 0 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.1rem solid ${(props) => props.theme.color.default};
  border-radius: 0.8rem;
`

export const CheckboxWrapper = styled.div`
  margin: 0 0 2rem;
  padding: 0 1.2rem;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: ${(props) => transparentize(0.9, props.theme.color.default)};
  border-radius: 1.2rem;
`

export const CheckboxLabel = styled.label`
  width: 100%;
  cursor: pointer;
`

export const CheckboxText = styled.p`
  margin: 0;
  padding: 1.4rem 1rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.33;
  color: ${(props) => props.theme.color.text};
  a {
    font-weight: ${(props) => props.theme.font.weight.regular};
    color: ${(props) => props.theme.color.default};
    text-decoration: underline;
  }
`

export const SignaturePadLabel = styled.span<{ isShow?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.font.family.primary};
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 1.8rem;
  line-height: 1.33;
  color: ${(props) => transparentize(0.5, props.theme.color.text)};
  background-color: ${(props) => props.theme.color.white};
  transition: visibility 0.2s ease, opacity 0.2s ease;
  ${(props) =>
    props.isShow &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`

export const SignaturePadWrap = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  height: 15rem;
  max-height: 15rem;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 1.2rem;
  overflow: hidden;
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;)'><text y='50%'>🖋️</text></svg>")
      0 30,
    auto;
  &:hover {
    ${SignaturePadLabel} {
      visibility: hidden;
      opacity: 0;
    }
  }
`

export const ButtonWrap = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 12.5rem);
  justify-content: flex-end;
  align-items: center;
  gap: 1.6rem;
`

export const StyledButton = styled(Button)<{ $isBlocked: boolean }>`
  ${(props) =>
    props.$isBlocked &&
    css`
      opacity: ${(props) => props.theme.opacity.opacity05};
    `}
`

export const SupportLink = styled(Href)`
  color: ${(props) => props.theme.color.textHint};
  text-decoration: underline;
`

export const ErrorWrap = styled.div`
  margin: 1.6rem 0 0;
  display: inline-flex;
`

export const ErrorContent = styled.span`
  margin: 0;
  padding: 0.6rem 1rem;
  width: fit-content;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.67;
  letter-spacing: 0.06rem;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.danger};
  border-radius: 0.6rem;
  z-index: ${(props) => props.theme.zIndex.minimal};
  &::first-letter {
    text-transform: uppercase;
  }
`
