const Pen = () => (
  <svg viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M5.53 12.53a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 0 1 0-1.06l7-7a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-7 7ZM10.94 5 9 3.06 3.06 9 5 10.94 10.94 5Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M22.53 22.53a.75.75 0 0 1-.706.199l-14.5-3.5a.75.75 0 0 1-.56-.582l-1.5-7.5a.75.75 0 0 1 .206-.677l5-5a.75.75 0 0 1 .677-.205l7.5 1.5a.75.75 0 0 1 .582.559l3.5 14.5a.75.75 0 0 1-.199.706Zm-1.547-1.547-3.1-12.841-6.636-1.328-4.433 4.432 1.328 6.637 12.841 3.1Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M22.53 22.53a.75.75 0 0 1-1.06 0l-7.586-7.586a.75.75 0 1 1 1.06-1.06l7.586 7.586a.75.75 0 0 1 0 1.06Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M13 14.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM15.75 13a2.75 2.75 0 1 1-5.5 0 2.75 2.75 0 0 1 5.5 0Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Pen }
