import { ChangeEvent, Component } from 'react'

import { Icon } from 'components/ui'

import { CheckboxWrapper, CheckboxLabel, CheckboxInput, CheckboxContainer, CheckboxIcon } from './Style'

type Props = {
  id?: string
  value?: string
  checked?: boolean
  disabled?: boolean
  onClick?: (value?: string) => void
}

class Checkbox extends Component<Props> {
  public handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onClick } = this.props

    const value = event.target.value

    if (typeof onClick === 'function') {
      onClick(value)
    }
  }

  public render() {
    const { id, value, checked, disabled } = this.props

    return (
      <CheckboxWrapper>
        <CheckboxLabel>
          <CheckboxInput
            disabled={disabled}
            checked={checked}
            id={id}
            value={value}
            onChange={this.handleChange}
            type='checkbox'
          />
          <CheckboxContainer>
            <CheckboxIcon>
              <Icon name='tick' size='extraMedium' />
            </CheckboxIcon>
          </CheckboxContainer>
        </CheckboxLabel>
      </CheckboxWrapper>
    )
  }
}

export { Checkbox }
