import { useMemo } from 'react'
import { DateTime } from 'luxon'

import { Wrapper, Title, TimeSection } from './Style'

type Props = {
  title: string
  area?: string
  timestamp?: number
}
export function TimeCell({ timestamp, title, area }: Props) {
  const date = useMemo(() => {
    if (!timestamp) {
      return ''
    }
    return DateTime.fromMillis(timestamp).toFormat('dd-LLL-yy').toString()
  }, [timestamp])

  const time = useMemo(() => {
    if (!timestamp) {
      return ''
    }
    return DateTime.fromMillis(timestamp).toFormat('HH:mm:ss').toString()
  }, [timestamp])

  return (
    <Wrapper area={area}>
      <Title>{title}</Title>
      <TimeSection>
        <span>{time}</span>
        <span>{date}</span>
      </TimeSection>
    </Wrapper>
  )
}
