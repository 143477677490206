import { useCallback } from 'react'

import { getApiError } from 'utils/helpers'

import { NewDocInput, UpdateDocStatusInput } from './types'
import { useNewDocMutation, useGetDocsQuery, useUpdateDocStatusMutation } from './api'

const POOLING_INTERVAL = 30_000
export function useGetAllDocsRequest(shouldPooling: boolean, pollingInterval = POOLING_INTERVAL) {
  const request = useGetDocsQuery(shouldPooling, {
    skip: !shouldPooling,
    pollingInterval,
  })

  return { ...request, error: getApiError(request.error) }
}

export function useOnNewDoc() {
  const [newDonation, requestState] = useNewDocMutation()

  const onNewDoc = useCallback(
    async (params: NewDocInput) => {
      try {
        const response = await newDonation(params).unwrap()
        return response
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [newDonation],
  )

  return { ...requestState, error: getApiError(requestState.error), onNewDoc }
}

export function useOnUpdateDocStatus() {
  const [updateDocStatus, requestState] = useUpdateDocStatusMutation()
  const onUpdateDocStatus = useCallback(
    async (params: UpdateDocStatusInput) => {
      try {
        const response = await updateDocStatus(params).unwrap()
        return response
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [updateDocStatus],
  )

  return { ...requestState, error: getApiError(requestState.error), onUpdateDocStatus }
}
