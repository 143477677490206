const Dai = () => (
  <svg fill='none' viewBox='0 0 24 24'>
    <path
      fill='#F5AC37'
      d='M12 0c6.628 0 12 5.373 12 12 0 6.628-5.372 12-12 12-6.627 0-12-5.372-12-12C0 5.373 5.373 0 12 0Z'
    />
    <path
      fill='#FEFEFD'
      d='M12.442 12.847h4.56c.097 0 .143 0 .15-.127a8.78 8.78 0 0 0 0-1.395c0-.09-.045-.127-.142-.127H7.935c-.113 0-.143.037-.143.142v1.335c0 .172 0 .172.18.172h4.47Zm4.2-3.21a.146.146 0 0 0 0-.104 2.96 2.96 0 0 0-.27-.473 3.826 3.826 0 0 0-.555-.697 1.84 1.84 0 0 0-.345-.338 5.39 5.39 0 0 0-2.25-1.14 5.593 5.593 0 0 0-1.275-.135H7.919c-.112 0-.127.045-.127.143v2.662c0 .112 0 .142.142.142h8.655s.075-.015.09-.06h-.037Zm0 4.77a1.743 1.743 0 0 0-.383 0H7.942c-.112 0-.15 0-.15.15v2.603c0 .12 0 .15.15.15h3.84c.184.015.367.002.547-.037a5.72 5.72 0 0 0 1.628-.36 2.99 2.99 0 0 0 .547-.255h.053a4.873 4.873 0 0 0 2.092-2.107s.053-.113-.006-.143ZM6.286 18.66v-4.147c0-.098 0-.113-.12-.113H4.538c-.09 0-.128 0-.128-.12v-1.425h1.74c.097 0 .135 0 .135-.127v-1.41c0-.09 0-.112-.12-.112H4.538c-.09 0-.128 0-.128-.12v-1.32c0-.083 0-.105.12-.105h1.612c.113 0 .143 0 .143-.142V5.476c0-.12 0-.15.15-.15h5.625a8.57 8.57 0 0 1 1.215.135 7.331 7.331 0 0 1 2.347.87 6.64 6.64 0 0 1 1.32 1.02c.297.308.565.642.802.997.236.36.433.746.586 1.148.02.105.12.175.225.158h1.342c.172 0 .172 0 .18.165v1.23c0 .12-.045.15-.165.15h-1.036c-.104 0-.135 0-.127.135a7.65 7.65 0 0 1 0 1.372c0 .128 0 .143.143.143h1.184c.053.067 0 .135 0 .203.008.087.008.175 0 .262v.908c0 .127-.037.165-.15.165H18.51a.188.188 0 0 0-.218.142 5.997 5.997 0 0 1-1.575 2.295c-.255.23-.523.446-.803.645-.3.173-.592.353-.9.495a8.106 8.106 0 0 1-1.77.563 9.226 9.226 0 0 1-1.755.142H6.283v-.008l.002-.001Z'
    />
  </svg>
)

export { Dai }
