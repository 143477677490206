import { SwitchButtonSize } from '../@types'

export const getSize = (size: SwitchButtonSize) =>
  ({
    default: {
      maxWidth: '100%',
      fontSize: '1.8rem',
      lineHeight: 1.43,
      letterSpacing: 'normal',
    },
    medium: {
      maxWidth: '100%',
      fontSize: '1.4rem',
      lineHeight: 1.43,
      letterSpacing: 'normal',
    },
    small: {
      maxWidth: '26.2rem',
      fontSize: '1.2rem',
      lineHeight: 1.33,
      letterSpacing: '0.006rem',
    },
  }[size])
