import { createGlobalStyle } from 'styled-components/macro'
import 'sanitize.css'
import 'simplebar/dist/simplebar.min.css'
import { breakpoints, color, font } from 'theme'

const GlobalStyle = createGlobalStyle`
  :root {
    --rt-opacity: 1;
  }
  html,
  body,
  #root {
    font-family: ${font.family.default};
  }
  html {
    box-sizing: border-box;
    height: 100%;
    font-size: 62.5%;
    ${breakpoints.greaterThan('xxl')`
      font-size: calc(62.5% * 2.6);
    `};
    ${breakpoints.greaterThan('xxxl')`
      font-size: calc(62.5% * 3);
    `};
    ${breakpoints.greaterThan('extraXl')`
      font-size: calc(62.5% * 5.2);
    `};
  }
  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    display: grid;
    min-height: 100vh;
    font-size: 1.6rem;
    line-height: 1.5;
    color: ${color.text};
    background-color: ${color.pageBg};
  }
  b {
    font-weight: ${font.weight.medium};
  }
  small {
    font-size: 1.4rem;
  }
  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
    -webkit-touch-callout: none;
    -webkit-text-decoration-skip: objects;
  }
  h1 {
    font-family: ${font.family.primary};
    font-weight: ${font.weight.bold};
    font-size: 2.5rem;
  }
  button {
    font-family: ${font.family.default};
    font-weight: ${font.weight.medium};
  }
  input {
    appearance: none;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="time"]::-webkit-calendar-picker-indicator,
  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="week"]::-webkit-calendar-picker-indicator,
  input[type="month"]::-webkit-calendar-picker-indicator,
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    display: none;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
    text-rendering: optimizeLegibility;
  }
  img {
    border-style: none;
  }
  video {
    width: 100%;
  }
  .simplebar-scrollbar::before {
    background-color: ${color.scrollbarColor};
  }
`

export { GlobalStyle }
