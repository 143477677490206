import { forwardRef, cloneElement, ForwardedRef, isValidElement, SyntheticEvent, ReactNode } from 'react'

import { useTransition } from 'react-spring'
import useErrorBoundary from 'use-error-boundary'

import { errorParser } from 'utils'
import { SUPPORT_EMAIL } from 'constants/link'

import { ModalLayout } from './ModalLayout'
import { CloseButton } from './CloseButton'

import { ModalType, ModalAlign, ModalSize } from './@types'
import { StyledDialogOverlay, StyledDialogContent, ErrorWrapper, Subtitle, SupportLink, ErrorContent } from './Style'

export type StyledModalProps = {
  type?: ModalType
  size?: ModalSize
  align?: ModalAlign
  children: ReactNode
  onClose: VoidFunction
  title?: string | ReactNode
  isMultiplyModals?: boolean
  withDefaultLayout?: boolean
  onCurrentClose: VoidFunction
  subtitle?: string | ReactNode
  disableOverlayClose?: boolean
}

function SelectModalType(
  {
    title,
    subtitle,
    children,
    align = 'left',
    type = 'default',
    size = 'default',
    disableOverlayClose,
    withDefaultLayout = true,
    ...rest
  }: StyledModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const fadeTransition = useTransition(true, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const { ErrorBoundary, didCatch, error } = useErrorBoundary()

  const onOverlayClick = ({ target, currentTarget }: SyntheticEvent) => {
    if (target === currentTarget && !disableOverlayClose) {
      rest.onClose()
    }
  }

  return (
    <>
      {fadeTransition(
        ({ opacity }, item) =>
          item && (
            <StyledDialogOverlay
              type={type}
              initialFocusRef={ref}
              onClick={onOverlayClick}
              disableOverlayClose={disableOverlayClose}
              style={{ opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }) }}
            >
              <StyledDialogContent type={type} size={size} aria-label='dialog'>
                {didCatch ? (
                  <ErrorWrapper>
                    <h3>Oops!</h3>
                    <Subtitle>
                      Something went wrong. Please <SupportLink href={SUPPORT_EMAIL}>contact support</SupportLink>.
                    </Subtitle>
                    <ErrorContent>{errorParser(error.message)}</ErrorContent>
                    <CloseButton onClick={rest.onCurrentClose} />
                  </ErrorWrapper>
                ) : (
                  <ErrorBoundary>
                    {withDefaultLayout ? (
                      <ModalLayout type={type} align={align} title={title} subtitle={subtitle}>
                        {isValidElement(children) && cloneElement(children, rest)}
                      </ModalLayout>
                    ) : (
                      <>{isValidElement(children) && cloneElement(children, rest)}</>
                    )}
                  </ErrorBoundary>
                )}
              </StyledDialogContent>
            </StyledDialogOverlay>
          ),
      )}
    </>
  )
}

export const SelectModal = forwardRef<HTMLDivElement, StyledModalProps>(SelectModalType)
