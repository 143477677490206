import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'

import { Allowances } from 'types/entities'

import { getMulticall } from 'contracts'
import { isAddress, isEther } from 'utils/crypto'

import { MulticallArgs } from './types'
import { getAllowancesMap, getMulticallParams } from './helpers'

const multicallBaseQuery = (): BaseQueryFn<MulticallArgs, Allowances, Error> => async (params) => {
  try {
    const _tokens = params.tokens.filter((_token) => Boolean(_token) && !isEther(_token.address))
    if (!params.customer || _tokens.length === 0 || !isAddress(params.customer)) {
      return { data: {} }
    }

    const multicallContract = getMulticall(params.chainId, params.rpcUrl)
    const multicallParams = getMulticallParams({ ...params, tokens: _tokens })
    if (!multicallParams) {
      return { data: {} }
    }
    const { returnData } = await multicallContract.callStatic.multicall(multicallParams)

    const allowances = returnData.reduce<Allowances>(
      (acc, curr, index) => getAllowancesMap(acc, curr, _tokens[index]),
      {},
    )

    return { data: allowances }
  } catch (error) {
    return { error }
  }
}

export const allowancesApi = createApi({
  reducerPath: 'allowances',
  baseQuery: multicallBaseQuery(),
  refetchOnReconnect: true,
  endpoints: (build) => ({
    getAllowances: build.query<Allowances, MulticallArgs>({
      query: (params: MulticallArgs) => params,
    }),
  }),
})

export const { useGetAllowancesQuery } = allowancesApi
