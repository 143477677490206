import styled, { css } from 'styled-components/macro'
import { darken, transparentize } from 'polished'
import { Href } from 'components/ui'

export const Wrap = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const HeaderModal = styled.header`
  margin: 0;
  padding: 2.8rem 3.2rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: -0.041rem;
  color: ${(props) => props.theme.color.white};
  text-align: center;
  border-bottom: 0.1rem solid ${(props) => transparentize(0.9, props.theme.color.white)};
  &::first-letter {
    text-transform: capitalize;
  }
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    padding: 1.2rem 3.2rem;
  `};
`

export const HeaderTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: -0.041rem;
  color: ${(props) => props.theme.color.white};
`

export const Container = styled.div`
  margin: 0;
`

export const WarningText = styled.p`
  margin: 0;
  padding: 0 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: -0.032rem;
  text-align: center;
`

export const WrapContent = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  height: 38.2rem;
`

export const WrapContentApprove = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  height: 31rem;
`

export const Wrapper = styled.div<{ type?: string }>`
  margin: 0 0 3.8rem;
  ${(props) =>
    props.type === 'success' &&
    css`
      color: ${(props) => props.theme.color.success};
    `}
  ${(props) =>
    props.type === 'error' &&
    css`
      color: ${(props) => props.theme.color.danger};
    `}
`

export const Title = styled.h3`
  margin: 0 0 0.6rem;
  padding: 0;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 1.8rem;
  line-height: 1.33;
  text-align: center;
  color: ${(props) => props.theme.color.text};
`

export const Text = styled.p<{ type?: string; size?: string; align?: string }>`
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.12;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  color: ${(props) => props.theme.color.text};
  &::first-letter {
    text-transform: capitalize;
  }
  ${(props) =>
    props.type === 'error' &&
    css`
      color: ${(props) => props.theme.color.danger};
    `}
  ${(props) =>
    props.type === 'support' &&
    css`
      color: ${(props) => transparentize(0.5, props.theme.color.white)};
    `}
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: 1.4rem;
      line-height: 1.12;
    `}
`

export const StyledLink = styled(Href)`
  width: 100%;
  text-align: center;
`

export const Section = styled.section`
  margin: 0 0 2.4rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  background-color: ${(props) => props.theme.color.elementBgSecond};
  border-radius: 0.8rem;
  overflow: hidden;
`

export const DataItem = styled.div`
  padding: 1.2rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  &:first-child {
    border-bottom: 0.1rem solid ${(props) => transparentize(0.5, props.theme.color.textPrimary)};
  }
`

export const DataItemTitle = styled.h5`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.33;
  color: ${(props) => transparentize(0.3, props.theme.color.textPrimary)};
`

export const DataItemValue = styled.span`
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.6rem;
  line-height: 1.33;
  color: ${(props) => props.theme.color.textPrimary};
`

const Status = css`
  padding: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: -0.032rem;
  border-radius: 1.2rem;
`

export const Warning = styled.div`
  ${Status};
  display: grid;
  grid-template-columns: 2rem 1fr;
  color: ${(props) => props.theme.color.risky};
  background-color: ${(props) => transparentize(0.9, props.theme.color.risky)};
  border: 0.1rem solid ${(props) => transparentize(0.8, props.theme.color.risky)};
`

export const StatusIconWrap = styled.span`
  flex: 0 0 auto;
`

export const Symbol = styled.span`
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.textSecondary};
`

export const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Line = styled.span`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 0.2rem;
  transform: translateY(-50%);
`

export const TokenItem = styled.div`
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex: 0 0 auto;
  width: auto;
  min-width: 10.6rem;
  text-align: center;
  border-radius: 0.8rem;
  &::before {
    left: -1.6rem;
    content: '';
    position: absolute;
    top: calc(50% - 0.3rem);
    width: calc(50% + 0.2rem);
    height: 1rem;
    background: linear-gradient(
      -90deg,
      ${(props) => props.theme.color.mediumBg} 20%,
      ${(props) => props.theme.color.transparent} 100%
    );
    z-index: ${(props) => props.theme.zIndex.minimal};
  }
  &::after {
    right: -1.6rem;
    content: '';
    position: absolute;
    top: calc(50% - 0.3rem);
    width: calc(50% + 0.2rem);
    height: 1rem;
    background: linear-gradient(
      90deg,
      ${(props) => props.theme.color.mediumBg} 20%,
      ${(props) => props.theme.color.transparent} 100%
    );
    z-index: ${(props) => props.theme.zIndex.minimal};
  }
  &:first-child {
    &::before {
      left: unset;
      right: -1.6rem;
      content: '';
      position: absolute;
      top: calc(50% - 0.3rem);
      width: calc(50% + 0.2rem);
      height: 1rem;
      background: linear-gradient(
        90deg,
        ${(props) => props.theme.color.mediumBg} 20%,
        ${(props) => props.theme.color.transparent} 100%
      );
      z-index: ${(props) => props.theme.zIndex.minimal};
    }
    &::after {
      left: 0;
      right: unset;
      background-color: ${(props) => props.theme.color.mediumBg};
      content: '';
      position: absolute;
      top: calc(50% - 0.3rem);
      width: calc(50% + 0.2rem);
      height: 1rem;
      z-index: ${(props) => props.theme.zIndex.minimal};
    }
  }
  &:last-child {
    &::before {
      right: unset;
      left: -1.6rem;
      content: '';
      position: absolute;
      top: calc(50% - 0.3rem);
      width: calc(50% + 0.2rem);
      height: 1rem;
      background: linear-gradient(
        -90deg,
        ${(props) => props.theme.color.mediumBg} 20%,
        ${(props) => props.theme.color.transparent} 100%
      );
      z-index: ${(props) => props.theme.zIndex.minimal};
    }
    &::after {
      right: 0;
      left: unset;
      background-color: ${(props) => props.theme.color.mediumBg};
      content: '';
      position: absolute;
      top: calc(50% - 0.3rem);
      width: calc(50% + 0.2rem);
      height: 1rem;
      z-index: ${(props) => props.theme.zIndex.minimal};
    }
  }
`

export const TokenTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.2rem;
  line-height: 1.67;
  letter-spacing: -0.015rem;
  text-transform: capitalize;
  color: ${(props) => transparentize(0.5, props.theme.color.white)};
`

export const TokenIconWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0.7rem;
  z-index: ${(props) => props.theme.zIndex.preloader};
`

export const TokenValue = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 1.2rem;
  line-height: 1.33;
  text-transform: capitalize;
  color: ${(props) => props.theme.color.white};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    font-size: 1.4rem;
    line-height: 1.43;
  `};
`

export const Info = styled.div`
  position: relative;
  margin: 0 0 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const InfoText = styled.p`
  margin: 0.8rem auto 2.4rem;
  max-width: 39.2rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.015rem;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  text-transform: uppercase;
`

export const InfoTextSmall = styled.p`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.33;
  letter-spacing: -0.03rem;
  text-align: center;
  color: ${(props) => props.theme.color.text};
`

export const InfoHeader = styled.h5`
  margin: 0 0 0.8rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.12;
  color: ${(props) => props.theme.color.text};
`

export const InfoWrap = styled.div`
  padding: 0 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: ${(props) => props.theme.color.elementBgSecond};
  border-radius: 0.8rem;
`

export const InfoItem = styled.div`
  padding: 1.4rem 0;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  &:not(:last-child) {
    border-bottom: 0.1rem solid ${(props) => transparentize(0.95, props.theme.color.white)};
  }
`

export const InfoItemTitle = styled.span<{ isTooltip?: boolean }>`
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.33;
  color: ${(props) => transparentize(0.3, props.theme.color.textPrimary)};
  ${(props) =>
    props.isTooltip &&
    css`
      text-decoration-style: dotted;
      text-decoration-line: underline;
      cursor: pointer;
    `}
`

export const InfoItemValue = styled.span<{ align?: string }>`
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: ${({ align }) => (align ? align : 'flex-end')};
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  color: ${(props) => props.theme.color.textPrimary};
  sup {
    font-size: 1rem;
    line-height: 1;
    color: ${(props) => props.theme.color.textSecondary};
  }
`

export const GasValue = styled.span`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.4rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: -0.03rem;
  text-align: left;
`

export const LoadingWrap = styled.div`
  height: 1.6rem;
`

export const TextGrey = styled.span`
  margin: 0 0.8rem 0 0;
  color: ${(props) => props.theme.color.text};
`

export const ColorPnl = styled.span<{ value: number }>`
  display: flex;
  gap: 0.4rem;
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.43;
  color: ${(props) => props.theme.color.text};
  ${(props) =>
    props.value > 0 &&
    css`
      color: ${(props) => props.theme.color.positive};
    `}
  ${(props) =>
    props.value < 0 &&
    css`
      color: ${(props) => props.theme.color.negative};
    `}
`

export const Steps = styled.div`
  position: relative;
  margin: 0;
  height: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    margin: 0 2rem;
  `};
`

export const StepLabel = styled.label<{ isActive: boolean; status: string }>`
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  font-family: ${(props) => props.theme.font.family.primary};
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  color: ${(props) => transparentize(0.5, props.theme.color.black)};
  ${(props) =>
    props.isActive &&
    css`
      color: ${(props) => props.theme.color.default};
    `}
  ${(props) =>
    props.status === 'complete' &&
    css`
      color: ${(props) => props.theme.color.success};
    `}
  ${(props) =>
    props.status === 'failed' &&
    css`
      color: ${(props) => props.theme.color.danger};
    `}
`

export const StepStatus = styled.span`
  position: relative;
  display: inline-flex;
  flex: 0 0 auto;
`

export const StepInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.elementBg};
  padding: 0 0.7rem;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 1rem;
    height: 100%;
    background-color: ${(props) => props.theme.color.elementBg};
    z-index: ${(props) => props.theme.zIndex.minimal};
  }
  &::before {
    left: -1rem;
  }
  &::after {
    right: -1rem;
  }
`

export const HeaderModalStepper = styled.div`
  margin: 0;
  padding: 0 0 2.4rem;
  font-size: 0;
  border-bottom: 0.1rem solid ${(props) => transparentize(0.9, props.theme.color.black)};
`

export const SkeletonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
`

export const Ring = styled.span`
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  border: 0.1rem solid;
  border-color: inherit;
  border-radius: 50%;
`

export const SubTitle = styled.p`
  margin: -1.2rem auto 0.8rem;
  padding: 0;
  max-width: 39rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.43;s
  text-align: center;
  color: ${(props) => props.theme.color.text};
  a {
    color: ${(props) => props.theme.color.textHint};
    &:hover {
      color: ${(props) => darken(0.1, props.theme.color.textHint)};
    }
  }
`

export const Hint = styled.span`
  color: ${(props) => props.theme.color.textHint};
`

export const LoadingContainer = styled.div`
  padding: 0;
  display: flex;
  gap: 1.2rem;
`
