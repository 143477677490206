const Eth = () => (
  <svg fill='none' viewBox='0 0 24 24'>
    <path fill='#627eea' d='M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z' />
    <path fill='#fff' d='M11.999 4v5.913l4.998 2.234L11.999 4Zm0 0L7 12.147l4.999-2.234V4Z' />
    <path fill='#fff' fillOpacity='.602' d='M11.999 15.979v4.018L17 13.077l-5 2.903Z' />
    <path fill='#fff' d='M11.999 19.997v-4.02L7 13.078l4.999 6.92Z' />
    <path fill='#fff' fillOpacity='.2' d='m11.999 15.048 4.998-2.902-4.998-2.232v5.134Z' />
    <path fill='#fff' fillOpacity='.602' d='m7 12.146 4.999 2.902V9.914L7 12.146Z' />
  </svg>
)

export { Eth }
