const Wbnb = () => (
  <svg fill='none' viewBox='0 0 24 25'>
    <path
      fill='#F3BA2F'
      d='M12 24.73c6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12s-12 5.373-12 12c0 6.628 5.373 12 12 12Z'
    />
    <path
      fill='#fff'
      d='M13.542 18.017v1.794l-1.564.92-1.52-.92v-1.794l1.52.92 1.564-.92Zm-8.37-6.206 1.52.92v3.077l2.617 1.564v1.794L5.17 16.727v-4.916Zm13.612 0v4.924l-4.182 2.439V17.38l2.618-1.565v-3.077l1.564-.927Zm-4.19-2.439 1.565.92v1.794l-2.617 1.564v3.122l-1.52.919-1.512-.92v-3.128l-2.714-1.565v-1.794l1.564-.92 2.618 1.565 2.617-1.557Zm-6.798 3.996 1.52.92v1.794l-1.52-.92v-1.794Zm8.363 0v1.795l-1.52.919v-1.794l1.52-.92Zm-9.468-5.56 1.565.92-1.565.918v1.795l-1.52-.92V8.727l1.52-.92Zm10.573 0 1.564.92v1.793l-1.564.92V9.646l-1.52-.919 1.52-.92Zm-5.286 0 1.564.92-1.564.918-1.52-.919 1.52-.92Zm0-3.077 4.181 2.44-1.52.918-2.617-1.564-2.669 1.564-1.52-.919 4.145-2.44Z'
    />
  </svg>
)

export { Wbnb }
