import { ReactNode, ComponentType } from 'react'

export enum ModalName {
  CONFIRM_MODAL = 'CONFIRM_MODAL',
  CONFIRM_DONATION = 'CONFIRM_DONATION',
}

export type Modal = {
  name: ModalName
  additionalMeta?: ModalAdditionalMeta
  container: ComponentType
}

export type ModalAlign = 'left' | 'center' | 'right'
export type ModalSize = 'small' | 'default' | 'large'
export type ModalType = 'default' | 'content' | 'primary' | 'position'

export type Modals = Modal[]

export type ModalAdditionalMeta = {
  tokenChainId?: number
}

export type ModalContainerOwnProps = {
  name: ModalName
  type?: ModalType
  size?: ModalSize
  align?: ModalAlign
  title?: string | ReactNode
  withDefaultLayout?: boolean
  disableOverlayClose?: boolean
  subtitle?: string | ReactNode
  additionalMeta?: ModalAdditionalMeta
}

export type CreateModalProps = {
  container: ReactNode
} & ModalContainerOwnProps

export type ModalProps = ModalContainerOwnProps

export type ModalState = {
  modal: Modals
}
