import { ButtonSize } from '../@types'

export const getSize = (size: ButtonSize) =>
  ({
    default: {
      padding: '1.2rem 1.6rem',
      fontSize: '1.6rem',
      lineHeight: 1.43,
      letterSpacing: '0.04rem',
    },
    large: {
      padding: '1.6rem',
      fontSize: '1.8rem',
      lineHeight: 1.43,
      letterSpacing: '0.04rem',
    },
    medium: {
      padding: '0.8rem 2rem',
      fontSize: '1.6rem',
      lineHeight: 1.43,
      letterSpacing: '0.04rem',
    },
    small: {
      padding: '1rem',
      fontSize: '1.2rem',
      lineHeight: 1.33,
      letterSpacing: '0.04rem',
    },
    symbol: {
      padding: '0',
      fontSize: '1.2rem',
      lineHeight: 1,
      letterSpacing: '0.04rem',
    },
    block: {
      padding: '1.6rem',
      fontSize: '1.6rem',
      lineHeight: 1.25,
      letterSpacing: '0.04rem',
    },
  }[size])
