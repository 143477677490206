import { useMemo, useState } from 'react'

import { BigNumber } from 'ethers'
import { keccak256, toHex } from 'viem'

import { Hash } from 'types/entities'
import { useChainData, useDonateEstimation, useGasFetcher } from 'hooks/app'

import { useOnNewDonation } from 'state/donation/hooks'

import { fromWei, toChecksumAddress } from 'utils/crypto'

import { createModal, ModalName } from 'modal'

import { Confirmation } from './Confirmation'
import { DonationInput } from './types'

type ConnectModalProps = {
  donation: DonationInput
  onCurrentClose?: VoidFunction
  action: (
    signature: string,
    gas?: bigint,
    maxFeePerGas?: bigint,
    maxPriorityFeePerGas?: bigint,
  ) => Promise<string | undefined>
}

function Donation({ onCurrentClose, action, donation }: ConnectModalProps) {
  const gasCost = useGasFetcher()

  const { onNewDonation, ...createDonateRequest } = useOnNewDonation()

  const { chainId } = useChainData()

  const [isFetching, setIsFetching] = useState(false)

  const gasParams = useMemo(
    () => ({
      maxFeePerGas: gasCost.data?.maxFeePerGas || undefined,
      maxPriorityFeePerGas: gasCost.data?.maxPriorityFeePerGas || undefined,
    }),
    [gasCost.data],
  )

  const {
    isLoading,
    isBalanceEnough,
    isRevokeRequired,
    isAllowanceEnough,
    estimateDonationGas,
    getRecipientAddress,
    isAllowancesLoading,
  } = useDonateEstimation({
    chainId,
    gasCost: gasCost.cost,
    address: donation.address,
    token: donation.token.address,
    decimals: donation.token.decimals,
    amount: fromWei(donation.amount, donation.token.decimals),
  })

  const prepareData = async () => {
    const _bgAmount = BigNumber.from(donation.amount)
    const _tokenAddress = toChecksumAddress(donation.token.address) as Hash

    const recipientNameKeccak256 = keccak256(toHex(donation.recipient))
    const recipientAddress = await getRecipientAddress(recipientNameKeccak256)

    const _signature = await onNewDonation({
      token: _tokenAddress,
      amount: _bgAmount._hex,
      address: donation.address,
      recipient: recipientAddress,
    })

    const _estimateGas = await estimateDonationGas([
      _tokenAddress,
      _bgAmount._hex,
      recipientNameKeccak256,
      _signature as Hash,
    ])
    return { estimateGas: _estimateGas, signature: _signature }
  }

  const handleCreate = async (signature: string, gas: bigint) => {
    try {
      setIsFetching(true)
      const txHash = await action(signature, gas, gasParams.maxFeePerGas, gasParams.maxPriorityFeePerGas)
      return txHash
    } finally {
      setIsFetching(false)
    }
  }

  const _isInfoLoading = useMemo(
    () => isLoading || isFetching || isAllowancesLoading || gasCost.isLoading || createDonateRequest.isLoading,
    [isLoading, isFetching, isAllowancesLoading, gasCost.isLoading, createDonateRequest.isLoading],
  )

  return (
    <Confirmation
      donation={donation}
      prepareData={prepareData}
      isLoading={_isInfoLoading}
      handleCreate={handleCreate}
      approveToken={donation.token}
      onCurrentClose={onCurrentClose}
      approveAmount={donation.amount}
      isBalanceEnough={isBalanceEnough}
      gasPrice={gasParams.maxFeePerGas}
      isRevokeRequired={isRevokeRequired}
      isAllowanceEnough={isAllowanceEnough}
    />
  )
}

const newConfirmDonationModal = ({ ...props }: ConnectModalProps) =>
  createModal({
    type: 'primary',
    align: 'center',
    title: 'Confirm Donation',
    disableOverlayClose: true,
    name: ModalName.CONFIRM_DONATION,
    container: <Donation {...props} />,
  })

export { newConfirmDonationModal }
