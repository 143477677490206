const BASE_URL =
  process.env?.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_DEV_API_URL

const MOCK_URL = '/'

const mail = {
  verify: 'mail/verify',
  resend: 'mail/resend',
}

const auth = {
  signIn: 'auth/signin',
  signUp: 'auth/signup',
  logout: 'auth/logout',
  refresh: 'auth/refresh',
  createManage: 'auth/create-manager',
  // // ToDo change
  // restorePassword: 'restorePassword',
  // verifyRestorePassword: 'verifyRestorePassword',
  // getRestorePasswordCode: 'getRestorePasswordCode',
}

const user = {
  getUser: 'user/',
  getAll: 'user/all',
  donations: 'user/donations',
}

const kyc = {
  getAll: 'kyc/all',
  start: 'kyc/start',
  update: 'kyc/update',
  updateStatus: 'kyc/update-status',
  checkExternal: 'kyc/check-external',
  startExternal: 'kyc/start-external',
}

const donation = {
  getAll: 'donation/all',
  newDonation: 'donation/new',
  updateStatus: 'donation/update-status',
}

const docs = {
  getAll: 'docs/all',
  newDoc: 'docs/new',
  updateStatus: 'docs/update-status',
}

export const apiLinks = {
  BASE_URL,
  MOCK_URL,
  auth,
  user,
  mail,
  kyc,
  docs,
  donation,
}
