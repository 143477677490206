const DoubleTick = () => (
  <svg viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M18.566 5.434a.8.8 0 0 1 0 1.132l-11 11a.8.8 0 0 1-1.131 0l-5-5a.8.8 0 0 1 1.13-1.132L7 15.87 17.434 5.434a.8.8 0 0 1 1.132 0Zm4 4a.8.8 0 0 1 0 1.132l-7.5 7.5a.8.8 0 0 1-1.132 0l-1.5-1.5a.8.8 0 0 1 1.132-1.132l.934.935 6.934-6.935a.8.8 0 0 1 1.132 0Z'
      clipRule='evenodd'
    />
  </svg>
)

export { DoubleTick }
