import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

export const Logo = styled.span`
  display: inline-flex;
  align-items: center;
  width: 12rem;
  flex: 0 0 auto;
  ${(props) => props.theme.breakpoints.greaterThan('lg')`
    width: 14rem;
  `};
`

export const LogoName = styled.span`
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.43;
`

export const LogoSpan = styled.span`
  display: none;
`

export const StyledLink = styled(NavLink)`
  padding: 0;
  display: inline-flex;
  align-items: center;
  z-index: ${(props) => props.theme.zIndex.minimal};
`

export const IconWrapper = styled.span`
  display: flex;
  height: 4.2rem;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.greaterThan('xsm')`
    width: 12rem;
  `};
  ${(props) => props.theme.breakpoints.greaterThan('lg')`
    width: 14rem;
  `};
`
