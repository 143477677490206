import { keyframes } from 'styled-components/macro'
import { theme } from 'theme'

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const pulseOpacityOnly = keyframes`
  0% {
    opacity: ${theme.opacity.default};
  }
  50% {
    opacity: ${theme.opacity.opacity05};
  }
  100% {
    opacity: ${theme.opacity.default};
  }
`

export const ripple = keyframes`
  0%  {
    transform: translate(-100%, -100%);
  }
  80%  {
    transform: translate(-100%, -100%) scale(50);
  }
  100% {
    transform: translate(-100%, -100%) scale(50);
    opacity: ${theme.opacity.invisible};
  }
`

export const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`

export const fadeInLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: ${theme.opacity.invisible};
  }
  to {
    opacity: ${theme.opacity.default};
  }
`

export const fadeOutLeft = keyframes`
  from {
    opacity: ${theme.opacity.default};
  }
  to {
    transform: translateX(-100%);
    opacity: ${theme.opacity.invisible};
  }
`
