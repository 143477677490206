import { useEffect, useMemo } from 'react'

import { Balances, Token } from 'types/entities'

import { crypto } from 'constants/variables'
import { Input, SkeletonElement } from 'components/ui'
import { formatPrice, thousandSeparated } from 'utils/format'

import { Wrap, LoadingWrap, Balance, MaxBtn } from './Style'

type Props = {
  token: Token
  amount: string
  error?: boolean
  data?: Balances
  isLoading: boolean
  errorMessage?: string
  onMaxAmount?: VoidFunction
  onChange: (amount: string) => void
}

const defaultBalance = {
  balance: '0',
  bgBalance: crypto.BG_ZERO._hex,
}

const DECIMALS_SEPARATOR = '.'

export function AmountInput({ data, error, token, amount, onChange, isLoading, onMaxAmount, errorMessage }: Props) {
  const formattedAmount = () => {
    if (!amount.includes(DECIMALS_SEPARATOR)) {
      return
    }
    const [ceil, decimals] = amount.split(DECIMALS_SEPARATOR)
    if (decimals.length <= token.decimals) {
      return
    }
    onChange(ceil + DECIMALS_SEPARATOR + decimals.substring(0, token.decimals))
  }

  useEffect(() => {
    formattedAmount()
  }, [token.decimals])

  const _isLoading = useMemo(() => !data && isLoading, [data, isLoading])

  const balances = useMemo(() => {
    if (data) {
      return data[token.address]
    }
    return defaultBalance
  }, [data, token])

  const formattedBalance = useMemo(() => {
    if (!balances?.balance) {
      return '0'
    }
    return thousandSeparated(formatPrice(balances?.balance, 6))
  }, [balances?.balance])

  return (
    <Wrap>
      <>
        {_isLoading ? (
          <LoadingWrap position='flex-start'>
            <SkeletonElement width='11.5rem' height='2.3rem' borderRadius='0.8rem' />
          </LoadingWrap>
        ) : (
          typeof onMaxAmount === 'function' && (
            <Balance>
              Balance: {formattedBalance}&nbsp;{token.symbol}
              <MaxBtn onClick={onMaxAmount}>Max</MaxBtn>
            </Balance>
          )
        )}
      </>
      <Input
        type='number'
        error={error}
        value={amount}
        onChange={onChange}
        decimals={token.decimals}
        errorMessage={errorMessage}
      />
    </Wrap>
  )
}
