const SentTx = () => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 72 72'>
    <path
      fill='#3A6355'
      fillRule='evenodd'
      d='M67.414 4.586a2 2 0 0 1 0 2.828l-33 33a2 2 0 1 1-2.828-2.828l33-33a2 2 0 0 1 2.828 0Z'
      clipRule='evenodd'
    />
    <path
      fill='#3A6355'
      fillRule='evenodd'
      d='M67.414 4.586a2 2 0 0 1 .474 2.075l-21 60a2 2 0 0 1-3.716.151L31.485 40.515 5.188 28.828a2 2 0 0 1 .151-3.716l60-21a2 2 0 0 1 2.075.474ZM11.422 27.22l22.39 9.951a2 2 0 0 1 1.016 1.016l9.95 22.39L62.74 9.26 11.422 27.221Z'
      clipRule='evenodd'
    />
  </svg>
)

export { SentTx }
