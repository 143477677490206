import { useMemo } from 'react'

import { Button, Icon } from 'components/ui'

import { ConfirmationStatuses, ConfirmationStep, Step } from './types'

type Props = {
  isError: boolean
  step: Step | null
  isLoading: boolean
  isSuccess: boolean
  loadingText: string
  isDisabled: boolean
  onRetry: VoidFunction
  onNextStep: VoidFunction
  shouldButtonHide: boolean
  onCurrentClose?: VoidFunction
  onAction: () => Promise<string | undefined | void> | void
}

export function ActionButton({
  step,
  isError,
  onRetry,
  onAction,
  isSuccess,
  isLoading,
  onNextStep,
  isDisabled,
  loadingText,
  onCurrentClose,
  shouldButtonHide,
}: Props) {
  const isStepCompleted = useMemo(() => {
    return step?.status === ConfirmationStatuses.COMPLETE
  }, [step?.status])

  const buttonText = useMemo(() => {
    if (step?.key === ConfirmationStep.REVOKE) {
      return (
        <>
          Next <Icon name='arrow' rotate='-90deg' />
        </>
      )
    }

    if (step?.key === ConfirmationStep.APPROVE) {
      return (
        <>
          Next <Icon name='arrow' rotate='-90deg' />
        </>
      )
    }

    return 'Proceed'
  }, [step?.key])

  const _isDisabled = useMemo(() => step === null || isDisabled, [step, isDisabled])
  const handleAction = useMemo(() => (isStepCompleted ? onNextStep : onAction), [isStepCompleted, onNextStep, onAction])

  if (isError) {
    return (
      <>
        <Button size='large' fullSize onClick={onRetry}>
          Try again
        </Button>
        <Button type='outline' size='large' onClick={onCurrentClose}>
          Close
        </Button>
      </>
    )
  }
  if (isSuccess) {
    return (
      <Button fullSize size='large' onClick={onCurrentClose}>
        Make a New donation
      </Button>
    )
  }

  if (shouldButtonHide) {
    return null
  }

  return (
    <Button size='large' isLoading={isLoading} onClick={handleAction} loadingText={loadingText} disabled={_isDisabled}>
      {buttonText}
    </Button>
  )
}
