import { useNavigate } from 'react-router-dom'

import { Links } from 'constants/link'
import { Button } from 'components/ui'

import { Section, Container, Title, Text } from './Style'

const NotFound = () => {
  const navigate = useNavigate()

  const handleGoHome = () => {
    navigate(Links.MAIN, { replace: true })
  }

  return (
    <Section>
      <Container>
        <Title>Page not found</Title>
        <Text>Sorry, the page you are looking for doesn&rsquo;t exist.</Text>
        <Button fullSize size='large' onClick={handleGoHome}>
          Take me home
        </Button>
      </Container>
    </Section>
  )
}

export { NotFound }
