import { VerificationSteps } from 'types/entities'

import { useSetVerificationStep } from 'state/user/hooks'

import { Payment } from 'components/features'

export function DonationFlow() {
  const setVerificationStep = useSetVerificationStep()

  const onDonationVerificationToggle = () => {
    setVerificationStep(VerificationSteps.DOC)
  }

  return <Payment onDocVerification={onDonationVerificationToggle} />
}
