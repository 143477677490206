import numbro from 'numbro'

export function formatPrice(_value: number | string, decimals = 0, isFiat = false) {
  const value = Number(_value)

  const minValue = Math.pow(10, -decimals)
  if (value < minValue && value > 0) {
    if (isFiat) {
      return `> ${numbro(minValue).formatCurrency({ mantissa: decimals })}`
    }
    return `> ${numbro(minValue).format({ mantissa: decimals })}`
  }

  if (isFiat) {
    return numbro(value).formatCurrency({
      trimMantissa: true,
      mantissa: decimals,
    })
  }
  return numbro(value).format({
    trimMantissa: true,
    mantissa: decimals,
  })
}

export function thousandSeparated(amount?: number | string, isFiat?: boolean) {
  if (!amount) {
    return '-'
  }

  if (isNaN(Number(amount))) {
    return String(amount)
  }

  if (isFiat) {
    return numbro(amount).formatCurrency({
      thousandSeparated: true,
    })
  }
  return numbro(amount).format({
    thousandSeparated: true,
  })
}
