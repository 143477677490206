import { Keyof, ValueOf } from 'types/default'
import { UserData, Hash } from 'types/entities'

import { PaymentType } from 'constants/app'
import { SupportedChainId } from 'constants/chains'

export type User = null | UserData

export enum Setting {
  PAYMENT_TYPE = 'PAYMENT_TYPE',
}

export type Settings = {
  [Setting.PAYMENT_TYPE]: PaymentType
}

export type UpdateSettingInput = {
  key: Keyof<Settings>
  value: ValueOf<Settings>
}

export type GetUserInput = void | boolean
export type GetUserResponse = UserData

export type GetAllUsersInput = void | boolean
export type GetAllUsersResponse = UserData[]

export enum UserOperationsTypes {
  'DONATE' = 'Donate',
  'APPROVE' = 'Approve',
  'REVOKE' = 'Revoke',
}

export enum UserOperationsStatuses {
  'COMPLETE' = 'Complete',
  'FAILED' = 'Failed',
  'PENDING' = 'Pending',
}

export type UserTransaction = {
  id: string
  value: string
  wallet?: string
  txHash?: string
  timestamp: number
  address?: string
  currency?: string | Hash
  paymentType: PaymentType
  chainId?: SupportedChainId
  operation: UserOperationsTypes
  status: UserOperationsStatuses
}

export type UserTransactions = Record<string, UserTransaction>
