import { useCallback } from 'react'

import { getApiError } from 'utils/helpers'

import { NewDonationInput } from './types'
import { useGetDonationsQuery, useNewDonationMutation } from './api'

const POOLING_INTERVAL = 30_000
export function useGetAllDonationsRequest(shouldPooling: boolean, pollingInterval = POOLING_INTERVAL) {
  const request = useGetDonationsQuery(shouldPooling, {
    skip: !shouldPooling,
    pollingInterval,
  })

  return { ...request, error: getApiError(request.error) }
}

export function useOnNewDonation() {
  const [newDonation, requestState] = useNewDonationMutation()

  const onNewDonation = useCallback(
    async (params: NewDonationInput) => {
      try {
        const response = await newDonation(params).unwrap()
        return response
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [newDonation],
  )

  return { ...requestState, error: getApiError(requestState.error), onNewDonation }
}
