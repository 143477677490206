import { Token, Allowances } from 'types/entities'

import { isEther } from 'utils/crypto'

import { getERC20Token } from 'contracts'

import { MulticallArgs, MulticallParams } from './types'

const GAS_LIMIT = 100000
export const getMulticallParams = ({ customer, tokens, chainId, rpcUrl, targetAddress }: MulticallArgs) => {
  const [randomToken] = tokens

  const erc20Token = getERC20Token(randomToken.address, chainId, rpcUrl)

  const getMulticallParam = (acc: MulticallParams, token: Token) => {
    if (!isEther(token.address)) {
      const callData = erc20Token.interface.encodeFunctionData('allowance', [customer, targetAddress])

      acc.push({ callData, gasLimit: GAS_LIMIT, target: token.address })
    }
    return acc
  }

  const multicallParams = tokens.reduce<MulticallParams>(getMulticallParam, [])
  return multicallParams
}

export const getAllowancesMap = (acc: Allowances, curr: { returnData: string }, token: Token) => {
  const { address } = token
  acc[address] = curr.returnData === '0x' ? '0x0' : curr.returnData
  return acc
}
