import { components } from 'react-select'
import { ControlProps } from 'react-select/src/components/Control'
import { GroupTypeBase, OptionTypeBase } from 'react-select/src/types'

import { Icon } from '../Icon'
import { CustomComponentSelectProps } from './@types'

import { OptionRow } from './Style'

function DropdownIndicator(props: CustomComponentSelectProps) {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name='chevron' size='fill' />
    </components.DropdownIndicator>
  )
}

function Option(props: CustomComponentSelectProps) {
  return (
    <components.Option {...props}>
      <OptionRow>
        {props.data.icon && <Icon name={props.data.icon} />}
        {props.data.label}
      </OptionRow>
    </components.Option>
  )
}

function Control<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
>({ children, ...props }: ControlProps<OptionType, IsMulti, GroupType>) {
  return (
    <components.Control {...props}>
      {/* @ts-ignore */}
      {props.selectProps?.value?.icon ? (
        <OptionRow>
          {/* @ts-ignore */}
          <Icon name={props.selectProps.value.icon} />
          {children}
        </OptionRow>
      ) : (
        children
      )}
    </components.Control>
  )
}

export default { DropdownIndicator, Option, Control }
