import { useCallback, useEffect, useState } from 'react'

import { Child, Props, ActiveTab } from './@types'

import { Tab } from './Tab'
import { Wrapper, Tabs, TabWrap, TabsContent } from './Style'

const initialTab: ActiveTab = {
  id: null,
  title: null,
  content: null,
}

function TabsSwitcher({ padding, value, children, onTabChange, isLink, align = 'start', hideTabs = false }: Props) {
  const [activeTab, setActiveTab] = useState<ActiveTab>(initialTab)

  useEffect(() => {
    const element = Array.isArray(children) ? children : [children]

    const content = element.find(({ props }: Child) => props.id === value)

    if (content) {
      setActiveTab({
        id: content.props.id,
        title: content.props.title,
        content: content.props.children,
      })
    } else {
      setActiveTab(initialTab)
    }
  }, [value, children])

  const onHandleTabChange = useCallback(
    (id: string) => {
      if (typeof onTabChange === 'function') {
        onTabChange(id)
      }
    },
    [onTabChange],
  )

  const getItem = ({ props }: Child) => (
    <Tab
      key={props.id}
      id={props.id}
      isLink={isLink}
      title={props.title}
      onClick={onHandleTabChange}
      dataIcon={props['data-icon']}
      dataUpdate={props['data-update']}
      isActive={props.id === activeTab.id}
    />
  )

  return (
    <Wrapper>
      {!hideTabs && (
        <Tabs role='tablist'>
          <TabWrap align={align} padding={padding}>
            {Array.isArray(children) ? children?.map(getItem) : [children].map(getItem)}
          </TabWrap>
        </Tabs>
      )}
      <TabsContent>{activeTab.content}</TabsContent>
    </Wrapper>
  )
}

export { TabsSwitcher }
