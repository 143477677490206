import { useMemo } from 'react'

import { getPaginationItems } from 'utils'
import { numbers } from 'constants/variables'

import { Icon } from 'components/ui'
import {
  Wrapper,
  PageItem,
  ShowText,
  Container,
  ShowTextValue,
  PageItemMobile,
  PaginationButton,
  PagesMobileWrapper,
  PagesDesktopWrapper,
} from './Style'

type Props = {
  total: number
  current: number
  showText?: boolean
  disabled?: boolean
  onPageClick: (page: number) => void
}

function Pagination({ total, current, onPageClick, showText = false, disabled = false }: Props) {
  const items = useMemo(() => {
    return getPaginationItems({
      total,
      current,
      siblingCount: numbers.ONE,
    })
  }, [total, current])

  const disableFirst = current === numbers.ONE
  const disableLast = current === total

  const onClickPage = (page: number | null) => () => {
    if (page && !disabled) {
      onPageClick(page)
    }
  }

  return (
    <Container showText={showText}>
      {showText && (
        <ShowText>
          <ShowTextValue>Showing {current}</ShowTextValue> of {total}
        </ShowText>
      )}

      <Wrapper>
        {total !== numbers.ONE && (
          <PaginationButton size='symbol' disabled={disableFirst} onClick={onClickPage(current - numbers.ONE)}>
            <Icon name='chevron' size='extraMedium' rotate='90deg' />
          </PaginationButton>
        )}

        <PagesMobileWrapper>
          <PageItemMobile active={false} disabled>
            {current} of {total} total
          </PageItemMobile>
        </PagesMobileWrapper>

        <PagesDesktopWrapper>
          {items.map((item, index) => (
            <PageItem key={index} disabled={!item} active={current === item} onClick={onClickPage(item)}>
              {item || '...'}
            </PageItem>
          ))}
        </PagesDesktopWrapper>

        {total !== numbers.ONE && (
          <PaginationButton size='symbol' disabled={disableLast} onClick={onClickPage(current + numbers.ONE)}>
            <Icon name='chevron' size='extraMedium' rotate='-90deg' />
          </PaginationButton>
        )}
      </Wrapper>
    </Container>
  )
}

export { Pagination }
