import { createApi } from '@reduxjs/toolkit/query/react'

import { customAuthorizedQuery } from 'state/api'

import { apiLinks } from 'constants/api'

import { NewDonationInput, GetDonationsInput, NewDonationResponse, GetDonationsResponse } from './types'

export const donationApi = createApi({
  reducerPath: 'donationApi',
  baseQuery: customAuthorizedQuery({
    baseUrl: apiLinks.BASE_URL,
  }),
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    newDonation: builder.mutation<NewDonationResponse, NewDonationInput>({
      query: (params: NewDonationInput) => ({
        url: apiLinks.donation.newDonation,
        method: 'POST',
        data: params,
      }),
    }),
    getDonations: builder.query<GetDonationsResponse, GetDonationsInput>({
      query: () => ({
        url: apiLinks.donation.getAll,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetDonationsQuery, useNewDonationMutation } = donationApi
