import { useMemo, useState } from 'react'

import { DocStatus, KycStatus } from 'types/entities'

import { useGetAllKycRequest } from 'state/kyc/hooks'
import { useGetAllDocsRequest } from 'state/docs/hooks'

import { TabsSwitcher } from 'components/ui'

import { VerificationsTable, NewDocumentsTable } from './Table'

import { Section, TabsContent } from './Style'

export const CONTENT_TABS = {
  VERIFICATIONS: 'verifications',
  NEW_DOCUMENTS: 'newDocuments',
}

function AdminVerifications() {
  const [activeTab, setActiveTab] = useState(CONTENT_TABS.VERIFICATIONS)

  const { data: kycData, isLoading: kycIsLoading, refetch: kycRefetch } = useGetAllKycRequest(true)
  const { data: docsData, isLoading: docsLoading, refetch: docsRefetch } = useGetAllDocsRequest(true)

  const handleTabChange = (id: string) => {
    setActiveTab(id)
  }

  const updateData = async () => {
    await Promise.allSettled([kycRefetch(), docsRefetch()])
  }

  const getDataLengthByStatusType = <S, D extends Array<{ status: S }>>(data: D, status: S) => {
    return data.filter((item) => item.status === status).length || null
  }

  const pendingKycCount = useMemo(
    () => (kycData ? getDataLengthByStatusType(kycData, KycStatus.PENDING) : null),
    [kycData],
  )

  const pendingDocsCount = useMemo(
    () => (docsData ? getDataLengthByStatusType(docsData, DocStatus.PENDING) : null),
    [docsData],
  )

  const tabs = useMemo(() => {
    return [
      {
        title: 'Verifications',
        update: pendingKycCount,
        value: CONTENT_TABS.VERIFICATIONS,
        component: <VerificationsTable data={kycData} isLoading={kycIsLoading} updateData={updateData} />,
      },
      {
        title: 'New documents',
        update: pendingDocsCount,
        value: CONTENT_TABS.NEW_DOCUMENTS,
        component: <NewDocumentsTable data={docsData} isLoading={docsLoading} updateData={updateData} />,
      },
    ]
  }, [kycData, docsData, kycIsLoading, docsLoading, pendingKycCount, pendingDocsCount])

  return (
    <Section>
      <TabsSwitcher value={activeTab} onTabChange={handleTabChange}>
        {tabs?.map((tab) => (
          <div key={tab.value} id={tab.value} title={tab.title} data-update={tab.update}>
            <TabsContent>{tab.component}</TabsContent>
          </div>
        ))}
      </TabsSwitcher>
    </Section>
  )
}

export { AdminVerifications }
