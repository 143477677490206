import { numbers } from 'constants/variables'

import { icons } from './icons'
import { Props } from './@types'
import { IconWrapper } from './Style'

function CustomIcon({ name }: Props) {
  const IconComponent = icons[name]

  if (IconComponent) {
    return <IconComponent />
  }
  return <>{name ? name.charAt(numbers.ZERO) : null}</>
}

function Icon({ name, size = 'default', rotate = '0deg', ...rest }: Props) {
  return (
    <IconWrapper size={size} {...rest} rotate={rotate}>
      <CustomIcon name={name} />
    </IconWrapper>
  )
}

export { Icon }
