export function dataURLtoFile(dataUrl: string, filename: string) {
  const arr = dataUrl.split(',')
  const _mimeSource = arr[0].match(/:(.*?);/)
  if (!_mimeSource) {
    throw new Error('Converting Failed')
  }
  const mime = _mimeSource[1]

  const bstr = atob(arr[1])

  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}
