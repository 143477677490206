const Success = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'>
    <path
      fillRule='evenodd'
      d='M.204 12.482c0-6.628 5.373-12 12-12s12 5.372 12 12c0 6.627-5.373 12-12 12s-12-5.373-12-12Zm12-10.91c-6.025 0-10.909 4.885-10.909 10.91 0 6.024 4.884 10.909 10.91 10.909 6.024 0 10.908-4.884 10.908-10.91 0-6.024-4.884-10.909-10.909-10.909Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M16.953 8.823a.545.545 0 0 1 0 .771l-6 6a.545.545 0 0 1-.771 0l-2.727-2.727a.545.545 0 0 1 .771-.771l2.342 2.341 5.614-5.614a.545.545 0 0 1 .771 0Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Success }
