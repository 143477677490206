import { BigNumber, utils, BigNumberish } from 'ethers'

import { crypto, numbers } from 'constants/variables'
import { ETH_ADDRESS, ZERO_ADDRESS } from 'constants/addresses'
import { CHAIN_INFO, SupportedChainId } from 'constants/chains'

export function isAddress(value: any): string | false {
  try {
    return utils.getAddress(value)
  } catch {
    return false
  }
}

export function stringToHex(str: string) {
  return utils.hexlify(utils.toUtf8Bytes(str))
}

export function toChecksumAddress(value: any): string {
  try {
    return utils.getAddress(value)
  } catch {
    return ''
  }
}

export function compareAddresses(firstAddr?: string, secondAddr?: string): boolean {
  try {
    return toChecksumAddress(firstAddr) === toChecksumAddress(secondAddr)
  } catch (e) {
    return false
  }
}

export function toWei(value: BigNumberish, uintName: BigNumberish = 'ether'): BigNumber {
  if (!value) {
    return crypto.BG_ZERO
  }
  return utils.parseUnits(value.toString(), uintName)
}

export function hexToNumber(hex: string) {
  return BigNumber.from(hex).toNumber()
}

export function numberToHex(value: number | bigint | string | BigNumberish) {
  return utils.hexlify(value)
}

// @ts-expect-error for set unitName init value
export function fromWei(balance: BigNumberish, unitName?: string | BigNumberish = numbers.ETH_DECIMALS) {
  return utils.formatUnits(String(balance), unitName)
}

export function getEtherscanLink(
  chainId: SupportedChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block',
): string {
  const prefix = CHAIN_INFO[chainId].explorer

  switch (type) {
    case 'transaction': {
      return `${prefix}tx/${data}`
    }
    case 'token': {
      return `${prefix}token/${data}`
    }
    case 'block': {
      return `${prefix}block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}address/${data}`
    }
  }
}

export function shortenAddress(address: string, chars = Number('4')): string {
  const parsed = isAddress(address)

  const SKIP_LENGTH = 42

  if (parsed === false) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return `${parsed.substring(numbers.ZERO, chars + crypto.ZERO_X_LENGTH)}...${parsed.substring(SKIP_LENGTH - chars)}`
}

export function bump(gas: BigNumber, percent: number) {
  return gas.mul(percent).div(numbers.ONE_HUNDRED)._hex
}

export function isEther(address: string) {
  return address === ETH_ADDRESS || address === ZERO_ADDRESS
}
