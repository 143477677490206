import { useMemo } from 'react'

import { BigNumber } from 'ethers'

import { color } from 'theme'
import { UserOperationsTypes } from 'state/user/types'

import { fromWei } from 'utils/crypto'
import { formatPrice } from 'utils/format'

import { crypto } from 'constants/variables'
import { appConstants } from 'constants/app'

import { Loader } from 'components/ui'

import { DonationInput } from '../types'
import {
  Info,
  Section,
  DataItem,
  InfoWrap,
  InfoItem,
  InfoHeader,
  DataItemTitle,
  DataItemValue,
  InfoItemTitle,
  InfoItemValue,
  LoadingContainer,
} from '../Style'
import { TxStatus } from './TxStatus'

type ConnectModalProps = {
  txHash: string
  isSent: boolean
  gasPrice?: bigint
  isLoading: boolean
  estimateGas?: string
  errorMessage: string
  donation: DonationInput
  chainId: SupportedChainId
  onCurrentClose?: VoidFunction
}

export function DonationInfo({
  isSent,
  txHash,
  chainId,
  donation,
  gasPrice,
  isLoading,
  estimateGas,
  errorMessage,
  onCurrentClose,
}: ConnectModalProps) {
  const estimatedTxCost = useMemo(() => {
    if (!gasPrice || !estimateGas) {
      return null
    }

    const gasCost = BigNumber.from(gasPrice).mul(estimateGas)
    return {
      gasCost: formatPrice(fromWei(gasCost, crypto.WEI_DECIMALS), 6),
      gasPrice: formatPrice(fromWei(gasPrice, crypto.GWEI_DECIMALS), 2),
    }
  }, [gasPrice, estimateGas])

  const _amount = useMemo(() => {
    return formatPrice(fromWei(donation.amount, donation.token.decimals), 6)
  }, [donation.amount, donation.token.decimals])

  const _recipient = useMemo(() => appConstants.RECIPIENTS_DATA[donation.recipient]?.label, [donation.recipient])

  return (
    <>
      {!errorMessage && !txHash && (
        <>
          <Section>
            <DataItem>
              <DataItemTitle>Donation to</DataItemTitle>
              <DataItemValue>{_recipient}</DataItemValue>
            </DataItem>
            <DataItem>
              <DataItemTitle>Donation from</DataItemTitle>
              <DataItemValue>{donation.address}</DataItemValue>
            </DataItem>
            <DataItem>
              <DataItemTitle>Donation amount</DataItemTitle>
              <DataItemValue>
                {_amount} {donation.token.symbol}
              </DataItemValue>
            </DataItem>
          </Section>
          <Info>
            <InfoHeader>Additional information</InfoHeader>
            <InfoWrap>
              <InfoItem>
                <InfoItemTitle>Gas Fee</InfoItemTitle>
                <InfoItemValue>
                  {isLoading || !estimatedTxCost ? (
                    <LoadingContainer>
                      <Loader color={color.white} /> Loading...
                    </LoadingContainer>
                  ) : (
                    <>
                      <span>{estimatedTxCost.gasCost} ETH</span> <span>({estimatedTxCost.gasPrice} GWEI)</span>
                    </>
                  )}
                </InfoItemValue>
              </InfoItem>
            </InfoWrap>
          </Info>
        </>
      )}
      <TxStatus
        isSent={isSent}
        txHash={txHash}
        chainId={chainId}
        errorMessage={errorMessage}
        onCurrentClose={onCurrentClose}
        txType={UserOperationsTypes.DONATE}
        successTitle='Congratulations!'
        successText='Thank you for your support!'
      />
    </>
  )
}
