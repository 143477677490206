import styled, { css } from 'styled-components/macro'

export const Container = styled.section<{ layoutCenter?: boolean }>`
  width: 100%;
  height: calc(100vh - 5.8rem);
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.6rem;
  ${(props) => props.theme.breakpoints.greaterThan('md')`
     grid-template-columns: 1fr 0.75fr;
      ${
        props.layoutCenter &&
        css`
          grid-template-columns: 1fr;
        `
      }
  `};
`

export const Content = styled.div`
  margin: auto;
  padding: 4.8rem 1.6rem;
  width: 100%;
  max-width: 55.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  margin: 0 0 3.2rem;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2.4rem;
  line-height: 1.44;
  color: ${(props) => props.theme.color.text};
  ${(props) => props.theme.breakpoints.greaterThan('xsm')`
    margin: 0 0 5rem;
    font-size: 3.2rem;
  `};
`

export const PreTitle = styled.h4`
  margin: 2rem 0 3.2rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.33;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  a {
    color: ${(props) => props.theme.color.textHint};
  }
`

export const Form = styled.form`
  width: 100%;
`

export const InputWrap = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`

export const ErrorWrap = styled.div`
  margin: 0;
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ErrorContent = styled.span`
  margin: 0 auto;
  padding: 0.6rem 1rem;
  width: fit-content;
  display: flex;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.67;
  letter-spacing: 0.06rem;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.danger};
  border-radius: 0.6rem;
  z-index: ${(props) => props.theme.zIndex.minimal};
  &::first-letter {
    text-transform: uppercase;
  }
`
