import { useMemo } from 'react'

import { useAccount } from 'wagmi'
import { NavLink } from 'react-router-dom'
import { ConnectButton as RainbowButton } from '@rainbow-me/rainbowkit'
import { ConnectButtonProps } from '@rainbow-me/rainbowkit/dist/components/ConnectButton/ConnectButton'

import { useChainData, useGetBalancesRequest } from 'hooks/app'

import { usePendingTransactions } from 'state/user/hooks'

import { Links } from 'constants/link'
import { formatPrice } from 'utils/format'
import { ETH_ADDRESS } from 'constants/addresses'
import { Button, Loader } from 'components/ui'
import {
  Wrap,
  ImgChain,
  StyledButton,
  ChainButton,
  ChainButtonName,
  ChevronIcon,
  AccountBalance,
  AccountName,
} from './Style'

// https://github.com/rainbow-me/rainbowkit/blob/main/packages/rainbowkit/src/components/ConnectButton/ConnectButton.tsx
export const ConnectButton = (props: ConnectButtonProps) => {
  const { address } = useAccount()
  const { chainId, symbol } = useChainData()
  const { data: balances } = useGetBalancesRequest(address, chainId)

  const pendingTxs = usePendingTransactions()

  const pendingTxsCount = useMemo(() => Object.values(pendingTxs).length, [pendingTxs])
  const shouldPendingShow = useMemo(() => pendingTxsCount > 0, [pendingTxsCount])

  const etherBalance = useMemo(() => balances?.[ETH_ADDRESS], [balances?.[ETH_ADDRESS]])
  const displayedBalance = useMemo(() => (etherBalance ? formatPrice(etherBalance.balance, 4) : ''), [etherBalance])

  return (
    <RainbowButton.Custom {...props}>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
        const connected = mounted && account && chain

        return (
          <div
            {...(!mounted && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button type='primary' size='medium' onClick={openConnectModal}>
                    Connect Wallet
                  </Button>
                )
              }
              if (chain.unsupported) {
                return (
                  <Button type='primary' size='medium' onClick={openChainModal}>
                    Wrong network
                  </Button>
                )
              }
              return (
                <Wrap>
                  <ChainButton type='primary' size='medium' onClick={openChainModal}>
                    {chain.hasIcon && (
                      <>{chain.iconUrl && <ImgChain src={chain.iconUrl} alt={chain.name ?? 'Chain icon'} />}</>
                    )}
                    <ChainButtonName>{chain.name}</ChainButtonName>
                    <ChevronIcon name='chevron' size='extraMedium' />
                  </ChainButton>

                  {shouldPendingShow ? (
                    <NavLink to={Links.PROFILE}>
                      <Button type='outline' size='medium'>
                        {pendingTxsCount} Pending <Loader />
                      </Button>
                    </NavLink>
                  ) : (
                    <StyledButton type='outline' size='medium' onClick={openAccountModal}>
                      <AccountBalance>
                        {displayedBalance}&nbsp;{symbol}
                      </AccountBalance>
                      <AccountName>
                        {account.ensAvatar}
                        {account.displayName}
                      </AccountName>
                    </StyledButton>
                  )}
                </Wrap>
              )
            })()}
          </div>
        )
      }}
    </RainbowButton.Custom>
  )
}
