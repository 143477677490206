import { ReactNode } from 'react'
import { theme } from 'theme'

import { mainnet, goerli } from 'wagmi/chains'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { getDefaultWallets, RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit'

import { RPC_LIST } from 'constants/chains'
import { appConstants } from 'constants/app'

import '@rainbow-me/rainbowkit/styles.css'

type Props = {
  children: ReactNode
}

function RainbowProvider({ children }: Props) {
  const defaultChains = process.env?.NODE_ENV === 'production' ? [mainnet] : [mainnet, goerli]
  const { chains, publicClient } = configureChains(defaultChains, [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: RPC_LIST[chain.id as SupportedChainId],
      }),
    }),
  ])

  // TODO: need check to change
  const { connectors } = getDefaultWallets({
    chains,
    appName: appConstants.APP_NAME,
    projectId: appConstants.WALLET_CONNECT_APP_ID,
  })

  const wagmiConfig = createConfig({
    connectors,
    publicClient,
    autoConnect: true,
  })

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        theme={lightTheme({
          fontStack: 'system',
          borderRadius: 'small',
          accentColor: theme.color.default,
          accentColorForeground: theme.color.white,
        })}
      >
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  )
}

export default RainbowProvider
