import { ReactNode } from 'react'

import { Header, Title, Subtitle } from './Style'

type Props = {
  type?: string
  align?: string
  onClose?: VoidFunction
  children: ReactNode
  title: string | ReactNode
  subtitle?: string | ReactNode
}

function ModalLayout({ type = 'default', align = 'left', title, subtitle, children }: Props) {
  return (
    <>
      <Header type={type} align={align} className='modalHeader'>
        <Title type={type}>{title}</Title>
        {subtitle && <Subtitle type={type}>{subtitle}</Subtitle>}
      </Header>

      {children}
    </>
  )
}

export { ModalLayout }
