import { useMemo, useState } from 'react'

import { DateTime } from 'luxon'
import { Column } from 'react-table'

import { Kyc, KycStatus, KycType } from 'types/entities'

import { Table } from 'components/ui'

import {
  Name,
  Status,
  Location,
  Sanction,
  TimeCreated,
  TimeUpdated,
  ControlType,
  AcceptVerification,
  DeclineVerification,
} from './Cells'
import { Filters } from './Filters'

import { KycVerificationTableProps } from '../types'

const LIMIT = 8

function VerificationsTable({ data, isLoading, updateData }: KycVerificationTableProps) {
  const [page, setPage] = useState(1)
  const [nameFilter, setNameFilter] = useState('')
  const [kycTypeFilter, setKycTypeFilter] = useState<KycType | null>(null)
  const [kycStatusFilter, setKycStatusFilter] = useState<KycStatus | null>(null)

  const getMillis = (date: Date) => {
    return DateTime.fromISO(date.toString()).toMillis()
  }

  const handleChangeKycType = (_kycType: KycType | null) => {
    setKycTypeFilter(_kycType)
  }

  const handleChangeKycStatus = (kycStatus: KycStatus | null) => {
    setKycStatusFilter(kycStatus)
  }

  const handleChangeName = (value: string) => {
    setNameFilter(value)
  }

  const filterByStatus = (el: Kyc) => {
    if (kycStatusFilter) {
      return el.status === kycStatusFilter
    }
    return el.status !== KycStatus.NOT_STARTED && el.status !== KycStatus.STARTED
  }

  const filterByType = (el: Kyc) => {
    if (kycTypeFilter) {
      return el.controlType === kycTypeFilter
    }
    return true
  }

  const filterByName = (el: Kyc) => {
    if (nameFilter) {
      return el.name.toLowerCase().includes(nameFilter.toLowerCase())
    }
    return true
  }

  const tableData = useMemo(() => {
    if (!data) {
      return null
    }
    const filtered = [...data].filter((el) => filterByStatus(el) && filterByType(el) && filterByName(el))

    return filtered.sort((a, b) => getMillis(b.createdAt) - getMillis(a.createdAt))
  }, [data, nameFilter, kycTypeFilter, kycStatusFilter])

  const rows = useMemo(() => {
    if (!tableData) {
      return []
    }
    return tableData.slice((page - 1) * LIMIT, LIMIT * page)
  }, [page, tableData])

  const lastPage = useMemo(() => {
    if (!tableData) {
      return 0
    }
    return Math.ceil(tableData.length / LIMIT)
  }, [tableData])

  const columns: Column<Kyc>[] = useMemo(
    () => [
      {
        Header: 'Start at',
        accessor: 'createdAt',
        Cell: TimeCreated,
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        Cell: TimeUpdated,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: Name,
      },
      {
        Header: 'Location',
        accessor: 'location',
        Cell: Location,
      },
      {
        Header: 'Sanction',
        accessor: 'isSanction',
        Cell: Sanction,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: Status,
      },
      {
        Header: 'Control type',
        accessor: 'controlType',
        Cell: ControlType,
      },
      {
        Header: '',
        accessor: 'id',
        id: 'acceptVerificationButton',
        Cell: (data) => <AcceptVerification {...data} updateData={updateData} />,
      },
      {
        Header: '',
        accessor: 'id',
        id: 'declineVerificationButton',
        Cell: (data) => <DeclineVerification {...data} updateData={updateData} />,
      },
    ],
    [rows],
  )

  return (
    <>
      <Filters
        name={nameFilter}
        kycType={kycTypeFilter}
        kycStatus={kycStatusFilter}
        onChangeName={handleChangeName}
        onChangeKycType={handleChangeKycType}
        onChangeKycStatus={handleChangeKycStatus}
      />
      <Table<Kyc>
        showText
        data={rows}
        page={page}
        onPage={setPage}
        columns={columns}
        lastPage={lastPage}
        type='verifications'
        isLoading={isLoading}
        name='verificationsTable'
      />
    </>
  )
}

export { VerificationsTable }
