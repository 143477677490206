const Error = () => (
  <svg viewBox='0 0 25 25'>
    <path
      fillRule='evenodd'
      d='M.204 12.063c0-6.627 5.373-12 12-12s12 5.373 12 12c0 6.628-5.373 12-12 12s-12-5.372-12-12Zm12-10.909c-6.025 0-10.909 4.884-10.909 10.91 0 6.024 4.884 10.908 10.91 10.908 6.024 0 10.908-4.884 10.908-10.909S18.23 1.154 12.204 1.154Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M15.862 8.405a.545.545 0 0 1 0 .771l-6.545 6.546a.545.545 0 0 1-.771-.772l6.545-6.545a.545.545 0 0 1 .771 0Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M8.546 8.405a.545.545 0 0 1 .77 0l6.546 6.545a.545.545 0 1 1-.771.772L8.546 9.176a.545.545 0 0 1 0-.771Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Error }
