import { createApi } from '@reduxjs/toolkit/query/react'

import { customAuthorizedQuery } from 'state/api'

import { apiLinks } from 'constants/api'

import {
  NewDocInput,
  GetDocsInput,
  NewDocResponse,
  GetDocsResponse,
  UpdateDocStatusInput,
  UpdateDocStatusResponse,
} from './types'

export const docsApi = createApi({
  reducerPath: 'docsApi',
  baseQuery: customAuthorizedQuery({
    baseUrl: apiLinks.BASE_URL,
  }),
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    newDoc: builder.mutation<NewDocResponse, NewDocInput>({
      query: ({ file, ...params }: NewDocInput) => {
        const bodyFormData = new FormData()
        bodyFormData.append('file', file)
        bodyFormData.append('sourceOfFunds', params.sourceOfFunds)

        return {
          method: 'POST',
          data: bodyFormData,
          url: apiLinks.docs.newDoc,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      },
    }),
    updateDocStatus: builder.mutation<UpdateDocStatusResponse, UpdateDocStatusInput>({
      query: (params: UpdateDocStatusInput) => ({
        url: apiLinks.docs.updateStatus,
        method: 'PUT',
        data: params,
      }),
    }),
    getDocs: builder.query<GetDocsResponse, GetDocsInput>({
      query: () => ({
        url: apiLinks.docs.getAll,
        method: 'GET',
      }),
    }),
  }),
})

export const { useNewDocMutation, useGetDocsQuery, useUpdateDocStatusMutation } = docsApi
