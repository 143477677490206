import { useMemo, useState } from 'react'

import { DateTime } from 'luxon'
import { Column } from 'react-table'

import { Donation } from 'types/entities'

import { Table } from 'components/ui'
import { useGetAllDonationsRequest } from 'state/donation/hooks'

import { Email, Amount, Address, Time } from './Cells'

const LIMIT = 8

function DonationsTable() {
  const [page, setPage] = useState(1)
  const { data, isLoading } = useGetAllDonationsRequest(true)

  const donations = useMemo(() => {
    if (!data) {
      return null
    }
    const _data = [...data]
    return _data.sort(
      (a, b) =>
        DateTime.fromISO(b.createdAt.toString()).toMillis() - DateTime.fromISO(a.createdAt.toString()).toMillis(),
    )
  }, [data])

  const rows = useMemo(() => {
    if (!donations) {
      return []
    }
    return donations.slice((page - 1) * LIMIT, LIMIT * page)
  }, [page, donations])

  const lastPage = useMemo(() => {
    if (!donations) {
      return 0
    }
    return Math.ceil(donations.length / LIMIT)
  }, [donations])

  const columns: Column<Donation>[] = useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'createdAt',
        Cell: Time,
      },
      {
        Header: 'Donor e-mail',
        accessor: 'user',
        Cell: Email,
      },
      {
        Header: 'Donor',
        accessor: 'address',
        Cell: Address,
      },
      {
        Header: 'Recipient',
        accessor: 'recipient',
        Cell: Address,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: Amount,
      },
    ],
    [rows],
  )

  return (
    <Table<Donation>
      showText
      data={rows}
      page={page}
      onPage={setPage}
      columns={columns}
      lastPage={lastPage}
      type='newDonations'
      title='New donations'
      isLoading={isLoading}
      name='newDonationsTable'
    />
  )
}

export { DonationsTable }
