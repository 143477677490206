import { useMemo } from 'react'

import { KycStatus, KycType, VerificationSteps } from 'types/entities'

import { useKycStatus, useKycType, useVerificationStep } from 'state/user/hooks'

export function useStepper() {
  const kycType = useKycType()
  const kycStatus = useKycStatus()
  const verificationStep = useVerificationStep()

  const isInit = useMemo(() => verificationStep === VerificationSteps.INIT, [verificationStep])
  const isKycTypeNotSelected = useMemo(() => kycType === KycType.NOT_SELECT || isInit, [kycType, isInit])

  const isKyc = useMemo(
    () => verificationStep === VerificationSteps.KYC || isKycTypeNotSelected,
    [isKycTypeNotSelected, verificationStep],
  )
  const isDocVerification = useMemo(() => verificationStep === VerificationSteps.DOC, [verificationStep])

  const isCompleteKyc = useMemo(() => kycStatus === KycStatus.COMPLETED, [kycStatus])
  const isVerificationComplete = useMemo(() => verificationStep === VerificationSteps.COMPLETE, [verificationStep])

  const isStatus = useMemo(
    () => verificationStep === VerificationSteps.STATUS || (isVerificationComplete && !isCompleteKyc),
    [verificationStep, isVerificationComplete, isCompleteKyc],
  )

  const isPayment = useMemo(() => isVerificationComplete && isCompleteKyc, [isVerificationComplete, isCompleteKyc])

  return {
    isInit,
    isKyc,
    isStatus,
    isPayment,
    isDocVerification,
  }
}
