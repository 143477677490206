const DownLoad = () => (
  <svg viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M4.5 17.4c0 .6.5 1.1 1.1 1.1h12.8c.6 0 1.1-.5 1.1-1.1v-4a.5.5 0 0 1 1 0v4a2.1 2.1 0 0 1-2.1 2.1H5.6a2.1 2.1 0 0 1-2.1-2.1v-4a.5.5 0 0 1 1 0v4Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M15.55 10.65c.2.2.2.5 0 .7l-3.2 3.2a.5.5 0 0 1-.7 0l-3.2-3.2a.5.5 0 0 1 .7-.7L12 13.49l2.85-2.84c.2-.2.5-.2.7 0Z'
      clipRule='evenodd'
    />
    <path fillRule='evenodd' d='M12 14.7a.5.5 0 0 1-.5-.5V4.6a.5.5 0 0 1 1 0v9.6a.5.5 0 0 1-.5.5Z' clipRule='evenodd' />
  </svg>
)

export { DownLoad }
