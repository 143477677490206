import { useMemo, useState } from 'react'

import { DocStatus, KycType, VerificationSteps } from 'types/entities'

import { useOnNewDoc } from 'state/docs/hooks'
import { useDocStatus, useKycType, useSetVerificationStep, useUpdateUser } from 'state/user/hooks'

import { errors } from 'constants/errors'
import { notifications } from 'constants/notifications'
import { sourceOfFunds, SourceOfFunds, appConstants, OfacReasons } from 'constants/app'

import { CheckTypes, ValidationResults, validations } from 'utils/validation'

import { useCallNotification } from 'notifications/hooks'
import { NotificationTypes } from 'notifications/service'

import { Button, Checkbox, Icon, Input, Select, SelectOptions } from 'components/ui'

import { DocSigning } from './DocSigning'
import { sourceOfFundsOptions } from './selectOptions'

import {
  Title,
  Container,
  ErrorWrap,
  StatusWrap,
  StatusText,
  ErrorContent,
  CheckboxText,
  StatusContent,
  CheckboxLabel,
  StatusIconWrap,
  CheckboxWrapper,
  ContainerVerification,
} from './Style'

export function DocVerification() {
  const kycType = useKycType()
  const callNotification = useCallNotification()
  const setVerificationStep = useSetVerificationStep()

  const docStatus = useDocStatus()
  const { onNewDoc, ...createDocRequest } = useOnNewDoc()
  const { refetch, ...updateUserRequest } = useUpdateUser()

  const [sign, setSign] = useState<File | null>(null)
  const [sof, setSof] = useState<SourceOfFunds | null>(null)

  const [isDocRead, setIsDocRead] = useState(false)

  const [isUserAccepted, setIsUserAccepted] = useState(false)

  const [isOFACListsChecked, setIsOFACListsChecked] = useState(false)
  const [isOFACFundsChecked, setIsOFACFundsChecked] = useState(false)
  const [isOFACCountryChecked, setIsOFACCountryChecked] = useState(false)

  const [otherSourceOfFunds, setOtherSourceOfFunds] = useState('')
  const [validationResults, setValidationResults] = useState<ValidationResults>({})

  const handleSourceOfFundsSelect = (option: SelectOptions<SourceOfFunds | null> | null) => {
    if (option?.value) {
      setSof(option?.value)
    }
  }

  const handleChangeOtherSourceOfFunds = (value: string) => {
    setOtherSourceOfFunds(value)
  }

  const handleSignDoc = (file: File | null) => {
    setSign(file)
  }

  const customCheckOtherSourceOfFunds = (value: string) => {
    const isError = sof === SourceOfFunds.OTHER && !Boolean(value)
    return { isError, errorMessage: errors.validation.REQUIRED_FIELD }
  }

  const validatePaymentData = () => {
    const validationResult = validations.formCheck([
      {
        key: 'sourceOfFunds',
        isRequired: true,
        value: sof,
      },
      {
        key: 'otherSourceOfFunds',
        isRequired: false,
        value: otherSourceOfFunds,
        checkTypes: [CheckTypes.CUSTOM, CheckTypes.LATIN],
        customValidations: [customCheckOtherSourceOfFunds, null],
      },
      {
        key: 'isUserAccepted',
        isRequired: true,
        value: isUserAccepted,
      },
      {
        key: 'isOFACListsChecked',
        isRequired: true,
        value: isOFACListsChecked,
      },
      {
        key: 'isOFACFundsChecked',
        isRequired: true,
        value: isOFACFundsChecked,
      },
      {
        key: 'isOFACCountryChecked',
        isRequired: true,
        value: isOFACCountryChecked,
      },
      {
        key: 'sign',
        value: sign?.toString(),
        isRequired: true,
      },
    ])

    setValidationResults(validationResult)
    return Object.values(validationResult).filter((check) => check.isError).length === 0
  }

  const handleContinue = async () => {
    try {
      const result = validatePaymentData()
      if (result && sign && sof) {
        await onNewDoc({
          file: sign,
          sourceOfFunds: (sof === SourceOfFunds.OTHER ? otherSourceOfFunds : sourceOfFunds[sof]) as string,
        })
        await refetch()
        const nextStep = kycType === KycType.SELF ? VerificationSteps.STATUS : VerificationSteps.COMPLETE
        setVerificationStep(nextStep)
      }
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.donation(err.message) })
    }
  }

  const onChangeDocRead = (_isDocRead: boolean) => {
    setIsDocRead(_isDocRead)
  }

  const onChangeUserAccepted = (_isUserAccepted: boolean) => {
    setIsUserAccepted(_isUserAccepted)
  }

  const onChangeOfacListsToggle = () => {
    setIsOFACListsChecked((prev) => !prev)
  }

  const onChangeOfacFundsToggle = () => {
    setIsOFACFundsChecked((prev) => !prev)
  }

  const onChangeOfacCountryToggle = () => {
    setIsOFACCountryChecked((prev) => !prev)
  }

  const isDisabled = useMemo(() => !isUserAccepted || !sign || !sof, [isUserAccepted, sign, sof])
  const isPrevVerificationFailed = useMemo(() => docStatus === DocStatus.DECLINED, [docStatus])
  const isOtherSourceOfFunds = useMemo(() => sof === SourceOfFunds.OTHER, [sof])
  const isLoading = useMemo(
    () => createDocRequest.isLoading || updateUserRequest.isLoading,
    [createDocRequest.isLoading, updateUserRequest.isLoading],
  )
  return (
    <Container>
      <Title>Please complete below</Title>
      {isPrevVerificationFailed && (
        <StatusWrap>
          <StatusContent type='danger'>
            <StatusIconWrap>
              <Icon name='danger' />
            </StatusIconWrap>
            <StatusText>
              We&nbsp;are not able to&nbsp;accept your donation with the previously listed source of&nbsp;funds. Please
              specify a&nbsp;different source of&nbsp;funds in&nbsp;order to&nbsp;donate.
            </StatusText>
          </StatusContent>
        </StatusWrap>
      )}

      <ContainerVerification>
        <Select<SourceOfFunds | null>
          value={sof}
          label='Source of funds'
          options={sourceOfFundsOptions}
          select={handleSourceOfFundsSelect}
          error={validationResults.sourceOfFunds?.isError}
          errorMessage={validationResults.sourceOfFunds?.errorMessage}
        />

        {isOtherSourceOfFunds && (
          <Input
            inputSize='medium'
            label='Source of funds description'
            placeholder='Crypto mining'
            value={otherSourceOfFunds}
            onChange={handleChangeOtherSourceOfFunds}
            error={validationResults.otherSourceOfFunds?.isError}
            errorMessage={validationResults.otherSourceOfFunds?.errorMessage}
          />
        )}

        <DocSigning
          isDocRead={isDocRead}
          onSignDoc={handleSignDoc}
          isUserAccepted={isUserAccepted}
          onChangeDocRead={onChangeDocRead}
          isError={validationResults.sign?.isError}
          onChangeUserAccepted={onChangeUserAccepted}
          errorMessage={validationResults.sign?.errorMessage}
        />

        <div>
          <CheckboxWrapper isError={validationResults.isOFACCountryChecked?.isError}>
            <Checkbox id={OfacReasons.COUNTRY} checked={isOFACCountryChecked} onClick={onChangeOfacCountryToggle} />
            <CheckboxLabel htmlFor={OfacReasons.COUNTRY}>
              <CheckboxText>{appConstants.OFAC_TERMS[OfacReasons.COUNTRY]}</CheckboxText>
            </CheckboxLabel>
          </CheckboxWrapper>

          <CheckboxWrapper isError={validationResults.isOFACListsChecked?.isError}>
            <Checkbox id={OfacReasons.LISTS} checked={isOFACListsChecked} onClick={onChangeOfacListsToggle} />
            <CheckboxLabel htmlFor={OfacReasons.LISTS}>
              <CheckboxText>{appConstants.OFAC_TERMS[OfacReasons.LISTS]}</CheckboxText>
            </CheckboxLabel>
          </CheckboxWrapper>

          <CheckboxWrapper isError={validationResults.isOFACFundsChecked?.isError}>
            <Checkbox id={OfacReasons.FUNDS} checked={isOFACFundsChecked} onClick={onChangeOfacFundsToggle} />
            <CheckboxLabel htmlFor={OfacReasons.FUNDS}>
              <CheckboxText>{appConstants.OFAC_TERMS[OfacReasons.FUNDS]}</CheckboxText>
            </CheckboxLabel>
          </CheckboxWrapper>
        </div>
      </ContainerVerification>

      <Button fullSize size='large' onClick={handleContinue} isLoading={isLoading} disabled={isDisabled}>
        Continue
      </Button>

      <ErrorWrap>
        {validationResults.isUserAccepted?.isError && (
          <ErrorContent>{errors.validation.READ_DOC_REQUIRED}</ErrorContent>
        )}
      </ErrorWrap>
    </Container>
  )
}
