import { errors } from 'constants/errors'
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from 'constants/chains'

import { isAddress } from './crypto'

type CustomValidationFunc = (value: string) => Result
type CustomValidations = Array<CustomValidationFunc | null>

type Field = {
  key: string
  length?: number
  isRequired?: boolean
  checkTypes?: CheckTypes[]
  value?: string | boolean | null
  customValidations?: CustomValidations // key: Field.key, value: validationFunction
}

export type Fields = Field[]

type Result = {
  isError: boolean
  errorMessage: string
}

export type ValidationResults = Record<string, Result>

function externalLink(link: string) {
  const EXTERNAL_LINK_REGEXP = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g

  return EXTERNAL_LINK_REGEXP.test(link)
}

function checkCryptoNumeric(value: string, maxDecimals = 18) {
  const CRYPTO_NUMERIC_REGEXP = new RegExp(`^[+]?(\\d+\\.?\\d{0,${maxDecimals}})$`, 'g')

  return CRYPTO_NUMERIC_REGEXP.test(value)
}

function checkIsValidChainId(chainId: SupportedChainId) {
  return ALL_SUPPORTED_CHAIN_IDS.includes(chainId)
}

function checkName(value: string) {
  if (!value) return false

  const NAME_REGEXP = new RegExp(/^[A-Za-z][A-Za-z\s]{1,16}$/, 'g')
  return NAME_REGEXP.test(value)
}

function checkLatin(value: string) {
  if (!value) return false

  const LATIN_REGEXP = new RegExp(/^[\dA-Za-z][\dA-Za-z\s]*/, 'g')
  return LATIN_REGEXP.test(value)
}

function checkEmail(value: string) {
  if (!value) return false

  const EMAIL_REGEXP = new RegExp(
    /^[*+\/0-9=?A-Z_a-z{|}~](\.?[-+\/0-9=?A-Z_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
  )
  return EMAIL_REGEXP.test(value)
}

function checkPassword(value: string) {
  if (!value) return false

  const PASSWORD_REGEXP = new RegExp(/(?=^.{6,}$)^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/)
  return PASSWORD_REGEXP.test(value)
}

function checkIsNumberIncludes(value: string) {
  if (!value) return false

  const IS_NUMBER_REGEXP = new RegExp(/\/*[0-9]/)
  return IS_NUMBER_REGEXP.test(value)
}

function checkLowerCase(value: string) {
  if (!value) return false

  const LOWER_CASE_REGEXP = new RegExp(/.*[a-z]/)
  return LOWER_CASE_REGEXP.test(value)
}

function checkUpperCase(value: string) {
  if (!value) return false

  const UPPER_CASE_REGEXP = new RegExp(/.*[A-Z]/)
  return UPPER_CASE_REGEXP.test(value)
}

function checkLength(value?: string | null, length = 1) {
  if (!value) return false

  return value.length >= length
}

function checkIsAllowedImage(fileName: string) {
  const CHECK_IMAGES_REGEXP = /(.*?)\.(jpg|jpeg|png|gif)$/

  return CHECK_IMAGES_REGEXP.test(fileName)
}

export enum CheckTypes {
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  LATIN = 'LATIN',
  CUSTOM = 'CUSTOM',
  ADDRESS = 'ADDRESS',
  NUMERIC = 'NUMERIC',
  PASSWORD = 'PASSWORD',
}

const DEFAULT_VALIDATION_RESULT = { isError: false, errorMessage: '' }
function fieldTypedCheck(value: string, checkType: CheckTypes, customValidation?: CustomValidationFunc | null) {
  if (!value) {
    if (checkType === CheckTypes.CUSTOM) {
      if (typeof customValidation !== 'function') {
        return DEFAULT_VALIDATION_RESULT
      }
      return customValidation(value)
    }
    return DEFAULT_VALIDATION_RESULT
  }

  switch (checkType) {
    case CheckTypes.EMAIL:
      const _isEmail = checkEmail(value)
      return { isError: !_isEmail, errorMessage: errors.validation.INVALID_EMAIL }
    case CheckTypes.PASSWORD:
      const isPassword = checkPassword(value)
      return { isError: !isPassword, errorMessage: errors.validation.INCORRECT_PASSWORD }
    case CheckTypes.NAME:
      const _isName = checkName(value)
      return { isError: !_isName, errorMessage: errors.validation.LATIN }
    case CheckTypes.LATIN:
      const _isLatin = checkLatin(value)
      return { isError: !_isLatin, errorMessage: errors.validation.LATIN }
    case CheckTypes.ADDRESS:
      const _isAddress = isAddress(value)
      return { isError: !_isAddress, errorMessage: errors.validation.INCORRECT_ADDRESS }
    case CheckTypes.NUMERIC:
      const _isNumeric = checkCryptoNumeric(value)
      return { isError: !_isNumeric, errorMessage: errors.validation.NUMERIC_REQUIRED }
    case CheckTypes.CUSTOM:
      if (typeof customValidation !== 'function') {
        return DEFAULT_VALIDATION_RESULT
      }
      return customValidation(value)
    default:
      return DEFAULT_VALIDATION_RESULT
  }
}

function fieldCheck({ isRequired, value, length, checkTypes, customValidations }: Field) {
  if (isRequired && !value) {
    return { isError: true, errorMessage: errors.validation.REQUIRED_FIELD }
  }

  if (typeof value === 'boolean') {
    return DEFAULT_VALIDATION_RESULT
  }

  if (length && (!value || !checkLength(value, length))) {
    return { isError: true, errorMessage: `${errors.validation.REQUIRED_MIN_LENGTH} ${length}` }
  }

  // if (!value && checkTypes?.length) {
  //   return { isError: Boolean(isRequired), errorMessage: errors.validation.REQUIRED_FIELD }
  // }

  if (!checkTypes) {
    return DEFAULT_VALIDATION_RESULT
  }
  const _error = checkTypes.reduce((acc, curr, index) => {
    const result = fieldTypedCheck(value || '', curr, customValidations?.[index])
    if (result.isError) {
      acc = result
    }
    return acc
  }, DEFAULT_VALIDATION_RESULT)

  return _error
}

function formCheck(fields: Fields) {
  return fields.reduce<ValidationResults>((acc, curr) => {
    acc[curr.key] = fieldCheck(curr)
    return acc
  }, {})
}

export const validations = {
  formCheck,
  checkEmail,
  checkLength,
  externalLink,
  checkPassword,
  checkUpperCase,
  checkLowerCase,
  checkCryptoNumeric,
  checkIsValidChainId,
  checkIsAllowedImage,
  checkIsNumberIncludes,
}
