import styled from 'styled-components/macro'
import { darken, transparentize } from 'polished'

export const Warning = styled.div`
  margin: 0 auto 1.6rem;
  padding: 0 1.6rem;
`

export const AlertWrapper = styled.div`
  margin: 3.2rem 0 0;
  padding: 0.8rem 1.4rem;
  width: 100%;
  max-width: 45rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  background-color: ${(props) => transparentize(0.9, props.theme.color.risky)};
  border: 0.1rem solid ${(props) => transparentize(0.5, props.theme.color.risky)};
  border-radius: 1.2rem;
`

export const AlertIconWrap = styled.span`
  flex: 0 0 auto;
  color: ${(props) => props.theme.color.risky};
`

export const AlertMessage = styled.h5`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 2rem;
  word-wrap: break-word;
  word-break: break-all;
  color: ${(props) => darken(0.1, props.theme.color.risky)};
`

export const Wrap = styled.div`
  margin: 3.2rem 0 1.6rem;
  padding: 0 0 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 1.2rem;
  box-shadow: 0 0.4rem 1.6rem 0 ${(props) => props.theme.color.elementBorder};
`

export const Title = styled.h5`
  margin: 0;
  padding: 1.6rem;
  width: 100%;
  font-family: ${(props) => props.theme.font.family.primary};
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2.4rem;
  line-height: 1.33;
  letter-spacing: 0.04rem;
  text-align: center;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.default};
  border-radius: 1.2rem 1.2rem 0 0;
`

export const TokenWrap = styled.div`
  padding: 1.6rem 0.6rem;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-end;
  gap: 1.6rem;
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    padding: 1.6rem;
    grid-template-columns: minmax(14rem, auto) 1fr;
  `};
`

export const RecipientsWrap = styled.div`
  padding: 1.6rem 0.6rem;
  & label {
    margin: 0;
    width: fit-content;
    display: inline-block;
    font-weight: ${(props) => props.theme.font.weight.regular};
    font-size: 1.4rem;
    line-height: 1.4;
    color: ${(props) => transparentize(0.3, props.theme.color.text)};
  }
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    padding: 1.6rem;
  `};
`
