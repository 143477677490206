import { useEffect, useMemo, useState } from 'react'

import { BigNumber } from 'ethers'

import { Hash } from 'types/entities'

import { useDebounce } from 'hooks/helpers'
import { useGetAllowancesRequest, useGetBalancesRequest } from 'hooks/app'

import { getDonation } from 'contracts'
import { tokens } from 'constants/tokens'
import { RPC_LIST } from 'constants/chains'
import { crypto } from 'constants/variables'
import { appConstants } from 'constants/app'
import { ETH_ADDRESS } from 'constants/addresses'

import { bump, isEther, toWei } from 'utils/crypto'

type Params = {
  token?: Hash
  amount?: string
  address?: Hash
  gasCost?: string
  decimals?: number
  withEstimate?: boolean
  chainId: SupportedChainId
}

export function useDonateEstimation({ amount, token, decimals, address, chainId, gasCost }: Params) {
  const [isBalanceEnough, setIsBalanceEnough] = useState(true)
  const [isTokenBalanceEnough, setIsTokenBalanceEnough] = useState(true)

  const { data: balances, isLoading: isBalancesLoading } = useGetBalancesRequest(address, chainId)
  const { data: allowances, isLoading: isAllowancesLoading } = useGetAllowancesRequest(address, chainId)

  const _allowance = useMemo(() => (token ? allowances?.[token] : null), [allowances, token])

  const _amount = useMemo(() => (amount ? toWei(amount, decimals) : crypto.BG_ZERO), [amount, decimals])

  const _balances = useMemo(() => (token ? balances?.[token] : null), [token, balances])
  const _tokenBalance = useMemo(() => _balances?.bgBalance || crypto.BG_ZERO._hex, [token, _balances])
  const _ethBalance = useMemo(() => balances?.[ETH_ADDRESS]?.bgBalance || crypto.BG_ZERO._hex, [balances])

  const _isAllowanceEnough = useMemo(() => {
    return !_allowance || BigNumber.from(_allowance).gte(_amount)
  }, [_amount, _allowance, allowances])

  const _isRevokeRequired = useMemo(() => {
    if (!token) {
      return false
    }
    const isUSDT = tokens[chainId][token]?.symbol === 'USDT'
    return Boolean(_allowance && BigNumber.from(_allowance).gt(crypto.BG_ZERO) && !_isAllowanceEnough && isUSDT)
  }, [_isAllowanceEnough, _allowance, token])

  const isInvalidDataForEstimate = useMemo(
    () => !address || crypto.BG_ZERO.eq(_amount) || !token,
    [address, _amount, token],
  )

  const approximateGasLimit = useMemo(() => {
    if (!address || !token) {
      return crypto.BG_ZERO
    }

    const _data = isEther(token) ? appConstants.GAS_LIMITS.NATIVE : appConstants.GAS_LIMITS.TOKEN
    return BigNumber.from(_data)
  }, [token, gasCost])

  const approximateCost = useMemo(() => {
    if (!address || !token) {
      return crypto.BG_ZERO
    }
    const gasPriceValue = gasCost || '0'

    return BigNumber.from(gasPriceValue).mul(approximateGasLimit)
  }, [token, gasCost, approximateGasLimit])

  const args = useDebounce(
    useMemo(
      () => [token, toWei(_amount, decimals), _balances, gasCost],
      [token, _amount._hex, _balances, decimals, gasCost],
    ),
    300,
  )

  const getIsNativeBalanceEnough = () => {
    if (_amount.gte(_ethBalance)) {
      return isBalancesLoading
    }

    return _amount.add(approximateCost).lte(_ethBalance)
  }

  const getRecipientAddress = async (recipientNameKeccak256: Hash) => {
    const donationContract = getDonation(chainId, RPC_LIST[chainId])
    const address = donationContract.callStatic.recipients(recipientNameKeccak256)
    return address as unknown as Hash
  }

  const estimateDonationGas = async ([token, amount, recipient, signature]: [string, string, Hash, Hash]) => {
    const donationContract = getDonation(chainId, RPC_LIST[chainId])
    const estimateGas = await donationContract.estimateGas.donate(token, amount, recipient, signature, {
      from: address,
      value: isEther(token) ? amount : '0x0',
    })

    return bump(estimateGas, 110)
  }

  const getIsTokenBalanceEnough = () => {
    if (!_isAllowanceEnough) {
      return BigNumber.from(_ethBalance).gte(approximateCost)
    }

    return approximateCost.lte(_ethBalance)
  }

  const getIsBalanceEnoughForToken = () => {
    if (_amount.lte(_tokenBalance)) {
      setIsTokenBalanceEnough(true)
    } else {
      setIsTokenBalanceEnough(false)
      return true
    }

    return getIsTokenBalanceEnough()
  }

  const getIsBalanceEnoughForEthers = () => {
    setIsTokenBalanceEnough(true)

    return getIsNativeBalanceEnough()
  }

  const getIsBalanceEnough = () => {
    try {
      if (isInvalidDataForEstimate) {
        setIsTokenBalanceEnough(true)
        return setIsBalanceEnough(true)
      }

      if (isEther(token as string)) {
        const result = getIsBalanceEnoughForEthers()
        return setIsBalanceEnough(result)
      }

      const result = getIsBalanceEnoughForToken()
      return setIsBalanceEnough(result)
    } catch (err) {
      return setIsBalanceEnough(false)
    }
  }

  useEffect(() => {
    getIsBalanceEnough()
  }, [...args, address])

  const _isBalancesLoading = useMemo(() => !balances && isBalancesLoading, [balances, isBalancesLoading])
  const _isAllowancesLoading = useMemo(() => !allowances && isAllowancesLoading, [allowances, isAllowancesLoading])
  const _isLoading = useMemo(
    () => _isAllowancesLoading || _isBalancesLoading,
    [_isAllowancesLoading, _isBalancesLoading],
  )

  return {
    balances,
    allowances,
    approximateCost,
    isBalanceEnough,
    isBalancesLoading,
    getIsBalanceEnough,
    isAllowancesLoading,
    approximateGasLimit,
    estimateDonationGas,
    getRecipientAddress,
    isTokenBalanceEnough,
    isLoading: _isLoading,
    assetBalances: _balances,
    isRevokeRequired: _isRevokeRequired,
    isAllowanceEnough: _isAllowanceEnough,
  }
}
