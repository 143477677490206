import { useMemo, useState } from 'react'

import { DateTime } from 'luxon'
import { Column } from 'react-table'

import { UserData } from 'types/entities'

import { Table } from 'components/ui'
import { useGetAllUsersRequest } from 'state/user/hooks'

import { Email, Status, KycStatus, DocStatus, Role } from './Cells'

const LIMIT = 8

function UsersTable() {
  const [page, setPage] = useState(1)
  const { data, isLoading } = useGetAllUsersRequest(true)

  const donations = useMemo(() => {
    if (!data) {
      return null
    }
    const _data = [...data]
    return _data.sort(
      (a, b) =>
        DateTime.fromISO(b.createdAt.toString()).toMillis() - DateTime.fromISO(a.createdAt.toString()).toMillis(),
    )
  }, [data])

  const rows = useMemo(() => {
    if (!donations) {
      return []
    }
    return donations.slice((page - 1) * LIMIT, LIMIT * page)
  }, [page, donations])

  const lastPage = useMemo(() => {
    if (!donations) {
      return 0
    }
    return Math.ceil(donations.length / LIMIT)
  }, [donations])

  const columns: Column<UserData>[] = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        Cell: Email,
      },
      {
        Header: 'Status',
        accessor: 'isVerifiedEmail',
        Cell: Status,
      },
      {
        Header: 'Role',
        accessor: 'roleName',
        Cell: Role,
      },
      {
        Header: 'KYC status',
        accessor: 'kyc',
        Cell: KycStatus,
      },
      {
        Header: 'DOC status',
        accessor: 'docs',
        Cell: DocStatus,
      },
    ],
    [rows],
  )

  return (
    <Table<UserData>
      showText
      data={rows}
      page={page}
      type='users'
      title='Users'
      onPage={setPage}
      name='usersTable'
      columns={columns}
      lastPage={lastPage}
      isLoading={isLoading}
    />
  )
}

export { UsersTable }
