import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

export const Wrap = styled.div`
  margin: 0 auto 1.6rem;
  padding: 0 1.6rem;
`

export const IconWrap = styled.div`
  flex: 0 0 auto;
`

export const Content = styled.div<{ type?: string }>`
  padding: 0.8rem 1.4rem;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: ${(props) => props.theme.color.default};
  background-color: ${(props) => transparentize(0.9, props.theme.color.default)};
  border: 0.1rem solid ${(props) => transparentize(0.2, props.theme.color.default)};
  border-radius: 1.2rem;
  z-index: ${(props) => props.theme.zIndex.overlay};
  ${(props) =>
    props.type === 'risky' &&
    css`
      color: ${(props) => props.theme.color.risky};
      background-color: ${(props) => transparentize(0.9, props.theme.color.risky)};
      border: 0.1rem solid ${(props) => transparentize(0.2, props.theme.color.risky)};
    `}
  ${(props) =>
    props.type === 'danger' &&
    css`
      color: ${(props) => props.theme.color.danger};
      background-color: ${(props) => transparentize(0.9, props.theme.color.danger)};
      border: 0.1rem solid ${(props) => transparentize(0.2, props.theme.color.danger)};
    `}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const Text = styled.p`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.57rem;
  color: ${(props) => props.theme.color.text};
`
