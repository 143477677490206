import { createApi } from '@reduxjs/toolkit/query/react'

import { apiLinks } from 'constants/api'
import { customAuthorizedQuery } from 'state/api'

import {
  InitKycInput,
  UpdateKycInput,
  GetAllKycInput,
  InitKycResponse,
  GetAllKycResponse,
  UpdateKycResponse,
  UpdateKycStatusInput,
  InitExternalKycInput,
  UpdateKycStatusResponse,
  InitExternalKycResponse,
  CheckExternalInput,
  CheckExternalResponse,
} from './types'

export const kycApi = createApi({
  reducerPath: 'kycApi',
  baseQuery: customAuthorizedQuery({
    baseUrl: apiLinks.BASE_URL,
  }),
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    initKyc: builder.mutation<InitKycResponse, InitKycInput>({
      query: () => ({
        url: apiLinks.kyc.start,
        method: 'PUT',
      }),
    }),
    initExternalKyc: builder.mutation<InitExternalKycResponse, InitExternalKycInput>({
      query: (params: InitExternalKycInput) => ({
        url: apiLinks.kyc.startExternal,
        method: 'PUT',
        data: params,
      }),
    }),
    checkExternal: builder.mutation<CheckExternalResponse, CheckExternalInput>({
      query: (params: CheckExternalInput) => ({
        url: apiLinks.kyc.checkExternal,
        method: 'PUT',
        data: params,
      }),
    }),
    updateKyc: builder.mutation<UpdateKycResponse, UpdateKycInput>({
      query: (params: UpdateKycInput) => ({
        url: apiLinks.kyc.update,
        method: 'PUT',
        data: params,
      }),
    }),
    updateKycStatus: builder.mutation<UpdateKycStatusResponse, UpdateKycStatusInput>({
      query: (params: UpdateKycStatusInput) => ({
        url: apiLinks.kyc.updateStatus,
        method: 'PUT',
        data: params,
      }),
    }),
    getAllKyc: builder.query<GetAllKycResponse, GetAllKycInput>({
      query: () => ({
        url: apiLinks.kyc.getAll,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useInitKycMutation,
  useInitExternalKycMutation,
  useUpdateKycMutation,
  useUpdateKycStatusMutation,
  useGetAllKycQuery,
  useCheckExternalMutation,
} = kycApi
