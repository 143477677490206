const validation = {
  REQUIRED_FIELD: 'Required field',
  REQUIRED_SIGN: 'Signature is needed',
  INCORRECT_AMOUNT: 'Incorrect amount',
  INCORRECT_ADDRESS: 'Incorrect address',
  LATIN: 'Enter English characters only',
  INSUFFICIENT_FUNDS: 'Insufficient funds',
  INCORRECT_PASSWORD: 'Incorrect password',
  GAS_REQUIRED: 'Gas Limit is required param',
  PASSWORDS_MUST_BE_SAME: 'Passwords must match',
  REQUIRED_MIN_LENGTH: 'Required minimal length is',
  NAMES_REQUIRED: 'First and Last names are required',
  INSUFFICIENT_TOKEN_FUNDS: 'Insufficient token balance',
  NUMERIC_REQUIRED: 'The value entered must be a number',
  SMALL_AMOUNT: 'Please add funds to wallet to cover transactions fees (gas)',
  INVALID_EMAIL: 'Please check the spelling of your email address. You may have missed a “.” or “@“',
  READ_DOC_REQUIRED:
    'Before signing, please ensure that you have read the document in the link above. Familiarize yourself with its content to proceed with the signing process. Thank you.',
}

const token = {
  TWO_STEP_APPROVE: 'Two step approve required',
}

const wallet = {
  WALLET_REQUIRED: 'Connect wallet at first',
  USER_CLOSED_MODAL: 'You closed the connection modal',
  NO_ACCOUNTS: 'You declined an action in your wallet',
  USER_DENIED: 'You declined an action in your wallet',
  USER_REJECTED: 'You declined an action in your wallet',
  USER_CANCELED: 'You declined an action in your wallet',
  LOCKED_PROVIDER: 'Please, unlock your wallet and try again',
  TRY_ADDING_THE_CHAIN: 'Please add a new chain to the wallet',
  ALREADY_PENDING: 'You already have the pending request in the wallet',
  ALREADY_PROCESSING: 'You already have the pending request in the wallet',
}

const providerErrorsCodes = {
  userRejectedRequest: 4001,
  unauthorized: 4100,
  unsupportedMethod: 4200,
  disconnected: 4900,
  chainDisconnected: 4901,
}

const apiStatuses = {
  UNAUTHORIZED: 401,
}

const errors = {
  token,
  wallet,
  validation,
  apiStatuses,
  providerErrorsCodes,
}

export { errors }
