import { useEffect, useMemo, useState } from 'react'

import { BigNumber } from 'ethers'

import { Token } from 'types/entities'

import { useChainData, useRequestController, useSentryError } from 'hooks/app'

import { UserOperationsStatuses, UserOperationsTypes } from 'state/user/types'
import { usePendingTransactions, useSetPendingTransaction, useTransactions } from 'state/user/hooks'

import { errors } from 'constants/errors'
import { crypto } from 'constants/variables'
import { DONATIONS } from 'constants/addresses'
import { appConstants, PaymentType } from 'constants/app'

import { CloseButton } from 'modal'
import { errorParser } from 'utils/errors'
import { useCallNotification } from 'notifications/hooks'
import { NotificationTypes } from 'notifications/service'

import { Loader } from 'components/ui'
import { ConfirmationStatuses, ConfirmationStep } from './types'

import { useStep } from './useStep'

import { Stepper } from './Stepper'
import { Approve } from './Approve'
import { ActionButton } from './ActionButton'

import { DonationInput } from '../types'
import { DonationInfo } from './DonationInfo'
import { Container, InfoTextSmall, Text, Wrap, WrapContent } from '../Style'

type ConnectModalProps = {
  gasPrice?: bigint
  approveToken: Token
  approveAmount: string
  donation: DonationInput
  onCurrentClose?: VoidFunction
  prepareData: () => Promise<{ estimateGas: string; signature: any }>
  handleCreate: (signature: string, gas: bigint) => Promise<string | undefined>

  isLoading: boolean
  isBalanceEnough: boolean
  isRevokeRequired: boolean
  isAllowanceEnough: boolean
}

export function Confirmation({
  donation,
  gasPrice,
  isLoading,
  prepareData,
  handleCreate,
  approveToken,
  approveAmount,
  onCurrentClose,
  isBalanceEnough,
  isRevokeRequired,
  isAllowanceEnough,
}: ConnectModalProps) {
  const [txHash, setTxHash] = useState('')
  const [signature, setSignature] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [estimateGas, setEstimateGas] = useState<undefined | bigint>()
  const [isPrepareDataLoading, setPrepareDataLoading] = useState(false)

  const { chainId } = useChainData()
  const txHistory = useTransactions()
  const pendingTransactions = usePendingTransactions()

  const onSentryError = useSentryError()
  const callNotification = useCallNotification()
  const onPendingTransaction = useSetPendingTransaction()
  const { approve, ...requestControllerState } = useRequestController(approveToken)

  const { steps, actualStep, changeStepStatus, setupSteps, goNextStep } = useStep()

  const currentStepTx = useMemo(() => txHistory[txHash], [txHistory, txHash])

  useEffect(() => {
    if (currentStepTx) {
      if (currentStepTx.status === UserOperationsStatuses.COMPLETE) {
        changeStepStatus(actualStep ?? null, ConfirmationStatuses.COMPLETE)
      }

      if (currentStepTx.status === UserOperationsStatuses.FAILED) {
        errorHandler(`${currentStepTx.operation} transaction failed`)
      }
    }
  }, [currentStepTx])

  useEffect(() => {
    if (!steps) {
      const steps: ConfirmationStep[] = [ConfirmationStep.CONFIRM]

      if (!isAllowanceEnough) {
        steps.unshift(ConfirmationStep.APPROVE)
      }

      if (isRevokeRequired) {
        steps.unshift(ConfirmationStep.REVOKE)
      }

      setupSteps(steps)
    }
  }, [isAllowanceEnough, isRevokeRequired])

  useEffect(() => {
    if (actualStep === ConfirmationStep.CONFIRM) {
      onPrepareData()
    } else if (actualStep) {
      action()
    }
  }, [actualStep])

  const onPrepareData = async () => {
    try {
      setPrepareDataLoading(true)
      const data = await prepareData()

      setSignature(data.signature)
      setEstimateGas(BigNumber.from(data.estimateGas).toBigInt())
    } catch (err) {
      errorHandler(errorParser(err.message))
      onSentryError('prepareData', err)
    } finally {
      setPrepareDataLoading(false)
    }
  }

  const getIsTxWithTypes = (txTypes: UserOperationsTypes[]) => {
    return Object.values(pendingTransactions).some((tx) => txTypes.includes(tx.operation))
  }

  const isPendingApproveTx = useMemo(
    () => getIsTxWithTypes([UserOperationsTypes.APPROVE, UserOperationsTypes.REVOKE]),
    [pendingTransactions],
  )

  const isPendingDonateTx = useMemo(() => getIsTxWithTypes([UserOperationsTypes.DONATE]), [pendingTransactions])

  const handleChangeAllowance = async (amount: bigint, operationType: UserOperationsTypes) => {
    try {
      changeStepStatus(actualStep ?? null, ConfirmationStatuses.PENDING)
      const { hash } = await approve(DONATIONS[chainId], amount)

      handleSetPendingTransaction(hash, BigNumber.from(amount)._hex, operationType)
    } catch (err) {
      onSentryError('operationType', err)

      errorHandler(errorParser(err.message))
    }
  }

  const handleSetPendingTransaction = (hash: string, value: string, operation: UserOperationsTypes) => {
    setTxHash(hash)
    onPendingTransaction({
      value,
      chainId,
      id: hash,
      operation,
      txHash: hash,
      timestamp: Date.now(),
      wallet: donation.address,
      address: donation.address,
      currency: approveToken.address,
      paymentType: PaymentType.CRYPTO,
      status: UserOperationsStatuses.PENDING,
    })
  }

  const handleApprove = async () => {
    await handleChangeAllowance(BigNumber.from(approveAmount).toBigInt(), UserOperationsTypes.APPROVE)
  }

  const handleRevoke = async () => {
    await handleChangeAllowance(crypto.BG_ZERO.toBigInt(), UserOperationsTypes.REVOKE)
  }

  const handleDonate = async () => {
    try {
      changeStepStatus(actualStep ?? null, ConfirmationStatuses.PENDING)
      if (!estimateGas) {
        throw new Error(errors.validation.GAS_REQUIRED)
      }
      const hash = await handleCreate(signature, estimateGas)
      if (hash) {
        handleSetPendingTransaction(hash, BigNumber.from(donation.amount)._hex, UserOperationsTypes.DONATE)
      }
    } catch (err) {
      onSentryError('donate', err)
      errorHandler(errorParser(err.message))
    }
  }

  const action = useMemo(() => {
    if (actualStep === ConfirmationStep.REVOKE) {
      return handleRevoke
    }

    if (actualStep === ConfirmationStep.APPROVE) {
      return handleApprove
    }

    if (!signature) {
      return onPrepareData
    }
    return handleDonate
  }, [actualStep, estimateGas, signature])

  const onNextStep = () => {
    goNextStep()
    setTxHash('')
    setErrorMessage('')
  }

  const errorHandler = (errorMessage: string) => {
    setErrorMessage(errorMessage)
    if (errorMessage) {
      callNotification({
        type: NotificationTypes.ERROR,
        message: errorMessage,
      })
      changeStepStatus(actualStep ?? null, ConfirmationStatuses.FAILED)
    }
  }

  const handleRetry = async () => {
    setErrorMessage('')
    await action()
  }

  const _error = useMemo(() => (isBalanceEnough ? '' : errors.validation.INSUFFICIENT_FUNDS), [isBalanceEnough])

  const _isLoading = useMemo(() => {
    return (
      (isPendingApproveTx || requestControllerState.isLoading || isPendingDonateTx) &&
      actualStep === ConfirmationStep.CONFIRM
    )
  }, [isPendingApproveTx, isPendingDonateTx, requestControllerState.isLoading, actualStep, isPrepareDataLoading])

  const isDisabled = useMemo(() => {
    return (isPendingApproveTx || requestControllerState.isLoading) && actualStep !== ConfirmationStep.CONFIRM
  }, [isPendingApproveTx, requestControllerState.isLoading, actualStep])

  const loadingText = useMemo(() => {
    if (isPendingApproveTx || isPendingDonateTx) {
      return appConstants.ACTION_BUTTON_TEXTS.WAIT_TIL_MINING
    }

    if (requestControllerState.isLoading && actualStep === ConfirmationStep.CONFIRM) {
      return appConstants.ACTION_BUTTON_TEXTS.CONFIRM_BUTTON_TEXT
    }

    return appConstants.ACTION_BUTTON_TEXTS.PROCESSING
  }, [_isLoading, isPendingApproveTx, isPendingDonateTx, requestControllerState.isLoading])

  const _step = useMemo(() => (steps && actualStep ? steps[actualStep] : null), [steps, actualStep])
  const isConfirmPage = useMemo(() => _step?.key === ConfirmationStep.CONFIRM, [_step])

  const stepLength = useMemo(() => (steps ? Object.keys(steps).length : 0), [steps])
  const isSentDonateTx = useMemo(() => Boolean(!isPendingDonateTx && txHash), [isPendingDonateTx, txHash])
  const isSentApproveTx = useMemo(() => Boolean(!isPendingApproveTx && txHash), [isPendingApproveTx, txHash])

  const isSuccess = useMemo(
    () => Boolean(isSentDonateTx && txHash && !errorMessage && isConfirmPage),
    [isSentDonateTx, txHash, errorMessage, isConfirmPage],
  )

  const shouldButtonHide = useMemo(
    () => isConfirmPage && (isPendingDonateTx || isSentDonateTx),
    [isConfirmPage, isPendingDonateTx, isSentDonateTx],
  )

  return (
    <Wrap>
      <Stepper steps={steps} stepLength={stepLength} current={actualStep ?? null} />

      <Container>
        {!actualStep && (
          <WrapContent>
            <Loader size={7.2} ringSize={0.6} />
            <Text>Check allowance</Text>
          </WrapContent>
        )}

        {actualStep === ConfirmationStep.REVOKE && (
          <Approve
            txHash={txHash}
            errorMessage={errorMessage}
            txType={UserOperationsTypes.REVOKE}
            isSent={isSentApproveTx}
          >
            <InfoTextSmall>
              Certain tokens require several transactions to&nbsp;change allowance. First you would need to&nbsp;revoke
              allowance to&nbsp;zero, and only then set new allowance value.
            </InfoTextSmall>
          </Approve>
        )}

        {actualStep === ConfirmationStep.APPROVE && (
          <Approve
            txHash={txHash}
            errorMessage={errorMessage}
            txType={UserOperationsTypes.APPROVE}
            isSent={isSentApproveTx}
          />
        )}

        {actualStep === ConfirmationStep.CONFIRM && (
          <>
            <DonationInfo
              txHash={txHash}
              chainId={chainId}
              donation={donation}
              gasPrice={gasPrice}
              isLoading={isLoading}
              isSent={isSentDonateTx}
              errorMessage={errorMessage}
              onCurrentClose={onCurrentClose}
              estimateGas={estimateGas?.toString()}
            />
            {_error && <div>Error: {_error}</div>}
          </>
        )}
      </Container>

      <ActionButton
        step={_step}
        onAction={action}
        isSuccess={isSuccess}
        onRetry={handleRetry}
        isLoading={isLoading}
        onNextStep={onNextStep}
        isDisabled={isDisabled}
        loadingText={loadingText}
        isError={Boolean(errorMessage)}
        onCurrentClose={onCurrentClose}
        shouldButtonHide={shouldButtonHide}
      />

      <CloseButton onClick={onCurrentClose} />
    </Wrap>
  )
}
