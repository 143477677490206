import { ChangeEvent, useMemo, useState } from 'react'

import { useEmailFieldData, usePasswordFieldData } from 'hooks/form'

import { useCreateManagerRequest } from 'state/auth/hooks'

import { errors } from 'constants/errors'
import { notifications } from 'constants/notifications'

import { Button, Input } from 'components/ui'
import { PasswordField } from 'components/features'

import { useCallNotification } from 'notifications/hooks'
import { NotificationTypes } from 'notifications/service'

import { Container, Content, Title, Form, InputWrap, ButtonWrap, ErrorWrap, ErrorContent } from './Style'

export function ManagerSignUp() {
  const email = useEmailFieldData()
  const password = usePasswordFieldData()

  const { onCreateManager, ...createManagerRequest } = useCreateManagerRequest()

  const callNotification = useCallNotification()

  const [confirmValue, setConfirmValue] = useState('')
  const [confirmError, setConfirmError] = useState('')

  const onHandleSignUp = async (e: ChangeEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      if (isDisabled) {
        return
      }

      const isSame = password.value === confirmValue
      setConfirmError(isSame ? '' : errors.validation.PASSWORDS_MUST_BE_SAME)
      if (!isSame) {
        return
      }
      await onCreateManager(email.value, password.value)

      onChangeConfirm('')
      email.onChange('')
      password.onChange('')
      callNotification({ type: NotificationTypes.SUCCESS, message: notifications.successful.signUp })
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.signUp(err.message) })
    }
  }

  const onChangeConfirm = (value: string) => {
    setConfirmValue(value)
  }

  const isFieldsFilled = useMemo(() => email.value && password.value, [email.value, password.value])

  const isDisabled = useMemo(
    () => email.isError || password.isError || !isFieldsFilled,
    [email.isError, password.isError, isFieldsFilled],
  )

  const isLoading = useMemo(() => createManagerRequest.isLoading, [createManagerRequest.isLoading])
  const error = useMemo(() => createManagerRequest.error || confirmError, [createManagerRequest.error, confirmError])
  const isError = useMemo(
    () => createManagerRequest.isError || Boolean(confirmError),
    [createManagerRequest.isError, confirmError],
  )

  return (
    <Container>
      <Content>
        <Title>Create manager account</Title>
        <Form onSubmit={onHandleSignUp}>
          <InputWrap>
            <Input
              type='email'
              label='E-mail'
              value={email.value}
              error={email.isError}
              onChange={email.onChange}
              errorMessage={email.errorMessage}
            />

            <PasswordField
              label='Password'
              name='new-password'
              confirmValue={confirmValue}
              onChangeConfirm={onChangeConfirm}
              isPasswordError={password.isError}
              ariaDescribedby='password-constraints'
              isConfirmPasswordError={Boolean(confirmError)}
              confirmPasswordErrorMessage={confirmError}
              {...password}
            />
          </InputWrap>

          <ButtonWrap>
            <Button submit fullSize size='large' isLoading={isLoading} disabled={isDisabled}>
              Create manager
            </Button>
          </ButtonWrap>

          <ErrorWrap>{isError && error && <ErrorContent>{error}</ErrorContent>}</ErrorWrap>
        </Form>
      </Content>
    </Container>
  )
}
