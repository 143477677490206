import { Icon, IconSize } from 'components/ui'
import { Wrap } from './Style'

type Props = {
  text?: string
  height?: string
  size?: IconSize
}

const NoData = ({ height = '100%', size = 'xl', text = 'No Data' }: Props) => {
  return (
    <Wrap height={height}>
      <Icon name='noContent' size={size} />
      {text}
    </Wrap>
  )
}

export { NoData }
