import useErrorBoundary from 'use-error-boundary'

import { Icon } from 'components/ui'
import { errorParser } from 'utils/errors'

import { NewDocumentsTable } from './NewDocumentsTable'
import { DocsVerificationTableProps } from '../types'
import { ErrorContent, ErrorWrapper, ErrorTitle, ErrorIcon, Subtitle } from '../Style'

function NewDocumentsTableWrapper(props: DocsVerificationTableProps) {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary()

  return didCatch ? (
    <ErrorWrapper>
      <ErrorTitle>Oops!</ErrorTitle>
      <ErrorIcon>
        <Icon name='skull' size='xl' />
      </ErrorIcon>
      <Subtitle>Something went wrong</Subtitle>
      <ErrorContent>{errorParser(error.toString())}</ErrorContent>
    </ErrorWrapper>
  ) : (
    <ErrorBoundary>
      <NewDocumentsTable {...props} />
    </ErrorBoundary>
  )
}

export { NewDocumentsTableWrapper as NewDocumentsTable }
