import styled, { css } from 'styled-components/macro'

import ReactSelect from 'react-select'
import { transparentize } from 'polished'

import { shimmer } from 'utils/animations'

import { getSize } from './helpers'

export const Wrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
`

export const Label = styled.label`
  margin: 0 0 0.4rem;
  display: inline-block;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.43;
  color: ${(props) => props.theme.color.text};
`

export const Hint = styled.span`
  margin: 0;
  display: inline-block;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.2rem;
  line-height: 1.3;
  color: ${(props) => props.theme.color.text};
`

export const StyledSelect = styled(ReactSelect)<{ isLoading?: boolean }>`
  .Select {
    &__control {
      padding: ${({ size }) => getSize(size).padding};
      width: 100%;
      height: ${({ size }) => getSize(size).height};
      min-height: ${({ size }) => getSize(size).height};
      color: ${(props) => props.theme.color.text};
      background-color: ${(props) => props.theme.color.white};
      border: 0.1rem solid ${(props) => transparentize(0.5, props.theme.color.black)};
      border-radius: 1rem;
      box-shadow: none;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.3s ease-out;
      z-index: ${(props) => props.theme.zIndex.minimal};
      ${(props) =>
        props.isLoading &&
        css`
          user-select: none;
          pointer-events: none;
        `}
      &--is-disabled {
        pointer-events: auto;
        cursor: no-drop;
      }
      &:hover,
      &--is-focused {
        border-color: ${(props) => props.theme.color.default};
      }
      &--menu-is-open {
        .Select__dropdown-indicator {
          transform: rotate(-180deg);
        }
      }
      ${(props) =>
        props.type === 'default' &&
        css`
          color: ${(props) => props.theme.color.text};
          background-color: ${(props) => props.theme.color.inputColor};
          border-color: ${(props) => transparentize(0.5, props.theme.color.black)};
          &:hover {
            box-shadow: inset 0 0 0 0.1rem ${(props) => props.theme.color.default};
            border-color: ${(props) => props.theme.color.black};
          }
          &--is-focused {
            background-color: ${(props) => props.theme.color.white};
            border-color: ${(props) => props.theme.color.default};
            box-shadow: inset 0 0 0 0.2rem ${(props) => props.theme.color.default};
            caret-color: ${(props) => props.theme.color.text};
            outline: none;
          }
        `}
      ${(props) =>
        props.type === 'primary' &&
        css`
          color: ${(props) => props.theme.color.white};
          background-color: ${(props) => props.theme.color.elementBgThird};
          border-color: ${(props) => transparentize(0.95, props.theme.color.white)};
        `}
       ${(props) =>
        props.type === 'transparent' &&
        css`
          color: ${(props) => props.theme.color.white};
          background-color: ${(props) => props.theme.color.transparent};
          border-color: ${(props) => props.theme.color.transparent};
          &:hover,
          &--is-focused {
            background-color: ${(props) => transparentize(0.95, props.theme.color.white)};
            border-color: ${(props) => props.theme.color.transparent};
          }
        `}
       ${(props) =>
        props.error &&
        css`
          border-color: ${(props) => props.theme.color.danger};
        `}
    }
    &__value-container {
      padding: 0;
      height: 100%;
    }
    &__single-value {
      margin: 0;
      width: 100%;
      font-size: ${({ size }) => getSize(size).fontSize};
      line-height: ${({ size }) => getSize(size).lineHeight};
      color: currentColor;
    }
    &__placeholder {
      position: static;
      margin: 0 1rem 0 0;
      font-family: ${(props) => props.theme.font.family.default};
      font-weight: ${(props) => props.theme.font.weight.regular};
      font-size: ${({ size }) => getSize(size).fontSize};
      line-height: ${({ size }) => getSize(size).lineHeight};
      color: ${(props) => transparentize(0.3, props.theme.color.text)};
      transform: none;
      transform-origin: left center;
    }
    &__loading-indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-flex;
      background-image: ${(props) => props.theme.color.placeholderLoading};
      transform: translateX(-100%);
      animation: ${shimmer} 1.5s infinite;
      animation-fill-mode: both;
      & > span {
        display: none;
      }
    }
    &__indicator-separator {
      display: none;
    }
    &__dropdown-indicator {
      padding: 0;
      color: ${(props) => props.theme.color.black};
      transition: all 0.2s ease-out;
      &:hover {
        color: ${(props) => props.theme.color.black};
      }
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
      ${(props) =>
        props.type === 'transparent' &&
        css`
          color: ${(props) => props.theme.color.textHint};
          opacity: ${(props) => props.theme.opacity.opacity05};
        `}
    }
    &__menu {
      margin: 0.4rem 0;
      padding: 0;
      background-color: ${(props) => props.theme.color.elementBgSecond};
      border: none;
      border-radius: 1rem;
      transition: all 0.3s ease-in;
      overflow: hidden;
      box-shadow: none;
      z-index: ${(props) => props.theme.zIndex.preloader};
    }
    &__menu-list {
      padding: 0.4rem;
      &::-webkit-scrollbar-track {
        background-color: ${(props) => props.theme.color.transparent};
        border-radius: 0.8rem;
      }
      &::-webkit-scrollbar {
        width: 0.8rem;
        background-color: ${(props) => props.theme.color.scrollbarColor};
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.defaultLight};
        border-radius: 0.8rem;
      }
    }
    &__option {
      padding: ${({ size }) => getSize(size).paddingMenu};
      font-family: ${(props) => props.theme.font.family.default};
      font-size: ${({ size }) => getSize(size).fontSize};
      line-height: ${({ size }) => getSize(size).lineHeight};
      color: ${(props) => props.theme.color.white};
      background-color: ${(props) => props.theme.color.transparent};
      cursor: pointer;
      transition: all 0.3s ease-out;
      &--is-disabled {
        opacity: ${(props) => props.theme.opacity.opacity05};
        user-select: none;
        pointer-events: none;
      }
      &:hover {
        color: ${(props) => props.theme.color.text};
        background-color: ${(props) => transparentize(0.15, props.theme.color.white)};
        border-radius: 0.8rem;
      }
      &--is-focused {
        color: ${(props) => props.theme.color.defaultLight};
      }
    }
  }
`

export const OptionRow = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.4rem;
`

export const ErrorMessage = styled.span`
  position: absolute;
  top: calc(100% + 0.2rem);
  left: 0;
  padding: 0 1rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.2rem;
  line-height: 1.67;
  letter-spacing: 0.03rem;
  color: ${(props) => props.theme.color.danger};
  background-color: ${(props) => props.theme.color.transparent};
  z-index: ${(props) => props.theme.zIndex.minimal};
`
