import styled from 'styled-components/macro'
import { transparentize } from 'polished'

export const Wrapper = styled.div<{ area?: string; justifySelf?: string }>`
  display: inline-flex;
  flex-direction: column;
  gap: 0.4rem;
  grid-area: ${(props) => props.area};
  justify-self: ${(props) => props.justifySelf};
`

export const Title = styled.span`
  margin: 0;
  display: inline-block;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: -0.03rem;
  text-align: left;
  color: ${(props) => transparentize(0.5, props.theme.color.text)};
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    display: none;
  `};
`

export const Value = styled.span`
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  text-align: left;
  color: ${(props) => props.theme.color.text};
`

export const Wrap = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`

export const ErrorWrapper = styled.div`
  padding: 1.6rem 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const ErrorTitle = styled.h3`
  margin: 0 0 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 1.8rem;
  line-height: 1.43;
  letter-spacing: -0.03rem;
  text-align: center;
  color: ${(props) => props.theme.color.text};
`

export const ErrorIcon = styled.span`
  margin: 0 0 1.6rem;
  color: ${(props) => props.theme.color.text};
`

export const Subtitle = styled.p`
  margin: 0 0 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  letter-spacing: -0.03rem;
  text-align: center;
  color: ${(props) => props.theme.color.text};
`

export const ErrorContent = styled.span`
  margin: 1.2rem 0 0 0;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: -0.03rem;
  color: ${(props) => props.theme.color.textHint};
`
