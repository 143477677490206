import { useCallback } from 'react'

import { Hash } from 'types/entities'

import { useErc20Approve, useUsdtApprove } from './generated'

export function useApprove(address?: Hash) {
  const { writeAsync: asyncApprove, ...approveRequest } = useErc20Approve({ address })
  const { writeAsync: asyncUsdtApprove, ...usdtApproveRequest } = useUsdtApprove({ address })

  const approve = useCallback(
    async (spender: Hash, amount: bigint) => {
      try {
        const response = await asyncApprove({ args: [spender, amount] })
        return response
      } catch (err) {
        // https://github.com/wagmi-dev/wagmi/issues/2419
        if (err.message.includes('"approve" returned no data ("0x")')) {
          const response = await asyncUsdtApprove({ args: [spender, amount] })
          return response
        }
        throw err
      }
    },
    [asyncApprove, asyncUsdtApprove],
  )

  return {
    approve,
    error: approveRequest.error || usdtApproveRequest.error,
    isError: approveRequest.isError || usdtApproveRequest.isError,
    isSuccess: approveRequest.isSuccess || usdtApproveRequest.isSuccess,
    isLoading: approveRequest.isLoading || usdtApproveRequest.isLoading,
  }
}
