import { Kyc, KycStatus } from 'types/entities'

import { useOnUpdateKycStatus } from 'state/kyc/hooks'

import { notifications } from 'constants/notifications'

import { useOpenModal } from 'modal'
import { newConfirmModal } from 'modals'
import { CellProps } from 'components/ui'
import { useCallNotification } from 'notifications/hooks'
import { NotificationTypes } from 'notifications/service'

import { ButtonClose } from '../Style'

type Props = {
  content?: string
  updateData: () => Promise<void>
}

type AcceptProps = CellProps<Kyc> & Props

export function AcceptVerification({ row, content, updateData }: AcceptProps) {
  const openModal = useOpenModal()
  const callNotification = useCallNotification()
  const { onUpdateKycStatus, ...request } = useOnUpdateKycStatus()

  const { id } = row.original

  const handleAccept = () => {
    openModal(newConfirmModal({ action: handleUpdateStatus }))
  }

  const handleUpdateStatus = async () => {
    try {
      await onUpdateKycStatus({ id, status: KycStatus.COMPLETED })

      await updateData()
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.changeKycStatus(err.message) })
    }
  }

  return (
    <ButtonClose size='medium' loadingText='' onClick={handleAccept} isLoading={request.isLoading}>
      {content ? content : 'Accept'}
    </ButtonClose>
  )
}
