const Bnb = () => (
  <svg fill='none' viewBox='0 0 24 24'>
    <path fill='#F3BA2F' d='M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z' />
    <path
      fill='#fff'
      d='m8.894 13.277 3.108 3.107 3.108-3.108 1.809 1.806h-.001L12.002 20l-4.916-4.915-.003-.002 1.811-1.806Zm4.941-1.278h.002l-.002.002-1.833 1.835-1.834-1.833-.002-.004.323-.323.157-.156 1.356-1.356L13.835 12Zm2.55.002 1.807-1.808L20 12.001l-1.808 1.807-1.808-1.807ZM4 12l1.808-1.808L7.616 12l-1.808 1.808L4 12Zm4.894-1.278 3.108-3.106 3.108 3.109 1.808-1.809L12.002 4 7.086 8.916l1.808 1.807Z'
    />
  </svg>
)

export { Bnb }
