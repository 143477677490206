import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'
import { Button, Href } from 'components/ui'

export const Expand = styled.span`
  display: flex;
  width: 1.6rem;
  height: 1.6rem;
`

export const StyledHref = styled(Href)`
  padding: 0.2rem 0.6rem;
  display: inline-flex;
  align-items: center;
  gap: 0.6rem;
  text-decoration: none;
  border-radius: 0.8rem;
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    color: ${(props) => props.theme.color.defaultLight};
    background-color: ${(props) => props.theme.color.default};
  }
`

export const TimeSection = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  text-align: left;
  color: ${(props) => props.theme.color.text};
`

export const Value = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  text-align: left;
  color: ${(props) => props.theme.color.text};
`

export const Wrapper = styled.div<{ area?: string; justifySelf?: string }>`
  display: inline-flex;
  flex-direction: column;
  align-self: center;
  gap: 0.4rem;
  grid-area: ${(props) => props.area};
  justify-self: ${(props) => props.justifySelf};
  ${(props) =>
    props.area === 'Email' &&
    css`
      ${Value} {
        text-align: left;
        text-wrap: balance;
        word-break: break-all;
      }
    `}
`

export const Title = styled.span`
  margin: 0;
  display: inline-block;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  text-align: left;
  color: ${(props) => transparentize(0.5, props.theme.color.text)};
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    display: none;
  `};
`

export const ButtonClose = styled(Button)<{ shouldOpenModal?: boolean }>`
  grid-area: CloseButton;
  width: 100%;
  align-self: center;
  ${(props) =>
    props.shouldOpenModal &&
    css`
      grid-area: CloseButton;
      padding: 0.8rem;
      width: auto;
      align-self: center;
      color: ${(props) => props.theme.color.default};
    `}
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    width: 6.9rem;
    align-self: unset;
  `};
`

export const PositionItem = styled.div<{ type?: string }>`
  padding: 1.6rem 0.8rem 1.6rem 1.6rem;
  width: 100%;
  height: 11.9rem;
  display: grid;
  background-color: ${(props) => transparentize(0.95, props.theme.color.text)};
  border-radius: 1.2rem;
  overflow: auto;
  ${(props) =>
    props.type === 'open' &&
    css`
      grid-template-columns: 1fr 1fr auto;
      grid-template-rows: repeat(2, auto);
      gap: 1.2rem 1.6rem;
      grid-template-areas:
        'DebtToken DebtToken CloseButton'
        'Size UnrealizedPnL CloseButton';
    `}
  ${(props) =>
    props.type === 'closed' &&
    css`
      color: ${(props) => props.theme.color.negative};
    `}
`
