const Usdt = () => (
  <svg fill='none' viewBox='0 0 25 22'>
    <path
      fill='#50AF95'
      d='M4.601.105.118 9.523a.183.183 0 0 0 .039.213l12.109 11.603a.184.184 0 0 0 .256 0L24.63 9.737a.182.182 0 0 0 .039-.213L20.186.106A.181.181 0 0 0 20.021 0H4.768A.181.181 0 0 0 4.6.105Z'
    />
    <path
      fill='#fff'
      d='M13.95 10.49c-.087.007-.537.033-1.538.033-.797 0-1.363-.024-1.562-.033-3.08-.135-5.378-.672-5.378-1.313 0-.642 2.299-1.178 5.378-1.315v2.094c.202.015.778.049 1.575.049.957 0 1.435-.04 1.522-.048V7.863c3.073.137 5.366.673 5.366 1.314 0 .64-2.293 1.176-5.366 1.312l.003.001Zm0-2.843V5.772h4.288V2.914H6.562v2.858h4.288v1.874c-3.486.16-6.107.85-6.107 1.678 0 .827 2.621 1.517 6.107 1.678v6.005h3.099V11c3.477-.16 6.094-.85 6.094-1.676 0-.827-2.615-1.516-6.094-1.677Z'
    />
  </svg>
)

export { Usdt }
