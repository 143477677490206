import { color } from 'theme'
import { StyleLoader, LoaderLine } from './Style'

type Props = {
  size?: number
  color?: string
  ringSize?: number
}

const colorDefault = color.default

function Loader({ size = 2, ringSize = 0.2, color = colorDefault }: Props) {
  return (
    <StyleLoader width={size} height={size}>
      <LoaderLine width={size} height={size} ringSize={ringSize} color={color} />
    </StyleLoader>
  )
}

export { Loader }
