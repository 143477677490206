const Eraser = () => (
  <svg viewBox='0 0 25 24'>
    <path
      fillRule='evenodd'
      d='M15.605 4.235c-.566-.63-1.534-.674-2.24.03l-9.6 9.6-.03.03c-.63.566-.674 1.534.03 2.24l4.3 4.3a.8.8 0 0 1-1.13 1.13l-4.3-4.3c-1.29-1.289-1.34-3.31.013-4.545l9.586-9.586c1.29-1.29 3.31-1.34 4.545.014l5.587 5.586c1.29 1.29 1.34 3.31-.014 4.546l-8.286 8.286a.8.8 0 0 1-1.132-1.132l8.3-8.3.03-.029c.63-.566.675-1.534-.03-2.24l-5.6-5.6a.825.825 0 0 1-.029-.03Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M6.7 21a.8.8 0 0 1 .8-.8h15a.8.8 0 0 1 0 1.6h-15a.8.8 0 0 1-.8-.8Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M4.935 10.434a.8.8 0 0 1 1.13 0l9 9a.8.8 0 0 1-1.13 1.132l-9-9a.8.8 0 0 1 0-1.132Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Eraser }
