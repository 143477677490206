import { ReactNode } from 'react'

import { useChainData } from 'hooks/app'

import { UserOperationsTypes } from 'state/user/types'

import { Loader } from 'components/ui'

import { TxStatus } from './TxStatus'
import { InfoText, WrapContentApprove, Text, Wrapper } from '../Style'

type Props = {
  txHash: string
  isSent: boolean
  children?: ReactNode
  errorMessage: string
  txType: UserOperationsTypes.REVOKE | UserOperationsTypes.APPROVE
}

export function Approve({ txHash, children, txType, isSent, errorMessage }: Props) {
  const { chainId } = useChainData()

  return (
    <>
      {!isSent && !errorMessage && (
        <InfoText>Give permission to&nbsp;the smart contract to&nbsp;interact with your tokens</InfoText>
      )}
      {!errorMessage && !txHash && (
        <WrapContentApprove>
          <Wrapper>
            <Loader size={7.2} ringSize={0.6} />
          </Wrapper>
          <Text>Please, sign the {txType} transaction in&nbsp;your wallet</Text>
          {children}
        </WrapContentApprove>
      )}
      <TxStatus
        isSent={isSent}
        txType={txType}
        txHash={txHash}
        chainId={chainId}
        errorMessage={errorMessage}
        successText='Now you can make a donation.'
        pendingText='If you close this window, you can continue to&nbsp;execute the donation after receiving approval'
      />
    </>
  )
}
