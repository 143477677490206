import { IconSize } from '../@types'

export const getSize = (size: IconSize) =>
  ({
    extraSmall: {
      width: '1rem',
      padding: 0,
    },
    small: {
      height: '1.4rem',
      width: '1.4rem',
      padding: 0,
    },
    medium: {
      height: '1.6rem',
      width: '1.6rem',
      padding: 0,
    },
    mediumPlus: {
      height: '1.8rem',
      width: '1.8rem',
      padding: 0,
    },
    extraMedium: {
      height: '2rem',
      width: '2rem',
      padding: 0,
    },
    default: {
      height: '2.4rem',
      width: '2.4rem',
      padding: 0,
    },
    large: {
      height: '3.2rem',
      width: '3.2rem',
      padding: 0,
    },
    xlarge: {
      height: '4.4rem',
      width: '4.4rem',
      padding: 0,
    },
    xl: {
      height: '6.4rem',
      width: '6.4rem',
      padding: 0,
    },
    xxl: {
      height: '7.2rem',
      width: '7.2rem',
      padding: 0,
    },
    fill: {
      height: 'inherit',
      width: 'inherit',
      padding: 0,
    },
    auto: {
      height: 'auto',
      width: '100%',
      padding: 0,
    },
  }[size])
