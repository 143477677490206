import { color } from 'theme'
import { tint } from 'polished'

import { Loader } from 'components/ui'

import { Props } from './@types'
import { StyledButton } from './Style'

const colorLoader = tint(0.28, color.default)

function Button({
  onClick,
  children,
  type = 'default',
  size = 'default',
  disabled = false,
  fullSize = false,
  isLoading = false,
  loadingSize = 0.2,
  isUpperCase = false,
  shouldOpenModal = false,
  loadingText = 'Loading...',
  ...rest
}: Props) {
  return (
    <StyledButton
      size={size}
      type={type}
      onClick={onClick}
      disabled={disabled}
      fullsize={fullSize ? 'true' : 'false'}
      isloading={isLoading ? 'true' : 'false'}
      isuppercase={isUpperCase ? 'true' : 'false'}
      shouldopenmodal={shouldOpenModal ? 'true' : 'false'}
      {...rest}
    >
      {isLoading ? (
        <>
          <Loader ringSize={loadingSize} color={colorLoader} />
          {loadingText}
        </>
      ) : (
        children
      )}
    </StyledButton>
  )
}

export { Button }
