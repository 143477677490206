const NoContent = () => (
  <svg fill='none' viewBox='0 0 72 73'>
    <g filter='url(#a_NoContent)'>
      <path
        fill='url(#b_NoContent)'
        fillOpacity='.2'
        fillRule='evenodd'
        d='M15 13.5a3 3 0 0 1 3-3h28.5V18a3 3 0 0 0 3 3H57v38.5a3 3 0 0 1-3 3H18a3 3 0 0 1-3-3v-46Z'
        clipRule='evenodd'
      />
      <path fill='url(#c_NoContent)' fillOpacity='.4' d='M57 21 46.5 10.5V18a3 3 0 0 0 3 3H57Z' />
    </g>
    <path
      fill='#000'
      fillOpacity='.2'
      fillRule='evenodd'
      d='M49.5 26.25a.5.5 0 0 1-.5.5H23.5a.5.5 0 0 1-.5-.5v-1.5a.5.5 0 0 1 .5-.5H49a.5.5 0 0 1 .5.5v1.5Z'
      clipRule='evenodd'
    />
    <path
      fill='#000'
      fillOpacity='.2'
      fillRule='evenodd'
      d='M45 37.25a.5.5 0 0 1-.5.5h-21a.5.5 0 0 1-.5-.5v-1.5a.5.5 0 0 1 .5-.5h21a.5.5 0 0 1 .5.5v1.5Z'
      clipRule='evenodd'
      opacity='.75'
    />
    <path
      fill='#000'
      fillOpacity='.2'
      fillRule='evenodd'
      d='M37 48.25a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.5Z'
      clipRule='evenodd'
      opacity='.5'
    />
    <path
      fill='url(#d_NoContent)'
      fillRule='evenodd'
      d='M58.19 38.81a9.46 9.46 0 0 0-13.38 0 9.46 9.46 0 0 0 0 13.38 9.46 9.46 0 0 0 13.38 0 9.46 9.46 0 0 0 0-13.38Zm.997 16.498c-4.89 3.842-11.99 3.51-16.497-.997-4.866-4.866-4.866-12.756 0-17.622s12.755-4.866 17.62 0c4.508 4.507 4.84 11.608.998 16.498l6.753 6.752a1.5 1.5 0 0 1-2.122 2.122l-6.752-6.753Z'
      clipRule='evenodd'
    />
    <defs>
      <linearGradient id='b_NoContent' x1='15' x2='57' y1='10.5' y2='62.5' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#000' stopOpacity='.5' />
        <stop offset='1' stopColor='#000' />
      </linearGradient>
      <linearGradient id='c_NoContent' x1='57' x2='46.5' y1='10.5' y2='21' gradientUnits='userSpaceOnUse'>
        <stop offset='.465' stopColor='#000' stopOpacity='.49' />
        <stop offset='1' stopColor='#000' />
      </linearGradient>
      <linearGradient id='d_NoContent' x1='36' x2='67' y1='30' y2='61' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#000' />
        <stop offset='1' stopColor='#B0B0C0' stopOpacity='.7' />
      </linearGradient>
      <filter
        id='a_NoContent'
        width='62'
        height='72'
        x='5'
        y='.5'
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation='5' />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_2482_47631' />
        <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_2482_47631' result='shape' />
      </filter>
    </defs>
  </svg>
)

export { NoContent }
