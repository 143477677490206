import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

import { Href } from 'components/ui'

export const Wrap = styled.div`
  margin: 3.2rem 0 0;
  padding: 0 0 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 1.2rem;
  box-shadow: 0 0.4rem 1.6rem 0 ${(props) => props.theme.color.elementBorder};
`

export const Title = styled.h5`
  margin: 0;
  padding: 1rem 1.2rem;
  width: 100%;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.8rem;
  line-height: 1.33;
  text-align: left;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.default};
  border-radius: 1.2rem 1.2rem 0 0;
`

export const Container = styled.div`
  display: block;
  max-width: calc(100vw - 3.2rem);
  overflow: auto hidden;
`

export const ContainerContent = styled.div`
  width: auto;
`

export const Header = styled.header`
  padding: 0 1.2rem;
  position: relative;
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 7.2rem 12rem 13rem 9rem minmax(11rem, auto) 8rem 11rem;
`

export const HeaderCell = styled.div`
  padding: 1.2rem 0.6rem 0.9rem;
  height: 4.1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  text-align: left;
  text-transform: capitalize;
  white-space: nowrap;
  color: ${(props) => transparentize(0.4, props.theme.color.text)};
  &:first-child {
    padding: 1.2rem 0.2rem 0.9rem 1.2rem;
  }
  &:last-child {
    padding: 1.2rem 1.2rem 0.9rem 0.2rem;
    justify-content: flex-end;
  }
`

export const WrapRow = styled.div`
  width: 100%;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column-reverse;
  align-items: center;
  gap: 0.4rem;
`

export const Row = styled.div`
  position: relative;
  padding: 0 1.2rem;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 7.2rem 12rem 13rem 9rem minmax(11rem, auto) 8rem 11rem;
`

export const RowCell = styled.div<{ type?: string }>`
  padding: 0.9rem 0.6rem;
  height: 5.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  color: ${(props) => props.theme.color.text};
  text-align: left;
  white-space: nowrap;
  background-color: ${(props) => props.theme.color.pageBg};
  &:first-child {
    border-radius: 1.2rem 0 0 1.2rem;
  }
  &:last-child {
    border-radius: 0 1.2rem 1.2rem 0;
  }
  ${(props) =>
    props.type === 'status' &&
    css`
      justify-content: flex-end;
    `}
`

export const StyledHref = styled(Href)`
  padding: 0.2rem 0.6rem;
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  gap: 0.6rem;
  text-decoration: none;
  border-radius: 0.8rem;
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    color: ${(props) => props.theme.color.defaultLight};
    background-color: ${(props) => props.theme.color.default};
  }
`
