import styled from 'styled-components/macro'
import { transparentize } from 'polished'

import { NavLink } from 'react-router-dom'
import { Href } from 'components/ui'

export const Container = styled.div`
  position: relative;
  margin: 3.2rem auto;
  padding: 1.6rem;
  width: 100%;
  max-width: 74rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  margin: 0 0 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2.4rem;
  line-height: 1.44;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
     font-size: 3rem;
  `};
`

export const PreTitle = styled.h4`
  margin: 0 0 3.2rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.33;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  ${(props) => props.theme.breakpoints.greaterThan('xsm')`
     margin: 0 0 5.8rem;
  `};
`

export const StyledNavLink = styled(NavLink)`
  padding: 1.2rem 1.6rem;
  width: 37.6rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  font-family: ${(props) => props.theme.font.family.default};
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 1.8rem;
  line-height: 1.43;
  letter-spacing: 0.04rem;
  text-decoration: none;
  color: ${(props) => props.theme.color.white};

  background-color: ${(props) => props.theme.color.default};
  border: 0.1rem solid ${(props) => props.theme.color.transparent};
  border-radius: 6em;

  transition: all 0.4s ease-out;
  &:disabled {
    opacity: ${(props) => props.theme.opacity.opacity05};
  }
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    box-shadow: 0 1rem 1.8rem -0.9rem ${(props) => transparentize(0.63, props.theme.color.black)};
  }
`

export const SupportLink = styled(Href)`
  color: ${(props) => props.theme.color.textHint};
  text-decoration: underline;
`
