import styled from 'styled-components/macro'
import { transparentize } from 'polished'

export const TimeSection = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: -0.03rem;
  text-align: left;
  color: ${(props) => props.theme.color.text};
`

export const Wrapper = styled.div<{ area?: string; justifySelf?: string }>`
  display: inline-flex;
  flex-direction: column;
  align-self: center;
  gap: 0.4rem;
  grid-area: ${(props) => props.area};
  justify-self: ${(props) => props.justifySelf};
`

export const Title = styled.span`
  margin: 0;
  display: none;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: -0.03rem;
  text-align: left;
  color: ${(props) => transparentize(0.5, props.theme.color.text)};
`
