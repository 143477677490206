import { useMemo, useState } from 'react'

import { debounce } from 'utils/lodash'
import { validations } from 'utils/validation'

import { errors } from 'constants/errors'

export function useEmailFieldData() {
  const [value, setValue] = useState('')
  const [isValidateError, setValidateError] = useState(false)

  const checkValidation = useMemo(() => {
    return debounce(function (_value: string) {
      if (!_value) {
        setValidateError(false)
      } else {
        setValidateError(!validations.checkEmail(_value))
      }
    }, 500)
  }, [])

  const isError = useMemo(() => Boolean(value) && isValidateError, [value, isValidateError])

  const onChange = (value: string) => {
    setValue(value)
    checkValidation(value)
  }

  const errorMessage = errors.validation.INVALID_EMAIL

  return { value, onChange, isError, errorMessage }
}
