import useErrorBoundary from 'use-error-boundary'

import { errorParser } from 'utils/errors'
import { Icon } from 'components/ui'

import { DonationsTable } from './DonationsTable'
import { ErrorContent, ErrorWrapper, ErrorTitle, ErrorIcon, Subtitle } from '../Style'

function DonationsTableWrapper() {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary()

  return didCatch ? (
    <ErrorWrapper>
      <ErrorTitle>Oops!</ErrorTitle>
      <ErrorIcon>
        <Icon name='skull' size='xl' />
      </ErrorIcon>
      <Subtitle>Something went wrong</Subtitle>
      <ErrorContent>{errorParser(error.toString())}</ErrorContent>
    </ErrorWrapper>
  ) : (
    <ErrorBoundary>
      <DonationsTable />
    </ErrorBoundary>
  )
}

export { DonationsTableWrapper as DonationsTable }
