import { InputSize } from '../@types'

export const getSize = (size: InputSize) =>
  ({
    default: {
      padding: '1.2rem',
      fontSize: '1.6rem',
      lineHeight: 1.5,
      letterSpacing: '0',
    },
    medium: {
      padding: '1.2rem',
      fontSize: '1.4rem',
      lineHeight: 1.43,
      letterSpacing: '0',
    },
  }[size])
