import { TooltipProps } from './types'

import { ReactTip } from './Style'

function Tooltip({
  id,
  delay = 100,
  type = 'dark',
  place = 'bottom',
  effect = 'solid',
  clickable = false,
  position = 'absolute',
  ...rest
}: TooltipProps) {
  return (
    <ReactTip
      variant={type}
      anchorId={id}
      place={place}
      delayHide={delay}
      delayShow={delay}
      aria-haspopup='true'
      clickable={clickable}
      className='customTooltip'
      float={effect === 'float'}
      positionStrategy={position}
      classNameArrow='customTooltipArrow'
      {...rest}
    />
  )
}

export { Tooltip }
