import styled from 'styled-components/macro'

export const Content = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.6rem;
`

export const ButtonWrap = styled.div`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
`
