import { useLocation } from 'react-router-dom'
import { useEffect, useLayoutEffect } from 'react'

export function useScrollToTop() {
  const location = useLocation()

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
}
