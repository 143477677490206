import { generateMedia } from 'styled-media-query'

import { Theme } from './@types'
import { color } from './Color'

export const breakpoints = generateMedia({
  xs: '22.5em', // 360
  xsm: '32.5em', // 520
  sm: '48em', // 768
  md: '64em', // 1024
  lg: '76.5em', // 1224
  xl: '90em', // 1440
  xxl: '240em', // 3840
  xxxl: '320em', // 5120
  extraXl: '480em', // 7680
})

export const font = {
  family: {
    default: `'IBM Plex Sans', sans-serif`,
    primary: `'Unbounded', cursive`,
  },
  weight: {
    regular: 400,
    medium: 500,
    bold: 600,
  },
  style: {
    normal: 'normal',
    italic: 'italic',
    oblique: 'oblique',
    inherit: 'inherit',
  },
  size: {
    extraSmall: '1rem',
    small: '1.2rem',
    regular: '1.4rem',
    default: '1.6rem',
    medium: '1.8rem',
    large: '2.2rem',
    largeX: '2.4rem',
    largeXX: '2.8rem',
    max: '3.4rem',
    maxPlus: '3.6rem',
  },
  letterSpacing: {
    extraSmall: '-0.075rem',
    small: '-0.05rem',
    min: '0.05rem',
  },
  lineHeight: {
    extraSmall: 1,
    smallerX: 1.15,
    smaller: 1.25,
    lowSmall: 1.3,
    small: 1.5,
    regular: 2,
    medium: 2.5,
    large: 3,
    extraLarge: 3.5,
  },
}

export const opacity = {
  default: 1,
  opacity09: 0.9,
  opacity08: 0.8,
  opacity07: 0.7,
  opacity06: 0.6,
  opacity05: 0.5,
  opacity04: 0.4,
  opacity03: 0.3,
  opacity02: 0.2,
  opacity01: 0.1,
  invisible: 0,
}

export const zIndex = {
  modal: 6,
  modalContainer: 5,
  overlay: 4,
  navigation: 3,
  preloader: 2,
  minimal: 1,
  default: 0,
  negative: -1,
}

export const defaultTheme: Theme = {
  font,
  color,
  zIndex,
  opacity,
  breakpoints,
}

export const theme = defaultTheme
