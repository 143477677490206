import { useCallback } from 'react'

import { AppState } from 'state'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { Modal, ModalName } from 'modal/@types'

import { closeModal, openModal } from './actions'

const OPEN_MODAL = 1

export function useOpenModal() {
  const dispatch = useAppDispatch()

  return useCallback(
    (modal: Modal) => {
      dispatch(openModal(modal))
    },
    [dispatch],
  )
}

export function useCloseModal() {
  const dispatch = useAppDispatch()

  return useCallback(
    (modalName: ModalName) => {
      dispatch(closeModal(modalName))
    },
    [dispatch],
  )
}

export function useSelectModals() {
  return useAppSelector((state: AppState) => state.modal)
}

export function useSelectModal(name: ModalName) {
  return useAppSelector((state: AppState) => state.modal.find((m) => m.name === name))
}

export function useIsMultiplyModals() {
  return useAppSelector((state: AppState) => state.modal.length > OPEN_MODAL)
}
