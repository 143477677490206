import { UserData } from 'types/entities'

import { CellProps, Badge } from 'components/ui'

import { Wrapper, Title, Value } from '../Style'

export function KycStatus({ row }: CellProps<UserData>) {
  const { kyc } = row.original

  return (
    <Wrapper area='Status'>
      <Title>Status</Title>
      <Value>
        <Badge value={kyc.status} />
      </Value>
    </Wrapper>
  )
}
