import { useMemo } from 'react'
import { DateTime } from 'luxon'

import { Doc } from 'types/entities'

import { CellProps, TimeCell } from 'components/ui'

export function TimeCreated({ row }: CellProps<Doc>) {
  const { createdAt } = row.original

  const _time = useMemo(() => DateTime.fromISO(createdAt.toString()).toMillis(), [createdAt])

  return <TimeCell area='CreatedAt' title='Created at' timestamp={_time} />
}
