import { Container, Text } from './Style'

type Props = {
  id?: string
  value: string
}

const Badge = ({ id, value }: Props) => {
  return (
    <Container id={id} value={value.toLowerCase()}>
      <Text>{value ? value.toLowerCase() : 'complete'}</Text>
    </Container>
  )
}

export { Badge }
