import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ApplicationState {
  token: string
}

const initialState: ApplicationState = {
  token: '',
}

const kyc = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    setToken: (state: ApplicationState, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    kycClearState: () => initialState,
  },
})

export const {
  setToken,
  //
  kycClearState,
} = kyc.actions

export default kyc.reducer
