import { configureStore } from '@reduxjs/toolkit'

import { persistReducer, persistStore } from 'redux-persist'

import { rootReducer } from './reducers'
import { persistConfig } from './persistConfig'

import { kycApi } from './kyc/api'
import { userApi } from './user/api'
import { authApi } from './auth/api'
import { docsApi } from './docs/api'
import { donationApi } from './donation/api'

import { multicalls } from './multicalls'

type State = ReturnType<typeof rootReducer>

const persistedReducer = persistReducer<State>(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env?.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware({ serializableCheck: false }).concat(
        // user
        userApi.middleware,
        authApi.middleware,
        // verification
        kycApi.middleware,
        docsApi.middleware,
        // donate
        donationApi.middleware,
        // multicall
        multicalls.balances.middleware,
        multicalls.allowances.middleware,
      ),
    ]
  },
})

if (module.hot) {
  module.hot.accept('./reducers', () => {
    // This fetch the new state of the above reducers.
    // eslint-disable-next-line
    const reducers = require('./reducers').rootReducer
    store.replaceReducer(persistReducer<State>(persistConfig, reducers))
  })
}

const persistor = persistStore(store)

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export { persistor, store }
