import React, { ChangeEvent, MouseEvent, useRef, useState, useMemo, useEffect } from 'react'

import { crypto } from 'constants/variables'
import { Icon, IconMap } from 'components/ui'
import { validations } from 'utils/validation'

import { Props, DefaultValue } from './@types'
import {
  Label,
  Wrapper,
  InputBtn,
  Container,
  InputIcon,
  InputTicker,
  ErrorMessage,
  InputElement,
  InputIconSearch,
  ButtonSearchClean,
  InputIconPassword,
} from './Style'

function Input<Value extends DefaultValue>({
  id,
  type,
  icon,
  name,
  label,
  value,
  ticker,
  onBlur,
  onChange,
  inputMode,
  autoComplete,
  errorMessage,
  error = false,
  cleared = false,
  disabled = false,
  iconInput = false,
  tickerInput = false,
  inputButton = false,
  inputButtonElement,
  inputType = 'default',
  inputSize = 'default',
  decimals = crypto.WEI_DECIMALS,
  ...rest
}: Props<Value>) {
  const wrapper = useRef<HTMLDivElement>(null)

  const [isFocused, setFocus] = useState(false)
  const [isVisible, setVisible] = useState(false)

  const _label = useMemo(() => label || id, [label, id])
  const isPassword = useMemo(() => type === 'password', [type])
  const _type = useMemo(() => (isVisible ? 'text' : type), [type, isVisible])
  const passwordInputIcon = useMemo(() => (isVisible ? 'eye' : 'eyeOff'), [isVisible])
  const submitListenerCondition = useMemo(() => isFocused && isPassword, [isFocused, isPassword])
  const isShowClear = useMemo(() => (cleared || type === 'search') && value, [value, cleared, type])

  const listenerHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSetVisible()
    }
  }

  useEffect(() => {
    if (wrapper.current && submitListenerCondition) {
      wrapper.current.addEventListener('keydown', listenerHandler)
    }
    return () => {
      if (wrapper.current && isPassword) {
        wrapper.current.removeEventListener('keydown', listenerHandler)
      }
    }
  }, [submitListenerCondition, isPassword])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (typeof onChange === 'function') {
      if (type === 'number' && value) {
        const amount = value.replaceAll(',', '.').trim()
        const isValid = validations.checkCryptoNumeric(amount, decimals)

        if (isValid) {
          onChange(amount as Value, name)
        }
        return
      }
      onChange(value as Value, name)
    }
  }

  const toggleFocus = (value: boolean) => {
    if (wrapper?.current) {
      setFocus(value)
    }
  }

  const handleOnFocus = () => {
    toggleFocus(true)
  }

  const handleOnBlur = () => {
    toggleFocus(true)
  }

  const handleOnBlurInput = () => {
    if (typeof onBlur === 'function') {
      onBlur()
    }
  }

  const handleValueClear = (event: MouseEvent) => {
    event.preventDefault()

    if (typeof onChange === 'function') {
      onChange('' as Value, name)
    }
  }

  const handleSetVisible = (event?: MouseEvent) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    setVisible((prev) => !prev)
  }

  return (
    <Wrapper ref={wrapper} onFocus={handleOnFocus} onBlur={handleOnBlur}>
      {label && <Label htmlFor={label}>{label}</Label>}

      <Container>
        <InputElement
          {...rest}
          id={_label}
          name={name}
          type={_type}
          value={value}
          error={error}
          disabled={disabled}
          inputType={inputType}
          inputSize={inputSize}
          inputMode={inputMode}
          iconInput={iconInput}
          onChange={handleChange}
          tickerInput={tickerInput}
          onBlur={handleOnBlurInput}
          autoComplete={autoComplete}
        />

        {type === 'search' && (
          <InputIconSearch>
            <Icon name='search' size='auto' />
          </InputIconSearch>
        )}

        {iconInput && (
          <InputIcon>
            <Icon name={icon as IconMap} size='auto' />
          </InputIcon>
        )}

        {isShowClear && (
          <ButtonSearchClean onClick={handleValueClear}>
            <Icon name='clear' size='auto' />
          </ButtonSearchClean>
        )}

        {inputButton && <InputBtn inputType={inputType}>{inputButtonElement}</InputBtn>}

        {tickerInput && <InputTicker>{ticker}</InputTicker>}

        {type === 'password' && (
          <InputIconPassword
            onClick={handleSetVisible}
            type='button'
            id='toggle-password'
            aria-label='Show password as plain text. Warning: this will display your password on the screen.'
          >
            <Icon name={passwordInputIcon} size='extraMedium' />
          </InputIconPassword>
        )}
      </Container>

      {error && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  )
}

export { Input }
