import { useMemo } from 'react'

import { DocStatus } from 'types/entities'

import { Button, Icon } from 'components/ui'
import { Wrap, Content, IconWrap, Text, Wrapper } from './Style'

type Props = {
  docStatus: DocStatus
  onDocVerification: VoidFunction
}

export function DocVerificationStatus({ onDocVerification, docStatus }: Props) {
  const isDonationPending = useMemo(() => docStatus === DocStatus.PENDING, [docStatus])
  const isDonationDeclined = useMemo(() => docStatus === DocStatus.DECLINED, [docStatus])

  return (
    <>
      {isDonationPending && (
        <Wrap>
          <Content type='risky'>
            <IconWrap>
              <Icon name='warning' />
            </IconWrap>
            <Text>Source of funds is&nbsp;currently under verification.</Text>
          </Content>
        </Wrap>
      )}

      {isDonationDeclined && (
        <Wrap>
          <Wrapper>
            <Content type='danger'>
              <IconWrap>
                <Icon name='danger' />
              </IconWrap>
              <Text>Incorrect source of funds.</Text>
            </Content>
            <Button size='small' type='link' onClick={onDocVerification}>
              Change Source of funds
            </Button>
          </Wrapper>
        </Wrap>
      )}
    </>
  )
}
