import styled from 'styled-components/macro'
import { transparentize } from 'polished'

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CheckboxWrapper = styled.div`
  margin: 0 0 2rem;
  padding: 0 1.2rem;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: ${(props) => transparentize(0.9, props.theme.color.default)};
  border-radius: 1.2rem;
`

export const CheckboxLabel = styled.label`
  width: 100%;
`

export const CheckboxText = styled.h5`
  margin: 0 0 0 1.2rem;
  padding: 1.2rem 1.2rem 1.2rem 0;
  width: 100%;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.67;
  user-select: none;
  cursor: pointer;
  a {
    color: ${(props) => props.theme.color.default};
    text-decoration: underline;
  }
`
