import { Icon } from 'components/ui'

import { Stat as StatType } from './types'
import { ListItem } from './Style'

export function Stat({ stat }: { stat: StatType }) {
  return (
    <ListItem isValid={stat.isValid}>
      <Icon name='tick' size='medium' />
      {stat.description}
    </ListItem>
  )
}
