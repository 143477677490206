import { Name, VerificationSteps } from 'types/entities'

import { useSetVerificationStep, useUpdateUser } from 'state/user/hooks'
import { useOnSelfHostedKycStart, useOnUpdateKyc } from 'state/kyc/hooks'

import { KycStarter } from './KycStarter'
import { Container } from './Style'

export function SelfHosted() {
  const updateStep = useSetVerificationStep()

  const { refetch } = useUpdateUser()
  const { onUpdateKyc } = useOnUpdateKyc()
  const { onStart } = useOnSelfHostedKycStart()

  const handleContinue = async (name: Name, location: string) => {
    await onStart()
    await onUpdateKyc({ location, name: `${name.firstName} ${name.lastName}` })
    await refetch()
    updateStep(VerificationSteps.DOC)
  }

  return (
    <Container maxWidth='70rem'>
      <KycStarter onKycStart={handleContinue} />
    </Container>
  )
}
