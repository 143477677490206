import { useMemo } from 'react'

import { useChainData } from 'hooks/app'

import { getProvider } from 'services/ether'

export function useEthersProvider() {
  const { chainId, rpcUrl } = useChainData()

  return useMemo(() => getProvider(chainId, rpcUrl), [chainId, rpcUrl])
}
