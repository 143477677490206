import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useIsAuth } from 'state/auth/hooks'
import { useIsAdmin } from 'state/user/hooks'

import { Links } from 'constants/link'
import { ConnectButton } from 'components/elements'
import { CustomLink, LogoLink } from 'components/ui'

import {
  Nav,
  Wrap,
  List,
  Burger,
  Overlay,
  SignText,
  ListItem,
  Container,
  SignBlock,
  StyledButton,
  WrapConnectButton,
} from './Style'

const Header = () => {
  const isAuth = useIsAuth()
  const isAdmin = useIsAdmin()

  const location = useLocation()
  const navigate = useNavigate()

  const [isOpenNav, setOpenNav] = useState(false)

  const isSignIn = useMemo(() => {
    return location.pathname === Links.SIGN_IN //|| location.pathname === Links.RESTORE_PASSWORD
  }, [location.pathname])

  const handleClick = () => {
    setOpenNav(!isOpenNav)
    document.body.style.overflowY = isOpenNav ? 'unset' : 'hidden'
    document.body.style.overflowX = 'hidden'
  }

  const handleClickMenu = () => {
    setOpenNav(!isOpenNav)
    document.body.style.overflow = 'unset'
  }

  const signInClick = () => {
    navigate(Links.SIGN_IN, { replace: true })
  }

  const signUpClick = () => {
    navigate(Links.SIGN_UP, { replace: true })
  }

  return (
    <Wrap id='appHeader'>
      <Container isAuth={isAuth}>
        {!isAuth ? (
          <>
            <LogoLink />

            <SignBlock>
              {isSignIn ? (
                <>
                  <SignText>Don&rsquo;t have an&nbsp;account?</SignText>
                  <StyledButton type='primary' size='medium' onClick={signUpClick}>
                    Sign up
                  </StyledButton>
                </>
              ) : (
                <>
                  <SignText>Already have an&nbsp;account?</SignText>
                  <StyledButton type='primary' size='medium' onClick={signInClick}>
                    Sign in
                  </StyledButton>
                </>
              )}
            </SignBlock>
          </>
        ) : (
          <>
            <LogoLink />

            <Nav role='navigation' aria-label='Navigation' isOpen={isOpenNav}>
              <List>
                <ListItem onClick={handleClickMenu}>
                  <CustomLink title='Home' url={Links.MAIN} />
                </ListItem>

                {isAdmin && (
                  <ListItem onClick={handleClickMenu}>
                    <CustomLink title='Register Manager' url={Links.MANGER_SIGN_UP} />
                  </ListItem>
                )}

                <ListItem onClick={handleClickMenu}>
                  <CustomLink title='Profile' url={Links.PROFILE} />
                </ListItem>
              </List>
            </Nav>

            <WrapConnectButton>
              <ConnectButton accountStatus='address' chainStatus='icon' />
            </WrapConnectButton>

            <Burger isOpen={isOpenNav} onClick={handleClick} />
            <Overlay isOpen={isOpenNav} onClick={handleClickMenu} role='presentation' />
          </>
        )}
      </Container>
    </Wrap>
  )
}

export { Header }
