import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 1.6rem;
    height: 6.5rem;
    z-index: ${(props) => props.theme.zIndex.minimal};
  }
`

export const Tabs = styled.div`
  position: relative;
  padding: 0;
  width: auto;
  max-width: initial;
  min-width: 100%;
  display: flex;
  white-space: nowrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const TabWrap = styled.div<{ padding?: string; align: 'start' | 'center' | 'end' }>`
  padding: ${(props) => (props.padding ? props.padding : '0 1.6rem')};
  display: flex;
  width: auto;
  min-width: 100%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  vertical-align: inherit;
  gap: 0.4rem;
  border-bottom: 0.1rem solid ${(props) => transparentize(0.95, props.theme.color.text)};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    width: 100%;
    ${
      props.align === 'start' &&
      css`
        justify-content: flex-start;
      `
    }
    ${
      props.align === 'center' &&
      css`
        justify-content: center;
      `
    }
    ${
      props.align === 'end' &&
      css`
        justify-content: flex-end;
      `
    }
  `}
`

export const TabsContent = styled.div`
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`

export const TabInfo = styled.span`
  margin: 0;
  padding: 0.3rem 0.5rem;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.font.family.default};
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: -0.015rem;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.default};
  border-radius: 0.4rem;
`

export const TabButton = styled.span<{ isActive: boolean }>`
  position: relative;
  padding: 2.2rem 1.2rem;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  font-family: ${(props) => props.theme.font.family.default};
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.6rem;
  line-height: 1.25;

  letter-spacing: -0.032rem;
  text-decoration: none;

  color: ${(props) => props.theme.color.text};
  background-color: ${(props) => props.theme.color.transparent};

  border: none;
  transition: all 0.3s ease-out;
  opacity: ${(props) => props.theme.opacity.opacity05};
  outline: none;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: ${(props) => props.theme.color.transparent};
    z-index: ${(props) => props.theme.zIndex.minimal};
  }

  &:disabled {
    opacity: ${(props) => props.theme.opacity.opacity08};
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    color: ${(props) => props.theme.color.text};
    opacity: ${(props) => props.theme.opacity.default};
  }
  &:active:not([disabled]) {
    color: ${(props) => props.theme.color.text};
    opacity: ${(props) => props.theme.opacity.default};
  }

  ${(props) =>
    props.isActive &&
    css`
      color: ${(props) => props.theme.color.text};
      opacity: ${(props) => props.theme.opacity.default};
      &::after {
        background-color: ${(props) => props.theme.color.text};
      }
    `}
`

export const IconWrap = styled.span`
  margin: 0 0.7rem 0 0;
`
