import { Mutex } from 'async-mutex'
import axios, { AxiosError } from 'axios'
import { BaseQueryApi } from '@reduxjs/toolkit/query'

import { ApiError } from 'types/entities'

import { rootReducer } from 'state/reducers'
import { setCredentials } from 'state/auth/slice'

import { apiLinks } from 'constants/api'
import { errors } from 'constants/errors'

import {
  CustomError,
  BaseQueryArgs,
  CustomBaseQuery,
  CustomRequestInput,
  CustomBaseQueryInput,
  RefreshTokenResponse,
} from './types'

// create a new mutex
const mutex = new Mutex()

const getIsUnauthorized = (errorStatus?: any) => {
  if (errorStatus) {
    return errorStatus.toString() === errors.apiStatuses.UNAUTHORIZED.toString()
  }
  return false
}

const getError = (axiosError: any): { error: CustomError } => {
  const _err = axiosError as AxiosError<ApiError>
  return {
    error: {
      status: _err.response?.status,
      data: _err.response?.data?.message || _err.message,
    },
  }
}

const getAuth = (api: BaseQueryApi) => {
  return (api.getState() as ReturnType<typeof rootReducer>).auth
}

const customQuery = async (params: CustomRequestInput) => {
  try {
    const { headers = {}, ...args } = params.args
    if (params.token) {
      headers.Authorization = `Bearer ${params.token}`
    }

    const url = params.baseUrl + args.url
    const result = await axios({ ...args, url, headers })
    return { data: result.data }
  } catch (err) {
    throw err
  }
}

const safeRequest = async (params: CustomRequestInput) => {
  try {
    const result = await customQuery(params)
    return result
  } catch (axiosError) {
    throw getError(axiosError)
  }
}

const refreshTokenRequest = async (baseUrl: string, token: string) => {
  try {
    const args: BaseQueryArgs = { url: apiLinks.auth.refresh, method: 'PUT' }
    const result = await customQuery({ baseUrl, args, token })
    return result.data as unknown as RefreshTokenResponse
  } catch {
    return null
  }
}

export const customAuthorizedQuery =
  ({ baseUrl = '' }: CustomBaseQueryInput): CustomBaseQuery =>
  async (args, api) => {
    try {
      await mutex.waitForUnlock()

      const token = getAuth(api).accessToken
      const response = await customQuery({ baseUrl, args, token })
      return response
    } catch (axiosError) {
      const errorData = getError(axiosError)

      if (getIsUnauthorized(errorData.error.status)) {
        const refreshToken = getAuth(api).refreshToken

        if (!mutex.isLocked() && refreshToken) {
          const release = await mutex.acquire()

          try {
            const refreshResult = await refreshTokenRequest(baseUrl, refreshToken)
            api.dispatch(setCredentials(refreshResult))

            if (refreshResult) {
              return await safeRequest({ baseUrl, args, token: refreshResult.accessToken })
            }
          } finally {
            release()
          }
        } else {
          await mutex.waitForUnlock()

          const token = getAuth(api).accessToken
          const response = await safeRequest({ baseUrl, args, token })
          return response
        }
      }

      return errorData
    }
  }
