import { useCallback } from 'react'

import { useAppDispatch } from 'state/hooks'
import { removeNotification } from 'notifications/service'

type Props = {
  id: string | number
}

export function useRemoveNotification() {
  const dispatch = useAppDispatch()

  return useCallback(({ id }: Props) => {
    dispatch(removeNotification({ id }))
  }, [])
}
