import { useMemo } from 'react'

import { KycStatus, UserData } from 'types/entities'

import { CellProps, Badge } from 'components/ui'

import { Wrapper, Title, Value } from '../Style'

export function DocStatus({ row }: CellProps<UserData>) {
  const { docs } = row.original

  const value = useMemo(() => docs?.status || KycStatus.NOT_STARTED, [docs?.status])
  return (
    <Wrapper area='Status'>
      <Title>Status</Title>
      <Value>
        <Badge value={value} />
      </Value>
    </Wrapper>
  )
}
