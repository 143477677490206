import { useState } from 'react'

import { Name, Location } from 'types/entities'

import { Button } from 'components/ui'
import { notifications } from 'constants/notifications'
import { CheckTypes, Fields, ValidationResults, validations } from 'utils/validation'

import { useCallNotification } from 'notifications/hooks'
import { NotificationTypes } from 'notifications/service'

import { UserData } from './UserData'

import { Title, ButtonWrap } from './Style'

type Props = {
  onKycStart: (name: Name, location: string) => Promise<void>
}
export function KycStarter({ onKycStart }: Props) {
  const callNotification = useCallNotification()

  const [isLoading, setLoading] = useState(false)
  const [location, setLocation] = useState<Location | undefined>()
  const [validationResults, setValidationResults] = useState<ValidationResults>({})
  const [name, setName] = useState<Name>({
    firstName: '',
    lastName: '',
  })

  const handleChangLocation = (location: Location) => {
    setLocation(location)
  }

  const handleChangeName = (name: Name) => {
    setName(name)
  }

  function validatePersonalData() {
    const checks: Fields = [
      {
        key: 'firstName',
        isRequired: true,
        value: name.firstName,
        checkTypes: [CheckTypes.NAME],
      },
      {
        key: 'lastName',
        isRequired: true,
        value: name.lastName,
        checkTypes: [CheckTypes.NAME],
      },
      {
        key: 'city',
        isRequired: true,
        value: location?.city,
        checkTypes: [CheckTypes.LATIN],
      },
      {
        key: 'country',
        isRequired: true,
        value: location?.country,
        checkTypes: [CheckTypes.LATIN],
      },
      {
        key: 'region',
        isRequired: true,
        value: location?.region,
        checkTypes: [CheckTypes.LATIN],
      },
      {
        key: 'postcode',
        isRequired: true,
        value: location?.postcode,
      },
      {
        key: 'addressLine1',
        isRequired: true,
        value: location?.addressLine1,
        checkTypes: [CheckTypes.LATIN],
      },
      {
        key: 'addressLine2',
        isRequired: false,
        value: location?.addressLine2,
        checkTypes: [CheckTypes.LATIN],
      },
    ]

    const validationResult = validations.formCheck(checks)

    return {
      validationResult,
      isError: Object.values(validationResult).filter((check) => check.isError).length === 0,
    }
  }

  const onValidatePersonalData = () => {
    const { validationResult, isError } = validatePersonalData()
    setValidationResults(validationResult)
    return isError
  }

  const handleContinue = async () => {
    try {
      setLoading(true)
      const validation = onValidatePersonalData()
      if (!location || !validation) {
        return
      }

      const _location = [
        location.addressLine1,
        location.addressLine2,
        location.city,
        location.region,
        location.country,
        location.postcode,
      ]
        .map((el) => el || '')
        .join(',')
      await onKycStart(name, _location)
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.kyc(err.message) })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Title>Please complete the fields below</Title>
      <UserData
        userName={name}
        location={location}
        validationResults={validationResults}
        onChangeUserName={handleChangeName}
        onChangeLocation={handleChangLocation}
      />
      <ButtonWrap>
        <Button fullSize size='large' onClick={handleContinue} isLoading={isLoading}>
          Continue
        </Button>
      </ButtonWrap>
    </>
  )
}
