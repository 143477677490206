import { ReactNode } from 'react'

export enum NotificationTypes {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export type Notification = {
  ttl?: number
  id: number | string
  type: NotificationTypes
  message: string | ReactNode
  isHidden: boolean
}

export type CallNotification = Omit<Notification, 'id' | 'isHidden'> & {
  id?: number | string
  isHidden?: boolean
}

export type HideNotification = {
  id: number | string
}

export type RemoveNotification = {
  id: number | string
}

export type Notifications = Notification[]
