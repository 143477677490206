import styled, { css } from 'styled-components/macro'

import { Button } from 'components/ui'

export const Container = styled.div`
  margin: 3.2rem auto;
  padding: 1.6rem;
  width: 100%;
  max-width: 54.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  margin: 0 0 1rem;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 3rem;
  line-height: 1.44;
  color: ${(props) => props.theme.color.text};
`

export const PreTitle = styled.h4`
  margin: 0 0 3.2rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.33;
  color: ${(props) => props.theme.color.text};
  b {
    font-weight: ${(props) => props.theme.font.weight.bold};
    color: ${(props) => props.theme.color.default};
  }
`

export const Form = styled.form`
  width: 100%;
  max-width: 42rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const Wrap = styled.div`
  margin: 0 0 3rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1.6rem;
  grid-template-areas:
    'Input Button'
    'Message Message';
`

export const InputWrap = styled.div`
  grid-area: Input;
`

export const Resend = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1.6rem;
  grid-area: Button;
`

export const ResendButton = styled(Button)`
  width: 13.6rem;
`

export const Timer = styled.div<{ show?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  grid-area: Message;
  opacity: ${(props) => props.theme.opacity.invisible};
  transition: opacity 0.3s ease-out;
  ${(props) =>
    props.show &&
    css`
      opacity: ${(props) => props.theme.opacity.default};
    `}
`

export const TimerValue = styled.span`
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 1.4rem;
  line-height: 1.67;
  z-index: ${(props) => props.theme.zIndex.minimal};
`

export const ErrorWrap = styled.div`
  margin: 1.6rem auto 0;
  height: 3.6rem;
`

export const ErrorContent = styled.span`
  margin: 0;
  padding: 0.6rem 1rem;
  width: fit-content;
  display: flex;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.67;
  letter-spacing: 0.06rem;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.danger};
  border-radius: 0.6rem;
  z-index: ${(props) => props.theme.zIndex.minimal};
  &::first-letter {
    text-transform: uppercase;
  }
`
