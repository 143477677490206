import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { VerificationSteps, Hash } from 'types/entities'

import { PaymentType } from 'constants/app'

import { Setting, Settings, UpdateSettingInput, User, UserTransaction, UserTransactions } from './types'

export interface ApplicationState {
  data: User
  settings: Settings
  bannedAddresses: Hash[]
  transactions: UserTransactions
  verificationStep: VerificationSteps
  pendingTransactions: UserTransactions
}

const initialState: ApplicationState = {
  data: null,
  transactions: {},
  bannedAddresses: [],
  pendingTransactions: {},
  verificationStep: VerificationSteps.INIT,
  settings: {
    [Setting.PAYMENT_TYPE]: PaymentType.CRYPTO,
  },
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: ApplicationState, action: PayloadAction<User>) => {
      state.data = action.payload
    },
    setSetting: (state: ApplicationState, action: PayloadAction<UpdateSettingInput>) => {
      state.settings = { ...state.settings, [action.payload.key]: action.payload.value }
    },
    setPendingTransaction: (state: ApplicationState, action: PayloadAction<UserTransaction>) => {
      state.pendingTransactions[action.payload.id] = action.payload
    },
    setTransaction: (state: ApplicationState, action: PayloadAction<UserTransaction>) => {
      if (state.pendingTransactions[action.payload.id]) {
        delete state.pendingTransactions[action.payload.id]
      }
      state.transactions[action.payload.id] = action.payload
    },
    setBannedAddresses: (state: ApplicationState, action: PayloadAction<Hash>) => {
      if (!state.bannedAddresses.includes(action.payload)) {
        state.bannedAddresses = state.bannedAddresses.concat(action.payload)
      }
    },
    setVerificationStep: (state: ApplicationState, action: PayloadAction<VerificationSteps>) => {
      state.verificationStep = action.payload
    },
    userClearState: (state: ApplicationState) => ({ ...initialState, setting: state.settings }),
  },
})

export const {
  setUser,
  setSetting,
  setBannedAddresses,
  // transactions
  setTransaction,
  setPendingTransaction,
  // verification
  setVerificationStep,
  //
  userClearState,
} = user.actions

export default user.reducer
