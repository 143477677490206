import { Button, BurgerLine } from './Style'

type Props = {
  isOpen: boolean
}

const BtnBurger = ({ isOpen, ...etc }: Props) => (
  <Button aria-label={isOpen ? 'Close menu' : 'Open menu'} {...etc}>
    <BurgerLine active={isOpen} />
    <BurgerLine active={isOpen} />
    <BurgerLine active={isOpen} />
  </Button>
)

export { BtnBurger }
