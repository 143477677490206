import { useCallback } from 'react'

import { Notification } from 'notifications/service'
import { useRemoveNotification } from 'notifications/hooks'

import { NotificationStatus } from './NotificationStatus'

export function NotificationFactory(notification: Notification) {
  const removeNotification = useRemoveNotification()

  const onClose = useCallback(() => {
    removeNotification({ id: notification.id })
  }, [notification.id, removeNotification])

  return <NotificationStatus {...notification} onClose={onClose} />
}
