import { useCallback } from 'react'

import { Name } from 'types/entities'

import { appConstants } from 'constants/app'
import { notifications } from 'constants/notifications'

import { NotificationTypes } from 'notifications/service'
import { useCallNotification } from 'notifications/hooks'

type Params = {
  name: Name
  token: string
  location: string
  onCheckDocs: (data: DocsResult) => Promise<void>
  onStartKyc: (name: Name, location: string) => Promise<void>
}

export function useInitExternalKycSDK() {
  const callNotification = useCallNotification()

  return useCallback(
    (params: Params) => {
      return window.ComplyCube.mount({
        token: params.token,
        disableClientAnalytics: true,
        stages: appConstants.KYC_STAGES,
        branding: {
          appearance: {
            primaryButtonColor: '#3b6355',
          },
        },
        onComplete(data) {
          params.onCheckDocs(data)
        },
        onError({ type, message }: { type: string; message: string }) {
          if (type === 'token_expired') {
            if (params.location) {
              params.onStartKyc(params.name, params.location)
            }
          } else {
            callNotification({
              type: NotificationTypes.ERROR,
              message: notifications.errors.kyc(`${type} - ${message}`),
            })
          }
        },
      })
    },
    [callNotification],
  )
}
