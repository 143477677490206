import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

import { numbers } from 'constants/variables'
import { fadeInLeft, fadeOutLeft } from 'utils'
import { NotificationTypes } from 'notifications/service'

export const Wrapper = styled.div<{ isHidden: boolean }>`
  padding: 0.8rem 1.4rem;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  background-color: ${(props) => props.theme.color.white};
  border: 0.1rem solid ${(props) => transparentize(0.8, props.theme.color.black)};
  border-radius: 1.2rem;
  cursor: pointer;
  animation: ${numbers.NOTIFY_REMOVE_DELAY}ms ease-in-out 0s 1 normal none running ${fadeInLeft};
  z-index: ${(props) => props.theme.zIndex.overlay};
  ${(props) =>
    props.isHidden &&
    css`
      animation: ${numbers.NOTIFY_REMOVE_DELAY}ms ease-in-out 0s 1 normal none running ${fadeOutLeft};
    `}
`

export const IconWrap = styled.span<{ type: NotificationTypes }>`
  flex: 0 0 auto;
  ${(props) =>
    props.type === 'success' &&
    css`
      color: ${(props) => props.theme.color.success};
    `}
  ${(props) =>
    props.type === 'info' &&
    css`
      color: ${(props) => props.theme.color.risky};
    `}
  ${(props) =>
    props.type === 'error' &&
    css`
      color: ${(props) => props.theme.color.danger};
    `}
`

export const Message = styled.h5`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.2rem;
  line-height: 1.57rem;
  color: ${(props) => props.theme.color.text};
`
