import { MouseEvent, useRef } from 'react'
import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

import { ripple } from 'utils/animations'

import { StyledButtonProps } from './@types'
import { getSize } from './helpers'

export const ButtonWithType = ({ onClick, children, submit, ...props }: StyledButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const btnType = submit ? 'submit' : 'button'

  const handleClick = (event: MouseEvent<Element>) => {
    if (typeof onClick === 'function') {
      onClick(event)

      if (buttonRef && buttonRef.current) {
        const element = document.createElement('span')

        const pos = buttonRef.current.getBoundingClientRect()

        const x = event.clientX - pos.left
        const y = event.clientY - pos.top

        element.style.left = `${x / 10}rem`
        element.style.top = `${y / 10}rem`
        element.classList.add('wave')

        buttonRef.current.appendChild(element)

        const TIME_OUT = 3000

        setTimeout(() => {
          element.remove()
        }, TIME_OUT)
      }
    }
  }
  return (
    <button {...props} type={btnType} ref={buttonRef} onClick={handleClick}>
      {children}
    </button>
  )
}

export const StyledButton = styled(ButtonWithType)`
  position: relative;

  margin: 0;
  padding: ${({ size }) => getSize(size).padding};

  height: fit-content;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 0.8rem;

  font-family: ${(props) => props.theme.font.family.default};
  font-weight: ${(props) => props.theme.font.weight.bold};

  font-size: ${({ size }) => getSize(size).fontSize};
  line-height: ${({ size }) => getSize(size).lineHeight};
  letter-spacing: ${({ size }) => getSize(size).letterSpacing};

  text-decoration: none;
  text-transform: ${({ isuppercase }) => (isuppercase === 'true' ? 'uppercase' : 'none')};

  border: 0.1rem solid ${(props) => props.theme.color.transparent};

  transition: all 0.4s ease-out;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  overflow: hidden;
  outline: none;
  z-index: ${(props) => props.theme.zIndex.minimal};

  &:disabled {
    opacity: ${(props) => props.theme.opacity.opacity05};
  }

  .wave {
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: ${(props) => props.theme.color.buttonWave};
    border-radius: 50%;
    transform: translateX(-100%) translateY(-100%);
    animation-name: ${ripple};
    animation-duration: 1.4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: ${(props) => props.theme.zIndex.negative};
  }

  ${(props) =>
    props.type === 'default' &&
    css`
      color: ${(props) => props.theme.color.white};
      background-color: ${(props) => props.theme.color.default};
      border-radius: 6em;
      &:disabled {
        opacity: ${(props) => props.theme.opacity.opacity05};
      }
      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        box-shadow: 0 1rem 1.8rem -0.9rem ${(props) => transparentize(0.63, props.theme.color.black)};
      }
    `}

  ${(props) =>
    props.type === 'primary' &&
    css`
      color: ${(props) => props.theme.color.default};
      background-color: ${(props) => props.theme.color.defaultLight};
      border-radius: 6em;
      &:disabled {
        opacity: ${(props) => props.theme.opacity.opacity05};
      }
      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        box-shadow: 0 1rem 1.8rem -0.9rem ${(props) => transparentize(0.63, props.theme.color.black)};
      }
    `}

  ${(props) =>
    props.type === 'outline' &&
    css`
      color: ${(props) => props.theme.color.default};
      background-color: ${(props) => props.theme.color.white};
      border: 0.1rem solid ${(props) => props.theme.color.default};
      border-radius: 6em;
      &:disabled {
        opacity: ${(props) => props.theme.opacity.opacity05};
      }
      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        box-shadow: 0 1rem 1.8rem -0.9rem ${(props) => transparentize(0.63, props.theme.color.black)};
      }
    `}

  ${(props) =>
    props.type === 'status' &&
    css`
      color: ${(props) => props.theme.color.default};
      background-color: ${(props) => props.theme.color.white};
      border-radius: 6em;
      border-color: ${(props) => props.theme.color.default};
      &:disabled {
        opacity: ${(props) => props.theme.opacity.opacity05};
      }
      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        box-shadow: 0 1rem 1.8rem -0.9rem ${(props) => transparentize(0.63, props.theme.color.black)};
      }
    `}

  ${(props) =>
    props.type === 'link' &&
    css`
      padding: 0;
      font-weight: ${(props) => props.theme.font.weight.regular};
      color: ${(props) => props.theme.color.text};
      background-color: ${(props) => props.theme.color.transparent};
      border: none;
      border-radius: 0;
      .wave {
        display: none;
      }
      &:disabled {
        opacity: ${(props) => props.theme.opacity.opacity05};
      }
      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        color: ${(props) => props.theme.color.default};
      }
    `}

  ${(props) =>
    props.type === 'symbol' &&
    css`
      color: ${(props) => props.theme.color.white};
      background-color: ${(props) => props.theme.color.default};
      border: none;
      border-radius: 2rem;
      &:disabled {
        opacity: ${(props) => props.theme.opacity.opacity05};
      }
      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        background-color: ${(props) => transparentize(0.15, props.theme.color.black)};
      }
    `}

  ${(props) =>
    props.type === 'block' &&
    css`
      flex-direction: column;
      gap: 1.2rem;
      color: ${(props) => props.theme.color.white};
      background-color: ${(props) => transparentize(0.95, props.theme.color.white)};
      border: 0.1rem solid ${(props) => transparentize(0.8, props.theme.color.default)};
      border-radius: 1.6rem;
      &:disabled {
        opacity: ${(props) => props.theme.opacity.opacity05};
      }
      &:hover:not([disabled]),
      &:focus:not([disabled]) {
        border-color: ${(props) => props.theme.color.default};
      }
      &:active:not([disabled]) {
        border-color: ${(props) => props.theme.color.default};
      }
    `}

  ${(props) =>
    props.fullsize === 'true' &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.isloading === 'true' &&
    css`
      position: relative;
      pointer-events: none;
      user-select: none;
      gap: 1.2rem;
      font-weight: ${(props) => props.theme.font.weight.bold};
      line-height: ${({ size }) => getSize(size).lineHeight};
      color: ${(props) => props.theme.color.white};
      background-color: ${(props) => transparentize(0.8, props.theme.color.default)};
      overflow: hidden;
    `}
`
