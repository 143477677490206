import { useEffect, useMemo, useRef, useState } from 'react'

import { Name } from 'types/entities'

import { useInitExternalKycSDK } from 'hooks/app'

import { useUpdateUser } from 'state/user/hooks'
import { useOnCheckExternalKyc, useOnExternalKycStart, useToken } from 'state/kyc/hooks'

import { FormLoader } from 'components/ui'
import { notifications } from 'constants/notifications'

import { useCallNotification } from 'notifications/hooks'
import { NotificationTypes } from 'notifications/service'

import { KycStarter } from './KycStarter'
import { Container } from './Style'

type ComplyCube = {
  unmount: VoidFunction
}

export function ExternalKyc() {
  const complyCube = useRef<null | ComplyCube>(null)

  const token = useToken()
  const callNotification = useCallNotification()
  const initExternalKycSDK = useInitExternalKycSDK()

  const { refetch, ...userRequest } = useUpdateUser()
  const { onStart, ...kycRequest } = useOnExternalKycStart()
  const { onCheckExternalKyc, ...checkKycRequest } = useOnCheckExternalKyc()

  const [location, setLocation] = useState('')
  const [name, setName] = useState<Name>({ firstName: '', lastName: '' })

  useEffect(() => {
    if (token) {
      startVerification()
    }
    return () => {
      if (complyCube.current) {
        complyCube.current?.unmount()
      }
    }
  }, [token])

  const onCheckDocs = async ({ faceCapture, documentCapture }: DocsResult) => {
    try {
      await onCheckExternalKyc({
        livePhotoId: faceCapture.livePhotoId,
        documentCaptureId: documentCapture.documentId,
      })
      await refetch()
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.kyc(err.message) })
    }
  }

  const handleStartKyc = async (name: Name, location: string) => {
    setName(name)
    setLocation(location)
    await onStart(name.firstName, name.lastName, location)
    await refetch()
  }

  const startVerification = () => {
    complyCube.current = initExternalKycSDK({
      token,
      name,
      location,
      onCheckDocs,
      onStartKyc: handleStartKyc,
    })
  }

  const isLoading = useMemo(
    () => userRequest.isLoading || kycRequest.isLoading || checkKycRequest.isLoading,
    [userRequest.isLoading, kycRequest.isLoading, checkKycRequest.isLoading],
  )

  return (
    <Container maxWidth='54.2rem'>
      {isLoading && <FormLoader />}
      {!token && !isLoading && <KycStarter onKycStart={handleStartKyc} />}
      <div id='complycube-mount'></div>
    </Container>
  )
}
