import styled from 'styled-components/macro'

export const CloseButton = styled.button<{ top?: string }>`
  position: absolute;
  right: 2rem;
  top: ${(props) => (props.top ? props.top : '1.6rem')};
  margin: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  color: ${(props) => props.theme.color.black};
  background-color: ${(props) => props.theme.color.transparent};
  border: none;
  outline: none;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 1.2rem;
    width: 2.4rem;
    height: 0.3rem;
    background-color: currentColor;
    transform: rotate(-45deg);
    z-index: ${(props) => props.theme.zIndex.preloader};
  }
  &::after {
    content: '';
    position: absolute;
    top: 1.2rem;
    width: 2.4rem;
    height: 0.3rem;
    background-color: currentColor;
    transform: rotate(45deg);
    z-index: ${(props) => props.theme.zIndex.preloader};
  }
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    top: ${props.top ? props.top : '3.2rem'};
  `};
`
