import styled, { css } from 'styled-components/macro'

export const Container = styled.div<{ maxWidth: string }>`
  position: relative;
  margin: 3.2rem auto;
  padding: 1.6rem;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
`

export const Title = styled.h1`
  margin: 0 0 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2.4rem;
  line-height: 1.44;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
     font-size: 3.2rem;
  `};
`

export const PreTitle = styled.h4`
  margin: 0 0 3.2rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.6rem;
  line-height: 1.33;
  text-align: center;
  color: ${(props) => props.theme.color.textSecondary};
  a {
    color: ${(props) => props.theme.color.default};
  }
`

export const Wrap = styled.div<{ type?: string }>`
  margin: 0 0 3.2rem;
  display: flex;
  ${(props) =>
    props.type === 'selectKYC' &&
    css`
      flex-direction: column;
      justify-content: center;
    `}
`

export const ErrorWrap = styled.div`
  margin: 1.6rem 0 0;
  min-height: 3.6rem;
`

export const ErrorContent = styled.span`
  margin: 0;
  padding: 0.6rem 1rem;
  width: fit-content;
  display: flex;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.67;
  letter-spacing: 0.06rem;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.danger};
  border-radius: 0.6rem;
  z-index: ${(props) => props.theme.zIndex.minimal};
  &::first-letter {
    text-transform: uppercase;
  }
`

export const FormWrap = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const ButtonWrap = styled.div`
  width: 100%;
  max-width: 37.6rem;
`
