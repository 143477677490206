import styled from 'styled-components/macro'
import { transparentize } from 'polished'

import { Button } from 'components/ui'

export const Section = styled.section`
  grid-area: DocsTable;
  padding: 0;
  height: fit-content;
  background-color: ${(props) => transparentize(0.9, props.theme.color.default)};
  border-radius: 0.8rem;
  overflow: hidden;
`

export const Pair = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabsContent = styled.div`
  margin: 0;
  padding: 2rem 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: -0.015rem;
  color: ${(props) => props.theme.color.white};
  overflow-x: hidden;
  overflow-y: auto;
  overflow-wrap: anywhere;
`

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem;
`

export const Subtitle = styled.p`
  margin: 1rem;
  font-weight: 500;
`

export const ErrorContent = styled.span`
  margin: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.textTertiary};
`

export const StyledButton = styled(Button)`
  width: 100%;
`
