import { UserData } from 'types/entities'

import { CellProps } from 'components/ui'
import { Wrapper, Title, Value } from '../Style'

export function Email({ row }: CellProps<UserData>) {
  const { email } = row.original

  return (
    <Wrapper area='Email'>
      <Title>Email</Title>
      <Value>{email}</Value>
    </Wrapper>
  )
}
