import { SkeletonElementStyle } from './Style'

type Props = {
  width?: string
  height?: string
  borderRadius?: string
}

const SkeletonElement = ({ width = '100%', height = '100%', borderRadius = '1.2rem' }: Props) => {
  return <SkeletonElementStyle width={width} height={height} borderRadius={borderRadius} />
}

export { SkeletonElement }
