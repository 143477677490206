import { Kyc } from 'types/entities'

import { CellProps } from 'components/ui'
import { Wrapper, Title, Value } from '../Style'

export function Location({ row }: CellProps<Kyc>) {
  const { location } = row.original

  return (
    <Wrapper area='Location'>
      <Title>Location</Title>
      <Value>{location}</Value>
    </Wrapper>
  )
}
