import { useMemo } from 'react'

import { Links } from 'constants/link'
import { CustomLink, Input } from 'components/ui'

import { PasswordFieldProps } from './types'

import { Stat } from './Stat'
import { Container, ContainerInputs, ForgotPass, List } from './Style'

export function PasswordField({
  label,
  name,
  stats,
  value,
  onChange,
  confirmValue,
  autoComplete,
  ariaDescribedby,
  onChangeConfirm,
  isPasswordError,
  isConfirmPasswordError,
  confirmPasswordErrorMessage,
  forgotPassword = false,
}: PasswordFieldProps) {
  const _stats = useMemo(() => {
    if (!stats) {
      return null
    }
    return Object.values(stats)
  }, [stats])

  const withConfirm = useMemo(
    () => typeof onChangeConfirm === 'function' && confirmValue !== undefined,
    [onChangeConfirm, confirmValue],
  )

  return (
    <Container>
      <ContainerInputs>
        <Input
          type='password'
          placeholder='Enter password'
          name={name}
          label={label}
          value={value}
          error={isPasswordError}
          autoComplete={autoComplete}
          aria-describedby={ariaDescribedby}
          onChange={onChange}
        />
        {withConfirm && (
          <Input
            name={name}
            type='password'
            label='Confirm password'
            placeholder='Re-enter password'
            value={confirmValue}
            onChange={onChangeConfirm}
            error={isConfirmPasswordError}
            errorMessage={confirmPasswordErrorMessage}
          />
        )}
        {forgotPassword && (
          <ForgotPass>
            <CustomLink url={Links.RESTORE_PASSWORD} title='Forgot password?' />
          </ForgotPass>
        )}
      </ContainerInputs>

      {_stats && (
        <List id='password-constraints'>
          {_stats.map((stat, index) => (
            <Stat key={index} stat={stat} />
          ))}
        </List>
      )}
    </Container>
  )
}
