import { SelectSize } from '../@types'

export const getSize = (size: SelectSize) =>
  ({
    default: {
      padding: '1rem 0.8rem 1rem 1.2rem',
      height: '4.4rem',
      paddingMenu: '1rem 1.2rem',
      fontSize: '1.6rem',
      lineHeight: 1.43,
    },
    large: {
      padding: '2rem 2.2rem',
      height: '7.2rem',
      paddingMenu: '2rem 2.2rem',
      fontSize: '1.8rem',
      lineHeight: 1.43,
    },
    small: {
      padding: '1rem 2rem',
      height: '4.4rem',
      paddingMenu: '0.6rem 0.8rem',
      fontSize: '1.4rem',
      lineHeight: 1.43,
    },
    mini: {
      padding: '1rem 0.8rem',
      height: '4.4rem',
      paddingMenu: '0.6rem',
      fontSize: '1.4rem',
      lineHeight: 1.43,
    },
  }[size])
