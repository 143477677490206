import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'

import { Balances } from 'types/entities'

import { isAddress } from 'utils/crypto'
import { getMulticall } from 'contracts'

import { MulticallArgs } from './types'
import { formatBalances, getMulticallParams } from './helpers'

const multicallBaseQuery = (): BaseQueryFn<MulticallArgs, Balances, Error> => async (params) => {
  try {
    const isEmptyTokens = params.tokens.filter((_token) => Boolean(_token)).length === 0
    if (!params.customer || isEmptyTokens || !isAddress(params.customer)) {
      return { data: {} }
    }

    const multicallContract = getMulticall(params.chainId, params.rpcUrl)
    const multicallParams = getMulticallParams(params, multicallContract)

    const { returnData } = await multicallContract.callStatic.multicall(multicallParams)

    const balances = returnData.reduce<Balances>(
      (acc, curr, index) => formatBalances(acc, curr, params.tokens[index]),
      {},
    )

    return { data: balances }
  } catch (error) {
    return { error }
  }
}

export const balancesApi = createApi({
  reducerPath: 'balances',
  baseQuery: multicallBaseQuery(),
  refetchOnReconnect: true,
  endpoints: (build) => ({
    getBalances: build.query<Balances, MulticallArgs>({
      query: (params: MulticallArgs) => params,
    }),
  }),
})

export const { useGetBalancesQuery } = balancesApi
