import styled, { css } from 'styled-components/macro'
import { darken, transparentize } from 'polished'
import { Button } from 'components/ui'

export const Wrap = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  gap: 0.4rem;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

export const LoadingWrap = styled.div<{ position: string }>`
  display: flex;
  justify-content: ${(props) => props.position};
`

export const Balance = styled.div`
  margin: 0;
  grid-area: balance;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${(props) => transparentize(0.3, props.theme.color.text)};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    font-size: 1.4rem;
  `};
`

export const MaxBtn = styled.button`
  margin: 0 0 0 0.8rem;
  padding: 0.3rem 0.9rem;
  display: flex;
  align-items: center;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.2rem;
  line-height: 1.44;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.default};
  border: none;
  border-radius: 2.4rem;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  outline: none;
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    color: ${(props) => props.theme.color.text};
    background-color: ${(props) => props.theme.color.defaultLight};
  }
  &:active:not([disabled]) {
    background-color: ${(props) => darken(0.2, props.theme.color.defaultLight)};
  }
`

export const TokenSelectWrap = styled(Button)`
  margin: 0 0 0 -0.8rem;
  width: auto;
  gap: 0.4rem;
  flex: 0 0 auto;
  text-transform: uppercase;
`

export const ChevronWrap = styled.span<{ isToggled?: boolean }>`
  opacity: ${(props) => props.theme.opacity.opacity05};
  transition: transform 0.3s ease-in-out;
  ${(props) =>
    props.isToggled &&
    css`
      transform: rotate(-180deg);
    `}
`

export const TokenList = styled.ul`
  list-style: none;
  left: 0;
  top: calc(100% + 0.4rem);
  position: absolute;
  margin: 0;
  padding: 0;
  width: 51rem;
  background-color: ${(props) => props.theme.color.white};
  border: 0.1rem solid ${(props) => props.theme.color.defaultColor};
  border-radius: 1.2rem;
  z-index: ${(props) => props.theme.zIndex.navigation};
`

export const TokenListItem = styled.li`
  margin: 0;
  padding: 0;
`
