import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

export const Container = styled.div`
  position: relative;
  margin: 3.2rem auto;
  padding: 1.6rem;
  width: 100%;
  max-width: 52rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  margin: 0 0 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2.4rem;
  line-height: 1.44;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
     font-size: 3.2rem;
  `};
`

export const StatusWrap = styled.div`
  margin: 0 auto 1.6rem;
  padding: 0;
`

export const StatusIconWrap = styled.div`
  flex: 0 0 auto;
`

export const StatusContent = styled.div<{ type?: string }>`
  padding: 0.8rem 1.4rem;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: ${(props) => props.theme.color.default};
  background-color: ${(props) => transparentize(0.9, props.theme.color.default)};
  border: 0.1rem solid ${(props) => transparentize(0.2, props.theme.color.default)};
  border-radius: 1.2rem;
  z-index: ${(props) => props.theme.zIndex.overlay};
  ${(props) =>
    props.type === 'risky' &&
    css`
      color: ${(props) => props.theme.color.risky};
      background-color: ${(props) => transparentize(0.9, props.theme.color.risky)};
      border: 0.1rem solid ${(props) => transparentize(0.2, props.theme.color.risky)};
    `}
  ${(props) =>
    props.type === 'danger' &&
    css`
      color: ${(props) => props.theme.color.danger};
      background-color: ${(props) => transparentize(0.9, props.theme.color.danger)};
      border: 0.1rem solid ${(props) => transparentize(0.2, props.theme.color.danger)};
    `}
`

export const StatusText = styled.p`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.57rem;
  color: ${(props) => props.theme.color.text};
`

export const ContainerVerification = styled.div`
  margin: 0 0 1.6rem;
  padding: 3rem 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 2.4rem;
  box-shadow: 0 0.4rem 1.6rem 0 ${(props) => props.theme.color.elementBorder};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
     padding: 3rem 2.4rem;
  `};
`

export const ErrorWrap = styled.div`
  margin: 1.6rem 0 0;
`

export const ErrorContent = styled.span`
  margin: 0;
  padding: 0.6rem 1rem;
  width: fit-content;
  display: flex;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.67;
  letter-spacing: 0.06rem;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.danger};
  border-radius: 0.6rem;
  z-index: ${(props) => props.theme.zIndex.minimal};
  &::first-letter {
    text-transform: uppercase;
  }
`

export const CheckboxWrapper = styled.div<{ isError?: boolean }>`
  margin: 0 0 2rem;
  padding: 0 1.2rem;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: ${(props) => transparentize(0.9, props.theme.color.default)};
  border-radius: 1.2rem;
  border: 0.1rem solid
    ${(props) => transparentize(0.2, props.isError ? props.theme.color.danger : props.theme.color.transparent)};
`

export const CheckboxLabel = styled.label`
  width: 100%;
  cursor: pointer;
`

export const CheckboxText = styled.p`
  margin: 0;
  padding: 1.4rem 1rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.33;
  color: ${(props) => props.theme.color.text};
  a {
    font-weight: ${(props) => props.theme.font.weight.regular};
    color: ${(props) => props.theme.color.default};
    text-decoration: underline;
  }
`
