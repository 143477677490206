import { useCallback } from 'react'

import * as Sentry from '@sentry/react'

import { walletErrorsParser } from 'utils/errors'

const isEnabled = process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_DSN
export function useSentryError() {
  return useCallback((key: string, err: Error) => {
    if (isEnabled) {
      if (walletErrorsParser(err.message)) {
        return
      }
      Sentry.captureException(`${key}: ${err.message}`)
    }
  }, [])
}
