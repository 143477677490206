const Ghost = () => (
  <svg viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M8 10a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm6 0a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H15a1 1 0 0 1-1-1Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M5.636 3.636A9 9 0 0 1 21 10v12a1 1 0 0 1-1.707.707L17 20.414l-1.793 1.793a1 1 0 0 1-1.414 0L12 20.414l-1.793 1.793a1 1 0 0 1-1.414 0L7 20.414l-2.293 2.293A1 1 0 0 1 3 22V10a9 9 0 0 1 2.636-6.364ZM12 3a7 7 0 0 0-7 7v9.586l1.293-1.293a1 1 0 0 1 1.414 0L9.5 20.086l1.793-1.793a1 1 0 0 1 1.414 0l1.793 1.793 1.793-1.793a1 1 0 0 1 1.414 0L19 19.586V10a7 7 0 0 0-7-7Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Ghost }
