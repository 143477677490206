import { SHA3 } from 'sha3'

import { isEmpty } from 'utils/lodash'

export async function sleep(ms: number) {
  return await new Promise((resolve) => setTimeout(resolve, ms))
}

export function hasValue(value: any) {
  return !isEmpty(value)
}

export function generateId(): number {
  const base = 10
  const exponent = 3

  const date = Date.now() * Math.pow(base, exponent)
  const extra = Math.floor(Math.random() * Math.pow(base, exponent))
  return date + extra
}

// ToDo think about
export function getApiError(error: any) {
  let message = 'unknown error'

  if (!error) {
    return undefined
  }

  if ('data' in error) {
    // ToDo fix it
    message = error.data.toString()
  }

  return message
}

export function getHash(value: string) {
  const hash = new SHA3(512)

  hash.update(value)
  hash.digest('hex')

  return hash.digest('hex')
}

// export const checkKycType = (data: CheckTypeInput) => {
//   if (data.reviewStatus === ReviewStatus.PENDING) {
//     return KycStatus.PENDING
//   }
//
//   switch (data.reviewResult?.reviewAnswer) {
//     case ReviewStatus.PENDING:
//       return KycStatus.PENDING
//     case ReviewStatus.SUCCESS:
//       return KycStatus.COMPLETED
//     case ReviewStatus.FAILED:
//       return KycStatus.DECLINED
//     default:
//       return KycStatus.NOT_STARTED
//   }
// }

export const cancellablePromise = <R>(promise: Promise<R>) => {
  const isCancelled = { value: false }

  const wrappedPromise = new Promise<R>((res, rej) =>
    promise
      .then((d) => (isCancelled.value ? rej('Canceled') : res(d)))
      .catch((e) => rej(isCancelled.value ? 'Canceled' : e)),
  )

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled.value = true
    },
  }
}

export function getPaginationItems({
  current,
  total = 1,
  siblingCount = 1,
}: {
  total: number
  current: number
  siblingCount: number
}) {
  const range = (start: number, end: number) => {
    const length = end - start + 1

    return Array.from({ length }, (_, idx) => idx + start)
  }

  // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
  const totalPageNumbers = siblingCount + 5
  const defaultPagination = range(1, total)

  if (totalPageNumbers >= total) {
    return defaultPagination
  }

  const leftSiblingIndex = Math.max(current - siblingCount, 1)
  const rightSiblingIndex = Math.min(current + siblingCount, total)

  const shouldShowLeftDots = leftSiblingIndex > 2
  const shouldShowRightDots = rightSiblingIndex < total - 2

  const firstPageIndex = 1
  const lastPageIndex = total

  const defaultSibling = 3 + 2 * siblingCount

  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftRange = range(1, defaultSibling)

    return [...leftRange, null, total]
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightRange = range(total - defaultSibling + 1, total)
    return [firstPageIndex, null, ...rightRange]
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
    const middleRange = range(leftSiblingIndex, rightSiblingIndex)
    return [firstPageIndex, null, ...middleRange, null, lastPageIndex]
  }

  return defaultPagination
}
