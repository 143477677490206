import styled from 'styled-components/macro'

export const Section = styled.section`
  padding: 3.2rem 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.transparent};
`

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 55.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
  z-index: ${(props) => props.theme.zIndex.minimal};
`

export const Title = styled.h2`
  margin: 0 0 3.2rem;
  font-family: ${(props) => props.theme.font.family.primary};
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2rem;
  line-height: 1.44;
  color: ${(props) => props.theme.color.text};
  ${(props) => props.theme.breakpoints.greaterThan('xsm')`
     margin: 0 0 5rem;
     font-size: 3rem;
  `};
`

export const Text = styled.p`
  margin: 2rem 0 3.2rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.33;
  text-align: center;
  color: ${(props) => props.theme.color.text};
`
