const Copy = () => (
  <svg viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M10 8.8A1.2 1.2 0 0 0 8.8 10v10a1.2 1.2 0 0 0 1.2 1.2h10a1.2 1.2 0 0 0 1.2-1.2V10A1.2 1.2 0 0 0 20 8.8H10ZM7.2 10A2.8 2.8 0 0 1 10 7.2h10a2.8 2.8 0 0 1 2.8 2.8v10a2.8 2.8 0 0 1-2.8 2.8H10A2.8 2.8 0 0 1 7.2 20V10Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M4 2.8c-.658 0-1.2.542-1.2 1.2v10c0 .658.542 1.2 1.2 1.2a.8.8 0 0 1 0 1.6A2.806 2.806 0 0 1 1.2 14V4c0-1.542 1.258-2.8 2.8-2.8h10c1.542 0 2.8 1.258 2.8 2.8a.8.8 0 0 1-1.6 0c0-.658-.542-1.2-1.2-1.2H4Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Copy }
