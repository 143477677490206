import { useMemo, useState } from 'react'

import { BigNumber } from 'ethers'
import { keccak256, toHex } from 'viem'
import { useAccount, useNetwork } from 'wagmi'

import { Hash, Token } from 'types/entities'

import { useChainData, useRequestController } from 'hooks/app'

import { UserOperationsStatuses, UserOperationsTypes } from 'state/user/types'
import { usePendingTransactions, useSetBannedAddresses, useSetPendingTransaction } from 'state/user/hooks'

import { Button } from 'components/ui'
import { ConnectButton } from 'components/elements'
import { ButtonSize } from 'components/ui/Button/@types'

import { crypto } from 'constants/variables'
import { notifications } from 'constants/notifications'
import { PaymentType, Recipients } from 'constants/app'

import { isEther, toChecksumAddress } from 'utils/crypto'

import { useOpenModal } from 'modal'
import { newConfirmDonationModal } from 'modals'

import { Wrap } from './Style'

type Donation = {
  token: Token
  amount: string
  address?: Hash
  recipient: Recipients
  isBalancesLoading: boolean
}

type Props = {
  text: string
  size?: ButtonSize
  isError?: boolean
  donation: Donation
  errorText?: string
  isLoading?: boolean
  isDisabled?: boolean
  loadingText?: string
  withConnectButton?: boolean
}

export function DonationButton({
  text,
  isError,
  donation,
  errorText,
  isLoading,
  isDisabled,
  loadingText,
  size = 'large',
  withConnectButton = true,
}: Props) {
  const { chain } = useNetwork()
  const { address } = useAccount()
  const { chainId } = useChainData()

  const openModal = useOpenModal()

  const setBannedAddresses = useSetBannedAddresses()
  const pendingTransactions = usePendingTransactions()
  const onPendingTransaction = useSetPendingTransaction()

  const { donate, ...requestControllerState } = useRequestController(donation.token)

  const [pendingTransaction, setPendingTransaction] = useState('')

  const _disabled = useMemo(() => isError || isDisabled, [isError, isDisabled])
  const _isEther = useMemo(() => isEther(donation.token.address), [donation.token])
  const _isPending = useMemo(
    () => Boolean(pendingTransactions[pendingTransaction]),
    [pendingTransaction, pendingTransactions],
  )
  const _isLoading = useMemo(
    () => isLoading || requestControllerState.isLoading || _isPending,
    [isLoading, requestControllerState.isLoading, _isPending],
  )

  const _text = useMemo(() => {
    if (_isLoading) {
      return loadingText == undefined ? 'Loading' : loadingText
    }
    if (isError && errorText) {
      return errorText
    }
    return text
  }, [text, loadingText, errorText, _isLoading, isError])

  const value = useMemo(
    () => (_isEther ? BigNumber.from(donation.amount).toBigInt() : crypto.BG_ZERO.toBigInt()),
    [_isEther, donation.amount],
  )
  const handleDonate = async (
    signature: string,
    gas?: bigint,
    maxFeePerGas?: bigint,
    maxPriorityFeePerGas?: bigint,
  ) => {
    try {
      if (!address) {
        return
      }

      setPendingTransaction('')
      const _bgAmount = BigNumber.from(donation.amount)
      const _tokenAddress = toChecksumAddress(donation.token.address) as Hash

      const { hash } = await donate({
        gas,
        value,
        maxFeePerGas,
        maxPriorityFeePerGas,
        args: [_tokenAddress, _bgAmount.toBigInt(), keccak256(toHex(donation.recipient)), signature as Hash],
      })

      handleSetPendingTransaction(hash, _bgAmount._hex, UserOperationsTypes.DONATE)
      return hash
    } catch (err) {
      if (err.message.toLowerCase().includes('risk') && donation.address) {
        setBannedAddresses(donation.address)
      }
      throw new Error(notifications.errors.donationTx(err.message))
    }
  }

  const handleSetPendingTransaction = (hash: string, value: string, operation: UserOperationsTypes) => {
    setPendingTransaction(hash)
    onPendingTransaction({
      value,
      chainId,
      id: hash,
      operation,
      txHash: hash,
      wallet: address,
      timestamp: Date.now(),
      address: donation.address,
      currency: donation.token.address,
      paymentType: PaymentType.CRYPTO,
      status: UserOperationsStatuses.PENDING,
    })
  }

  const handleClick = () => {
    if (!address) {
      return
    }

    openModal(
      newConfirmDonationModal({
        action: handleDonate,
        donation: {
          address,
          token: donation.token,
          amount: donation.amount,
          recipient: donation.recipient,
        },
      }),
    )
  }

  return (
    <Wrap>
      {(!address || chain?.unsupported) && withConnectButton ? (
        <ConnectButton />
      ) : (
        <Button fullSize size={size} onClick={handleClick} disabled={_disabled} isLoading={_isLoading}>
          {_text}
        </Button>
      )}
    </Wrap>
  )
}
