import { Layout, Main } from 'components/layouts'
import { Header } from 'components/elements'

import Router from './Router'

function App() {
  return (
    <Layout>
      <Header />
      <Main>
        <Router />
      </Main>
    </Layout>
  )
}

export default App
