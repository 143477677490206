import { Hash } from 'types/entities'
import { SupportedChainId } from 'constants/chains'

export const DONATIONS: Record<SupportedChainId, Hash> = {
  [SupportedChainId.MAINNET]: '0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa',
  [SupportedChainId.GOERLI]: '0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa',
}

export const MULTICALL: Record<SupportedChainId, Hash> = {
  [SupportedChainId.MAINNET]: '0x1F98415757620B543A52E61c46B32eB19261F984',
  [SupportedChainId.GOERLI]: '0x1F98415757620B543A52E61c46B32eB19261F984',
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const ETH_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
