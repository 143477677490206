import { useCallback } from 'react'

import { AppState } from 'state'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { RefreshTokenResponse } from 'state/api/types'
import {
  useSignInMutation,
  useSignUpMutation,
  useLogoutMutation,
  useVerifyEmailMutation,
  useCreateManagerMutation,
  useSendVerifyEmailCodeMutation,
  // useRestorePasswordMutation,
  // useVerifyRestorePasswordMutation,
  // useGetRestorePasswordCodeMutation,
} from 'state/auth/api'

import { getApiError, getHash } from 'utils/helpers'

import { setCredentials, clearState } from './slice'

export function useSignInRequest() {
  const [signIn, requestState] = useSignInMutation()
  const setCredentials = useSetCredentials()

  const onSignIn = useCallback(
    async (email: string, password: string) => {
      try {
        const passwordHash = getHash(password.trim())
        const response = await signIn({ email: email.trim(), passwordHash }).unwrap()
        setCredentials(response)
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [signIn, setCredentials],
  )

  return { ...requestState, error: getApiError(requestState.error), onSignIn }
}

export function useSignUpRequest() {
  const [signUp, requestState] = useSignUpMutation()
  const setCredentials = useSetCredentials()

  const onSignUp = useCallback(
    async (email: string, password: string) => {
      try {
        const passwordHash = getHash(password.trim())
        const response = await signUp({ email: email.trim(), passwordHash }).unwrap()
        setCredentials(response)
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [signUp, setCredentials],
  )

  return { ...requestState, error: getApiError(requestState.error), onSignUp }
}

export function useCreateManagerRequest() {
  const [createManager, requestState] = useCreateManagerMutation()

  const onCreateManager = useCallback(
    async (email: string, password: string) => {
      try {
        const passwordHash = getHash(password.trim())
        await createManager({ email: email.trim(), passwordHash }).unwrap()
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [createManager],
  )

  return { ...requestState, error: getApiError(requestState.error), onCreateManager }
}

export function useLogoutRequest() {
  const [logout, requestState] = useLogoutMutation()

  const setCredentials = useSetCredentials()

  const onLogout = useCallback(async () => {
    try {
      await logout().unwrap()
      setCredentials(null)
    } catch (err) {
      throw new Error(getApiError(err))
    }
  }, [logout, setCredentials])

  return { ...requestState, error: getApiError(requestState.error), onLogout }
}

export function useSignUpVerifyEmailRequest() {
  const [callVerifyEmail, verifyEmailState] = useVerifyEmailMutation()

  const onVerifyEmail = useCallback(
    async (code: string) => {
      try {
        await callVerifyEmail({ code: code.trim() }).unwrap()
      } catch (err) {
        throw new Error(getApiError(err))
      }
    },
    [callVerifyEmail],
  )

  return { ...verifyEmailState, error: getApiError(verifyEmailState.error), onVerifyEmail }
}

export function useSignUpGetCodeRequest() {
  const [callSendCode, sendCodeState] = useSendVerifyEmailCodeMutation()

  const onSendCode = useCallback(async () => {
    try {
      await callSendCode().unwrap()
    } catch (err) {
      throw new Error(getApiError(err))
    }
  }, [callSendCode])

  return { ...sendCodeState, error: getApiError(sendCodeState.error), onSendCode }
}

// export function useRestorePasswordGetCodeRequest() {
//   const [sendCode, sendCodeState] = useGetRestorePasswordCodeMutation()
//
//   const onSendCode = useCallback(
//     async (email: string) => {
//       try {
//         await sendCode({ email }).unwrap()
//       } catch (err) {
//         throw new Error(getApiError(err))
//       }
//     },
//     [sendCode],
//   )
//
//   return { ...sendCodeState, error: getApiError(sendCodeState.error), onSendCode }
// }

// export function useRestorePasswordRequest() {
//   const [restore, restoreState] = useRestorePasswordMutation()
//
//   const onRestorePassword = useCallback(
//     async (password: string) => {
//       try {
//         const passwordHash = getHash(password)
//         await restore({ passwordHash }).unwrap()
//       } catch (err) {
//         throw new Error(getApiError(err))
//       }
//     },
//     [restore],
//   )
//
//   return { ...restoreState, error: getApiError(restoreState.error), onRestorePassword }
// }
//
// export function useRestorePasswordVerifyRequest() {
//   const [verify, verifyState] = useVerifyRestorePasswordMutation()
//
//   const onVerify = useCallback(
//     async (code: string) => {
//       try {
//         await verify({ code }).unwrap()
//       } catch (err) {
//         throw new Error(getApiError(err))
//       }
//     },
//     [verify],
//   )
//
//   return { ...verifyState, error: getApiError(verifyState.error), onVerify }
// }

// actions
export function useSetCredentials() {
  const dispatch = useAppDispatch()
  return useCallback(
    (credentials: RefreshTokenResponse | null) => {
      dispatch(setCredentials(credentials))
    },
    [dispatch],
  )
}

export function useClearState() {
  const dispatch = useAppDispatch()
  return useCallback(() => {
    dispatch(clearState())
  }, [dispatch])
}

// selectors
export function useIsAuth() {
  return useAppSelector((state: AppState) => Boolean(state.auth.accessToken))
}
