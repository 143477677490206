import styled, { css } from 'styled-components/macro'
import { darken, transparentize } from 'polished'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`

export const ContainerInputs = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`

export const ForgotPass = styled.span`
  position: absolute;
  top: 0.4rem;
  right: 0;
  z-index: ${(props) => props.theme.zIndex.minimal};
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.33;
  color: ${(props) => props.theme.color.default};
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  list-style: none;
  gap: 1rem 2rem;
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: repeat(2, 1fr);
  `};
`

export const ListItem = styled.li<{ isValid?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.23;
  color: ${(props) => transparentize(0.5, props.theme.color.text)};
  ${(props) =>
    props.isValid &&
    css`
      color: ${(props) => darken(0.15, props.theme.color.success)};
    `}
  &:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  &:nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
  }
  &:nth-child(3) {
    grid-area: 1 / 2 / 2 / 3;
  }
  &:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }
`
