import { UserData } from 'types/entities'

import { CellProps } from 'components/ui'

import { Wrapper, Title, Value } from '../Style'

export function Role({ row }: CellProps<UserData>) {
  const { roleName } = row.original

  return (
    <Wrapper area='Status'>
      <Title>Role</Title>
      <Value>{roleName}</Value>
    </Wrapper>
  )
}
