const Matic = () => (
  <svg fill='none' viewBox='0 0 24 24'>
    <path fill='#8247E5' d='M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z' />
    <path
      fill='#fff'
      d='M16.083 9.292c-.291-.167-.666-.167-1 0l-2.333 1.375-1.583.875-2.292 1.375c-.292.166-.667.166-1 0l-1.792-1.084c-.291-.166-.5-.5-.5-.875V8.875c0-.333.167-.667.5-.875l1.792-1.042c.292-.166.667-.166 1 0l1.792 1.084c.291.166.5.5.5.875v1.375l1.583-.917V7.958a1.03 1.03 0 0 0-.5-.875L8.917 5.125c-.292-.167-.667-.167-1 0l-3.417 2c-.333.167-.5.5-.5.833v3.917c0 .333.167.667.5.875l3.375 1.958c.292.167.667.167 1 0l2.292-1.333 1.583-.917 2.292-1.333c.291-.167.666-.167 1 0l1.791 1.042c.292.166.5.5.5.875v2.083c0 .333-.166.667-.5.875l-1.75 1.042c-.291.166-.666.166-1 0L13.292 16c-.292-.167-.5-.5-.5-.875v-1.333l-1.584.916v1.375c0 .334.167.667.5.875l3.375 1.959c.292.166.667.166 1 0l3.375-1.959c.292-.166.5-.5.5-.875v-3.958c0-.333-.166-.667-.5-.875l-3.375-1.958Z'
    />
  </svg>
)

export { Matic }
