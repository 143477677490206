import { numbers } from 'constants/variables'

export function hashRender(hash: string, size = Number('4'), separator = '...') {
  return hash.slice(Number('0'), size) + separator + hash.slice(-size)
}

export function sliceHash(hash: string) {
  return '0x' + hashRender(hash.slice(Number('2')))
}

export function reduceText(message: string, length = 50) {
  return message.length > length ? `${message.substring(numbers.ZERO, length)}...` : message
}

export function shortenText(message: string, firstPartLength: number, secondPartLength: number) {
  if (!message?.length) {
    return ''
  }
  return `${message.substring(numbers.ZERO, firstPartLength)}...${message.substring(secondPartLength)}`
}

export function substringer(text: string, chars = 4) {
  return `${text.substring(numbers.ZERO, chars)}...${text.substring(text.length - chars)}`
}
