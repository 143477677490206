import { useMemo, useRef, useState } from 'react'

import { Token, Hash, TokenSelectItem } from 'types/entities'

import { useGetBalancesRequest } from 'hooks/app'
import { useOnClickOutside } from 'hooks/listeners'

import { crypto } from 'constants/variables'
import { Icon, IconMap, SkeletonElement } from 'components/ui'

import { TokenRow } from './TokenRow'
import { Wrap, LoadingWrap, TokenWrap, Label, TokenSelectWrap, ChevronWrap, TokenList, TokenListItem } from './Style'

type Props = {
  token: Token
  address?: Hash
  chainId: SupportedChainId
  onSelect: (token: Token) => void
}

const defaultBalance = {
  balance: '0',
  bgBalance: crypto.BG_ZERO._hex,
}

export function TokenSelect({ token, onSelect, chainId, address }: Props) {
  const container = useRef(null)

  const { data, isLoading, tokens } = useGetBalancesRequest(address, chainId)

  const [isToggled, setToggled] = useState(false)

  const _isLoading = useMemo(() => !data && isLoading, [data, isLoading])

  const _tokens = useMemo(() => {
    return tokens.map<TokenSelectItem>((el) => ({
      ...el,
      balances: data ? data[el.address] : defaultBalance,
    }))
  }, [tokens, data, address])

  const handleToggleTokens = () => {
    setToggled(!isToggled)
  }

  const handleCloseTokens = () => {
    setToggled(false)
  }

  useOnClickOutside(container, handleCloseTokens)

  return (
    <Wrap ref={container}>
      {_isLoading ? (
        <LoadingWrap position='flex-start'>
          <SkeletonElement width='11.5rem' height='4.4rem' borderRadius='0.8rem' />
        </LoadingWrap>
      ) : (
        <>
          <TokenWrap>
            <Label>Select token</Label>
            <TokenSelectWrap type='outline' size='medium' onClick={handleToggleTokens}>
              <Icon name={token.symbol.toLowerCase() as IconMap} />
              {token.symbol}
              <ChevronWrap isToggled={isToggled}>
                <Icon name='chevron' />
              </ChevronWrap>
            </TokenSelectWrap>
          </TokenWrap>

          {isToggled && (
            <TokenList>
              <TokenListItem>
                {_tokens.map((token) => (
                  <TokenRow key={token.address} token={token} onClose={handleCloseTokens} onSelect={onSelect} />
                ))}
              </TokenListItem>
            </TokenList>
          )}
        </>
      )}
    </Wrap>
  )
}
