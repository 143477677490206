import { useMemo, useState } from 'react'

import { DateTime } from 'luxon'
import { Column } from 'react-table'

import { Doc, DocStatus } from 'types/entities'

import { Table } from 'components/ui'
import { sourceOfFunds } from 'constants/app'

import { DocsVerificationTableProps } from '../types'

import { Filters } from './Filters'
import { Email, Status, TimeCreated, TimeUpdated, Sof, AcceptVerification, DeclineVerification, DocLink } from './Cells'

const LIMIT = 8

const sourcesFunds = Object.values(sourceOfFunds)
function NewDocumentsTable({ data, isLoading, updateData }: DocsVerificationTableProps) {
  const [page, setPage] = useState(1)
  const [emailFilter, setEmailFilter] = useState('')
  const [sofFilter, setSofFilter] = useState<string | null>(null)
  const [docStatusFilter, setDocStatusFilter] = useState<DocStatus | null>(null)

  const getMillis = (date: Date) => {
    return DateTime.fromISO(date.toString()).toMillis()
  }

  const handleChangeDocStatus = (_docStatus: DocStatus | null) => {
    setDocStatusFilter(_docStatus)
  }

  const handleChangeSof = (_sof: string | null) => {
    setSofFilter(_sof)
  }

  const handleChangeEmail = (value: string) => {
    setEmailFilter(value)
  }

  const filterByStatus = (el: Doc) => {
    if (docStatusFilter) {
      return el.status === docStatusFilter
    }
    return el.status !== DocStatus.NOT_CREATED
  }

  const filterBySof = (el: Doc) => {
    if (sofFilter) {
      const isIncludes = sourcesFunds.includes(el.sourceOfFunds)
      return sofFilter.toLowerCase().includes('other') ? !isIncludes : el.sourceOfFunds === sofFilter
    }
    return true
  }

  const filterByEmail = (el: Doc) => {
    if (emailFilter) {
      return el.user.email.toLowerCase().includes(emailFilter.toLowerCase())
    }
    return true
  }

  const donations = useMemo(() => {
    if (!data) {
      return null
    }
    const _data = [...data]
    return _data
      .filter((el) => filterByStatus(el) && filterBySof(el) && filterByEmail(el))
      .sort((a, b) => getMillis(b.createdAt) - getMillis(a.createdAt))
  }, [data, emailFilter, sofFilter, docStatusFilter])

  const rows = useMemo(() => {
    if (!donations) {
      return []
    }
    return donations.slice((page - 1) * LIMIT, LIMIT * page)
  }, [page, donations])

  const lastPage = useMemo(() => {
    if (!donations) {
      return 0
    }
    return Math.ceil(donations.length / LIMIT)
  }, [donations])

  const columns: Column<Doc>[] = useMemo(
    () => [
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: TimeCreated,
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        Cell: TimeUpdated,
      },
      {
        Header: 'Email',
        accessor: 'user',
        Cell: Email,
      },
      {
        Header: 'SoF',
        accessor: 'sourceOfFunds',
        Cell: Sof,
      },
      {
        Header: 'Link',
        accessor: 'link',
        Cell: DocLink,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: Status,
      },
      {
        Header: '',
        accessor: 'id',
        id: 'acceptVerificationButton',
        Cell: (data) => <AcceptVerification {...data} updateData={updateData} />,
      },
      {
        Header: '',
        accessor: 'id',
        id: 'declineVerificationButton',
        Cell: (data) => <DeclineVerification {...data} updateData={updateData} />,
      },
    ],
    [rows],
  )

  return (
    <>
      <Filters
        sof={sofFilter}
        email={emailFilter}
        docStatus={docStatusFilter}
        onChangeSof={handleChangeSof}
        onChangeEmail={handleChangeEmail}
        onChangeDocStatus={handleChangeDocStatus}
      />
      <Table<Doc>
        showText
        data={rows}
        page={page}
        onPage={setPage}
        columns={columns}
        lastPage={lastPage}
        type='newDocuments'
        isLoading={isLoading}
        name='newDocumentsTable'
      />
    </>
  )
}

export { NewDocumentsTable }
