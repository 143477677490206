import { Kyc } from 'types/entities'

import { CellProps } from 'components/ui'
import { Wrapper, Title, Value } from '../Style'

export function Name({ row }: CellProps<Kyc>) {
  const { name } = row.original

  return (
    <Wrapper area='Name'>
      <Title>Name</Title>
      <Value>{name}</Value>
    </Wrapper>
  )
}
