import { Loader } from '../Loader'
import { LoaderWrap } from './Style'

export function FormLoader() {
  return (
    <LoaderWrap>
      <Loader size={10} ringSize={0.4} />
    </LoaderWrap>
  )
}
