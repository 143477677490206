import { KycType, VerificationSteps } from 'types/entities'

import { useSetVerificationStep } from 'state/user/hooks'

import { Button, Icon } from 'components/ui'

import {
  Wrap,
  Title,
  Divider,
  IconWrap,
  PreTitle,
  Container,
  DividerText,
  SelectButton,
  IconWrapChevron,
  SelectButtonText,
  SelectButtonTitle,
  SelectButtonContent,
} from './Style'

type Props = {
  onChangeKycType: (kycType: KycType) => void
}
export function KycSelector({ onChangeKycType }: Props) {
  const setStep = useSetVerificationStep()

  const onUpdateKycType = async (kycType: KycType) => {
    setStep(VerificationSteps.KYC)
    onChangeKycType(kycType)
  }

  const handleChangeExternal = () => {
    onUpdateKycType(KycType.KYC)
  }

  const handleChangeSelf = () => {
    onUpdateKycType(KycType.SELF)
  }

  return (
    <Container maxWidth='76rem'>
      <Title>Select Verification method</Title>
      <PreTitle>
        We&nbsp;understand that you value privacy. The verification process is&nbsp;designed to&nbsp;protect your
        privacy while ensuring that we&nbsp;adhere to&nbsp;applicable compliance requirements.
      </PreTitle>
      <Wrap type='selectKYC'>
        <SelectButton onClick={handleChangeExternal}>
          <IconWrap>
            <Icon name='fingerprint' size='xl' />
          </IconWrap>
          <SelectButtonContent>
            <SelectButtonTitle>Automated verification</SelectButtonTitle>
            <SelectButtonText>with government&nbsp;ID</SelectButtonText>
          </SelectButtonContent>
          <IconWrapChevron>
            <Icon name='chevron' size='large' rotate='-90deg' />
          </IconWrapChevron>
        </SelectButton>

        <Divider>
          <DividerText>or</DividerText>
        </Divider>

        <Button type='link' onClick={handleChangeSelf}>
          OPT OUT&nbsp;&mdash; get verified without government&nbsp;ID (takes 1-2&nbsp;days)
        </Button>
      </Wrap>
    </Container>
  )
}
