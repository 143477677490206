import { useCallback } from 'react'

import {
  CallNotification,
  callNotification,
  hideNotification,
  removeNotification,
  useSelectNotifications,
} from 'notifications/service'
import { useAppDispatch } from 'state/hooks'
import { numbers } from 'constants/variables'
import { generateId } from 'utils/helpers'

export function useCallNotification() {
  const dispatch = useAppDispatch()
  const notifications = useSelectNotifications()

  return useCallback(
    ({ ttl = numbers.NOTIFY_HIDE_DELAY, id = generateId(), isHidden = false, ...rest }: CallNotification) => {
      const isAlreadyShowed = !!notifications?.find((notification: { id: string | number }) => notification.id === id)

      if (!isAlreadyShowed) {
        dispatch(callNotification({ ttl, id, isHidden, ...rest }))

        setTimeout(() => {
          dispatch(hideNotification({ id }))
          setTimeout(() => {
            dispatch(removeNotification({ id }))
          }, numbers.NOTIFY_REMOVE_DELAY)
        }, ttl)
      }
    },
    [notifications],
  )
}
