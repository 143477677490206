import { useMemo } from 'react'
import { Donation } from 'types/entities'

import { useChainData } from 'hooks/app'

import { fromWei } from 'utils/crypto'

import { CellProps } from 'components/ui'
import { tokens } from 'constants/tokens'

import { Wrapper, Title, Value } from '../Style'

export function Amount({ row }: CellProps<Donation>) {
  const { chainId } = useChainData()
  const { amount, token } = row.original

  const _token = useMemo(() => {
    if (!token) {
      return null
    }
    return tokens[chainId]?.[token]
  }, [token])

  const _amount = useMemo(() => {
    if (!amount || !_token) {
      return '-'
    }
    return fromWei(amount, _token.decimals)
  }, [amount, _token])

  return (
    <Wrapper area='Amount'>
      <Title>Amount</Title>
      <Value>
        {_amount} {_token?.symbol}
      </Value>
    </Wrapper>
  )
}
