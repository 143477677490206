import { ASIDE_IMAGE_FIRST, ASIDE_IMAGE_SECOND } from 'constants/link'
import { Icon } from 'components/ui'
import { Banner, IconWrap, BannerContent, BannerTitle, BannerImageWrap, BannerImage } from './Style'

const AsideBanner = () => {
  return (
    <Banner>
      <IconWrap>
        <Icon name='rounded' size='fill' />
      </IconWrap>
      <BannerContent>
        <BannerTitle>Defend the right to&nbsp;privacy in&nbsp;Web3</BannerTitle>
      </BannerContent>

      <BannerImageWrap>
        <BannerImage src={ASIDE_IMAGE_FIRST} alt='Aside Alex banner' />
        <BannerImage src={ASIDE_IMAGE_SECOND} alt='Aside Roman banner' />
      </BannerImageWrap>
    </Banner>
  )
}

export { AsideBanner }
