import { useMemo } from 'react'

import { DateTime } from 'luxon'

import { Donation } from 'types/entities'

import { CellProps, TimeCell } from 'components/ui'

export function Time({ row }: CellProps<Donation>) {
  const { updatedAt, createdAt } = row.original

  const timeValue = useMemo(() => (updatedAt > createdAt ? updatedAt : createdAt), [createdAt, updatedAt])

  const time = useMemo(() => DateTime.fromISO(timeValue.toString()).toMillis(), [timeValue])

  return <TimeCell area='time' title='time' timestamp={time} />
}
