import styled from 'styled-components/macro'

export const LoaderWrap = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(41rem - 6rem);
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
`
