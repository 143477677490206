import { useCallback, useEffect, useState } from 'react'
import { ConfirmationStatuses, ConfirmationStep, Steps } from './types'

const confirmationStepContent = {
  [ConfirmationStep.REVOKE]: {
    label: 'Revoke',
  },
  [ConfirmationStep.APPROVE]: {
    label: 'Approve',
  },
  [ConfirmationStep.CONFIRM]: {
    label: 'Confirm',
  },
}

export function useStep() {
  const [actualStep, setStep] = useState<ConfirmationStep | null>(null)
  const [steps, setSteps] = useState<Steps | null>(null)

  useEffect(() => {
    if (steps && !actualStep) {
      setStep(Number(Object.keys(steps)[0]))
    }
  }, [steps])

  const changeStepStatus = useCallback(
    (_step: ConfirmationStep | null, newStatus: ConfirmationStatuses) => {
      if (!_step || !steps) {
        return
      }

      const _steps = {
        ...steps,
        [_step]: {
          ...steps[_step],
          status: newStatus,
        },
      }

      setSteps(_steps)
    },
    [steps, setSteps],
  )

  const goNextStep = useCallback(() => {
    if (!actualStep || !steps) {
      return
    }

    const nextStep = (actualStep + 1) as ConfirmationStep
    setSteps({
      ...steps,
      [nextStep]: { ...steps[nextStep], status: ConfirmationStatuses.PREPARE },
      [actualStep]: { ...steps[actualStep], status: ConfirmationStatuses.COMPLETE },
    })
    setStep(steps[nextStep].key)
  }, [actualStep, steps, setSteps])

  const setupSteps = useCallback(
    (_stepKeys: ConfirmationStep[]) => {
      if (!_stepKeys?.length) {
        setSteps(null)
        return
      }

      const _steps = _stepKeys.reduce<Steps>((acc, curr, index) => {
        acc[curr] = {
          key: curr,
          label: confirmationStepContent[curr].label,
          status: index === 0 ? ConfirmationStatuses.PREPARE : ConfirmationStatuses.IN_LINE,
        }
        return acc
      }, {} as Steps)

      setSteps(_steps)
    },
    [setSteps],
  )

  return { steps, actualStep, changeStepStatus, goNextStep, setupSteps }
}
