import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

import { Button } from 'components/ui/Button'

export const Container = styled.div<{ showText?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2.4rem;
  ${(props) =>
    props.showText &&
    css`
      justify-content: space-between;
    `}
`

export const ShowText = styled.span`
  display: none;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: 0.006rem;
  text-transform: uppercase;
  color: ${(props) => transparentize(0.5, props.theme.color.text)};
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    display: flex;
    flex: 0 0 auto;
    gap: 0.4rem;
  `};
`

export const ShowTextValue = styled.span`
  color: ${(props) => props.theme.color.text};
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.9rem;
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    width: auto;
  `};
`

const PagesWrapper = css`
  position: relative;
  padding: 0.2rem;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 1rem;
    width: 0.1rem;
    height: 2.4rem;
    background-color: ${(props) => transparentize(0.6, props.theme.color.black)};
  }
  &::before {
    left: -0.4rem;
  }
  &::after {
    right: -0.4rem;
  }
`

export const PagesMobileWrapper = styled.div`
  ${PagesWrapper};
  display: flex;
  flex: 1 0 auto;
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    display: none;
  `};
`

export const PagesDesktopWrapper = styled.div`
  ${PagesWrapper};
  display: none;
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    display: flex;
  `};
`

export const PaginationButton = styled(Button)`
  width: 4rem;
  height: 4rem;
  color: ${(props) => props.theme.color.white};
  border-radius: 0.6rem;
  ${(props) => props.theme.breakpoints.greaterThan('lg')`
    width: 4.4rem;
    height: 4.4rem;
  `};
`

export const PageItem = styled.button<{ active: boolean; disabled: boolean }>`
  width: fit-content;
  min-width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: -0.015rem;
  color: ${(props) => (props.active ? props.theme.color.textPrimary : props.theme.color.text)};
  background-color: ${(props) => (props.active ? props.theme.color.default : props.theme.color.transparent)};
  border: 0.1rem solid ${(props) => props.theme.color.default};
  border-radius: 0.6rem;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${(props) => transparentize(0.6, props.theme.color.default)};
    border-color: ${(props) => transparentize(0.5, props.theme.color.default)};
  }
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
  ${(props) => props.theme.breakpoints.greaterThan('lg')`
    min-width: 4.4rem;
    height: 4.4rem;
  `};
`

export const PageItemMobile = styled(PageItem)`
  width: 17.8rem;
  white-space: nowrap;
  color: ${(props) => props.theme.color.text};
  background-color: ${(props) => props.theme.color.transparent};
`
