import { createApi } from '@reduxjs/toolkit/query/react'

import { apiLinks } from 'constants/api'

import { customAuthorizedQuery } from '../api'
import { GetUserInput, GetUserResponse, GetAllUsersInput, GetAllUsersResponse } from './types'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customAuthorizedQuery({
    baseUrl: apiLinks.BASE_URL,
  }),
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getUser: builder.query<GetUserResponse, GetUserInput>({
      query: () => ({
        url: apiLinks.user.getUser,
        method: 'GET',
      }),
    }),

    getAllUsers: builder.query<GetAllUsersResponse, GetAllUsersInput>({
      query: () => ({
        url: apiLinks.user.getAll,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetUserQuery, useGetAllUsersQuery } = userApi
