import { useMemo } from 'react'
import { PaymentType } from 'constants/app'

import { SwitchButtonGroup } from 'components/ui'
import { SwitchButtonType, SwitchButtonSize } from 'components/ui/SwitchButtonGroup/@types'

type Props = {
  isDisabled?: boolean
  type?: SwitchButtonType
  size?: SwitchButtonSize
  checkedPaymentType: PaymentType
  onChangePaymentType: (paymentType: PaymentType) => void
}

const GROUP_NAME = 'button-types-payment'

export function PaymentSelector({
  isDisabled,
  checkedPaymentType,
  onChangePaymentType,
  type = 'default',
  size = 'default',
}: Props) {
  const paymentTypes = useMemo(() => {
    return [
      {
        name: GROUP_NAME,
        value: PaymentType.CRYPTO,
        label: PaymentType.CRYPTO,
      },
      {
        name: GROUP_NAME,
        value: PaymentType.FIAT,
        label: PaymentType.FIAT,
      },
    ]
  }, [])

  return (
    <SwitchButtonGroup<PaymentType>
      typeSwitch={type}
      sizeSwitch={size}
      options={paymentTypes}
      isDisabled={isDisabled}
      checked={checkedPaymentType}
      onChange={onChangePaymentType}
    />
  )
}
