import { DonationsTable, UsersTable } from './Table'

function AdminAnalytics() {
  return (
    <>
      <DonationsTable />
      <UsersTable />
    </>
  )
}

export { AdminAnalytics }
