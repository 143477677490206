export enum Links {
  MAIN = '/',
  PROFILE = '/profile',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  MANGER_SIGN_UP = '/manager-sign-up',
  RESTORE_PASSWORD = '/restore-password',
}

export const EXTERNAL_LINK_REGEXP = /^https?:\/\//

export const SUPPORT_EMAIL = 'mailto:hello@wewantjusticedao.org'

export const APP_LINK = 'https://app.wewantjusticedao.org'
export const PRODUCTION_LINK = 'https://wewantjusticedao.org'
export const STATIC_LINK = 'https://static.wewantjusticedao.org'

export const DOC_LINK = `${STATIC_LINK}/docs/Justice_DAO_legal_defense_funding_disclosure_to_donors.pdf`
export const PRIVACY_POLICY = `${STATIC_LINK}/docs/Justice_DAO_-_General_Privacy_Policy_.pdf`
export const TERMS_OF_SERVICE = `${STATIC_LINK}/docs/Justice_DAO_Donations_Terms_of_Service_Updated_October_1,_2023.pdf`

export const ASIDE_IMAGE_FIRST = `${STATIC_LINK}/images/alexBanner.webp`
export const ASIDE_IMAGE_SECOND = `${STATIC_LINK}/images/romanBanner.webp`
