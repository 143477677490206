import { useMemo } from 'react'

import { Donation } from 'types/entities'
import { useChainData } from 'hooks/app'

import { appConstants } from 'constants/app'
import { compareAddresses, getEtherscanLink, shortenAddress } from 'utils/crypto'

import { CellProps, Icon } from 'components/ui'

import { Wrapper, Title, Value, StyledHref } from '../Style'

export function Address({ value: address }: CellProps<Donation>) {
  const { chainId } = useChainData()

  const _address = useMemo(() => (address ? address : '-'), [address])
  const _addressShorten = useMemo(() => {
    if (!address) {
      return '-'
    }

    const recipientName = Object.values(appConstants.RECIPIENTS_DATA).find((recipient) =>
      compareAddresses(recipient.address, address),
    )?.label

    return recipientName || shortenAddress(address)
  }, [address])
  return (
    <Wrapper area='Address'>
      <Title>Address</Title>
      <Value>
        <StyledHref href={`${getEtherscanLink(chainId, _address, 'address')}`}>
          {_addressShorten}
          {address && <Icon name='externalLink' size='mediumPlus' />}
        </StyledHref>
      </Value>
    </Wrapper>
  )
}
