const Chevron = () => (
  <svg viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M18.707 8.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 1.414-1.414L12 13.586l5.293-5.293a1 1 0 0 1 1.414 0Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Chevron }
