import { createReducer } from '@reduxjs/toolkit'

import { Modals } from '../@types'
import { openModal, closeModal, closeAllModal } from './actions'

const initialState: Modals = []

export default createReducer(initialState, (builder) =>
  builder
    .addCase(openModal, (state, action) => {
      const { name, container } = action.payload
      return [{ name, container }, ...state]
    })
    .addCase(closeModal, (state, action) => {
      const modalName = action.payload
      return state.filter((m) => m.name !== modalName)
    })
    .addCase(closeAllModal, () => {
      return initialState
    }),
)
