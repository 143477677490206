import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

export const Header = styled.header<{ type: string; align?: string }>`
  margin: 0 0 2.4rem;
  padding: 1.2rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  text-align: ${(props) => props.align};
  ${(props) =>
    props.align === 'left' &&
    css`
      align-items: flex-start;
    `}
  ${(props) =>
    props.align === 'center' &&
    css`
      align-items: center;
    `}
  ${(props) =>
    props.align === 'right' &&
    css`
      align-items: flex-end;
    `}

  ${(props) =>
    props.type === 'content' &&
    css`
      position: fixed;
      top: 1rem;
      left: 3.2rem;
    `}

  ${(props) =>
    props.type === 'primary' &&
    css`
      margin: 0 0 1rem;
      border-bottom: none;
    `}

  ${(props) => props.theme.breakpoints.greaterThan('sm')`
      ${
        props.type === 'content' &&
        css`
          position: relative;
          top: 0;
          left: 0;
          right: 0;
        `
      }
    `};
`

export const Title = styled.h2<{ type?: string }>`
  margin: 0;
  padding: 0;
  font-family: ${(props) => props.theme.font.family.primary};
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: 0.04rem;
  color: ${(props) => props.theme.color.text};
  ${(props) =>
    props.type === 'content' &&
    css`
      margin: 0 0 0.4rem;
      padding: 0;
      font-size: 1.6rem;
      color: ${(props) => props.theme.color.text};
    `}
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    ${
      props.type === 'content' &&
      css`
        padding: 0;
      `
    }
  `};
`

export const Subtitle = styled.p<{ type?: string }>`
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.43;
  color: ${(props) => transparentize(0.5, props.theme.color.text)};
  a {
    color: ${(props) => props.theme.color.textHint};
  }
  ${(props) =>
    props.type === 'content' &&
    css`
      margin: 0;
      padding: 0;
      color: ${(props) => transparentize(0.5, props.theme.color.text)};
    `}
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    ${
      props.type === 'content' &&
      css`
        margin: 0 0 2rem;
        padding: 0;
      `
    }
  `};
`
