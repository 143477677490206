import { createElement } from 'react'

import { useSelectModals } from './state'

import { Modals } from './@types'

function ModalConductor() {
  const modals = useSelectModals()

  const createModal = (modals: Modals) => {
    const [firstModal] = modals

    if (firstModal) {
      const { container, name } = firstModal

      return createElement(container, {
        key: name,
      })
    }

    return null
  }

  return createModal(modals)
}

export { ModalConductor }
