const Busd = () => (
  <svg fill='none' viewBox='0 0 25 24'>
    <path
      fill='#F3BA2F'
      d='M12.923 24c6.628 0 12-5.373 12-12s-5.372-12-12-12c-6.627 0-12 5.373-12 12s5.373 12 12 12Z'
    />
    <path fill='#fff' d='m12.923 4.449 1.866 1.91-4.698 4.699-1.866-1.866 4.698-4.743Z' />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='m12.925 4 2.306 2.362-5.14 5.14-2.31-2.309L12.925 4ZM8.669 9.19l1.422 1.423 4.255-4.256-1.425-1.46L8.67 9.192Z'
      clipRule='evenodd'
    />
    <path fill='#fff' d='m15.756 7.281 1.866 1.91-7.531 7.531-1.866-1.865 7.53-7.576Z' />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='m15.758 6.833 2.306 2.362-7.973 7.973-2.31-2.31 7.977-8.025ZM8.67 14.856l1.421 1.421 7.088-7.088-1.425-1.46-7.084 7.127Z'
      clipRule='evenodd'
    />
    <path fill='#fff' d='m7.259 10.114 1.866 1.91-1.866 1.866-1.866-1.866 1.866-1.91Z' />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='m7.258 9.663 2.309 2.364-2.309 2.308-2.308-2.308 2.308-2.364Zm-1.423 2.359 1.423 1.423 1.424-1.423-1.424-1.458-1.423 1.458Z'
      clipRule='evenodd'
    />
    <path fill='#fff' d='m18.588 10.114 1.866 1.91-7.53 7.53-1.866-1.865 7.53-7.575Z' />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='m18.59 9.665 2.306 2.362L12.924 20l-2.31-2.31 7.977-8.025Zm-7.088 8.024 1.421 1.42 7.089-7.087-1.426-1.46-7.084 7.127Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Busd }
