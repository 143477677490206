import { useMemo } from 'react'

import { DateTime } from 'luxon'

import { CellProps, TimeCell } from 'components/ui'

export function TimeCreated<T extends { createdAt: Date }>({ row }: CellProps<T>) {
  const { createdAt } = row.original

  const _time = useMemo(() => DateTime.fromISO(createdAt.toString()).toMillis(), [createdAt])

  return <TimeCell area='CreatedAt' title='Created at' timestamp={_time} />
}
