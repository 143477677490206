import styled from 'styled-components/macro'
import { transparentize } from 'polished'

import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

export const ReactTip = styled(Tooltip)<{ place?: string }>`
  &.customTooltip {
    padding: 1.4rem;
    max-width: 26.6rem;
    font-family: ${(props) => props.theme.font.family.default};
    font-weight: ${(props) => props.theme.font.weight.regular};
    font-size: 1.4rem;
    line-height: 1.43;
    text-align: left;
    letter-spacing: -0.015rem;
    text-transform: none;
    word-break: break-word;
    white-space: break-spaces;
    color: ${(props) => props.theme.color.white};
    background-color: ${(props) => props.theme.color.tooltipColor};
    border-radius: 1rem;
    box-shadow: 0 0 1.4rem 0.1rem ${(props) => transparentize(0.5, props.theme.color.black)};
    z-index: ${(props) => props.theme.zIndex.preloader};
    ${(props) => props.theme.breakpoints.greaterThan('sm')`
      max-width: 36rem;
    `};
  }
  .customTooltipArrow {
    background-color: ${(props) => props.theme.color.tooltipColor};
  }
`
