import { useEffect, PropsWithChildren, createRef, useCallback } from 'react'

import { useAppDispatch } from 'state/hooks'

import { ModalProps } from './@types'
import { useCloseModal, closeAllModal, useIsMultiplyModals } from './state'

import { SelectModal } from './StyledModal'

export function Modal({
  name,
  type,
  size,
  align,
  title,
  subtitle,
  children,
  withDefaultLayout,
  disableOverlayClose,
}: PropsWithChildren<ModalProps>) {
  const dispatch = useAppDispatch()
  const ref = createRef<HTMLDivElement>()
  const isMultiplyModals = useIsMultiplyModals()

  const closeModal = useCloseModal()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const onCurrentClose = () => {
    closeModal(name)
  }

  const onClose = () => {
    if (isMultiplyModals) {
      dispatch(closeAllModal())
    } else {
      closeModal(name)
    }
  }

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        onClose()
      }
    },
    [disableOverlayClose],
  )

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return (
    <SelectModal
      ref={ref}
      size={size}
      type={type}
      align={align}
      title={title}
      onClose={onClose}
      subtitle={subtitle}
      onCurrentClose={onCurrentClose}
      isMultiplyModals={isMultiplyModals}
      withDefaultLayout={withDefaultLayout}
      disableOverlayClose={disableOverlayClose}
    >
      {children}
    </SelectModal>
  )
}
