import { sourceOfFunds, SourceOfFunds } from 'constants/app'

export const sourceOfFundsOptions = [
  {
    value: SourceOfFunds.PERSONAL_SAVINGS,
    label: sourceOfFunds.PERSONAL_SAVINGS,
  },
  {
    value: SourceOfFunds.SALARY_OR_WAGES,
    label: sourceOfFunds.SALARY_OR_WAGES,
  },
  {
    value: SourceOfFunds.SELF_EMPLOYMENT_INCOME,
    label: sourceOfFunds.SELF_EMPLOYMENT_INCOME,
  },
  {
    value: SourceOfFunds.PENSION_OR_RETIREMENT_INCOME,
    label: sourceOfFunds.PENSION_OR_RETIREMENT_INCOME,
  },
  {
    value: SourceOfFunds.CAPITAL_GAINS_DIVIDENDS,
    label: sourceOfFunds.CAPITAL_GAINS_DIVIDENDS,
  },
  {
    value: SourceOfFunds.PROPERTY_SALE,
    label: sourceOfFunds.PROPERTY_SALE,
  },
  {
    value: SourceOfFunds.LOTTERY_WINNINGS,
    label: sourceOfFunds.LOTTERY_WINNINGS,
  },
  {
    value: SourceOfFunds.INHERITANCES_AND_GIFTS,
    label: sourceOfFunds.INHERITANCES_AND_GIFTS,
  },
  {
    value: SourceOfFunds.COMPENSATION_FROM_LEGAL_RULINGS,
    label: sourceOfFunds.COMPENSATION_FROM_LEGAL_RULINGS,
  },
  {
    value: SourceOfFunds.OTHER,
    label: sourceOfFunds.OTHER,
  },
]
