import { NavLink } from 'react-router-dom'

import { Href } from 'components/ui'
import { EXTERNAL_LINK_REGEXP } from 'constants/link'

type LinkProps = {
  id?: string
  url: string
  title: string
}

const CustomLink = ({ id, title, url }: LinkProps) => {
  return (
    <>
      {EXTERNAL_LINK_REGEXP.test(url) ? (
        <Href id={id} href={url}>
          {title}
        </Href>
      ) : (
        <NavLink caseSensitive to={url} className={({ isActive }) => (isActive ? 'activeNavLink' : undefined)} end>
          {title}
        </NavLink>
      )}
    </>
  )
}

export { CustomLink }
