import styled from 'styled-components/macro'
import { shimmer } from 'utils'
import { transparentize } from 'polished'

export const SkeletonElementStyle = styled.span<{ width: string; height: string; borderRadius: string }>`
  position: relative;
  display: block;
  overflow: hidden;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => transparentize(0.4, props.theme.color.default)};
  border-radius: ${(props) => props.borderRadius};
  user-select: none;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${(props) => props.height};
    display: inline-flex;
    transform: translateX(-100%);
    background-image: ${(props) => props.theme.color.placeholderLoading};
    animation: ${shimmer} 1.5s infinite;
    animation-fill-mode: both;
  }
`
