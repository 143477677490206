import { useMemo } from 'react'

import { Notification, NotificationTypes } from 'notifications/service'

import { errorParser } from 'utils/errors'
import { Icon, IconMap } from 'components/ui'

import { Wrapper, IconWrap, Message } from './Style'

type Props = Notification & {
  onClose: VoidFunction
}

const typeToName = {
  info: 'warning',
  error: 'danger',
  success: 'success',
}

export function NotificationStatus({ message, isHidden, type, onClose }: Props) {
  const _message = useMemo(
    () => (type === NotificationTypes.ERROR && typeof message === 'string' ? errorParser(message) : message),
    [message],
  )

  return (
    <Wrapper isHidden={isHidden} onClick={onClose}>
      <IconWrap type={type}>
        <Icon name={typeToName[type] as IconMap} />
      </IconWrap>

      <Message>{_message}</Message>
    </Wrapper>
  )
}
