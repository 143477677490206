import { useEffect, useMemo, useRef, useState } from 'react'

import SignaturePad from 'react-signature-canvas'

import { errors } from 'constants/errors'
import { dataURLtoFile } from 'utils/convert'
import { DOC_LINK, SUPPORT_EMAIL } from 'constants/link'

import { Button, Checkbox, CustomLink, Icon, Tooltip } from 'components/ui'
import {
  Container,
  ErrorWrap,
  ButtonWrap,
  SupportLink,
  StyledButton,
  ErrorContent,
  CheckboxText,
  CheckboxLabel,
  SignatureWrap,
  CheckboxWrapper,
  SignaturePadWrap,
  SignaturePadLabel,
} from './Style'

type Props = {
  isError?: boolean
  isDocRead: boolean
  errorMessage?: string
  isUserAccepted: boolean
  onSignDoc: (file: File | null) => void
  onChangeDocRead: (isRead: boolean) => void
  onChangeUserAccepted: (isUserAccepted: boolean) => void
}

const DOC_LINK_ID = 'signingDoc'
const CHECK_BOX_ID = 'signCheckBox'

export function DocSigning({
  isError,
  onSignDoc,
  isDocRead,
  errorMessage,
  isUserAccepted,
  onChangeDocRead,
  onChangeUserAccepted,
}: Props) {
  const ref = useRef<SignaturePad>(null)

  const [error, setError] = useState('')
  const [isEmpty, setEmpty] = useState(true)
  const [isSigned, setSigned] = useState(false)

  useEffect(() => {
    const doc = document.getElementById(DOC_LINK_ID)
    if (doc) {
      doc.addEventListener('click', handleChangeDocRead)
    }
    return () => {
      if (doc) {
        doc.removeEventListener('click', handleChangeDocRead)
      }
    }
  }, [])

  const handleChangeDocRead = () => {
    onChangeDocRead(true)
  }

  const handleChangeUserAccepted = () => {
    if (!isDocRead) {
      forcedReading()
      return
    }
    onChangeUserAccepted(!isUserAccepted)
  }

  const handleClear = () => {
    if (ref.current && typeof ref.current.clear === 'function') {
      ref.current.clear()
      setEmpty(true)
      onSignDoc(null)
      setSigned(false)
      ref.current.on()
    }
  }

  const handleCheckSign = () => {
    if (!ref.current) {
      setError('The sign area not loaded')
      return
    }

    const { _data } = ref.current.getSignaturePad()
    // @ts-ignore for some reason "points" not typed
    const _isEmpty = !_data.some((el) => el.length > 3)
    setEmpty(_isEmpty)
  }

  const forcedReading = () => {
    onChangeDocRead(true)
    window.open(DOC_LINK, '_blank')
  }

  const handleSign = () => {
    try {
      if (ref.current) {
        setError('')
        const png = ref.current.getTrimmedCanvas().toDataURL()
        const _file = dataURLtoFile(png, 'signature.png')
        onSignDoc(_file)
        setSigned(true)
        ref.current.off()
      } else {
        setError('The sign area not loaded')
      }
    } catch (err) {
      setError('Sign failed! Try again or ')
    }
  }

  const _errorMessage = useMemo(
    () => (errorMessage === errors.validation.REQUIRED_FIELD ? errors.validation.REQUIRED_SIGN : errorMessage),
    [errorMessage],
  )

  const isSignDisabled = useMemo(() => isEmpty || isSigned, [isEmpty, isSigned])
  const signButtonText = useMemo(() => (isSigned ? 'Signed' : 'Sign'), [isSigned])
  const isSignatureLabelShow = useMemo(() => !isSignDisabled || isSigned, [isSignDisabled, isSigned])
  return (
    <Container>
      <CheckboxWrapper>
        <Checkbox id={CHECK_BOX_ID} checked={isUserAccepted} onClick={handleChangeUserAccepted} />

        <CheckboxLabel htmlFor={CHECK_BOX_ID}>
          <CheckboxText>
            You must review and accept the following document to&nbsp;participate in&nbsp;this donation:{' '}
            <CustomLink id={DOC_LINK_ID} url={DOC_LINK} title='Disclosures regarding Legal Defense Fund' />
          </CheckboxText>
        </CheckboxLabel>
      </CheckboxWrapper>

      <SignatureWrap>
        <SignaturePadWrap>
          <SignaturePadLabel isShow={isSignatureLabelShow}>Your sign here</SignaturePadLabel>
          <SignaturePad
            ref={ref}
            onEnd={handleCheckSign}
            backgroundColor='transparent'
            canvasProps={{
              style: {
                width: '100%',
                height: '100%',
              },
            }}
          />
        </SignaturePadWrap>
      </SignatureWrap>

      <ButtonWrap>
        <Button type='outline' size='medium' onClick={handleClear}>
          Clear <Icon name='eraser' size='extraMedium' />
        </Button>

        {!isDocRead && <Tooltip id='isDocRead'>{errors.validation.READ_DOC_REQUIRED}</Tooltip>}
        <StyledButton
          size='medium'
          id='isDocRead'
          onClick={handleSign}
          disabled={isSignDisabled}
          $isBlocked={!isUserAccepted}
        >
          {signButtonText}
          {isSigned ? (
            <Icon name='doubleTick' size='extraMedium' noTransition />
          ) : (
            <Icon name='pen' size='extraMedium' noTransition />
          )}
        </StyledButton>
      </ButtonWrap>

      <ErrorWrap>
        {error && (
          <ErrorContent>
            {error} <SupportLink href={SUPPORT_EMAIL}>Contact support</SupportLink>
          </ErrorContent>
        )}
        {isError && <ErrorContent>{_errorMessage}</ErrorContent>}
      </ErrorWrap>
    </Container>
  )
}
