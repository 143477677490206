import { useMemo } from 'react'

import { useCopy } from 'hooks/helpers'
import { SUPPORT_EMAIL } from 'constants/link'

import { Button, Icon } from 'components/ui'

import { WrapList, Title, Info, PreTitle, SupportLink, CopyButtonWrap } from './Style'

export function FiatPayment() {
  const [isCopied, handleClick] = useCopy('hello@wewantjusticedao.org')
  const copyIcon = useMemo(() => (isCopied ? 'tick' : 'copy'), [isCopied])
  return (
    <>
      <WrapList>
        <Title>Bank Wire Instructions</Title>
        <Info>
          <Icon name='info' size='fill' />
          <PreTitle>
            For fiat donation via bank wire transfer, please contact&nbsp;us at
            <br />
            <SupportLink href={SUPPORT_EMAIL}>hello@wewantjusticedao.org</SupportLink>
          </PreTitle>
        </Info>
      </WrapList>
      <CopyButtonWrap>
        <Button fullSize size='large' onClick={handleClick}>
          Copy e-mail
          <Icon name={copyIcon} size='extraMedium' />
        </Button>
      </CopyButtonWrap>
    </>
  )
}
