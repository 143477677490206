import { useMemo } from 'react'

import { KycStatus, KycType } from 'types/entities'

import { useKycType } from 'state/user/hooks'
import { useKycStatus } from 'state/user/hooks'

import { SUPPORT_EMAIL } from 'constants/link'
import { Button, Icon, IconMap } from 'components/ui'

import { StatusContainer, StatusTitle, StatusText, StatusIconWrap, Container, ButtonWrap, SupportLink } from './Style'

type KycStatuses = {
  title: string
  icon?: string
  buttonText?: string
  action?: VoidFunction
  text: string | React.ReactNode
}

export function KycStatuses() {
  const kycType = useKycType()
  const kycStatus = useKycStatus()

  const isExternal = useMemo(() => kycType === KycType.KYC, [kycType])
  const statusProps = useMemo<KycStatuses | undefined>(
    () =>
      kycStatus
        ? {
            [KycStatus.NOT_STARTED]: {
              title: 'KYC',
              text: 'Please choose KYC type',
            },
            [KycStatus.STARTED]: {
              icon: 'clock',
              title: 'Please contact support',
              text: `Something went wrong`,
            },
            [KycStatus.COMPLETED]: {
              icon: 'success',
              title: 'Verification completed',
              text: `Thank you - your verification is successfully completed!`,
              buttonText: isExternal ? 'Continue' : '',
            },
            [KycStatus.PENDING]: {
              icon: 'clock',
              title: 'Your information is being reviewed',
              text: `Thank you for submitting the information! We will send payment instructions shortly - please look out for an email from us!`,
            },
            [KycStatus.DECLINED]: {
              icon: 'error',
              title: 'Verification unsuccessful',
              text: (
                <>
                  Looks like there is an issue with some of the information that was provided. Please{' '}
                  <SupportLink href={SUPPORT_EMAIL}>contact support</SupportLink>.
                </>
              ),
            },
          }[kycStatus]
        : undefined,
    [kycStatus, isExternal],
  )

  return (
    <Container maxWidth='60rem'>
      {statusProps && (
        <StatusContainer>
          <StatusIconWrap status={kycStatus}>
            <Icon name={statusProps.icon as IconMap} size='fill' />
          </StatusIconWrap>

          <StatusTitle>{statusProps.title}</StatusTitle>
          <StatusText>{statusProps.text}</StatusText>
        </StatusContainer>
      )}

      <>
        {statusProps && statusProps.buttonText && (
          <ButtonWrap>
            <Button fullSize size='large' onClick={statusProps.action}>
              {statusProps.buttonText}
            </Button>
          </ButtonWrap>
        )}
      </>
    </Container>
  )
}
