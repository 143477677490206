import { TERMS_OF_SERVICE, PRIVACY_POLICY } from 'constants/link'
import { Checkbox, CustomLink } from 'components/ui'

import { Container, CheckboxText, CheckboxWrapper, CheckboxLabel } from './Style'

const checkboxId = 'Terms'

type Props = {
  isChecked: boolean
  onCheckChange: (value: boolean) => void
}

export function TermsOfService({ isChecked, onCheckChange }: Props) {
  const onHandleChangeChecked = () => {
    onCheckChange(!isChecked)
  }

  return (
    <Container>
      <CheckboxWrapper>
        <Checkbox onClick={onHandleChangeChecked} id={checkboxId} checked={isChecked} />

        <CheckboxLabel htmlFor={checkboxId}>
          <CheckboxText>
            I&nbsp;have read, understood and agree to&nbsp;the terms listed in&nbsp;the&nbsp;
            <CustomLink url={TERMS_OF_SERVICE} title='Terms of use' /> &{' '}
            <CustomLink url={PRIVACY_POLICY} title='Privacy policy' />
          </CheckboxText>
        </CheckboxLabel>
      </CheckboxWrapper>
    </Container>
  )
}
