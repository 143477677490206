import { ethers } from 'ethers'

import { ConnectionInfo } from '@ethersproject/web'
import { Networkish } from '@ethersproject/networks'

import { sleep } from 'utils/helpers'
import { numbers } from 'constants/variables'

export class ExtendedProvider extends ethers.providers.StaticJsonRpcProvider {
  public constructor(url?: ConnectionInfo | string, network?: Networkish) {
    super(url, network)
  }

  // @ts-expect-error
  public async send(method: string, params: any[], retryAttempt: number = numbers.ZERO) {
    try {
      return await super.send(method, params)
    } catch (err) {
      if (method === 'eth_estimateGas') {
        throw err
      }
      if (!retryAttempt) {
        console.warn(`ExtendedProvider send ${method} has error:`, err.message)

        const TIME_OUT = 3000

        await sleep(TIME_OUT)

        return this.send(method, params, ++retryAttempt)
      }
      throw err
    }
  }
}
