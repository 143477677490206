import { useMemo } from 'react'

import { DocStatus } from 'types/entities'

import { Setting } from 'state/user/types'
import { useSettings, useSetSetting, useDocStatus } from 'state/user/hooks'

import { PaymentType } from 'constants/app'

import { FiatPayment } from './FiatPayment'
import { CryptoPayment } from './CryptoPayment'
import { DocVerificationStatus } from '../DocVerificationStatus'

import { PaymentSelector } from './PaymentSelector'
import { Container, Title, PreTitle, PaymentSelectorWrap } from './Style'

type Props = {
  onDocVerification: VoidFunction
}

export function Payment({ onDocVerification }: Props) {
  const settings = useSettings()
  const docStatus = useDocStatus()
  const setSetting = useSetSetting()

  const paymentType = useMemo(() => settings[Setting.PAYMENT_TYPE], [settings])

  const isDocAccepted = useMemo(() => docStatus === DocStatus.ACCEPTED, [docStatus])

  const isFiat = useMemo(() => paymentType === PaymentType.FIAT && isDocAccepted, [paymentType])
  const isCrypto = useMemo(() => paymentType === PaymentType.CRYPTO || !isDocAccepted, [paymentType])

  const handlePaymentTypeChange = (_paymentType: PaymentType) => {
    setSetting(Setting.PAYMENT_TYPE, _paymentType)
  }

  return (
    <Container>
      <Title>Donate</Title>
      <PreTitle>Select preferred donation method</PreTitle>
      <DocVerificationStatus onDocVerification={onDocVerification} docStatus={docStatus} />
      <PaymentSelectorWrap>
        <PaymentSelector
          isDisabled={!isDocAccepted}
          checkedPaymentType={paymentType}
          onChangePaymentType={handlePaymentTypeChange}
        />
      </PaymentSelectorWrap>
      {isCrypto && <CryptoPayment />}
      {isFiat && <FiatPayment />}
    </Container>
  )
}
