import { HeaderGroup } from 'react-table'
import { SkeletonElement } from 'components/ui'

import { DefaultTableOptions } from '../types'
import { Header as StyledHeader, LoadingCell, LoadingItemBulk } from './Style'

interface HeaderProps<R extends DefaultTableOptions> {
  type?: string
  headerGroups: HeaderGroup<R>[]
}

export function LoadingRow<T extends DefaultTableOptions>(props: HeaderProps<T>) {
  const { type, headerGroups } = props

  return (
    <>
      {headerGroups.map((group) => {
        const { key, style, ...rest } = group.getHeaderGroupProps()

        return (
          <StyledHeader key={key} type={type} style={style} {...rest}>
            {group.headers.map((columns) => {
              // there is no way to nicely extend the column type
              // if the tooltip is passed differently,
              // the code is increased several times
              // @ts-ignore
              const { getHeaderProps } = columns
              const { key: columnKey, ...restColumnProps } = getHeaderProps()

              return (
                <LoadingCell key={columnKey} {...restColumnProps}>
                  <LoadingItemBulk>
                    <SkeletonElement height='2rem' borderRadius='0.4rem' />
                    <SkeletonElement height='1.6rem' borderRadius='0.4rem' />
                  </LoadingItemBulk>
                </LoadingCell>
              )
            })}
          </StyledHeader>
        )
      })}
    </>
  )
}
