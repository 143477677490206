import styled from 'styled-components/macro'

import { Button, Icon } from 'components/ui'

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`

export const ImgChain = styled.img`
  width: 3.2rem;
  height: 3.2rem;
`

export const StyledButton = styled(Button)`
  padding: 0;
  height: auto;
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    padding: 0.2rem 0.1rem 0.2rem 1.2rem;
    height: 4.2rem;
  `};
`

export const ChainButton = styled(Button)`
  padding: 0;
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    padding: 0.4rem 1rem;
  `};
`

export const ChainButtonName = styled.span`
  display: none;
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    display: block;
  `};
`

export const ChevronIcon = styled(Icon)`
  display: none;
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    display: block;
  `};
`

export const AccountBalance = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    display: block;
  `};
`

export const AccountName = styled.span`
  padding: 0.8rem;
  max-width: 16rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: ${(props) => props.theme.color.defaultLight};
  border-radius: 2.2rem;
  overflow: hidden;
`
