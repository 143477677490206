import styled from 'styled-components/macro'

export const Section = styled.section`
  width: 100%;
`

export const Container = styled.div`
  margin: 3.2rem auto;
  padding: 1.6rem;
  width: 100%;
  max-width: 78.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Wrap = styled.div`
  margin: 0 0 2.6rem;
  padding: 2.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.color.default};
  border-radius: 2.4rem;
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    padding: 2.2rem 3rem;
  `};
`

export const Title = styled.h1`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 3.2rem;
  line-height: 1.44;
  color: ${(props) => props.theme.color.textPrimary};
`

export const Info = styled.div`
  margin: 0 auto;
  padding: 1.6rem;
  width: calc(100% - 3.2rem);
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 1.2rem;
  box-shadow: 0 0.4rem 1.6rem 0 ${(props) => props.theme.color.elementBorder};
`

export const InfoContent = styled.div`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.2rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.8rem;
  line-height: 1.33;
  text-align: left;
  color: ${(props) => props.theme.color.text};
`

export const InfoContentValue = styled.span`
  flex: 0 0 auto;
`

export const VerifyWrap = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.white};
`
