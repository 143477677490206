import styled from 'styled-components/macro'

export const Wrap = styled.div<{ height?: string }>`
  padding: 3rem;
  width: 100%;
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.4rem;
  line-height: 1.14;
  color: ${(props) => props.theme.color.text};
`
