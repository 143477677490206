import { useMemo } from 'react'
import { Kyc } from 'types/entities'

import { CellProps, Badge } from 'components/ui'

import { Wrapper, Title, Value } from '../Style'

export function Sanction({ row }: CellProps<Kyc>) {
  const { isSanction } = row.original

  const status = useMemo(() => (isSanction ? 'sanction' : 'clear'), [isSanction])
  return (
    <Wrapper area='Status'>
      <Title>Sanction</Title>
      <Value>
        <Badge value={status} />
      </Value>
    </Wrapper>
  )
}
