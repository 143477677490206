const DottedLine = () => (
  <svg viewBox='0 0 1000 12'>
    <linearGradient id='mask-opacity_DottedLine' x1='100%' y1='0' x2='100%' y2='0' gradientUnits='objectBoundingBox'>
      <animate attributeName='x1' values='-100%;100%;' dur='3s' repeatCount='indefinite' />
      <stop stopColor='rgb(0,0,0)' offset='0%' />
      <stop stopColor='rgb(0,0,0)' offset='35%' />
      <stop stopColor='rgb(255,255,255)' offset='45%' />
      <stop stopColor='rgb(255,255,255)' offset='55%' />
      <stop stopColor='rgb(0,0,0)' offset='65%' />
      <stop stopColor='rgb(0,0,0)' offset='100%' />
    </linearGradient>
    <mask id='mask-appear_DottedLine' maskUnits='userSpaceOnUse' maskContentUnits='userSpaceOnUse'>
      <rect width='100%' height='100%' fill='url(#mask-opacity_DottedLine)' />
    </mask>
    <line
      className='tech-line-back'
      y1='6'
      x1='0'
      y2='6'
      x2='100%'
      strokeLinecap='round'
      strokeDasharray='1,24'
      strokeWidth='8'
      stroke='#272727'
    />
    <line
      className='tech-line-front'
      y1='6'
      x1='0'
      y2='6'
      x2='100%'
      strokeLinecap='round'
      strokeDasharray='1,24'
      strokeWidth='12'
      stroke='#3a6355'
      mask='url(#mask-appear_DottedLine)'
    />
  </svg>
)

export { DottedLine }
