import { Doc } from 'types/entities'

import { CellProps } from 'components/ui'

import { Wrapper, Title, Value } from '../Style'

export function Sof({ row }: CellProps<Doc>) {
  const { sourceOfFunds } = row.original

  return (
    <Wrapper area='SoF'>
      <Title>SoF</Title>
      <Value>{sourceOfFunds}</Value>
    </Wrapper>
  )
}
