import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

import { StyledInputProps } from './@types'
import { getSize } from './helpers'

export const Wrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
`

export const Label = styled.label`
  margin: 0 0 0.4rem;
  display: inline-block;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.4rem;
  line-height: 1.43;
  color: ${(props) => props.theme.color.text};
`

export const Container = styled.div`
  position: relative;
`

export const InputElement = styled.input<StyledInputProps>`
  position: relative;
  margin: 0;
  padding: ${({ inputSize }) => getSize(inputSize).padding};
  display: block;
  width: 100%;
  font-family: ${(props) => props.theme.font.family.default};
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: ${({ inputSize }) => getSize(inputSize).fontSize};
  line-height: ${({ inputSize }) => getSize(inputSize).lineHeight};
  text-align: left;
  border: 0.1rem solid ${(props) => transparentize(0.5, props.theme.color.black)};
  border-radius: 1rem;
  box-shadow: none;
  cursor: text;
  transition: color 0.2s ease-out, background-color 0.3s ease-out, border-color 0.3s ease-out, box-shadow 0.3s ease-out;
  &::placeholder {
    font-family: ${(props) => props.theme.font.family.default};
    font-weight: ${(props) => props.theme.font.weight.regular};
    font-size: ${({ inputSize }) => getSize(inputSize).fontSize};
    line-height: ${({ inputSize }) => getSize(inputSize).lineHeight};
    color: ${(props) => transparentize(0.3, props.theme.color.text)};
    transform-origin: left center;
  }
  &:hover,
  &:focus {
    -moz-appearance: number-input;
  }
  &:disabled {
    opacity: ${(props) => props.theme.opacity.opacity05};
  }
  &[readonly] {
    cursor: no-drop;
    opacity: ${(props) => props.theme.opacity.opacity05};
  }
  &[type='password'] {
    letter-spacing: normal;
    &::placeholder {
      letter-spacing: normal;
    }
  }

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.inputType === 'default' &&
    css`
      color: ${(props) => props.theme.color.text};
      background-color: ${(props) => props.theme.color.inputColor};
      &:hover:not([disabled]) {
        box-shadow: inset 0 0 0 0.1rem ${(props) => props.theme.color.default};
        border-color: ${(props) => props.theme.color.black};
      }
      &:focus:not([disabled]) {
        background-color: ${(props) => props.theme.color.white};
        border-color: ${(props) => props.theme.color.default};
        box-shadow: inset 0 0 0 0.2rem ${(props) => props.theme.color.default};
        caret-color: ${(props) => props.theme.color.text};
        outline: none;
      }
      &:active:not([disabled]) {
        background-color: ${(props) => props.theme.color.white};
        border-color: ${(props) => props.theme.color.default};
      }
    `}

  ${(props) =>
    props.inputType === 'outlined' &&
    css`
      color: ${(props) => props.theme.color.text};
      background-color: ${(props) => props.theme.color.default};
      border-color: ${(props) => props.theme.color.elementBorder};
      &:hover:not([disabled]) {
        border-color: ${(props) => props.theme.color.elementBorderSecond};
      }
      &:focus:not([disabled]) {
        border-color: ${(props) => props.theme.color.default};
        caret-color: ${(props) => props.theme.color.text};
        outline: none;
      }
      &:active:not([disabled]) {
        border-color: ${(props) => props.theme.color.default};
      }
    `}

  ${(props) =>
    props.error &&
    css`
      border-color: ${(props) => props.theme.color.danger};
    `}

  ${(props) =>
    props.type === 'search' &&
    css`
      padding-left: 5.2rem;
      padding-right: 5.2rem;
    `}

  ${(props) =>
    props.iconInput &&
    css`
      padding-left: 5.9rem;
    `}

  ${(props) =>
    props.tickerInput &&
    css`
      padding-right: 5.2rem;
    `}

  ${(props) =>
    props.name === 'password' &&
    css`
      padding-right: 5.4rem;
    `}

  ${(props) =>
    props.name === 'new-password' &&
    css`
      padding-right: 5.4rem;
    `}
`

export const InputBtn = styled.button<{ inputType: 'default' | 'outlined' }>`
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  padding: 0.6rem;
  width: 6.8rem;
  height: calc(100% - 0.2rem);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.default};
  background-color: ${(props) => props.theme.color.default};
  border: none;
  border-radius: 1rem;
  box-shadow: none;
  transition: background-color 0.3s ease-out;
  cursor: pointer;
  z-index: ${(props) => props.theme.zIndex.minimal};
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.color.defaultSecondary};
    outline: none;
  }
`

export const InputIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
  width: 2.8rem;
  color: ${(props) => props.theme.color.elementBgSecond};
  background-color: ${(props) => props.theme.color.transparent};
  z-index: ${(props) => props.theme.zIndex.minimal};
`

export const InputIconSearch = styled.span`
  position: absolute;
  top: 50%;
  left: 1.8rem;
  transform: translateY(-50%);
  width: 2rem;
  color: ${(props) => props.theme.color.elementBgSecond};
  background-color: ${(props) => props.theme.color.transparent};
  z-index: ${(props) => props.theme.zIndex.minimal};
`

export const ButtonSearchClean = styled.button`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  padding: 0.6rem;
  width: 2.8rem;
  color: ${(props) => props.theme.color.elementBgSecond};
  background-color: ${(props) => props.theme.color.transparent};
  border: 0;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  z-index: ${(props) => props.theme.zIndex.minimal};
  &:hover,
  &:focus {
    outline: none;
  }
`

export const InputTicker = styled.span`
  position: absolute;
  top: 50%;
  right: 1.6rem;
  transform: translateY(-50%);
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 1.4rem;
  line-height: 1.71;
  color: ${(props) => props.theme.color.textSecondary};
  text-transform: uppercase;
  background-color: ${(props) => props.theme.color.transparent};
  z-index: ${(props) => props.theme.zIndex.minimal};
`

export const InputIconPassword = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  padding: 2rem 2rem 2rem 1rem;
  color: ${(props) => props.theme.color.textSecondary};
  background-color: ${(props) => props.theme.color.transparent};
  border: 0;
  box-shadow: none;
  opacity: ${(props) => props.theme.opacity.opacity05};
  cursor: pointer;
  transform: translateY(-50%);
  transition: opacity 0.3s ease-out;
  z-index: ${(props) => props.theme.zIndex.minimal};

  &:hover,
  &:focus {
    opacity: ${(props) => props.theme.opacity.default};
    outline: none;
  }
`

export const ErrorMessage = styled.span`
  position: absolute;
  top: calc(100% + 0.2rem);
  left: 0;
  padding: 0.2rem 0.8rem;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.2rem;
  line-height: 1.67;
  letter-spacing: 0.03rem;
  color: ${(props) => props.theme.color.textPrimary};
  background-color: ${(props) => props.theme.color.danger};
  border-radius: 0.8rem;
  z-index: ${(props) => props.theme.zIndex.minimal};
`
