import { createReducer } from '@reduxjs/toolkit'

import { Notifications } from './@types'
import { callNotification, hideNotification, removeNotification } from './actions'

const initialState: Notifications = []

export default createReducer(initialState, (builder) =>
  builder
    .addCase(callNotification, (state, action) => {
      return [{ ...action.payload }, ...state]
    })
    .addCase(removeNotification, (state, action) => {
      return state.filter(({ id }) => id !== action.payload.id)
    })
    .addCase(hideNotification, (state, action) => {
      return state.map((notification) =>
        notification.id === action.payload.id ? { ...notification, isHidden: true } : notification,
      )
    }),
)
