const Fingerprint = () => (
  <svg viewBox='0 0 64 64'>
    <path
      fillRule='evenodd'
      d='M32 7.333C18.438 7.333 7.334 18.438 7.334 32a2 2 0 0 1-4 0C3.333 16.229 16.229 3.333 32 3.333A28.667 28.667 0 0 1 54.934 14.8a2 2 0 0 1-3.2 2.4A24.667 24.667 0 0 0 32 7.333Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M17.566 24.779a2 2 0 0 1 1.23 2.547A14.115 14.115 0 0 0 18 32c0 8.16-1.352 16.383-2.77 20.633a2 2 0 1 1-3.794-1.265C12.686 47.618 14 39.839 14 32c0-2.098.36-4.108 1.019-5.993a2 2 0 0 1 2.547-1.229ZM47.634 46.01a2 2 0 0 1 1.797 2.184c-.198 2.033-1.044 6.66-1.363 8.252a2 2 0 0 1-3.922-.785c.321-1.607 1.128-6.048 1.304-7.855a2 2 0 0 1 2.184-1.797Zm-20.82-19.195A7.333 7.333 0 0 1 32 24.667a2 2 0 0 1 0 4A3.333 3.333 0 0 0 28.667 32c0 2.82-.275 6.871-.705 10.88a2 2 0 0 1-3.977-.427c.423-3.938.682-7.833.682-10.453 0-1.945.772-3.81 2.147-5.185Zm-1.88 24.549a2 2 0 0 1 1.622 2.317c-.286 1.62-.81 3.22-1.278 4.65-.106.324-.209.638-.306.942a2 2 0 0 1-3.811-1.213c.118-.373.236-.734.351-1.086.46-1.411.873-2.675 1.105-3.988a2 2 0 0 1 2.317-1.622Zm12.399-18.377a2 2 0 0 1 2 2v.023c0 3.164 0 7.48-.34 11.852-.337 4.35-1.021 8.927-2.47 12.547a2 2 0 1 1-3.713-1.485c1.218-3.046 1.867-7.135 2.196-11.372.326-4.205.327-8.385.327-11.565a2 2 0 0 1 2-2Zm-34 9.68a2 2 0 0 1 2-2h.027a2 2 0 1 1 0 4h-.027a2 2 0 0 1-2-2Zm54.403-17.961a2 2 0 0 1 2.357 1.563c.122.6.203 1.63.26 2.775.06 1.198.1 2.673.111 4.254.023 3.148-.064 6.798-.34 9.568a2 2 0 0 1-3.98-.398c.256-2.564.343-6.052.32-9.14a99.772 99.772 0 0 0-.106-4.085c-.059-1.183-.132-1.918-.185-2.179a2 2 0 0 1 1.563-2.358Z'
      clipRule='evenodd'
    />
    <path
      fillRule='evenodd'
      d='M32.002 17.99c-2.458 0-4.873.646-7.002 1.875a2 2 0 0 1-2-3.464 18 18 0 0 1 27 15.6L48 32h2v.003c0 1.255 0 3.141-.054 5.379a2 2 0 1 1-3.999-.097C46 35.098 46 33.252 46 32v-.001a14 14 0 0 0-13.998-14.01Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Fingerprint }
