import { InputElementProps } from '../@types'
import { Label, Radio } from '../Style'

const SwitchButton = ({
  id,
  label,
  disabled,
  quantity,
  typeSwitch = 'default',
  sizeSwitch = 'default',
  ...rest
}: InputElementProps) => {
  return (
    <>
      <Radio id={id} type='radio' typeSwitch={typeSwitch} disabled={disabled} {...rest} />
      <Label htmlFor={id} quantity={quantity} disabled={disabled} typeSwitch={typeSwitch} sizeSwitch={sizeSwitch}>
        {label}
      </Label>
    </>
  )
}

export { SwitchButton }
