export enum ConfirmationStep {
  REVOKE = 1,
  APPROVE = 2,
  CONFIRM = 3,
}

export enum ConfirmationStatuses {
  IN_LINE,
  PREPARE,
  PENDING,
  COMPLETE,
  FAILED,
}

export type Step = {
  label: string
  key: ConfirmationStep
  status: ConfirmationStatuses
}

export type Steps = Record<ConfirmationStep, Step>
