import { getEtherscanLink } from 'utils/crypto'
import { Button, Icon, Loader } from 'components/ui'

import { InfoTextSmall, StyledLink, Title, Text, WrapContentApprove, Wrapper } from '../Style'

type Props = {
  txHash: string
  txType?: string
  chainId: number
  isSent?: boolean
  pendingText?: string
  successText?: string
  errorMessage: string
  successTitle?: string
  onCurrentClose?: VoidFunction
}

export function TxStatus({
  isSent,
  txType,
  txHash,
  chainId,
  pendingText,
  successTitle,
  successText,
  errorMessage,
}: Props) {
  return (
    <>
      {!isSent && txHash && !errorMessage && (
        <WrapContentApprove>
          <Wrapper>
            <Loader size={7.2} ringSize={0.6} />
          </Wrapper>
          <Text>Your transaction is&nbsp;being processed, please wait.</Text>
          {pendingText && <InfoTextSmall>{pendingText}</InfoTextSmall>}
        </WrapContentApprove>
      )}

      {isSent && txHash && !errorMessage && (
        <WrapContentApprove>
          <Wrapper type='success'>
            <Icon name='success' size='xxl' />
          </Wrapper>
          {successTitle && <Title>{successTitle}</Title>}
          <Text>
            {txType} transaction has been sent.
            {successText && (
              <>
                <br />
                {successText}
              </>
            )}
          </Text>
          <StyledLink href={`${getEtherscanLink(chainId, txHash, 'transaction')}`}>
            <Button type='link'>
              Explore Tx <Icon name='externalLink' size='extraMedium' />
            </Button>
          </StyledLink>
        </WrapContentApprove>
      )}

      {errorMessage && (
        <WrapContentApprove>
          <Wrapper type='error'>
            <Icon name='error' size='xxl' />
          </Wrapper>
          <Text type='error'>{errorMessage}</Text>
        </WrapContentApprove>
      )}
    </>
  )
}
