import { ReactNode } from 'react'
import { StyledWrap } from './Style'

type Props = {
  gridArea?: string
  children: ReactNode
}

const Wrap = ({ gridArea, children }: Props) => {
  return <StyledWrap gridArea={gridArea}>{children}</StyledWrap>
}

export { Wrap }
