import styled from 'styled-components/macro'

export const Layout = styled.div`
  padding: 0;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.transparent};
`
