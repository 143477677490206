const Rounded = () => (
  <svg viewBox='0 0 20 24'>
    <circle cx='1.089' cy='1.089' r='1.089' />
    <circle cx='1.089' cy='5.454' r='1.089' />
    <circle cx='1.089' cy='9.817' r='1.089' />
    <circle cx='1.089' cy='14.183' r='1.089' />
    <circle cx='1.089' cy='18.546' r='1.089' />
    <circle cx='1.089' cy='22.911' r='1.089' />
    <circle cx='5.453' cy='1.089' r='1.089' />
    <circle cx='5.453' cy='5.454' r='1.089' />
    <circle cx='5.453' cy='9.817' r='1.089' />
    <circle cx='5.453' cy='14.183' r='1.089' />
    <circle cx='5.453' cy='18.546' r='1.089' />
    <circle cx='5.453' cy='22.911' r='1.089' />
    <circle cx='9.818' cy='1.089' r='1.089' />
    <circle cx='9.818' cy='5.454' r='1.089' />
    <circle cx='9.818' cy='9.817' r='1.089' />
    <circle cx='9.818' cy='14.183' r='1.089' />
    <circle cx='9.818' cy='18.546' r='1.089' />
    <circle cx='9.818' cy='22.911' r='1.089' />
    <circle cx='14.182' cy='1.089' r='1.089' />
    <circle cx='14.182' cy='5.454' r='1.089' />
    <circle cx='14.182' cy='9.817' r='1.089' />
    <circle cx='14.182' cy='14.183' r='1.089' />
    <circle cx='14.182' cy='18.546' r='1.089' />
    <circle cx='14.182' cy='22.911' r='1.089' />
    <circle cx='18.547' cy='1.089' r='1.089' />
    <circle cx='18.547' cy='5.454' r='1.089' />
    <circle cx='18.547' cy='9.817' r='1.089' />
    <circle cx='18.547' cy='14.183' r='1.089' />
    <circle cx='18.547' cy='18.546' r='1.089' />
    <circle cx='18.547' cy='22.911' r='1.089' />
  </svg>
)

export { Rounded }
