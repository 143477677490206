import { useCallback, useEffect } from 'react'

import { useEthersProvider } from 'hooks/provider'

import { usePendingTransactions, useSetTransaction } from 'state/user/hooks'
import { UserOperationsStatuses, UserTransaction } from 'state/user/types'

import { isEmpty } from 'utils/lodash'
import { RPC_LIST } from 'constants/chains'
import { getProvider } from 'services/ether'

export function useWaitForTransaction() {
  const provider = useEthersProvider()
  const setTransaction = useSetTransaction()
  return useCallback(
    async (tx: UserTransaction) => {
      try {
        if (!tx.txHash || !tx.chainId) {
          return
        }
        const provider = getProvider(tx.chainId, RPC_LIST[tx.chainId])
        const receipt = await provider.waitForTxReceipt({ txHash: tx.txHash })

        if (Number(receipt.status) === 0) {
          throw new Error('Transaction failed: unknown reason')
        }

        const { timestamp } = await provider.provider.getBlock(receipt.blockNumber)

        setTransaction({ ...tx, timestamp, status: UserOperationsStatuses.COMPLETE })
      } catch (err) {
        if (tx.chainId) {
          const provider = getProvider(tx.chainId, RPC_LIST[tx.chainId])
          const { timestamp } = await provider.provider.getBlock('latest')

          setTransaction({ ...tx, timestamp, status: UserOperationsStatuses.FAILED })
        } else {
          setTransaction({ ...tx, timestamp: Date.now() / 1000, status: UserOperationsStatuses.FAILED })
        }
      }
    },
    [setTransaction, provider],
  )
}

const processingTxs = new Map()

export function usePendingTransactionsListener() {
  const pendingTxs = usePendingTransactions()

  const waitForTransaction = useWaitForTransaction()

  const onTxHandling = useCallback(() => {
    Object.values(pendingTxs).forEach((tx) => {
      if (!processingTxs.has(tx.txHash)) {
        processingTxs.set(tx.txHash, tx)
        waitForTransaction(tx).finally(() => processingTxs.delete(tx.txHash))
      }
    })
  }, [pendingTxs, waitForTransaction, processingTxs])

  useEffect(() => {
    if (!isEmpty(pendingTxs)) {
      onTxHandling()
    }
  }, [pendingTxs])
}
