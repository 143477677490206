import { Doc } from 'types/entities'

import { CellProps, Icon } from 'components/ui'

import { Wrapper, Title, Value, StyledHref } from '../Style'

export function DocLink({ row }: CellProps<Doc>) {
  const { link } = row.original

  return (
    <Wrapper area='Token'>
      <Title>Doc Link</Title>
      <Value>
        <StyledHref href={link}>
          Download
          <Icon name='download' size='mediumPlus' />
        </StyledHref>
      </Value>
    </Wrapper>
  )
}
