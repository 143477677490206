import { useMemo } from 'react'

import { DateTime } from 'luxon'

import { Doc } from 'types/entities'

import { CellProps, TimeCell } from 'components/ui'

export function TimeUpdated({ row }: CellProps<Doc>) {
  const { updatedAt } = row.original

  const _time = useMemo(() => DateTime.fromISO(updatedAt.toString()).toMillis(), [updatedAt])

  return <TimeCell area='UpdatedAt' title='Updated at' timestamp={_time} />
}
