const palette = {
  transparent: 'transparent',

  white: '#fff',
  whiteMedium: '#f6f8ff',
  whiteHard: '#dedfe3',
  whiteOpacity01: 'hsla(0, 0%, 100%, 0.1)',

  black: '#000',
  black06: 'hsla(0, 0%, 0%, 0.6)',
  black015: 'hsla(0, 0%, 0%, 0.15)',
  blackGradient: `linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0)
    )`,

  dark: '#272727',
  darkLight: '#485972',

  cian: '#3a6355',
  cianLight: '#dedb7b',
  pink: '#fec1ae',

  green: '#3ac63F',
  yellow: '#f5d423',
  orange: '#ca7f0e',
  red: '#e76452',
}

export const color = {
  transparent: palette.transparent,

  white: palette.white,
  black: palette.black,

  default: palette.cian,
  defaultLight: palette.cianLight,

  text: palette.dark,
  textHint: palette.cian,
  textPrimary: palette.white,
  textSecondary: palette.darkLight,
  textTertiary: palette.cianLight,

  button: palette.cian,
  buttonWave: palette.whiteOpacity01,

  pageBg: palette.whiteMedium,

  elementBg: palette.white,
  elementBgSecond: palette.dark,

  elementBorder: palette.whiteHard,

  inputColor: palette.white,
  tooltipColor: palette.dark,
  scrollbarColor: palette.dark,

  success: palette.green,
  warning: palette.yellow,
  risky: palette.orange,
  danger: palette.red,

  modalBg: palette.whiteMedium,
  modalOverflow: palette.black06,
  modalBgContent: palette.white,

  menuShadow: palette.black015,
  placeholderLoading: palette.blackGradient,
}
