import { useMemo } from 'react'
import { useTable, usePagination, useColumnOrder } from 'react-table'

import { generateId } from 'utils/helpers'
import { numbers } from 'constants/variables'

import { useWindowSize } from 'hooks/listeners'
import { NoData, Pagination } from 'components/ui'

import { TableProperties, DefaultTableOptions } from './types'
import { Header, Row, LoadingRow, LoadingMobileRow } from './components'
import { Wrap, Container, StyleTable, Title, RowGroup } from './Style'

const hooks = [usePagination, useColumnOrder]

const PAGE_STEP = 1

function Table<T extends DefaultTableOptions>({
  type,
  onPage,
  isLoading,
  showText,
  MobileRow,
  page = 1,
  title = '',
  lastPage = 0,
  noScroll = false,
  itemsPerPage = 4,
  withPagination = true,
  ...rest
}: TableProperties<T>) {
  const windowSize = useWindowSize()
  const instance = useTable<T>(rest, ...hooks)

  const defaultEmptyRows = useMemo(() => {
    const emptyArray = Array.from({ length: itemsPerPage }, () => ({ key: generateId() }))
    return emptyArray
  }, [itemsPerPage])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = instance

  const shouldPaginationShow = useMemo(
    () => Boolean(withPagination && page && lastPage),
    [page, lastPage, withPagination],
  )

  const isDesktopWidth = useMemo(() => windowSize.width >= numbers.MD_WIDTH, [windowSize.width])
  const shouldLoadingShow = useMemo(() => Boolean(isLoading && rows.length), [isLoading, rows.length])

  return (
    <Wrap>
      <Container type={type} noScroll={!rows.length || noScroll}>
        <StyleTable {...getTableProps()}>
          {title && <Title>{title}</Title>}

          {MobileRow ? (
            <>{isDesktopWidth && <Header<T> headerGroups={headerGroups} type={type} />}</>
          ) : (
            <Header<T> headerGroups={headerGroups} type={type} />
          )}

          {rows.length > 0 && (
            <RowGroup {...getTableBodyProps()}>
              {shouldLoadingShow
                ? rows.map((row) =>
                    MobileRow && !isDesktopWidth ? (
                      <LoadingMobileRow key={row.id} />
                    ) : (
                      <LoadingRow<T> key={row.id} headerGroups={headerGroups} type={type} />
                    ),
                  )
                : rows.map((row) =>
                    MobileRow && !isDesktopWidth ? (
                      <MobileRow key={row.id} row={row} />
                    ) : (
                      <Row<T> key={row.id} row={row} prepareRow={prepareRow} type={type} />
                    ),
                  )}
            </RowGroup>
          )}
        </StyleTable>

        {!rows.length && (
          <>
            {isLoading ? (
              defaultEmptyRows.map((el) => {
                MobileRow && !isDesktopWidth ? (
                  <LoadingMobileRow key={el.key} />
                ) : (
                  <LoadingRow<T> key={el.key} headerGroups={headerGroups} type={type} />
                )
              })
            ) : (
              <NoData height='22.4rem' />
            )}
          </>
        )}
      </Container>

      {shouldPaginationShow && onPage && !shouldLoadingShow && lastPage !== numbers.ONE && (
        <Pagination total={lastPage} current={page || PAGE_STEP} onPageClick={onPage} showText={showText} />
      )}
    </Wrap>
  )
}

export { Table }
