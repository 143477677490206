import styled, { css } from 'styled-components/macro'

export const FormWrap = styled.div<{ withLocation: boolean }>`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 2rem 1.4rem;
  grid-template-areas:
    'FirstName'
    'LastName';
  ${(props) =>
    props.withLocation &&
    css`
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
      grid-auto-columns: 1fr;
      gap: 2rem 1.4rem;
      grid-template-areas:
        'FirstName LastName'
        'AddressLineOne AddressLineOne'
        'AddressLineTwo AddressLineTwo'
        'City Region'
        'Country Zip';
    `}
`

export const ContainerKYC = styled.div`
  margin: 0;
  padding: 3rem 1.6rem;
  width: 100%;
  max-width: 40rem;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 2.4rem;
  box-shadow: 0 0.4rem 1.6rem 0 ${(props) => props.theme.color.elementBorder};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
     padding: 3rem 2.4rem;
  `};
`
