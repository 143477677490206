import { combineReducers } from 'redux'

import modal from 'modal/state/reducer'
import notifications from 'notifications/service/reducer'

import auth from './auth/slice'
import { authApi } from './auth/api'

import user from './user/slice'
import { userApi } from './user/api'

// verification
import kyc from './kyc/slice'
import { kycApi } from './kyc/api'
import { docsApi } from './docs/api'

// multicall
import { multicalls } from './multicalls'
import { donationApi } from './donation/api'

export const rootReducer = combineReducers({
  kyc,
  auth,
  user,
  modal,
  notifications,
  // API
  // user
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  // verification
  [kycApi.reducerPath]: kycApi.reducer,
  [docsApi.reducerPath]: docsApi.reducer,
  // donate
  [donationApi.reducerPath]: donationApi.reducer,
  // MULTICALL
  [multicalls.balances.reducerPath]: multicalls.balances.reducer,
  [multicalls.allowances.reducerPath]: multicalls.allowances.reducer,
})
