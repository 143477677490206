import { Link } from 'react-router-dom'

import { usePathParams } from 'hooks/router'
import { Icon } from 'components/ui'

import { TabElementProps, TabProps } from './@types'
import { TabButton, IconWrap, TabInfo } from './Style'

function TabElement({ title, onClick, isActive, dataUpdate, dataIcon }: TabElementProps) {
  return (
    <TabButton isActive={isActive} onClick={onClick}>
      {dataIcon && (
        <IconWrap>
          <Icon name={dataIcon} />
        </IconWrap>
      )}
      {title}
      {dataUpdate && <TabInfo>{dataUpdate}</TabInfo>}
    </TabButton>
  )
}

function Tab(props: TabProps) {
  const { id, isLink, onClick } = props

  const { pathname } = usePathParams()

  const onHeaderClick = () => {
    onClick(id)
  }

  if (isLink) {
    return (
      <Link to={`${pathname}?tab=${id}`}>
        <TabElement {...props} onClick={onHeaderClick} />
      </Link>
    )
  }

  return <TabElement {...props} onClick={onHeaderClick} />
}

export { Tab }
