import { useMemo } from 'react'

import { useFeeData } from 'wagmi'

import { Token } from 'types/entities'

import { useApprove } from 'hooks/contracts/useApprove'
import { useDonationsDonate } from 'hooks/contracts/generated'

export function useRequestController(token?: Token) {
  const { approve, ...approveRequest } = useApprove(token?.address)
  const { writeAsync: asyncDonate, ...donationRequest } = useDonationsDonate()
  const feeData = useFeeData({ watch: false })

  const _isTxLoading = useMemo(
    () => donationRequest.isLoading || approveRequest.isLoading,
    [donationRequest.isLoading, approveRequest.isLoading],
  )

  const _isLoading = useMemo(() => _isTxLoading || feeData.isLoading, [_isTxLoading, feeData.isLoading])

  return {
    approve,
    approveRequest,
    donate: asyncDonate,
    isLoading: _isLoading,
  }
}
