import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

import { Button, BtnBurger } from 'components/ui'

export const Wrap = styled.header`
  position: sticky;
  top: 0;
  margin: 0;
  width: 100%;
  height: 5.8rem;
  background-color: ${(props) => props.theme.color.default};
  z-index: ${(props) => props.theme.zIndex.navigation};
`

export const Container = styled.div<{ isAuth?: boolean }>`
  margin: 0 auto;
  padding: 0 1.6rem;
  max-width: 144rem;
  height: 5.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: max-width 0.3s ease;
  &:last-child {
    flex: 0 0 auto;
  }
  ${(props) =>
    props.isAuth &&
    css`
      max-width: 144rem;
    `}
  ${(props) => props.theme.breakpoints.greaterThan('xl')`
    max-width: 100%;
    ${
      props.isAuth &&
      css`
        max-width: 144rem;
      `
    }
  `};
`

export const SignBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

export const SignText = styled.h5`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: ${(props) => props.theme.font.size.small};
  line-height: ${(props) => props.theme.font.lineHeight.smaller};
  color: ${(props) => props.theme.color.textPrimary};
  text-align: right;
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
    font-size: ${props.theme.font.size.default};
    text-align: left;
  `};
`

export const StyledButton = styled(Button)`
  padding: 1rem 1.2rem;
  width: 13.2rem;
`

export const Nav = styled.nav<{ isOpen: boolean }>`
  padding: 0 1.6rem;
  position: absolute;
  left: 0;
  top: 5.8rem;
  right: 0;
  padding: 0;
  height: calc(100vh - 5.8rem);
  display: flex;
  flex-direction: column;
  transition: all 0.3 ease-out;
  overflow: auto;
  z-index: ${(props) => props.theme.zIndex.minimal};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? props.theme.opacity.default : props.theme.opacity.invisible)};
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    position: static;
    top: auto;
    left: auto;
    right: auto;
    margin: 0 0 0 1.6rem;
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    white-space: nowrap;
    background-color: ${props.theme.color.transparent};
    visibility: visible;
    opacity: ${props.theme.opacity.default};
  `};
`

export const List = styled.ul`
  margin: 0;
  padding: 0 0 3rem;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: auto;
  list-style: none;
  gap: 0;
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 auto;
    height: 100%;
    list-style: none;
    gap: 1.2rem;
  `};
  ${(props) => props.theme.breakpoints.greaterThan('xl')`
    gap: 1.6rem;
  `};
`

export const ListItem = styled.li<{ badge?: string }>`
  position: relative;
  padding: 0;
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  font-family: ${(props) => props.theme.font.family.default};
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: 1.8rem;
  line-height: 1.43;
  color: ${(props) => props.theme.color.textPrimary};
  &:not(:last-child) {
    border-bottom: 0.1rem solid ${(props) => transparentize(0.6, props.theme.color.white)};
  }
  &::before {
    display: none;
  }
  ${(props) =>
    props.badge === 'soon' &&
    css`
      padding: 1.4rem;
      cursor: no-drop;
      pointer-events: none;
      user-select: none;
    `}
  ${(props) =>
    props.badge &&
    css`
      &::after {
        content: '${props.badge}';
        position: absolute;
        top: 0.4rem;
        left: auto;
        margin: 0 0 0 0.8rem;
        padding: 0.15rem 0.4rem;
        display: none;
        font-weight: ${(props) => props.theme.font.weight.medium};
        font-size: 0.9rem;
        line-height: 1.44;
        text-transform: capitalize;
        color: ${(props) => props.theme.color.text};
        background-color: ${(props) => props.theme.color.black06};
        border-radius: 0.6rem;
        ${(props) => props.theme.breakpoints.greaterThan('md')`
          top: -0.2rem;
          right: 1rem;
          left: unset;
          display: inline-block;
        `};
      }
    `}
  a {
    padding: 1.4rem;
    width: 100%;
    &:hover,
    &.activeNavLink {
      color: ${(props) => props.theme.color.textTertiary};
    }
  }
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    font-size: 1.6rem;
    &:not(:last-child) {
      border-bottom: none;
    }
  `};
`

export const WrapConnectButton = styled.div`
  margin: 0 0 0 auto;
  flex: 0 0 auto;
`

export const Burger = styled(BtnBurger)<{ onClick: VoidFunction }>`
  margin: 0 0 0 1.2rem;
  color: ${(props) => props.theme.color.white};
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    display: none;
  `};
`

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 5.8rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.color.elementBgSecond};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? props.theme.opacity.default : props.theme.opacity.invisible)};
  transition: opacity 0.2s ease-out;
  z-index: ${(props) => props.theme.zIndex.default};
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    display: none;
    visibility: hidden;
  `};
`
