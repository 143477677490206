import { useLocation } from 'react-router'
import { Navigate, Outlet } from 'react-router-dom'

import { useIsAuth } from 'state/auth/hooks'

import { Links } from 'constants/link'

export function PrivateOutlet() {
  const isAuth = useIsAuth()
  const location = useLocation()

  return isAuth ? <Outlet /> : <Navigate to={Links.SIGN_IN} state={{ from: location }} />
}
