import { useMemo, useState } from 'react'

import { debounce } from 'utils/lodash'
import { validations } from 'utils/validation'

const MIN_LENGTH = 6
export function usePasswordFieldData(withValidation = true) {
  const [value, setValue] = useState('')
  const [isValidValue, setIsValidValue] = useState(true)

  const checkValidation = useMemo(() => {
    return debounce(function (_value: string) {
      if (!_value) {
        setIsValidValue(false)
      } else {
        setIsValidValue(validations.checkPassword(_value))
      }
    }, 500)
  }, [])

  const isError = useMemo(() => {
    return Boolean(value) && withValidation && !isValidValue
  }, [value, isValidValue, withValidation])

  const stats = useMemo(() => {
    return {
      isLength: {
        isValid: isValidValue || validations.checkLength(value, MIN_LENGTH),
        description: `At least ${MIN_LENGTH} characters long`,
      },
      isNumber: {
        isValid: isValidValue || validations.checkIsNumberIncludes(value),
        description: 'One digit',
      },
      isLowerCase: {
        isValid: isValidValue || validations.checkLowerCase(value),
        description: 'Must contain at least one lower case letter',
      },
      isUpperCase: {
        isValid: isValidValue || validations.checkUpperCase(value),
        description: 'Must contain at least one upper case letter',
      },
    }
  }, [value, isValidValue])

  const onChange = (value: string) => {
    setValue(value)
    if (withValidation) {
      checkValidation(value)
    }
  }

  return { value, onChange, isError, stats }
}
