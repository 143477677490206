import { useMemo } from 'react'

import { Hash } from 'types/entities'

import { UserTransaction } from 'state/user/types'

import { tokens } from 'constants/tokens'
import { hashRender } from 'utils/stringUtils'
import { fromWei, getEtherscanLink, shortenAddress } from 'utils/crypto'

import { Badge, Icon, IconMap, TimeCell } from 'components/ui'

import { Row, RowCell, StyledHref } from './Style'

type Props = {
  tx: UserTransaction
}

export function Transaction({ tx }: Props) {
  const _asset = useMemo(() => {
    if (!tx.chainId || !tx.currency) {
      return '-'
    }
    const token = tokens[tx.chainId]?.[tx.currency as Hash]
    if (!token) {
      return tx.currency
    }
    return token
  }, [tx.currency, tx.chainId])

  const _value = useMemo(() => {
    if (!tx.value || typeof _asset === 'string') {
      return '-'
    }
    return fromWei(tx.value, _asset.decimals)
  }, [_asset, tx.value])

  return (
    <Row>
      <RowCell>
        <TimeCell area='Time' title='time' timestamp={tx.timestamp * 1000} />
      </RowCell>
      <RowCell>
        {tx.txHash && tx.chainId ? (
          <StyledHref href={`${getEtherscanLink(tx.chainId, tx.txHash, 'transaction')}`}>
            {hashRender(tx.txHash)}
            <Icon name='externalLink' size='mediumPlus' />
          </StyledHref>
        ) : (
          <span>-</span>
        )}
      </RowCell>
      <RowCell>
        {tx.wallet && tx.chainId ? (
          <StyledHref href={`${getEtherscanLink(tx.chainId, tx.wallet, 'address')}`}>
            {shortenAddress(tx.wallet)}
            <Icon name='externalLink' size='mediumPlus' />
          </StyledHref>
        ) : (
          <span>-</span>
        )}
      </RowCell>
      <RowCell>
        {typeof _asset === 'string' ? (
          _asset
        ) : (
          <>
            <Icon name={_asset ? (_asset.symbol.toLowerCase() as IconMap) : 'tick'} /> {_asset.symbol}
          </>
        )}
      </RowCell>
      <RowCell title={_value}>{_value}</RowCell>
      <RowCell type='operation' title={tx.operation}>
        {tx.operation}
      </RowCell>
      <RowCell type='status' title={tx.status}>
        <Badge value={tx.status} />
      </RowCell>
    </Row>
  )
}
