import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { ApiRequestState } from 'types/entities'

import { useSimpleTimer } from 'hooks/helpers'

import { Button, Input } from 'components/ui'
import { notifications } from 'constants/notifications'

import { useCallNotification } from 'notifications/hooks'
import { NotificationTypes } from 'notifications/service'

import {
  Wrap,
  Form,
  Title,
  Timer,
  Resend,
  PreTitle,
  ErrorWrap,
  InputWrap,
  Container,
  TimerValue,
  ResendButton,
  ErrorContent,
} from './Style'

type Props = {
  title: string
  email: string
  skipText?: string
  onSkipStep?: VoidFunction
  verifyState: ApiRequestState
  sendCodeState: ApiRequestState
  verify: (code: string) => Promise<void>
  callGetCode: (email: string) => Promise<void>
}

export function VerifyEmail({
  email,
  verify,
  onSkipStep,
  verifyState,
  callGetCode,
  sendCodeState,
  title = 'Verify',
  skipText = 'Skip verifying',
}: Props) {
  const [code, setCode] = useState('')
  const [isCodeSent, setCodeSent] = useState(true)

  const { time, run } = useSimpleTimer()
  const callNotification = useCallNotification()

  useEffect(() => {
    run()
  }, [])

  const handleChange = (value: string) => {
    setCode(value)
  }

  const handleCodeSend = async () => {
    try {
      await callGetCode(email)
      run(true)
      setCodeSent(true)
      callNotification({ type: NotificationTypes.SUCCESS, message: notifications.successful.sendCode })
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.sendCode(err.message) })
    }
  }

  const handleVerifyCode = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    await verify(code)
  }

  const codeVerified = useMemo(() => verifyState.isSuccess, [verifyState.isSuccess])

  const isError = useMemo(
    () => (isCodeSent ? verifyState.isError : sendCodeState.isError),
    [verifyState.isError, sendCodeState.isError, isCodeSent],
  )

  const sendCodeError = useMemo(() => {
    if (!sendCodeState.error) {
      return ''
    }

    return !isCodeSent ? verifyState.error : ''
  }, [isCodeSent, sendCodeState?.error])

  const verifyError = useMemo(() => {
    if (!verifyState.error) {
      return ''
    }

    return verifyState.error
  }, [verifyState?.error])

  const error = useMemo(() => sendCodeError || verifyError, [sendCodeError, verifyError])

  const isGetCodeActive = useMemo(() => (time <= 0 || !isCodeSent) && !codeVerified, [time, isCodeSent, codeVerified])
  const isVerifyCodeActive = useMemo(() => isCodeSent && !codeVerified && code, [isCodeSent, codeVerified, code])

  return (
    <Container>
      <Title>{title}</Title>
      <PreTitle>
        Verification code was sent to <b>{email}</b>
      </PreTitle>

      <Form onSubmit={handleVerifyCode}>
        <Wrap>
          <InputWrap>
            <Input
              label='E-mail verification code'
              value={code}
              onChange={handleChange}
              disabled={!isCodeSent}
              placeholder='Enter your verification code'
            />
          </InputWrap>

          <Resend>
            <ResendButton
              type='outline'
              onClick={handleCodeSend}
              isLoading={sendCodeState.isLoading}
              disabled={!isGetCodeActive}
            >
              Resend code
            </ResendButton>
          </Resend>

          <Timer show={!isGetCodeActive}>
            <TimerValue>Resend the code in&nbsp;{time}&nbsp;second(s).</TimerValue>
          </Timer>
        </Wrap>

        <Button submit fullSize size='large' isLoading={verifyState.isLoading} disabled={!isVerifyCodeActive}>
          Submit
        </Button>

        {onSkipStep && (
          <Button fullSize type='link' size='large' onClick={onSkipStep}>
            {skipText}
          </Button>
        )}

        <ErrorWrap>{isError && error && <ErrorContent>{error}</ErrorContent>}</ErrorWrap>
      </Form>
    </Container>
  )
}
