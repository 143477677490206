import styled, { css } from 'styled-components/macro'
import { KycStatus } from 'types/entities'
import { Href } from 'components/ui'

export const Container = styled.div<{ maxWidth: string }>`
  position: relative;
  margin: 3.2rem auto;
  padding: 1.6rem;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
`

export const StatusContainer = styled.div`
  margin: 0;
  padding: 3rem 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 2.4rem;
  box-shadow: 0 0.4rem 1.6rem 0 ${(props) => props.theme.color.elementBorder};
  ${(props) => props.theme.breakpoints.greaterThan('sm')`
     padding: 3rem 2.4rem;
  `};
`

export const StatusIconWrap = styled.div<{ status?: KycStatus }>`
  margin: 0 0 3.2rem;
  width: 8rem;
  ${(props) =>
    KycStatus.NOT_STARTED === props.status &&
    css`
      color: ${(props) => props.theme.color.text};
    `}
  ${(props) =>
    KycStatus.PENDING === props.status &&
    css`
      color: ${(props) => props.theme.color.warning};
    `}
  ${(props) =>
    KycStatus.COMPLETED === props.status &&
    css`
      color: ${(props) => props.theme.color.success};
    `}
  ${(props) =>
    KycStatus.DECLINED === props.status &&
    css`
      color: ${(props) => props.theme.color.danger};
    `}
`

export const StatusTitle = styled.h1`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 2.2rem;
  line-height: 1.44;
  text-align: center;
  color: ${(props) => props.theme.color.text};
`

export const StatusText = styled.p`
  margin: 0;
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1.6rem;
  line-height: 1.33;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  a {
    color: ${(props) => props.theme.color.default};
  }
`

export const ButtonWrap = styled.div`
  width: 100%;
  max-width: 37.6rem;
`

export const SupportLink = styled(Href)`
  color: ${(props) => props.theme.color.textHint};
  text-decoration: underline;
`
