import { useMemo } from 'react'

import { Hash } from 'types/entities'
import { useGetBalancesQuery } from 'state/multicalls/balances'

import { tokens } from 'constants/tokens'

import { useGetChainData } from './useChainData'

export function useGetBalancesRequest(address: Hash | undefined, chainId: SupportedChainId) {
  const { rpcUrl, blockDuration } = useGetChainData(chainId)

  const _tokens = useMemo(() => Object.values(tokens[chainId]), [tokens, chainId])

  const request = useGetBalancesQuery(
    {
      rpcUrl,
      chainId,
      tokens: _tokens,
      customer: String(address),
    },
    {
      pollingInterval: blockDuration * 3,
    },
  )

  return { ...request, tokens: _tokens }
}
