import { StagesNames, Stages, Hash } from 'types/entities'

const APP_NAME = 'Justice DAO'
// TODO: need check to change
const WALLET_CONNECT_APP_ID = '3982875cc1822449612649a29777daf5'
const MAP_BOX_API_KEY = 'pk.eyJ1IjoiZGFuMWtvdiIsImEiOiJjbGl0emx6OHIwOTBoM2RsZjFoajI4djZnIn0.zTgexPX5KqM47A1035Bm6Q'

const DOMESTIC_BANK_DETAILS = {
  bank: {
    title: 'Bank:',
    description: 'United Bank',
  },
  ABA: {
    title: 'ABA No.:',
    description: '056004445',
  },
  acct: {
    title: 'Acct. No.:',
    description: '00085391828',
  },
  bankAddress: {
    title: 'Bank Address:',
    description: 'United Bank\n1100 Connecticut Avenue, NW, Washington, DC 20036',
  },
  creditFor: {
    title: 'For Credit to:',
    description: 'Berliner Corcoran & Rowe LLP Trust Account\n1101 17th St. NW, Suite 1100, Washington, DC 20036',
  },
}

// ACH and Wire routing number: 084009519
// Account type: Checking
const DOMESTIC_BANK_DETAILS_DESCRIPTION = `
Bank: United Bank
ABA No.: 056004445
Acct. No.: 00085391828
Bank Address: United Bank
1100 Connecticut Avenue, NW,
Washington, DC 20036
For Credit to: Berliner Corcoran & Rowe LLP Trust Account
1101 17th St. NW, Suite 1100,
Washington, DC 20036
`

const INTERNATIONAL_BANK_DETAILS = {
  receivingBank: {
    title: 'Receiving Bank:',
    description: 'Wells Fargo International',
  },
  address: {
    title: 'Address:',
    description: '420 Montgomery Street, San Francisco, CA 94104',
  },
  // accountNumber: {
  //   title: 'Account number:',
  //   description: '9800385627',
  // },
  beneficiary: {
    title: 'Beneficiary Bank:',
    description: 'United Bank VA',
  },
  aba: {
    title: 'ABA #:',
    description: '056004445',
  },
  account: {
    title: 'Account No.:',
    description: '00085391828',
  },
  swift: {
    title: 'SWIFT Code:',
    description: 'WFBIUS6S',
  },
  credit: {
    title: 'Credit:',
    description: 'For further credit to Berliner, Corcoran & Rowe, L.L.P.',
  },
}

const INTERNATIONAL_BANK_DETAILS_DESCRIPTION = `
Receiving Bank: Wells Fargo International
Address: 420 Montgomery Street,
San Francisco, CA 94104
Beneficiary Bank: United Bank VA
ABA #: 056004445
SWIFT Code: WFBIUS6S
Credit: For further credit to Berliner, Corcoran & Rowe, L.L.P.
`

const SUMSUB_CONTAINER = 'sumsub-container'

const GAS_LIMITS = {
  NATIVE: 70_000,
  TOKEN: 120_000,
}

export enum PaymentType {
  CRYPTO = 'crypto',
  FIAT = 'fiat',
}

export enum FiatPaymentType {
  DOMESTIC = 'USA',
  INTERNATIONAL = 'international',
}

export enum SourceOfFunds {
  OTHER = 'OTHER',
  PROPERTY_SALE = 'PROPERTY_SALE',
  SALARY_OR_WAGES = 'SALARY_OR_WAGES',
  PERSONAL_SAVINGS = 'PERSONAL_SAVINGS',
  LOTTERY_WINNINGS = 'LOTTERY_WINNINGS',
  SELF_EMPLOYMENT_INCOME = 'SELF_EMPLOYMENT_INCOME',
  INHERITANCES_AND_GIFTS = 'INHERITANCES_AND_GIFTS',
  CAPITAL_GAINS_DIVIDENDS = 'CAPITAL_GAINS_DIVIDENDS',
  PENSION_OR_RETIREMENT_INCOME = 'PENSION_OR_RETIREMENT_INCOME',
  COMPENSATION_FROM_LEGAL_RULINGS = 'COMPENSATION_FROM_LEGAL_RULINGS',
}

export const sourceOfFunds = {
  [SourceOfFunds.PERSONAL_SAVINGS]: 'Personal savings',
  [SourceOfFunds.SALARY_OR_WAGES]: 'Salary or wages',
  [SourceOfFunds.SELF_EMPLOYMENT_INCOME]: 'Self-employment income',
  [SourceOfFunds.PENSION_OR_RETIREMENT_INCOME]: 'Pension or retirement income',
  [SourceOfFunds.CAPITAL_GAINS_DIVIDENDS]: 'Capital gains and/or dividends',
  [SourceOfFunds.PROPERTY_SALE]: 'Property sale',
  [SourceOfFunds.LOTTERY_WINNINGS]: 'Lottery winnings',
  [SourceOfFunds.INHERITANCES_AND_GIFTS]: 'Inheritances and gifts',
  [SourceOfFunds.COMPENSATION_FROM_LEGAL_RULINGS]: 'Compensation from legal rulings',
  [SourceOfFunds.OTHER]: 'Other (please describe)',
}

export enum OfacReasons {
  COUNTRY = 'COUNTRY',
  LISTS = 'LISTS',
  FUNDS = 'FUNDS',
}

const ACTION_BUTTON_TEXTS = {
  CHECKING: 'Checking',
  PROCESSING: 'Processing',
  DEFAULT_BUTTON_TEXT: 'Create',
  APPROVE_BUTTON_TEXT: 'Approve',
  WAIT_TIL_MINING: 'Transaction processing',
  CONFIRM_BUTTON_TEXT: 'Please confirm transaction',
}

const STATUSES = {
  SUCCESS: ['success', 'accepted', 'complete', 'completed', 'approved', 'verified', 'clear'],
  WARNING: ['pending', 'upcoming', 'checking'],
  DANGER: ['declined', 'failed', 'not_verified', 'sanction'],
  NEW: ['status', 'not_checked'],
}

const KYC_STAGES: Array<StagesNames | Stages> = [
  {
    name: 'intro',
    options: {
      heading: 'We need to verify your identity',
      message: ['In order to accept a donation,  we need to check a few things.', 'This will only take a moment'],
      startButtonText: 'Start Verification',
    },
  },
  'documentCapture',
  'faceCapture',
  {
    name: 'completion',
    options: {
      heading: 'Thank you for completing the process',
      message: ['We will get in touch shortly via e-mail'],
    },
  },
]

export enum Recipients {
  ROMAN = 'ROMAN',
  ALEX = 'ALEX',
  DEFENDS = 'GLOBAL',
}

const RECIPIENTS_DATA = {
  [Recipients.ROMAN]: {
    value: Recipients.ROMAN,
    label: 'Roman Storm',
    address: '0xbaa5ed145798d2b6dafabc5dd05f9afa98ac4283' as Hash,
  },
  [Recipients.ALEX]: {
    value: Recipients.ALEX,
    label: 'Alex Pertsev',
    address: '0xeadcf32b358fb51a6d459033125903357be023b9' as Hash,
  },
  [Recipients.DEFENDS]: {
    value: Recipients.DEFENDS,
    label: 'General Defense Fund',
    address: '0x4f6a87aa669f928d69ec0a00b3fe4f8b6fb53569' as Hash,
  },
}

const RECIPIENTS_OPTIONS = Object.values(RECIPIENTS_DATA).map((el) => ({
  value: el.value,
  label: el.label,
}))

const OFAC_TERMS = {
  [OfacReasons.COUNTRY]:
    'I confirm and certify that I am not a citizen or resident of any of the OFAC- sanctioned countries, including Burma, Crimea and Sevastopol, Côte d’Ivoire, Cuba, Democratic Republic of Congo, Iran, Iraq, Libya, North Korea, Sudan, Syria, or Zimbabwe.',
  [OfacReasons.LISTS]:
    'I confirm and certify that I, as an individual or the legal entity that I represented, is not listed in Specially Designated Nationals and Blocked Persons list ("SDN List") and other sanctions lists administered by OFAC, including the Foreign Sanctions Evaders List, the Sectoral Sanctions Identifications List, the List of Foreign Financial Institutions Subject to Correspondent Account or Payable-Through Account Sanctions, the Non-SDN Palestinian Legislative Council List, the Non-SDN Menu-Based Sanctions List, and the Non-SDN Communist Chinese Military Companies List.',
  [OfacReasons.FUNDS]:
    'I hereby confirm that the donated funds are not generated as a result of any unlawful activity.',
}
export const appConstants = {
  APP_NAME,
  STATUSES,
  KYC_STAGES,
  GAS_LIMITS,
  OFAC_TERMS,
  MAP_BOX_API_KEY,
  RECIPIENTS_DATA,
  SUMSUB_CONTAINER,
  RECIPIENTS_OPTIONS,
  ACTION_BUTTON_TEXTS,
  WALLET_CONNECT_APP_ID,
  DOMESTIC_BANK_DETAILS,
  INTERNATIONAL_BANK_DETAILS,
  DOMESTIC_BANK_DETAILS_DESCRIPTION,
  INTERNATIONAL_BANK_DETAILS_DESCRIPTION,
}
