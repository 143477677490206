const Tick = () => (
  <svg viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M13.805 3.862c.26.26.26.682 0 .943L6.47 12.138a.667.667 0 0 1-.942 0L2.195 8.805a.667.667 0 1 1 .943-.943L6 10.724l6.862-6.862c.26-.26.682-.26.943 0Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Tick }
