import { useState, useEffect, useMemo, SyntheticEvent } from 'react'
import { nanoid } from '@reduxjs/toolkit'

import { isEqual } from 'utils/lodash'
import CustomComponentsSelect from './CustomComponentsSelect'

import { SelectProps, SelectOptions, OnSelectParams } from './@types'
import { Wrapper, Label, StyledSelect, Hint, ErrorMessage } from './Style'

const DEFAULT_MENU_HEIGHT = 112

function Select<T, N = string>({
  name,
  hint,
  label,
  value,
  error,
  select,
  options,
  isDisabled,
  placeholder,
  errorMessage,
  maxMenuHeight,
  type = 'default',
  size = 'default',
  isLoading = false,
  menuIsOpen = false,
  isSearchable = false,
  classNamePrefix = 'Select',
  minMenuHeight = DEFAULT_MENU_HEIGHT,
  ...rest
}: SelectProps<T, N>) {
  const ID = nanoid()
  const [selected, setSelect] = useState<SelectOptions<T> | null>(null)

  useEffect(() => {
    const initialValue = options.find((i) => isEqual(i.value, value))
    setSelect(initialValue || null)
  }, [value, options])

  const placeholderValue = useMemo(() => {
    return options.length && value ? selected : placeholder
  }, [options.length, placeholder, selected, value])

  const handleSelect = (selectedOption: OnSelectParams<T>) => {
    select(selectedOption, name)
  }

  const _stopPropagation = (event: SyntheticEvent) => event.stopPropagation()

  return (
    <Wrapper onClick={_stopPropagation}>
      {label && <Label htmlFor={ID}>{label}</Label>}

      <StyledSelect
        type={type}
        size={size}
        inputId={ID}
        error={error}
        value={selected}
        options={options}
        className='Select'
        isLoading={isLoading}
        menuPlacement='auto'
        isDisabled={isDisabled}
        onChange={handleSelect}
        errorMessage={errorMessage}
        isSearchable={isSearchable}
        minMenuHeight={minMenuHeight}
        maxMenuHeight={maxMenuHeight}
        placeholder={placeholderValue}
        defaultMenuIsOpen={menuIsOpen}
        classNamePrefix={classNamePrefix}
        components={CustomComponentsSelect}
        {...rest}
      />

      {hint && !error && <Hint>{hint}</Hint>}
      {error && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  )
}

export { Select }
