import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RefreshTokenResponse } from 'state/api/types'

type AuthState = {
  accessToken: string | null
  refreshToken: string | null
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<RefreshTokenResponse | null>) => {
      state.accessToken = action.payload?.accessToken || null
      state.refreshToken = action.payload?.refreshToken || null
    },
    clearState: () => initialState,
  },
})

export const { setCredentials, clearState } = slice.actions

export default slice.reducer
