const Exit = () => (
  <svg viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M15.293 6.293a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414L19.586 12l-4.293-4.293a1 1 0 0 1 0-1.414Z'
      clipRule='evenodd'
    />
    <path fillRule='evenodd' d='M8 12a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Z' clipRule='evenodd' />
    <path
      fillRule='evenodd'
      d='M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h4a1 1 0 1 1 0 2H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h4a1 1 0 0 1 0 2H5Z'
      clipRule='evenodd'
    />
  </svg>
)

export { Exit }
