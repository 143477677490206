import { AdminAnalytics, AdminVerifications } from 'components/features'

import { Title, Wrap } from './Style'

export function Dashboard() {
  return (
    <>
      <Title>Dashboard</Title>
      <Wrap>
        <AdminAnalytics />
        <AdminVerifications />
      </Wrap>
    </>
  )
}
