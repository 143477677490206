import { createApi } from '@reduxjs/toolkit/query/react'

import { apiLinks } from 'constants/api'
import { customAuthorizedQuery } from 'state/api'

import {
  SignInInput,
  SignUpInput,
  LogoutInput,
  SignInResponse,
  SignUpResponse,
  LogoutResponse,
  VerifyEmailInput,
  CreateManagerInput,
  VerifyEmailResponse,
  CreateManagerResponse,
  SendVerifyEmailCodeInput,
  SendVerifyEmailCodeResponse,
  // GetCodeOnEmailInput,
  // RestorePasswordInput,
  // GetCodeOnEmailResponse,
  // RestorePasswordResponse,
} from './types'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customAuthorizedQuery({
    baseUrl: apiLinks.BASE_URL,
  }),
  endpoints: (builder) => ({
    signUp: builder.mutation<SignUpResponse, SignUpInput>({
      query: ({ email, passwordHash }) => ({
        url: apiLinks.auth.signUp,
        method: 'POST',
        data: { email, password_hash: passwordHash },
      }),
    }),
    signIn: builder.mutation<SignInResponse, SignInInput>({
      query: ({ email, passwordHash }) => ({
        url: apiLinks.auth.signIn,
        method: 'POST',
        data: { email, password_hash: passwordHash },
      }),
    }),
    createManager: builder.mutation<CreateManagerResponse, CreateManagerInput>({
      query: ({ email, passwordHash }) => ({
        url: apiLinks.auth.createManage,
        method: 'POST',
        data: { email, password_hash: passwordHash },
      }),
    }),
    logout: builder.mutation<LogoutResponse, LogoutInput>({
      query: () => ({
        url: apiLinks.auth.logout,
        method: 'PUT',
      }),
    }),
    //mail
    sendVerifyEmailCode: builder.mutation<SendVerifyEmailCodeResponse, SendVerifyEmailCodeInput>({
      query: () => ({
        url: apiLinks.mail.resend,
        method: 'GET',
      }),
    }),
    verifyEmail: builder.mutation<VerifyEmailResponse, VerifyEmailInput>({
      query: ({ code }) => ({
        url: apiLinks.mail.verify,
        method: 'PUT',
        data: { token: code },
      }),
    }),
  }),
})

export const {
  useSignInMutation,
  useSignUpMutation,
  useLogoutMutation,
  useVerifyEmailMutation,
  useCreateManagerMutation,
  useSendVerifyEmailCodeMutation,
} = authApi
