import styled, { css } from 'styled-components/macro'

export const Wrap = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.2rem;
  overflow: hidden;
`

export const Container = styled.div<{ type?: string; noScroll?: boolean }>`
  display: block;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  ${(props) =>
    props.type === 'newDonations' &&
    css`
      border-bottom: none;
    `}
  ${(props) =>
    props.type === 'users' &&
    css`
      border-bottom: none;
    `}
  ${(props) =>
    props.type === 'donations' &&
    css`
      border-bottom: none;
    `}
  ${(props) =>
    props.type === 'transactions' &&
    css`
      border-bottom: none;
    `}
  ${(props) =>
    props.noScroll &&
    css`
      overflow-x: hidden;
    `}
`

export const StyleTable = styled.div`
  width: auto;
`

export const Title = styled.h4`
  margin: 0 0 1.6rem;
  font-weight: ${(props) => props.theme.font.weight.bold};
  font-size: 1.6rem;
  line-height: 1.44;
  color: ${(props) => props.theme.color.text};
  ${(props) => props.theme.breakpoints.greaterThan('xsm')`
    font-size: 1.8rem;
  `};
`

export const RowGroup = styled.div`
  padding: 0 0 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`
