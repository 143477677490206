import styled from 'styled-components/macro'
import { transparentize } from 'polished'

export const Button = styled.button`
  position: relative;
  padding: 1.2rem;
  display: block;
  width: 4rem;
  height: 4rem;
  box-shadow: none;
  border: none;
  background-color: ${(props) => props.theme.color.transparent};
  border-radius: 1.2rem;
  cursor: pointer;
  transition: background-color 0.2s;
  outline: none;
  z-index: ${(props) => props.theme.zIndex.overlay};
  &:hover,
  &:focus {
    background-color: ${(props) => transparentize(0.95, props.theme.color.white)};
  }
`

export const BurgerLine = styled.span<{ active: boolean }>`
  position: absolute;
  left: 50%;
  width: 2rem;
  height: 0.2rem;
  background-color: currentColor;
  border-radius: 5rem;
  &:nth-of-type(1) {
    ${(props) =>
      props.active
        ? `
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      color: ${props.theme.color.white};`
        : `
      top: 1.3rem;
      transform: translateX(-50%);
    `};
  }
  &:nth-of-type(2) {
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: ${(props) => (props.active ? props.theme.opacity.invisible : props.theme.opacity.default)};
  }
  &:nth-of-type(3) {
    ${(props) =>
      props.active
        ? `
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      color: ${props.theme.color.white};`
        : `
      bottom: 1.3rem;
      width: 1rem;
      transform: translateX(-100%);
    `};
  }
`
