import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useEmailFieldData, usePasswordFieldData } from 'hooks/form'

import { useIsAuth, useSignUpGetCodeRequest, useSignUpRequest, useSignUpVerifyEmailRequest } from 'state/auth/hooks'

import { Links } from 'constants/link'
import { errors } from 'constants/errors'
import { notifications } from 'constants/notifications'

import { Button, CustomLink, Input } from 'components/ui'
import { AsideBanner, TermsOfService, PasswordField, VerifyEmail } from 'components/features'

import { useCallNotification } from 'notifications/hooks'
import { NotificationTypes } from 'notifications/service'

import { Container, Content, Title, PreTitle, Form, InputWrap, ErrorWrap, ErrorContent } from './Style'

enum Steps {
  FILL_FORM,
  VERIFY_EMAIL,
}
export function SignUp() {
  const isAuth = useIsAuth()
  const email = useEmailFieldData()
  const password = usePasswordFieldData()

  const { onSignUp, ...signUpRequest } = useSignUpRequest()
  const { onSendCode, ...sendCodeRequest } = useSignUpGetCodeRequest()
  const { onVerifyEmail, ...verifyEmailRequest } = useSignUpVerifyEmailRequest()

  const callNotification = useCallNotification()

  const [isChecked, setChecked] = useState(false)
  const [step, stepStep] = useState(Steps.FILL_FORM)
  const [confirmValue, setConfirmValue] = useState('')
  const [confirmError, setConfirmError] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    if (isAuth) {
      navigate(Links.MAIN)
    }
  }, [])

  const onHandleSignUp = async (e: ChangeEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      if (isDisabled) {
        return
      }

      const isSame = password.value === confirmValue
      setConfirmError(isSame ? '' : errors.validation.PASSWORDS_MUST_BE_SAME)
      if (!isSame) {
        return
      }
      await onSignUp(email.value, password.value)

      callNotification({ type: NotificationTypes.SUCCESS, message: notifications.successful.signUp })
      stepStep(Steps.VERIFY_EMAIL)
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.signUp(err.message) })
    }
  }

  const handleGoApp = () => {
    navigate(Links.MAIN, { replace: true })
  }

  const onChangeConfirm = (value: string) => {
    setConfirmValue(value)
  }

  const onCheckChange = (_checked: boolean) => {
    setChecked(_checked)
  }

  const handleVerifyCode = async (code: string) => {
    try {
      await onVerifyEmail(code)

      callNotification({ type: NotificationTypes.SUCCESS, message: notifications.successful.verified })
      handleGoApp()
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.verified(err.message) })
    }
  }

  const isFieldsFilled = useMemo(() => email.value && password.value, [email.value, password.value])

  const isDisabled = useMemo(
    () => email.isError || password.isError || !isFieldsFilled || !isChecked,
    [email.isError, password.isError, isFieldsFilled, isChecked],
  )

  const isError = useMemo(() => {
    return signUpRequest.isError || sendCodeRequest.isError || Boolean(confirmError)
  }, [signUpRequest.isError, sendCodeRequest.isError, confirmError])

  const error = useMemo(() => {
    return signUpRequest.error || sendCodeRequest.error || confirmError
  }, [signUpRequest.error, sendCodeRequest.error, confirmError])

  const isLoading = useMemo(() => {
    return signUpRequest.isLoading || sendCodeRequest.isLoading
  }, [signUpRequest.isLoading, sendCodeRequest.isLoading])

  return (
    <Container layoutCenter={step === Steps.VERIFY_EMAIL}>
      <Content>
        {step === Steps.FILL_FORM && (
          <>
            <Title>Create your account</Title>

            <Form onSubmit={onHandleSignUp}>
              <InputWrap>
                <Input
                  type='email'
                  label='E-mail'
                  value={email.value}
                  error={email.isError}
                  onChange={email.onChange}
                  errorMessage={email.errorMessage}
                />

                <PasswordField
                  label='Password'
                  name='new-password'
                  confirmValue={confirmValue}
                  onChangeConfirm={onChangeConfirm}
                  isPasswordError={password.isError}
                  ariaDescribedby='password-constraints'
                  isConfirmPasswordError={Boolean(confirmError)}
                  confirmPasswordErrorMessage={confirmError}
                  {...password}
                />

                <TermsOfService isChecked={isChecked} onCheckChange={onCheckChange} />
              </InputWrap>

              <Button submit fullSize size='large' isLoading={isLoading} disabled={isDisabled}>
                Create account
              </Button>
              <PreTitle>
                Already have an&nbsp;account? <CustomLink url={Links.SIGN_IN} title='Sign in' />
              </PreTitle>

              <ErrorWrap>{isError && error && <ErrorContent>{error}</ErrorContent>}</ErrorWrap>
            </Form>
          </>
        )}

        {step === Steps.VERIFY_EMAIL && (
          <VerifyEmail
            email={email.value}
            callGetCode={onSendCode}
            onSkipStep={handleGoApp}
            verify={handleVerifyCode}
            title='Security verification'
            sendCodeState={sendCodeRequest}
            skipText='Verify e-mail later'
            verifyState={verifyEmailRequest}
          />
        )}
      </Content>

      {step === Steps.FILL_FORM && <AsideBanner />}
    </Container>
  )
}
