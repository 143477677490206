import React from 'react'

type Props = {
  id?: string
  href: string
  className?: string
  children: React.ReactNode
}

function Href({ id, href, children, className }: Props) {
  return (
    <a id={id} href={href} className={className} target='_blank' rel='noopener noreferrer'>
      {children}
    </a>
  )
}

export { Href }
