import styled from 'styled-components/macro'
import { spin } from 'utils/animations'

export const StyleLoader = styled.span<{ width: number; height: number }>`
  display: block;
  width: ${(props) => props.width}rem;
  height: ${(props) => props.height}rem;
  flex: 0 0 auto;
  overflow: hidden;
  transform-origin: center center;
  mask-image: linear-gradient(
    90deg,
    ${(props) => props.theme.color.black},
    ${(props) => props.theme.color.transparent} 75%
  );
  -webkit-mask-image: -webkit-linear-gradient(
    90deg,
    ${(props) => props.theme.color.black},
    ${(props) => props.theme.color.transparent} 75%
  );
  animation: ${spin} 1.2s infinite linear;
`

export const LoaderLine = styled.span<{ width: number; height: number; ringSize: number; color: string }>`
  display: block;
  width: ${(props) => props.height}rem;
  height: ${(props) => props.height}rem;
  border-radius: 50%;
  box-shadow: inset 0 0 0 ${(props) => props.ringSize}rem ${(props) => props.color};
`
