import { useMemo } from 'react'

import { Donation } from 'types/entities'

import { CellProps } from 'components/ui'
import { Wrapper, Title, Value } from '../Style'

export function Email({ row }: CellProps<Donation>) {
  const { user } = row.original

  // add name
  const email = useMemo(() => user.email, [user])

  return (
    <Wrapper area='Email'>
      <Title>Email</Title>
      <Value>{email}</Value>
    </Wrapper>
  )
}
