import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: fixed;
  left: 2rem;
  bottom: 2rem;
  width: 100%;
  max-width: 32rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.8rem;
  z-index: ${(props) => props.theme.zIndex.overlay};
  ${(props) => props.theme.breakpoints.greaterThan('md')`
    max-width: 48rem;
  `}
  ${(props) => props.theme.breakpoints.greaterThan('lg')`
    max-width: 56rem;
  `}
`
