export type Location = {
  city: string
  region: string
  country: string
  postcode: string
  addressLine1: string // Apartment, suite, unit, building, floor, etc
  addressLine2: string // Apartment, suite, unit, building, floor, etc
}

export type Hash = `0x${string}`

export type Token = {
  name: string
  symbol: string
  logoURI: string
  decimals: number
  address: Hash
}

export type TokenBalance = {
  bgBalance: string
  balance: string
}

export type Allowances = Record<string, string>

export type Balances = Record<string, TokenBalance>

export type TokenSelectItem = Token & { balances: TokenBalance }

export enum Role {
  USER = 'USER',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
}

export type UserData = {
  kyc: Kyc
  docs?: Doc
  id: string
  email: string
  roleName: Role
  createdAt: Date
  updatedAt: Date
  isDeleted: boolean
  refreshToken: string
  passwordHash: string
  isVerifiedEmail: boolean
}

export type ApiRequestState = {
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  error?: string
}

export type ApiError = {
  status?: number
  message: string | string[]
}

export type Donation = {
  id: string
  token: Hash
  amount: string
  address: Hash
  user: UserData
  recipient: Hash
  createdAt: Date
  updatedAt: Date
  signature: string
  status: DonationStatus
}

export type Donations = Donation[]

export type Doc = {
  id: string
  link: string
  createdAt: Date
  updatedAt: Date
  status: DocStatus
  sourceOfFunds: string
  user: {
    email: string
    id: string
  }
}

export type Docs = Doc[]

export enum KycType {
  NOT_SELECT = 'NOT_SELECT',
  KYC = 'KYC',
  SELF = 'SELF',
}

export enum VerificationSteps {
  INIT = 'init',
  KYC = 'kyc',
  DOC = 'DOC',
  STATUS = 'status',
  COMPLETE = 'complete',
}

export enum KycStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
}

export enum DocStatus {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  NOT_CREATED = 'NOT_CREATED',
}

export enum DonationStatus {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
}

export type Kyc = {
  id: string
  name: string
  userId: string
  createdAt: Date
  updatedAt: Date
  location: string
  status: KycStatus
  isSanction: boolean
  controlType: KycType
}

export type Name = {
  firstName: string
  lastName: string
}

export type StagesNames =
  | 'intro'
  | 'userConsentCapture'
  | 'faceCapture'
  | 'documentCapture'
  | 'addressCapture'
  | 'poaCapture'
  | 'completion'

export type Stages = {
  name: StagesNames
  options: any
}
