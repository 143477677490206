import { ComponentType } from 'react'

import { CreateModalProps, ModalAdditionalMeta, ModalName } from './@types'
import { Modal } from './Modal'

export const createConfig = ({
  name,
  container,
  additionalMeta,
}: {
  name: ModalName
  container: ComponentType
  additionalMeta?: ModalAdditionalMeta
}) => {
  return {
    name,
    container,
    additionalMeta,
    isOpen: false,
  }
}

const createModal = ({
  name,
  title,
  subtitle,
  container,
  additionalMeta,
  align = 'left',
  type = 'default',
  size = 'default',
  disableOverlayClose,
  withDefaultLayout = true,
}: CreateModalProps) => {
  const modalWrapper = () => (
    <Modal
      name={name}
      type={type}
      size={size}
      align={align}
      title={title}
      subtitle={subtitle}
      withDefaultLayout={withDefaultLayout}
      disableOverlayClose={disableOverlayClose}
    >
      {container}
    </Modal>
  )

  return createConfig({
    name,
    additionalMeta,
    container: modalWrapper,
  })
}

export { createModal }
