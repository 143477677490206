import { useCallback, useEffect } from 'react'

import { AddressAutofill, config } from '@mapbox/search-js-react'
import { AddressAutofillRetrieveResponse } from '@mapbox/search-js-core'

import { Location } from 'types/entities'
import { appConstants } from 'constants/app'
import { Input, Wrap } from 'components/ui'
import { ValidationResults } from 'utils/validation'

type Props = {
  location?: Location
  locationErrors: ValidationResults
  onChange: (address: Location) => void
}

const DEFAULT_LOCATION = {
  country: '',
  postcode: '',
  city: '',
  region: '',
  addressLine2: '',
  addressLine1: '',
}

export function AddressField({ onChange, locationErrors, location = DEFAULT_LOCATION }: Props) {
  useEffect(() => {
    config.accessToken = appConstants.MAP_BOX_API_KEY
  }, [])

  const handleRetrieve = useCallback(
    ({ features }: AddressAutofillRetrieveResponse) => {
      const [{ properties }] = features

      onChange({
        country: properties.country,
        postcode: properties.postcode,
        city: properties.address_level2,
        region: properties.address_level1,
        addressLine2: properties.address_line2,
        addressLine1: properties.address_line1 || properties.matching_name,
      })
    },
    [onChange],
  )

  const handleChange = (value: string, name?: string) => {
    if (!name) return
    onChange({ ...location, [name]: value })
  }

  return (
    <>
      <Wrap gridArea='AddressLineOne'>
        <AddressAutofill onRetrieve={handleRetrieve} accessToken={appConstants.MAP_BOX_API_KEY}>
          <Input
            type='text'
            name='addressLine1'
            id='mapbox-autofill'
            inputSize='medium'
            label='Address line 1'
            onChange={handleChange}
            autoComplete='address-line1'
            value={location?.addressLine1}
            error={locationErrors.addressLine1?.isError}
            errorMessage={locationErrors.addressLine1?.errorMessage}
            placeholder='Start typing your address, e.g. 123 Main...'
          />
        </AddressAutofill>
      </Wrap>
      <Wrap gridArea='AddressLineTwo'>
        <Input
          inputSize='medium'
          name='addressLine2'
          label='Address line 2'
          onChange={handleChange}
          autoComplete='address-line2'
          value={location?.addressLine2 || ''}
          error={locationErrors.addressLine2?.isError}
          errorMessage={locationErrors.addressLine2?.errorMessage}
          placeholder='Apartment, suite, unit, building, floor, etc.'
        />
      </Wrap>
      <Wrap gridArea='City'>
        <Input
          name='city'
          label='City'
          inputSize='medium'
          placeholder='Limassol'
          onChange={handleChange}
          value={location?.city || ''}
          autoComplete='address-level2'
          error={locationErrors.city?.isError}
          errorMessage={locationErrors.city?.errorMessage}
        />
      </Wrap>
      <Wrap gridArea='Region'>
        <Input
          name='region'
          inputSize='medium'
          label='State/Region'
          placeholder='Cyprus'
          onChange={handleChange}
          value={location?.region || ''}
          autoComplete='address-level1'
          error={locationErrors.region?.isError}
          errorMessage={locationErrors.region?.errorMessage}
        />
      </Wrap>
      <Wrap gridArea='Country'>
        <Input
          name='country'
          label='Country'
          inputSize='medium'
          placeholder='Cyprus'
          onChange={handleChange}
          autoComplete='country-name'
          value={location?.country || ''}
          error={locationErrors.country?.isError}
          errorMessage={locationErrors.country?.errorMessage}
        />
      </Wrap>
      <Wrap gridArea='Zip'>
        <Input
          name='postcode'
          placeholder='9999'
          inputSize='medium'
          inputMode='numeric'
          label='ZIP / Postcode'
          onChange={handleChange}
          autoComplete='postal-code'
          value={location?.postcode || ''}
          error={locationErrors.postcode?.isError}
          errorMessage={locationErrors.postcode?.errorMessage}
        />
      </Wrap>
    </>
  )
}
