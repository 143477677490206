import { useEffect, useMemo, useState } from 'react'

import { useAccount } from 'wagmi'
import { BigNumber } from 'ethers'

import { Token, DocStatus } from 'types/entities'

import { useChainData, useDonateEstimation, useGasFetcher } from 'hooks/app'

import { useBannedAddresses, useDocStatus } from 'state/user/hooks'

import { fromWei, isEther, toWei } from 'utils/crypto'

import { errors } from 'constants/errors'
import { tokens } from 'constants/tokens'
import { crypto } from 'constants/variables'
import { ETH_ADDRESS } from 'constants/addresses'
import { Recipients, appConstants } from 'constants/app'

import { Icon, Select, SelectOptions } from 'components/ui'
import { DonationButton, TokenSelect, AmountInput } from 'components/features/index'

import { Wrap, Title, TokenWrap, AlertWrapper, AlertIconWrap, AlertMessage, RecipientsWrap } from './Style'

export function CryptoPayment() {
  const { address } = useAccount()
  const { chainId } = useChainData()

  const [amount, setAmount] = useState('0.001')
  const [recipient, setRecipient] = useState(Recipients.DEFENDS)
  const [token, setToken] = useState(tokens[chainId][ETH_ADDRESS])

  const gasCost = useGasFetcher()
  const docStatus = useDocStatus()
  const bannedAddresses = useBannedAddresses()

  const {
    balances,
    assetBalances,
    approximateCost,
    isBalanceEnough,
    isBalancesLoading,
    isTokenBalanceEnough,
    isLoading: isDonateEstimationLoading,
  } = useDonateEstimation({
    amount,
    address,
    chainId,
    token: token.address,
    gasCost: gasCost.cost,
    decimals: token.decimals,
  })

  const _isEther = useMemo(() => isEther(token.address), [token])
  const donateAmount = useMemo(() => toWei(amount, token.decimals).toBigInt(), [amount, token])

  const _amount = useMemo(() => toWei(amount, token.decimals), [amount, token.decimals])
  const _isLoading = useMemo(() => isDonateEstimationLoading, [isDonateEstimationLoading])

  const isAddressBanned = useMemo(() => address && bannedAddresses.includes(address), [bannedAddresses, address])

  const isError = useMemo(() => !isBalanceEnough || !isTokenBalanceEnough, [isBalanceEnough, isTokenBalanceEnough])
  const isDocAccepted = useMemo(() => docStatus === DocStatus.ACCEPTED, [docStatus])
  const _isDisabled = useMemo(
    () => _amount.lte(crypto.BG_ZERO) || !isDocAccepted || isAddressBanned,
    [_amount, isDocAccepted, isAddressBanned],
  )

  const errorText = useMemo(() => {
    if (!isBalanceEnough) {
      return errors.validation.INSUFFICIENT_FUNDS
    }
    if (!isTokenBalanceEnough) {
      return errors.validation.INSUFFICIENT_TOKEN_FUNDS
    }
    return undefined
  }, [isBalanceEnough, isTokenBalanceEnough])

  useEffect(() => {
    const _token = Object.values(tokens[chainId]).find((_token) => _token.name === token.name)
    handleSelect(tokens[chainId][_token?.address || ETH_ADDRESS])
  }, [chainId])

  const handleSelectRecipient = (option: SelectOptions<Recipients> | null) => {
    if (option?.value) {
      setRecipient(option.value)
    }
  }

  const handleChange = (value: string) => {
    setAmount(value)
  }

  const handleSelect = (token: Token) => {
    setToken(token)
  }

  const getMaxEtherAmount = async () => {
    try {
      if (!assetBalances) {
        return crypto.BG_ZERO
      }

      const _maxAmount = BigNumber.from(assetBalances.bgBalance).sub(approximateCost)
      return _maxAmount.gt(crypto.BG_ZERO) ? _maxAmount : '0'
    } catch {
      return crypto.BG_ZERO
    }
  }

  const handleMaxAmount = async () => {
    try {
      if (_isEther) {
        const _maxAmount = await getMaxEtherAmount()
        handleChange(fromWei(_maxAmount))
      } else {
        handleChange(assetBalances?.balance || '0')
      }
    } catch {
      handleChange('0')
    }
  }

  const donationData = useMemo(() => {
    return {
      token,
      address,
      recipient,
      isBalancesLoading,
      amount: donateAmount.toString(),
    }
  }, [donateAmount, token, address, isBalancesLoading, recipient])

  return (
    <>
      {isAddressBanned && (
        <AlertWrapper>
          <AlertIconWrap>
            <Icon name='warning' />
          </AlertIconWrap>
          <AlertMessage>
            Apologies, the service is&nbsp;currently unavailable at&nbsp;this address {address}
          </AlertMessage>
        </AlertWrapper>
      )}

      <Wrap>
        <Title>Payment</Title>
        <RecipientsWrap>
          <Select<Recipients>
            value={recipient}
            label='Select defense fund'
            select={handleSelectRecipient}
            options={appConstants.RECIPIENTS_OPTIONS}
          />
        </RecipientsWrap>

        <TokenWrap>
          <TokenSelect token={token} onSelect={handleSelect} address={address} chainId={chainId} />
          <AmountInput
            token={token}
            amount={amount}
            data={balances}
            onChange={handleChange}
            onMaxAmount={handleMaxAmount}
            isLoading={isBalancesLoading}
          />
        </TokenWrap>
      </Wrap>

      <DonationButton
        text='Donate'
        isError={isError}
        errorText={errorText}
        isLoading={_isLoading}
        donation={donationData}
        isDisabled={_isDisabled}
      />
    </>
  )
}
