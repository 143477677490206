import { Links } from 'constants/link'

import { Icon } from 'components/ui'
import { StyledLink, IconWrapper, LogoSpan, Logo } from './Style'

const LogoLink = () => (
  <StyledLink to={Links.MAIN} aria-label='Logo link'>
    <IconWrapper>
      <LogoSpan role='none'>Justice DAO logo</LogoSpan>
      <Logo>
        <Icon name='logo' size='fill' />
      </Logo>
    </IconWrapper>
  </StyledLink>
)

export { LogoLink }
