import { useMemo } from 'react'

import { useChainId } from 'wagmi'

import { DONATIONS } from 'constants/addresses'
import { CHAIN_INFO, RPC_LIST } from 'constants/chains'

export function useChainData() {
  const chainId = useChainId()

  const _chainId = useMemo(() => chainId as SupportedChainId, [chainId])

  return useGetChainData(_chainId)
}

export function useGetChainData(chainId: SupportedChainId) {
  const rpcUrl = useMemo(() => RPC_LIST[chainId], [chainId])
  const symbol = useMemo(() => CHAIN_INFO[chainId].symbol, [chainId])
  const donatesContractAddress = useMemo(() => DONATIONS[chainId], [chainId])
  const blockDuration = useMemo(() => CHAIN_INFO[chainId].blockDuration, [chainId])

  return {
    symbol,
    rpcUrl,
    chainId,
    blockDuration,
    donatesContractAddress,
  }
}
