// Generated by @wagmi/cli@1.2.0 on 10/24/2023 at 6:16:37 PM
import {
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
  useNetwork,
  Address,
} from 'wagmi'
import { ReadContractResult, WriteContractMode, PrepareWriteContractResult } from 'wagmi/actions'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// erc20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20ABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'name_', internalType: 'string', type: 'string' },
      { name: 'symbol_', internalType: 'string', type: 'string' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address' },
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// usdt
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const usdtABI = [
  {
    constant: false,
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_spender', type: 'address' },
      { name: '_value', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Donations
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export const donationsABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address', indexed: true },
      { name: 'name', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'AddRecipient',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'token', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'recipient', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'Donation',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'token', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Refund',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address', indexed: true },
      { name: 'name', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'RemoveRecipient',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address', indexed: true },
      { name: 'name', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'UpdateRecipient',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'token', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Withdrawal',
  },
  { stateMutability: 'payable', type: 'fallback' },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: '_token', internalType: 'contract IERC20', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
      { name: '_name', internalType: 'bytes32', type: 'bytes32' },
      { name: '_signature', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'donate',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_signer', internalType: 'address', type: 'address' },
      { name: '_newOwner', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    name: 'recipients',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_token', internalType: 'contract IERC20', type: 'address' },
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'refund',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_name', internalType: 'bytes32', type: 'bytes32' }],
    name: 'removeRecipient',
    outputs: [],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'renounceOwnership', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_recipient', internalType: 'address', type: 'address' },
      { name: '_name', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'setRecipient',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_signer', internalType: 'address', type: 'address' }],
    name: 'setSigner',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'signer',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_recipient', internalType: 'address', type: 'address' },
      { name: '_name', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'updateRecipient',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_recipients', internalType: 'address[]', type: 'address[]' },
      { name: '_names', internalType: 'bytes32[]', type: 'bytes32[]' },
    ],
    name: 'updateRecipients',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_token', internalType: 'contract IERC20', type: 'address' },
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdraw',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export const donationsAddress = {
  1: '0x22ab20Fc4bf9C14ee8697C51dC54fD27f61105fa',
  5: '0x22ab20Fc4bf9C14ee8697C51dC54fD27f61105fa',
} as const

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export const donationsConfig = { address: donationsAddress, abi: donationsABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(config: Omit<UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>, 'abi'> = {} as any) {
  return useContractRead({ abi: erc20ABI, ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"allowance"`.
 */
export function useErc20Allowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>, 'abi' | 'functionName'> = {} as any,
) {
  return useContractRead({ abi: erc20ABI, functionName: 'allowance', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useErc20BalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>, 'abi' | 'functionName'> = {} as any,
) {
  return useContractRead({ abi: erc20ABI, functionName: 'balanceOf', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decimals"`.
 */
export function useErc20Decimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>, 'abi' | 'functionName'> = {} as any,
) {
  return useContractRead({ abi: erc20ABI, functionName: 'decimals', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"name"`.
 */
export function useErc20Name<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>, 'abi' | 'functionName'> = {} as any,
) {
  return useContractRead({ abi: erc20ABI, functionName: 'name', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"symbol"`.
 */
export function useErc20Symbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>, 'abi' | 'functionName'> = {} as any,
) {
  return useContractRead({ abi: erc20ABI, functionName: 'symbol', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useErc20TotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>,
>(
  config: Omit<UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>, 'abi' | 'functionName'> = {} as any,
) {
  return useContractRead({ abi: erc20ABI, functionName: 'totalSupply', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Write<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof erc20ABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, TFunctionName, TMode>({ abi: erc20ABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"approve"`.
 */
export function useErc20Approve<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof erc20ABI, 'approve', TMode> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, 'approve', TMode>({
    abi: erc20ABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useErc20DecreaseAllowance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'decreaseAllowance'>['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof erc20ABI, 'decreaseAllowance', TMode> & {
        abi?: never
        functionName?: 'decreaseAllowance'
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, 'decreaseAllowance', TMode>({
    abi: erc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useErc20IncreaseAllowance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'increaseAllowance'>['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof erc20ABI, 'increaseAllowance', TMode> & {
        abi?: never
        functionName?: 'increaseAllowance'
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, 'increaseAllowance', TMode>({
    abi: erc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function useErc20Transfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof erc20ABI, 'transfer', TMode> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, 'transfer', TMode>({
    abi: erc20ABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useErc20TransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof erc20ABI, 'transferFrom', TMode> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any,
) {
  return useContractWrite<typeof erc20ABI, 'transferFrom', TMode>({
    abi: erc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function usePrepareErc20Write<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, TFunctionName>, 'abi'> = {} as any,
) {
  return usePrepareContractWrite({ abi: erc20ABI, ...config } as UsePrepareContractWriteConfig<
    typeof erc20ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareErc20Approve(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, 'approve'>, 'abi' | 'functionName'> = {} as any,
) {
  return usePrepareContractWrite({ abi: erc20ABI, functionName: 'approve', ...config } as UsePrepareContractWriteConfig<
    typeof erc20ABI,
    'approve'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareErc20DecreaseAllowance(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, 'decreaseAllowance'>, 'abi' | 'functionName'> = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareErc20IncreaseAllowance(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, 'increaseAllowance'>, 'abi' | 'functionName'> = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareErc20Transfer(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, 'transfer'>, 'abi' | 'functionName'> = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareErc20TransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, 'transferFrom'>, 'abi' | 'functionName'> = {} as any,
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link usdtABI}__.
 */
export function useUsdtWrite<TFunctionName extends string, TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<PrepareWriteContractResult<typeof usdtABI, string>['request']['abi'], TFunctionName, TMode>
    : UseContractWriteConfig<typeof usdtABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<typeof usdtABI, TFunctionName, TMode>({ abi: usdtABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link usdtABI}__ and `functionName` set to `"approve"`.
 */
export function useUsdtApprove<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof usdtABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof usdtABI, 'approve', TMode> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any,
) {
  return useContractWrite<typeof usdtABI, 'approve', TMode>({ abi: usdtABI, functionName: 'approve', ...config } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link usdtABI}__.
 */
export function usePrepareUsdtWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof usdtABI, TFunctionName>, 'abi'> = {} as any,
) {
  return usePrepareContractWrite({ abi: usdtABI, ...config } as UsePrepareContractWriteConfig<
    typeof usdtABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link usdtABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareUsdtApprove(
  config: Omit<UsePrepareContractWriteConfig<typeof usdtABI, 'approve'>, 'abi' | 'functionName'> = {} as any,
) {
  return usePrepareContractWrite({ abi: usdtABI, functionName: 'approve', ...config } as UsePrepareContractWriteConfig<
    typeof usdtABI,
    'approve'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link donationsABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof donationsABI, TFunctionName>,
>(
  config: Omit<UseContractReadConfig<typeof donationsABI, TFunctionName, TSelectData>, 'abi' | 'address'> & {
    chainId?: keyof typeof donationsAddress
  } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    ...config,
  } as UseContractReadConfig<typeof donationsABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"owner"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof donationsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof donationsABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof donationsAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof donationsABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"recipients"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsRecipients<
  TFunctionName extends 'recipients',
  TSelectData = ReadContractResult<typeof donationsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof donationsABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof donationsAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'recipients',
    ...config,
  } as UseContractReadConfig<typeof donationsABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"signer"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsSigner<
  TFunctionName extends 'signer',
  TSelectData = ReadContractResult<typeof donationsABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof donationsABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof donationsAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractRead({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'signer',
    ...config,
  } as UseContractReadConfig<typeof donationsABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof donationsABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, TFunctionName, TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"donate"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsDonate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'donate'>['request']['abi'],
        'donate',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'donate' }
    : UseContractWriteConfig<typeof donationsABI, 'donate', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'donate'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'donate', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'donate',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"initialize"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsInitialize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<typeof donationsABI, 'initialize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'initialize', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"refund"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsRefund<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'refund'>['request']['abi'],
        'refund',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'refund' }
    : UseContractWriteConfig<typeof donationsABI, 'refund', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'refund'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'refund', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'refund',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"removeRecipient"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsRemoveRecipient<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'removeRecipient'>['request']['abi'],
        'removeRecipient',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'removeRecipient' }
    : UseContractWriteConfig<typeof donationsABI, 'removeRecipient', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'removeRecipient'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'removeRecipient', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'removeRecipient',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsRenounceOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'renounceOwnership'>['request']['abi'],
        'renounceOwnership',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<typeof donationsABI, 'renounceOwnership', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'renounceOwnership', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"setRecipient"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsSetRecipient<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'setRecipient'>['request']['abi'],
        'setRecipient',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setRecipient' }
    : UseContractWriteConfig<typeof donationsABI, 'setRecipient', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setRecipient'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'setRecipient', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'setRecipient',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"setSigner"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsSetSigner<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'setSigner'>['request']['abi'],
        'setSigner',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setSigner' }
    : UseContractWriteConfig<typeof donationsABI, 'setSigner', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setSigner'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'setSigner', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'setSigner',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsTransferOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'transferOwnership'>['request']['abi'],
        'transferOwnership',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transferOwnership' }
    : UseContractWriteConfig<typeof donationsABI, 'transferOwnership', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferOwnership'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'transferOwnership', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"updateRecipient"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsUpdateRecipient<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'updateRecipient'>['request']['abi'],
        'updateRecipient',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'updateRecipient' }
    : UseContractWriteConfig<typeof donationsABI, 'updateRecipient', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'updateRecipient'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'updateRecipient', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'updateRecipient',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"updateRecipients"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsUpdateRecipients<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'updateRecipients'>['request']['abi'],
        'updateRecipients',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'updateRecipients' }
    : UseContractWriteConfig<typeof donationsABI, 'updateRecipients', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'updateRecipients'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'updateRecipients', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'updateRecipients',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"withdraw"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function useDonationsWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof donationsAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof donationsABI, 'withdraw'>['request']['abi'],
        'withdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof donationsABI, 'withdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdraw'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return useContractWrite<typeof donationsABI, 'withdraw', TMode>({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'withdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof donationsABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof donationsAddress
  } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"donate"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsDonate(
  config: Omit<UsePrepareContractWriteConfig<typeof donationsABI, 'donate'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof donationsAddress
  } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'donate',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'donate'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"initialize"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsInitialize(
  config: Omit<UsePrepareContractWriteConfig<typeof donationsABI, 'initialize'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof donationsAddress
  } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"refund"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsRefund(
  config: Omit<UsePrepareContractWriteConfig<typeof donationsABI, 'refund'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof donationsAddress
  } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'refund',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'refund'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"removeRecipient"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsRemoveRecipient(
  config: Omit<
    UsePrepareContractWriteConfig<typeof donationsABI, 'removeRecipient'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof donationsAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'removeRecipient',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'removeRecipient'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof donationsABI, 'renounceOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof donationsAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'renounceOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"setRecipient"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsSetRecipient(
  config: Omit<
    UsePrepareContractWriteConfig<typeof donationsABI, 'setRecipient'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof donationsAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'setRecipient',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'setRecipient'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"setSigner"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsSetSigner(
  config: Omit<UsePrepareContractWriteConfig<typeof donationsABI, 'setSigner'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof donationsAddress
  } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'setSigner',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'setSigner'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof donationsABI, 'transferOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof donationsAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'transferOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"updateRecipient"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsUpdateRecipient(
  config: Omit<
    UsePrepareContractWriteConfig<typeof donationsABI, 'updateRecipient'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof donationsAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'updateRecipient',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'updateRecipient'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"updateRecipients"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsUpdateRecipients(
  config: Omit<
    UsePrepareContractWriteConfig<typeof donationsABI, 'updateRecipients'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof donationsAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'updateRecipients',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'updateRecipients'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link donationsABI}__ and `functionName` set to `"withdraw"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0x22ab20fc4bf9c14ee8697c51dc54fd27f61105fa)
 */
export function usePrepareDonationsWithdraw(
  config: Omit<UsePrepareContractWriteConfig<typeof donationsABI, 'withdraw'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof donationsAddress
  } = {} as any,
) {
  const { chain } = useNetwork()
  const chainId = config.chainId ?? chain?.id
  return usePrepareContractWrite({
    abi: donationsABI,
    address: donationsAddress[chainId as keyof typeof donationsAddress],
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof donationsABI, 'withdraw'>)
}
