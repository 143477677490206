import { Kyc } from 'types/entities'

import { CellProps, Badge } from 'components/ui'

import { Wrapper, Title, Value } from '../Style'

export function Status({ row }: CellProps<Kyc>) {
  const { status } = row.original

  return (
    <Wrapper area='Status'>
      <Title>Status</Title>
      <Value>
        <Badge value={status} />
      </Value>
    </Wrapper>
  )
}
