import { createModal } from 'modal'
import { ModalName } from 'modal/@types'

import { Button } from 'components/ui'

import { Content, ButtonWrap } from './Style'

type Props = {
  action: VoidFunction
  onCurrentClose?: VoidFunction
}

function ConfirmModal({ onCurrentClose, action }: Props) {
  const handleCancel = () => {
    onCurrentClose?.()
  }

  const handleAccept = () => {
    action()
    onCurrentClose?.()
  }

  return (
    <Content>
      <ButtonWrap>
        <Button size='large' fullSize onClick={handleAccept}>
          Yes
        </Button>
        <Button type='outline' size='large' fullSize onClick={handleCancel}>
          Cancel
        </Button>
      </ButtonWrap>
    </Content>
  )
}

export const newConfirmModal = (props: Props) =>
  createModal({
    name: ModalName.CONFIRM_MODAL,
    title: 'Are you sure?',
    align: 'center',
    container: <ConfirmModal {...props} />,
  })
