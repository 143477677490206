import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useEmailFieldData, usePasswordFieldData } from 'hooks/form'

import { useIsAuth, useSignInRequest } from 'state/auth/hooks'

import { useCallNotification } from 'notifications/hooks'
import { NotificationTypes } from 'notifications/service'

import { Links } from 'constants/link'
import { notifications } from 'constants/notifications'

import { Button, CustomLink, Input } from 'components/ui'
import { AsideBanner, TermsOfService, PasswordField } from 'components/features'

import { Container, Content, Title, PreTitle, Form, InputWrap, ErrorWrap, ErrorContent } from './Style'

export function SignIn() {
  const isAuth = useIsAuth()
  const email = useEmailFieldData()
  const password = usePasswordFieldData(false)

  const callNotification = useCallNotification()

  const navigate = useNavigate()
  const { onSignIn, ...request } = useSignInRequest()

  const [isChecked, setChecked] = useState(false)

  useEffect(() => {
    if (isAuth) {
      navigate(Links.MAIN)
    }
  }, [])

  const isValue = useMemo(() => Boolean(email.value) && Boolean(password.value), [email.value, password.value])
  const isError = useMemo(() => email.isError || password.isError, [email.isError, password.isError])
  const isDisabled = useMemo(() => isError || !isValue || !isChecked, [isValue, isError, isChecked])

  const onHandleSignIn = async (e: ChangeEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      if (isDisabled) {
        return
      }
      await onSignIn(email.value, password.value)
      callNotification({ type: NotificationTypes.SUCCESS, message: notifications.successful.signIn })

      navigate(Links.MAIN, { replace: true })
    } catch (err) {
      callNotification({ type: NotificationTypes.ERROR, message: notifications.errors.signIn(err.message) })
    }
  }

  const onCheckChange = (_checked: boolean) => {
    setChecked(_checked)
  }

  const error = useMemo(() => request.error, [request.error])
  const isRequestError = useMemo(() => request.isError, [request.isError])

  const isLoading = useMemo(() => request.isLoading, [request.isLoading])

  return (
    <Container>
      <Content>
        <Title>Sign in to your account</Title>

        <Form onSubmit={onHandleSignIn}>
          <InputWrap>
            <Input
              type='email'
              name='email'
              label='E-mail'
              value={email.value}
              error={email.isError}
              autoComplete='username'
              onChange={email.onChange}
              errorMessage={email.errorMessage}
            />

            <PasswordField
              label='Password'
              name='current-password'
              value={password.value}
              isError={password.isError}
              onChange={password.onChange}
              autoComplete='current-password'
            />
          </InputWrap>

          <TermsOfService isChecked={isChecked} onCheckChange={onCheckChange} />
          <Button submit fullSize size='large' isLoading={isLoading} disabled={isDisabled}>
            Login
          </Button>
          <PreTitle>
            Don&rsquo;t have an&nbsp;account? <CustomLink url={Links.SIGN_UP} title='Sign up' />
          </PreTitle>

          <ErrorWrap>{isRequestError && error && <ErrorContent>{error}</ErrorContent>}</ErrorWrap>
        </Form>
      </Content>

      <AsideBanner />
    </Container>
  )
}
