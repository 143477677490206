import { useCallback, useEffect, useMemo, useState } from 'react'

import { Keyof, ValueOf } from 'types/default'
import { DocStatus, KycStatus, KycType, Role, UserData, VerificationSteps, Hash } from 'types/entities'

import { AppState } from 'state'
import { useIsAuth } from 'state/auth/hooks'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { getApiError } from 'utils/helpers'

import { Settings, User, UserTransaction } from './types'
import { useGetAllUsersQuery, useGetUserQuery } from './api'
import {
  setUser,
  setSetting,
  setTransaction,
  userClearState,
  setBannedAddresses,
  setVerificationStep,
  setPendingTransaction,
} from './slice'

function useGetKycInitialStep() {
  const verificationStep = useVerificationStep()
  const setVerificationStep = useSetVerificationStep()

  return useCallback(
    (user: UserData) => {
      if (!user.docs && user.kyc.name) {
        return setVerificationStep(VerificationSteps.DOC)
      }

      if (user.kyc.status === KycStatus.DECLINED) {
        return setVerificationStep(VerificationSteps.STATUS)
      }

      if (user.kyc.status === KycStatus.COMPLETED) {
        if (!user.docs?.link || user.docs?.status === DocStatus.DECLINED) {
          return setVerificationStep(VerificationSteps.DOC)
        } else {
          return setVerificationStep(VerificationSteps.COMPLETE)
        }
      }

      if (user.kyc.status === KycStatus.STARTED) {
        return setVerificationStep(VerificationSteps.KYC)
      }

      if (user.kyc.status === KycStatus.PENDING) {
        if (!user.docs?.link || user.docs?.status === DocStatus.DECLINED) {
          return setVerificationStep(VerificationSteps.DOC)
        } else {
          return setVerificationStep(VerificationSteps.STATUS)
        }
      }
    },
    [verificationStep, setVerificationStep],
  )
}

// function useGetExternalInitialStep() {
//   const verificationStep = useVerificationStep()
//   const setVerificationStep = useSetVerificationStep()
//
//   return useCallback(
//     (user: UserData) => {
//       if (user.kyc.status === KycStatus.STARTED) {
//         return setVerificationStep(VerificationSteps.KYC)
//       }
//
//       if (user.kyc.status === KycStatus.COMPLETED) {
//         if (!user.docs?.link || user.docs?.status === DocStatus.DECLINED) {
//           return setVerificationStep(VerificationSteps.DOC)
//         } else {
//           return setVerificationStep(VerificationSteps.COMPLETE)
//         }
//       }
//
//       return setVerificationStep(VerificationSteps.STATUS)
//     },
//     [verificationStep, setVerificationStep],
//   )
// }
function useGetInitVerificationStep() {
  const verificationStep = useVerificationStep()
  const setVerificationStep = useSetVerificationStep()

  const getKycInitialStep = useGetKycInitialStep()
  // const getExternalKycInitialStep = useGetExternalInitialStep()

  return useCallback(
    (user: UserData) => {
      if (user.kyc.controlType === KycType.NOT_SELECT) {
        return setVerificationStep(VerificationSteps.INIT)
      }

      if (user.kyc.status === KycStatus.NOT_STARTED) {
        return setVerificationStep(VerificationSteps.KYC)
      }

      return getKycInitialStep(user)
    },
    [verificationStep, setVerificationStep, getKycInitialStep],
  )
}

const MAX_ATTEMPTS = 3
// action creator
export function useUpdateUser() {
  const isAuth = useIsAuth()
  const [isError, setError] = useState(false)
  const [errorCounter, setErrorCounter] = useState(0)

  const request = useGetUserQuery(false, { skip: isError || !isAuth })
  const getInitVerificationStep = useGetInitVerificationStep()

  const setUser = useSetUser()

  useEffect(() => {
    if (request.error) {
      errorHandler()
    }
  }, [request.isSuccess, request.data, request.error])

  const successHandler = (userData?: UserData) => {
    setUser(userData || null)
    if (userData) {
      getInitVerificationStep(userData)
    }
  }

  const errorHandler = () => {
    if (errorCounter === MAX_ATTEMPTS) {
      setError(true)
      setUser(null)
    } else {
      setErrorCounter((prev) => prev + 1)
    }
  }

  const onFetch = async () => {
    try {
      setError(false)

      const { data } = await request.refetch()
      successHandler(data)
    } catch {
      setError(true)
    }
  }

  return {
    ...request,
    refetch: onFetch,
    error: getApiError(request.error),
    isLoading: request.isLoading || request.isFetching,
  }
}

const POOLING_INTERVAL = 30_000
export function useGetAllUsersRequest(shouldPooling: boolean, pollingInterval = POOLING_INTERVAL) {
  const request = useGetAllUsersQuery(shouldPooling, {
    skip: !shouldPooling,
    pollingInterval,
  })

  const users = useMemo(() => {
    if (!request.data) {
      return request.data
    }
    return request.data.filter((user) => user.roleName === Role.USER)
  }, [request.data])

  return { ...request, data: users, error: getApiError(request.error) }
}

// handlers
export function useIsAdmin() {
  const role = useRole()

  return useMemo(() => role && role === Role.ADMIN, [role])
}

export function useIsManager() {
  const role = useRole()

  return useMemo(() => role && role === Role.MANAGER, [role])
}

export function useKycType() {
  const user = useUser()
  return useMemo(() => (user?.kyc ? user.kyc.controlType : KycType.NOT_SELECT), [user?.kyc])
}

export function useKycStatus() {
  const user = useUser()
  return useMemo(() => (user?.kyc ? user.kyc.status : KycStatus.NOT_STARTED), [user?.kyc])
}

export function useDocStatus() {
  const user = useUser()

  return useMemo(() => user?.docs?.status || DocStatus.DECLINED, [user?.docs])
}

export function useShouldEmailVerify() {
  const user = useUser()
  return useMemo(() => user?.email && !user.isVerifiedEmail && user?.roleName !== Role.ADMIN, [user])
}

export function useIsUsa() {
  const usaFlags = ['united states', 'usa']

  const user = useUser()
  return useMemo(() => {
    const location = user?.kyc?.location
    if (!location) {
      return false
    }
    return usaFlags.some((flag) => location.toLowerCase().includes(flag))
  }, [user?.kyc?.location])
}

// actions
export function useSetUser() {
  const dispatch = useAppDispatch()
  return useCallback(
    (user: User) => {
      dispatch(setUser(user))
    },
    [dispatch],
  )
}

export function useSetVerificationStep() {
  const dispatch = useAppDispatch()
  return useCallback(
    (step: VerificationSteps) => {
      dispatch(setVerificationStep(step))
    },
    [dispatch],
  )
}

export function useSetBannedAddresses() {
  const dispatch = useAppDispatch()
  return useCallback(
    (address: Hash) => {
      dispatch(setBannedAddresses(address))
    },
    [dispatch],
  )
}

export function useSetSetting() {
  const dispatch = useAppDispatch()
  return useCallback(
    (key: Keyof<Settings>, value: ValueOf<Settings>) => {
      dispatch(setSetting({ key, value }))
    },
    [dispatch],
  )
}

export function useSetTransaction() {
  const dispatch = useAppDispatch()
  return useCallback(
    (transaction: UserTransaction) => {
      dispatch(setTransaction(transaction))
    },
    [dispatch],
  )
}

export function useSetPendingTransaction() {
  const dispatch = useAppDispatch()
  return useCallback(
    (transaction: UserTransaction) => {
      dispatch(setPendingTransaction(transaction))
    },
    [dispatch],
  )
}

export function useClearState() {
  const dispatch = useAppDispatch()
  return useCallback(() => {
    dispatch(userClearState())
  }, [dispatch])
}

// selectors
export function useUser() {
  return useAppSelector((state: AppState) => state.user.data)
}

export function useSettings() {
  return useAppSelector((state: AppState) => state.user.settings)
}

export function useRole() {
  return useAppSelector((state: AppState) => state.user.data?.roleName)
}

export function useBannedAddresses() {
  return useAppSelector((state: AppState) => state.user.bannedAddresses)
}

export function usePendingTransactions() {
  return useAppSelector((state: AppState) => state.user.pendingTransactions)
}

export function useTransactions() {
  return useAppSelector((state: AppState) => state.user.transactions)
}

export function useVerificationStep() {
  return useAppSelector((state: AppState) => state.user.verificationStep)
}
