import { SkeletonElement } from 'components/ui'

import { IInputGroup, IOption } from './@types'

import { SwitchButton } from './SwitchButton'

import { Wrap, Slider, LoadingWrap } from './Style'

function SwitchButtonGroup<T = string>({
  checked,
  options,
  onChange,
  isLoading,
  isDisabled,
  typeSwitch = 'default',
  sizeSwitch = 'default',
  direction = 'horizontal',
}: IInputGroup<T>) {
  function renderOptions() {
    return options.map(({ value, label, name, disabled }: IOption<T>) => {
      const shortenedOptionLabel = label.replace(/\s+/g, '')
      const optionId = `radio-option-${shortenedOptionLabel}`

      const handleChange = () => {
        onChange(value)
      }

      return isLoading ? (
        <LoadingWrap key={optionId}>
          <SkeletonElement width='6rem' height='1.6rem' borderRadius='0.6rem' />
        </LoadingWrap>
      ) : (
        <SwitchButton
          name={name}
          value={label}
          label={label}
          id={optionId}
          key={optionId}
          onChange={handleChange}
          typeSwitch={typeSwitch}
          sizeSwitch={sizeSwitch}
          quantity={options.length}
          checked={value === checked}
          disabled={disabled || isDisabled}
        />
      )
    })
  }

  return (
    <Wrap typeSwitch={typeSwitch} sizeSwitch={sizeSwitch} direction={direction}>
      {renderOptions()}
      <Slider typeSwitch={typeSwitch} quantity={options.length} />
    </Wrap>
  )
}
export { SwitchButtonGroup }
